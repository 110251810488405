import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FoodContext } from "../../../../../../contexts/Food";
import { getParsedProperties, validationGroup, autoPropertySelect } from "../helpers";
import "./itemModal.css";
import PropertyGroup from "./PropertyGroup";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const ItemModal = ({
  closeModal,
  showModal,
  itemId,
  itemData,
  addToCart,
  buttonDisabled,
  menuWisePrice,
  menuId
}) => {
  // PACKAGES SETTINGS OBJECT
  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };
  console.log(itemData);
  // context
  const { propertyGroupForSearch: propertyGroups } = useContext(FoodContext);

  // states
  const [properties, setProperties] = useState();
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(null);
  const [variationsSelected, setVariationsSelected] = useState(false);
  const [pickedProperties, setPickedProperties] = useState({});
  const [pickedVariation, setPickedVariation] = useState({});
  //validation
  const setPrpQty = () => {
    if(itemData && itemData.item.has_property === "1" && itemData.item.property_groups?.length){
      return {
        ...itemData.item.property_groups.reduce((acc, item) => {
          acc[item] = 0;
          return acc;
        }, {})
      }
    } else{
      return {};
    }
  };
  const prpValidation = setPrpQty();
  const [tempQty, setTempQty] = useState(prpValidation);
  const [selectGroup, setSelectGroup] = useState({});

  // useeffects
  useEffect(() => {
    // reset modal state
    setProperties(null);
    setSelectedPropertyGroup(null);
    setVariationsSelected(false);
    setPickedProperties({});
    setPickedVariation({});
    setSelectGroup({});
    setTempQty(prpValidation)
    if (!itemData || !showModal) return;
    if (itemData.item.has_variation === "1") {
      setVariationsSelected(true);
      if (itemData.hasOwnProperty("variation")) {
        setPickedVariation({
          [itemData.variation.food_with_variation_id]: itemData.variation
        });
      } else {
        itemData.item.variations && itemData.item.variations[0] && setPickedVariation({
          [itemData.item.variations[0].food_with_variation_id]:
            itemData.item.variations[0],
        });
      }
    }
    if (itemData.item.has_property === "1" && itemData.item.properties && itemData.item.properties.length) {
      const parsedProperties = getParsedProperties(itemData, propertyGroups, menuWisePrice, menuId);
      setProperties(parsedProperties);
      if (itemData.item.has_variation !== "1") {
        setSelectedPropertyGroup(itemData.item?.property_groups[0]);
        const autoPrp = autoPropertySelect(parsedProperties,itemData.item?.property_groups[0],pickedProperties,itemData.item?.property_groups_validation);
        if(autoPrp){
          validationGroup(
            autoPrp,
            itemData.item?.property_groups[0],
            itemData.item.property_groups_validation,
            setTempQty,
          );
          setPickedProperties(autoPrp);
        }
        if(itemData.item?.property_groups_validation){
          const selectGroup = itemData.item.property_groups_validation[itemData.item?.property_groups[0]]
          setSelectGroup(selectGroup);
        }
      }
      if (
        itemData.hasOwnProperty("properties") &&
        itemData.properties
      ) {
        itemData.item.property_groups.forEach((id)=>{
          validationGroup(
            itemData.properties,
            id,
            itemData.item.property_groups_validation,
            setTempQty,
          );

        })
        setPickedProperties(itemData.properties);
      }
    }
  }, [itemData, showModal, propertyGroups]);

  // helpers
  const handleSelectVariations = () => {
    setSelectedPropertyGroup(null);
    setVariationsSelected(true);
    setSelectGroup({});
  };
 
  const handleSelectPropertyGroup = (id) => {
    if (selectedPropertyGroup && itemData.item.property_groups_validation[selectedPropertyGroup]?.required && tempQty[selectedPropertyGroup] === 0) {
      toast.error("You have to pick one this is required!");
    } else {
      const autoPrp = autoPropertySelect(properties,id,pickedProperties,itemData.item?.property_groups_validation);
      if(autoPrp){
        validationGroup(
          autoPrp,
          id,
          itemData.item.property_groups_validation,
          setTempQty,
        );
        setPickedProperties(autoPrp);
      }
      setSelectedPropertyGroup(id);
      const selectGroup = itemData.item.property_groups_validation[id]
      setSelectGroup(selectGroup);
      setVariationsSelected(false);
    }
  };
  //select prop item
  const handleSelectSubItem = (item, qty=null) => {
    if (variationsSelected) {
      if(itemData.item?.property_groups_validation && Object.values(itemData.item.property_groups_validation)[0]?.is_variation){
        setPickedProperties({});
      }
      setPickedVariation({ [item.food_with_variation_id]: item });
    } else {
      let newData;
    
      if (pickedProperties[selectedPropertyGroup]) {
        if (
          (pickedProperties[selectedPropertyGroup][item.id] && !qty)
        ) {
          newData = { ...pickedProperties };
          delete newData[selectedPropertyGroup][item.id];
          if (!Object.keys(newData[selectedPropertyGroup]).length)
            delete newData[selectedPropertyGroup];
        } else {
          newData = {
            ...pickedProperties,
            [selectedPropertyGroup]: {
              ...pickedProperties[selectedPropertyGroup],
              [item.id]: {
                item,
                quantity: qty ? qty : 1,
              },
            },
          };
        }
      } else {
        newData = {
          ...pickedProperties,
          [selectedPropertyGroup]: {
            [item.id]: {
              item,
              quantity: qty ? qty : 1,
            },
          },
        };
      }
      validationGroup(
        newData,
        selectedPropertyGroup,
        itemData.item.property_groups_validation,
        setTempQty,
      );
      setPickedProperties(newData);
    }
  };

  //submit validation
  const validationSubmit = (
    data,
  ) =>{
      let checkValid = true;
      let groupName = "";
      const piked = data.item?.property_groups_validation;
      const isValid = [];
      // check property if required
      for (const key in piked) {
        // const picked = [];
        const items = piked[key];
        const selectedVariartion = pickedVariation && parseInt(Object.values(pickedVariation)[0]?.variation_id);
        const variationPrpValidation = items?.is_variation ? JSON.parse(items?.variation_id).includes(selectedVariartion) : true;
        if (items.required === 1 && variationPrpValidation) {
          // let qty = 0;
          const picked = pickedProperties?.[key] ? Object.values(pickedProperties?.[key]) : [];
          const qty = picked.reduce((acc, itm) => acc + (itm?.quantity || 1), 0);
          
          if(items.multi_select === "multiple"){
            if(items.limit > 0 && qty !== items.limit){
              groupName += `You have to pick atleast ${items.limit} item from ${properties && properties[key]?.details.name}.\n`;
              checkValid = false;
              isValid.push(parseInt(key));
            }else if(items.limit == 0 && qty <= 0){
              groupName += `You have to pick atleast one item from ${properties && properties[key]?.details.name}.\n`;
              checkValid = false;
              isValid.push(parseInt(key));
            }
          }else{
            if(qty <= 0){
              groupName += `You have to pick atleast one item from ${properties && properties[key]?.details.name}.\n`;
              checkValid = false;
              isValid.push(parseInt(key));
            }
          }
        }
      }
      return {checkValid, groupName,isValid}
  }

  const handleSubmit = () => {
    const data = {
      ...itemData,
      item: {
        ...itemData.item,
        price:
        itemData.item.has_variation === "1"
          ? Object.values(pickedVariation)[0].food_with_variation_price
          : itemData.item.price,
      },
      variation:
        itemData.item.has_variation === "1" ? Object.values(pickedVariation)[0] : null,
      properties:
        itemData.item.has_property === "1" &&
        Object.keys(pickedProperties).length ?
        pickedProperties : [],
    };
    const edit = Boolean(itemData.properties || itemData.variation);

    const {checkValid, groupName, isValid } = validationSubmit(data);

    if(checkValid === false || (groupName?.length > 0 && isValid?.length > 0)){
      if(
        //Object.keys(pickedProperties)?.length && 
        (data.item?.property_groups_validation !== null || data.item?.property_groups_validation !== undefined)){
        let prp_id = null;
        const itemGrp = data?.item?.property_groups_validation;
        const propertyGrp = data?.item?.property_groups;
        for(const group of propertyGrp){
          const validGrp = itemGrp[group];
          if(isValid.includes(group) && validGrp.required == 1){
            prp_id = parseInt(group);
            break
          }
        }
        const autoPrp = autoPropertySelect(properties,prp_id ? prp_id : propertyGrp && propertyGrp[0],pickedProperties,itemData.item?.property_groups_validation);
        if(autoPrp){
          validationGroup(
            autoPrp,
            prp_id ? prp_id : propertyGrp && propertyGrp[0],
            itemData.item.property_groups_validation,
            setTempQty,
          );
          setPickedProperties(autoPrp);
        }
        const selectGroup = itemData.item.property_groups_validation[prp_id]
        setSelectGroup(selectGroup)
        setSelectedPropertyGroup(prp_id ? prp_id : propertyGrp && propertyGrp[0]);
        setVariationsSelected(false);
      }
      toast.error(groupName, { whiteSpace: "pre-line" });

    }else{
      !buttonDisabled && addToCart(itemId, data, edit);
      setTempQty(prpValidation)
      setSelectGroup({});
      setPickedProperties({});
    }
  
    //closeModal();
  };
   
  if (showModal && itemData)
    return (
      <>
        <div className="d-flex align-items-center justify-content-center"></div>
        <Modal size="xl" show={showModal} onHide={() => closeModal()}>
          {" "}
          {/* modal dialog modal-lg */}
          <Modal.Body className="p-0 pt-0">
            <div className="row m-0">
             {
              itemData?.item &&(
              <div className="modal-item">
              <div className="modal-item-name">
               {itemData.item.name}
              </div>
              <button
              type="button"
              className="btn-close"
              onClick={()=>closeModal()}
            ></button>
              </div>
              )
             }
              {" "}
              {/* m-0 mh-inherit */}
              <div
                className="col-lg-2 col-sm-3 p-0 text-center modal-left-col" // mh-inherit
              >
                <div
                  className="list--menu-nav d-flex flex-column"
                  style={{ overflow: "auto" }}
                >
                  <ul className="list-group">
                    {/* variations */}
                    {itemData.item.has_variation === "1" &&
                     itemData.item.variations ? (
                      <li
                        className={`list-group-item modal-list-item my-1 py-2 green h6 border-0 pointer property-group ${
                          variationsSelected ? "property-group-selected" : ""
                        }`}
                        onClick={handleSelectVariations}
                      >
                        Variations
                      </li>
                    ) : null}
                    {/* properties */}
                    {itemData.item.has_property === "1" &&
                    properties &&
                    Object.keys(properties).length
                      ? itemData.item.property_groups
                      //Object.keys(properties)
                      // .sort((a, b) =>
                      //  // properties[a].details.sorting - properties[b].details.sorting 
                      //   //? 1 : -1
                      //  )
                      .map((propertyGroupId) => {
                        const selectedVariartion = pickedVariation && parseInt(Object.values(pickedVariation)[0]?.variation_id);
                        const prpValidation = itemData.item?.property_groups_validation[propertyGroupId];
                        const variationPrpValidation = prpValidation?.is_variation ? JSON.parse(prpValidation?.variation_id).includes(selectedVariartion) : true;
                        if(properties[propertyGroupId]?.details && variationPrpValidation) {
                          return(
                          <li
                          className={`list-group-item my-1 py-3 green h6 border-0 pointer property-group
                          ${
                            selectedPropertyGroup === propertyGroupId
                            ? "property-group-selected"
                            : ""
                          }`}
                          key={propertyGroupId}
                          onClick={() =>
                            handleSelectPropertyGroup(propertyGroupId)
                          }
                          >
                            {properties[propertyGroupId]?.details?.name}
                          </li>
                          )
                        }
                        })
                      : null}
                  </ul>
                </div>
              </div>
              <div className="col-lg-10 col-sm-9 modal-right-col">
                <div>
                  {selectedPropertyGroup && properties[selectedPropertyGroup] ? (
                    <PropertyGroup
                      name={properties[selectedPropertyGroup].details.name}
                      items={properties[selectedPropertyGroup].properties}
                      menuId={menuId}
                      nameKey="name"
                      priceKey="extra_price"
                      idKey="id"
                      setOnClick={handleSelectSubItem}
                      selected={
                        pickedProperties &&
                        pickedProperties[selectedPropertyGroup]
                      }
                      selectedPropertyGroup={selectedPropertyGroup}
                      showCounter={true}
                      tempQty={tempQty}
                      selectGroup={selectGroup}
                      isProperty
                    />
                  ) : variationsSelected ? (
                    <PropertyGroup
                      name="Variations"
                      items={itemData.item.variations}
                      menuId={menuId}
                      nameKey="variation_name"
                      priceKey="food_with_variation_price"
                      idKey="food_with_variation_id"
                      setOnClick={handleSelectSubItem}
                      selected={pickedVariation && pickedVariation}
                      showCounter={false}
                      tempQty={tempQty}
                      selectGroup={selectGroup}
                    />
                  ) : null}
                  <div className="row mx-2">

                    <div className="mb-3 row justify-content-end">
                      <div className="col-sm-4 col-lg-3 modal-btn-2 pr-0">
                        <button
                          type="button"
                          className="my-3 btn pos--btn btn-submit btn-lg btn-block bg-success"
                          onClick={()=>handleSubmit()}
                        >
                          Add To Cart
                        </button>
                      </div>
                      <div className="col-sm-4 col-lg-3 modal-btn pr-0">
                        <button
                          type="button"
                          className="m-2 my-3 btn pos--btn btn-cancel btn-lg btn-block"
                          onClick={()=>closeModal()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  else return null;
};

export default ItemModal;
