import React, { useEffect, useState, useContext, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import { _t, getCookie ,formatPrice,getSystemSettings,vatPercent, } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
//3rd party packages
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import KdsModal from "./KdsModal";

//importing context consumer here
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import {SettingsContext} from "../../../../contexts/Settings";

const KitchenDineIn = () => {
    const { t } = useTranslation();
    //getting context values here
    const {
      generalSettings,
    } = useContext(SettingsContext);

  const {
    //kitchen dashboard
    getKitchenNewOrders,
    branchForSearch,
    kithcenNewOrders,
    setKithcenNewOrders,
    kdsListForSearch,
    loading,
    setLoading,
  } = useContext(RestaurantContext);

  const {
    //food group
    foodGroupForSearch,
  } = useContext(FoodContext);

  //state hooks
  const [filterOrder, setFilterOrder] = useState({
    isFiltered: false,
    filterKey: "",
    groups: null,
  });
  
  //search result
  const [searchedOrder, setSearchedOrder] = useState({
    list: null,
    searched: false,
  });
  const [readyOrderCounter, setReadyOrderCounter] = useState(0);
  const [taxType, setTaxType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [itemData, setItemData] = useState({
    url:"",
    name: "",  
    token:"", 
  });
  const orderReady = useRef();
  const refresh = useRef();

  useEffect(() => {
    //get all orders when coming to kithcen
    getKitchenNewOrders("Dine-In");

    //add "All" option to group filter
    let tempFoodGroups = [];
    foodGroupForSearch &&
      foodGroupForSearch.map((item) => {
        if (item.name !== "All") {
          tempFoodGroups.push(item);
        }
      });
    //new option added to food group
    tempFoodGroups.unshift({ name: "All" });
    setFilterOrder({
      ...filterOrder,
      groups: tempFoodGroups,
    });
  }, [foodGroupForSearch]);

  useEffect(() => {
    if (!generalSettings || !Object.keys(generalSettings)?.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));
  }, [generalSettings]);


  //filter ordered items
  const handleFilter = (foodGrp) => {
    setFilterOrder({
      ...filterOrder,
      isFiltered: foodGrp.name == "All" ? false : true,
      filterKey: foodGrp.name,
    });
  };

  //search orders here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedOrder({ ...searchedOrder, searched: false });
    } else {
      let searchedList =
        kithcenNewOrders &&
        kithcenNewOrders.filter((item) => {
          //token
          let lowerCaseItemToken = item.token?.id.toLowerCase();

          return lowerCaseItemToken.includes(searchInput);
        });
      setSearchedOrder({
        ...searchedOrder,
        list: searchedList,
        searched: true,
      });
    }
  };

  // kitchen bell ring
  useEffect(()=>{
    setTimeout(() => {    
      kithcenNewOrders && setReadyOrderCounter(kithcenNewOrders?.length);
    }, 200);
  },[kithcenNewOrders]);
  
  orderReady.current = readyOrderCounter;
  refresh.current = kithcenNewOrders?.length;
 
  useEffect(() => {
    const interval = setInterval(() => {
          const url = BASE_URL + "/settings/get-new-orders/Dine-In";
          setLoading(true)
          return axios
              .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
              })
              .then((res) => {
                setKithcenNewOrders(res.data[0]);
                localStorage.setItem(url, JSON.stringify(res));
                let newOrder = orderReady.current;
                if(res.data[1] === 1){
                  let beep = document.getElementById("myAudioPhoneRing");
                  beep.play();
                }
                setReadyOrderCounter(res.data[0]?.length);
                setLoading(false)
              })
           .catch((err) => {
            setLoading(false)
           })
      
      }, 97000);
      return () => {
        clearInterval(interval);
      };
    
  }, []);

   // image pop up
   const handleOpenPopup = (item,token) => {
    setItemData({
      url: BASE_URL + item?.order_image,
      name: item.food_group + " - " + item.food_item,
      token: `Order Token: ${token}`,
    })
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };
  
  //accept or reject
  const handleAcceptOrReject = (id) => {
    setLoading(true);
    const url = BASE_URL + "/settings/kitchen-order-status";
    let formData = {
      id,
      status:"DA"
    };
  
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => {
        //remove ready item from order list
        getKitchenNewOrders("Dine-In");
        let newState = kithcenNewOrders.filter((orderItem) => {
          return orderItem.id !== id;
        });
        setReadyOrderCounter(newState?.length);
        //remove ready item from search list
        if (searchedOrder.searched) {
          setSearchedOrder({ ...searchedOrder,list: newState, searched: false })
        }
        setLoading(false);
       
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });

      });
  };

  //delete confirmation modal of waiter
  const handleReadyConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("All items are cooked?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleReady(id);
                  onClose();
                }}
              >
                {_t(t("YES, COOKED!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("NO"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  //make the order group ready here
  const handleReady = (id) => {
    setLoading(true);
    const url = BASE_URL + "/settings/mark-all-items-ready";
    let formData = {
      id,
    };
  
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => {
        //remove ready item from order list
        let newState = kithcenNewOrders.filter((orderItem) => {
          return orderItem.id !== id;
        });
        setKithcenNewOrders(newState);
        setReadyOrderCounter(newState?.length);
        //remove ready item from search list
        if (searchedOrder.searched) {
          let newSearchState = searchedOrder.list.filter(
            (orderItemSearched) => {
              return orderItemSearched.id !== id;
            }
          );
          setSearchedOrder({
            ...searchedOrder,
            list: newSearchState,
          });
        }
        setLoading(false);
      
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please refresh and try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });

      });
  };


  return (
    <>
      <Helmet>
        <title>{_t(t("Kitchen Dine In"))}</title>
      </Helmet>
      <main id="main" data-simplebar>
        <div className="fk-scroll--index t-mt-15 t-mb-15" data-simplebar>
          <div className="container-fluid">
            <div className="t-bg-white t-pt-10 t-pb-10 t-pl-15 t-pr-15">
              {/* next page data spin loading */}
              <div className={`${(loading) && "loadingBlur"}`}></div>
              <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              {/* spin loading ends */}
              <KdsModal
                show= {isOpen}
                handleClose= {handleClosePopup}
                itemData= {itemData}
              />
              <div className="row gx-2 align-items-center">
                <div className="col-md-6 t-mb-15 mb-md-0 col-sm-6">
                  <ul className="t-list fk-breadcrumb">
                    <li className="fk-breadcrumb__list">
                      <span className="t-link fk-breadcrumb__link text-uppercase">
                        <span className="mr-2">
                          <img
                            src="/assets/img/cooking.png"
                            alt="cooking"
                            className="img-fluid"
                            style={{ height: "40px", width: "40px" }}
                          />
                        </span>
                        <span className="mr-1">{_t(t("Dine In"))}</span>{" "}
                        {_t(t("Dashboard"))}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 rounded col-sm-6">
                  <button
                    type="button"
                    onClick={() => {
                      const promiseThen = new Promise((resolve, reject) => {
                        setTimeout(() => {
                          resolve(getKitchenNewOrders("Dine-In"));
                        }, 100);
                    });
                    
                    promiseThen
                        .then(() => {
                          let newOrder = orderReady.current ;
                          let refreshOrder = refresh.current;
                            if(newOrder < refreshOrder){
                              let beep = document.getElementById("myAudioPhoneRing");
                              beep.play();
                            }
                            setReadyOrderCounter(refreshOrder);
                        })
                        .catch((err) => console.log(err));
                     
                      setSearchedOrder({
                        ...searchedOrder,
                        searched: false,
                      });
                     
                    }}
                    className="btn btn-primary btn-block sm-text text-uppercase mb-2 mb-md-0 text-truncate rounded"
                  >
                    {_t(t("Refresh"))}
                  </button>
                </div>
                <div className="col-md-2 col-sm-6">
                  <Select
                    options={filterOrder.groups && filterOrder.groups}
                    components={makeAnimated()}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    classNamePrefix="select"
                    className="xsm-text mb-2 mb-md-0 "
                    onChange={handleFilter}
                    maxMenuHeight="200px"
                    placeholder={_t(t("Filter by group")) + ".."}
                  />
                </div>

                <div className="col-md-2 col-sm-6">
                  <div className="input-group">
                    <div className="form-file">
                      <input
                        type="text"
                        className="form-control border-0 form-control--light-1 rounded-left"
                        placeholder={_t(t("Search by token")) + ".."}
                        onChange={handleSearch}
                      />
                    </div>
                    <button className="btn btn-primary rounded-right" type="button">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {!searchedOrder.searched ? (
              <div className="row no-gutters g-4 mt-1">
                {kithcenNewOrders ? (
                  [
                    kithcenNewOrders.length > 0 ? (
                      kithcenNewOrders.map((item, index) => {
                        const kdsTimeColor = kdsListForSearch.find((item)=> item.name === "Dine in");
                          let bgColor = "";   
                          const dateTimeAgo = item.token?.time;
                          // Calculate the Unix timestamp for time1 minutes ago
                          const fiveMinutesAgoTimestamp = moment().subtract(kdsTimeColor?.time_1, 'minutes').valueOf();
                          // Calculate the Unix timestamp for time2 minutes ago
                          const tenMinutesAgoTimestamp = moment().subtract(kdsTimeColor?.time_2, 'minutes').valueOf();
                          if (dateTimeAgo > fiveMinutesAgoTimestamp) {
                              bgColor = kdsTimeColor.time_color_1
                            } else if (dateTimeAgo > tenMinutesAgoTimestamp) {
                              bgColor = kdsTimeColor.time_color_2
                           }else if(kdsTimeColor?.time_1 && kdsTimeColor?.time_2){
                              bgColor="#fa4851"
                           }
                        return (
                          <div
                            className="col-md-6 col-xl-4"
                            data-category={index + 1}
                          >
                            <div className="fk-order-token kitchen_order t-bg-white p-3 h-100">
                              <div className="fk-order-token__footer d-flex align-items-center justify-content-between">
                                { item.order_type_name ?
                                  <div className="text-capitalize">{item.order_type_name}</div>
                                  : ""
                                }
                                {/* {parseInt(item.is_accepted) === 0 && ( */}
                                  <button
                                    type="button"
                                    className="btn btn-success xsm-text text-uppercase btn-lg rounded"
                                    onClick={() =>
                                      handleAcceptOrReject(item.id)
                                    }
                                  >
                                    {_t(t("Accept order"))}
                                  </button>
                                {/* )} */}
                              </div>
                              <div className="fk-order-token__body">
                                <div className="fk-addons-table">
                                  <div className="fk-addons-table__head d-flex justify-content-between px-3" style={{backgroundColor: bgColor}}>
                                    <span>
                                      {_t(t("order token"))}: #{item.token?.id}
                                    </span>
                                    <div className="d-flex flex-column justify-content-end align-items-end">
                                      <div>
                                        {_t(t("ordered at"))}:{" "}
                                        <Moment format="LT">
                                          {item.token?.time}
                                        </Moment>
                                      </div>
                                      <div style={{fontSize:"12px"}}>
                                        <Moment fromNow>
                                            {item.token?.time}
                                        </Moment>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fk-addons-table__info">
                                    <div className="row g-0">
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("S/L"))}
                                        </span>
                                      </div>
                                      <div className="col-4 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("food"))}
                                        </span>
                                      </div>
                                      <div className="col-4 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("Additional Info"))}
                                        </span>
                                      </div>
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("QTY"))}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fk-addons-table__body">
                                    {item.orderedItems.map(
                                      (thisItem, indexThisItem) => {
                                        if (filterOrder.isFiltered) {
                                          if (
                                            thisItem.food_group ===
                                            filterOrder.filterKey
                                          ) {
                                            return (
                                              <div class="fk-addons-table__body-row">
                                                <div class="row g-0">
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {indexThisItem + 1}
                                                    </span>
                                                  </div>
                                                  <div class="col-4 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.food_item} (
                                                      {thisItem.food_group})
                                                    </span>
                                                  </div>
                                                  <div class="col-4 text-center border-right px-2 py-2">
                                                    {thisItem.variation !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("variation"))}:
                                                        </span>
                                                        {thisItem.variation}
                                                      </span>
                                                    )}

                                                    {thisItem.properties !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left ">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("properties"))}:
                                                        </span>
                                                        {JSON.parse(
                                                          thisItem.properties
                                                        ).map(
                                                          (
                                                            propertyItem,
                                                            thisIndex
                                                          ) => {
                                                            if (
                                                              thisIndex !==
                                                              JSON.parse(
                                                                thisItem.properties
                                                              ).length -
                                                                1
                                                            ) {
                                                              return (
                                                                propertyItem.property +
                                                                ", "
                                                              );
                                                            } else {
                                                              return propertyItem.property;
                                                            }
                                                          }
                                                        )}
                                                      </span>
                                                    )}
                                                    {thisItem.comments !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("instruction"))}:
                                                        </span>
                                                        {thisItem?.comments}
                                                      </span>
                                                    )}
                                                    
                                                  </div>
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.quantity}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        } else {
                                          return (
                                            <div class="fk-addons-table__body-row">
                                              <div class="row g-0">
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {indexThisItem + 1}
                                                  </span>
                                                </div>
                                                <div class="col-4 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.food_item} (
                                                    {thisItem.food_group})
                                                  </span>
                                                </div>
                                                <div class="col-4 text-center border-right px-2 py-2">
                                                  {thisItem.variation !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("variation"))}:
                                                      </span>
                                                      {thisItem.variation}
                                                    </span>
                                                  )}

                                                  {thisItem.properties !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left ">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("properties"))}:
                                                      </span>
                                                      {JSON.parse(
                                                        thisItem.properties
                                                      ).map(
                                                        (
                                                          propertyItem,
                                                          thisIndex
                                                        ) => {
                                                          if (
                                                            thisIndex !==
                                                            JSON.parse(
                                                              thisItem.properties
                                                            ).length -
                                                              1
                                                          ) {
                                                            return (
                                                              propertyItem.property +
                                                              ", "
                                                            );
                                                          } else {
                                                            return propertyItem.property;
                                                          }
                                                        }
                                                      )}
                                                    </span>
                                                  )}
                                                  {thisItem.comments !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("instruction"))}:
                                                      </span>
                                                      {thisItem?.comments}
                                                    </span>
                                                  )}
                                                </div>
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.quantity}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-8 offset-2" data-category="1">
                        <div className="fk-order-token t-bg-white p-5 text-center text-uppercase text-primary no-order">
                          {/* No order in the kitchen */}
                          <img
                            src="/assets/img/no-order.jpg"
                            alt="no order found"
                            className="img-fluid h-100"
                          />
                        </div>
                      </div>
                    ),
                  ]
                ) : (
                  <div className="col-12" data-category="1">
                    <Skeleton
                      className="fk-order-token t-bg-white p-3 border border-2"
                      style={{ minHeight: "560px" }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="row no-gutters g-4 mt-1">
                {searchedOrder ? (
                  [
                    searchedOrder.list.length > 0 ? (
                      searchedOrder.list.map((item, index) => {
                        return (
                          <div
                            className="col-md-6 col-xl-4"
                            data-category={index + 1}
                          >
                            <div className="fk-order-token kitchen_order t-bg-white p-3 h-100">
                              <div className="fk-order-token__footer d-flex align-items-center justify-content-between">
                                { item.order_type_name ?
                                  <div className="text-capitalize">{item.order_type_name}</div>
                                  : ""
                                }
                               
                                {parseInt(item.is_accepted) === 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-success xsm-text text-uppercase btn-lg rounded"
                                    onClick={() =>
                                      handleAcceptOrReject(item.id)
                                    }
                                  >
                                    {_t(t("Accept order"))}
                                  </button>
                                )}
                              </div>
                              <div className="fk-order-token__body">
                                <div className="fk-addons-table">
                                  <div className="fk-addons-table__head d-flex justify-content-between px-3">
                                    <span>
                                      {_t(t("order token"))}: #{item.token?.id}
                                    </span>
                                    <span>
                                      {_t(t("ordered at"))}:{" "}
                                      <Moment format="LT">
                                        {item.token?.time}
                                      </Moment>
                                    </span>
                                  </div>
                                  <div className="fk-addons-table__info">
                                    <div className="row g-0">
                                      <div className="col-1 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("S/L"))}
                                        </span>
                                      </div>
                                      <div className="col-4 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("food"))}
                                        </span>
                                      </div>
                                      <div className="col-4 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("Additional Info"))}
                                        </span>
                                      </div>
                                      <div className="col-2 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("QTY"))}
                                        </span>
                                      </div>
                                      <div className="col-1 text-center border-right py-2">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          <i className="fa fa-check"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fk-addons-table__body">
                                    {item.orderedItems.map(
                                      (thisItem, indexThisItem) => {
                                        if (filterOrder.isFiltered) {
                                          if (
                                            thisItem.food_group ===
                                            filterOrder.filterKey
                                          ) {
                                            return (
                                              <div class="fk-addons-table__body-row">
                                                <div class="row g-0">
                                                  <div class="col-1 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {indexThisItem + 1}
                                                    </span>
                                                  </div>
                                                  <div class="col-4 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.food_item} (
                                                      {thisItem.food_group})
                                                    </span>
                                                  </div>
                                                  <div class="col-4 text-center border-right px-2 py-2">
                                                    {thisItem.variation !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("variation"))}:
                                                        </span>
                                                        {thisItem.variation}
                                                      </span>
                                                    )}

                                                    {thisItem.properties !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left ">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("properties"))}:
                                                        </span>
                                                        {JSON.parse(
                                                          thisItem.properties
                                                        ).map(
                                                          (
                                                            propertyItem,
                                                            thisIndex
                                                          ) => {
                                                            if (
                                                              thisIndex !==
                                                              JSON.parse(
                                                                thisItem.properties
                                                              ).length -
                                                                1
                                                            ) {
                                                              return (
                                                                propertyItem.property +
                                                                ", "
                                                              );
                                                            } else {
                                                              return propertyItem.property;
                                                            }
                                                          }
                                                        )}
                                                      </span>
                                                    )}
                                                    {thisItem.comments !==
                                                      null && (
                                                      <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                        <span class="font-weight-bold mr-1">
                                                          {_t(t("instruction"))}:
                                                        </span>
                                                        {thisItem.comments}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div class="col-2 text-center border-right d-flex py-2">
                                                    <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                      {thisItem.quantity}
                                                    </span>
                                                  </div>
                                                  <div class="col-1 text-center border-right py-2">
                                                    {
                                                      thisItem?.order_image && (
                                                        <div class="text-center cursor-pointer xslg-text" onClick={()=>handleOpenPopup(thisItem,item.token?.id)}><i className="fa fa-eye"></i></div>
                                                      )
                                                    }
                                                  </div> 
                                                </div>
                                              </div>
                                            );
                                          }
                                        } else {
                                          return (
                                            <div class="fk-addons-table__body-row">
                                              <div class="row g-0">
                                                <div class="col-1 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {indexThisItem + 1}
                                                  </span>
                                                </div>
                                                <div class="col-4 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.food_item} (
                                                    {thisItem.food_group})
                                                  </span>
                                                </div>
                                                <div class="col-4 text-center border-right px-2 py-2">
                                                  {thisItem.variation !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pt-5">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("variation"))}:
                                                      </span>
                                                      {thisItem.variation}
                                                    </span>
                                                  )}

                                                  {thisItem.properties !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left ">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("properties"))}:
                                                      </span>
                                                      {JSON.parse(
                                                        thisItem.properties
                                                      ).map(
                                                        (
                                                          propertyItem,
                                                          thisIndex
                                                        ) => {
                                                          if (
                                                            thisIndex !==
                                                            JSON.parse(
                                                              thisItem.properties
                                                            ).length -
                                                              1
                                                          ) {
                                                            return (
                                                              propertyItem.property +
                                                              ", "
                                                            );
                                                          } else {
                                                            return propertyItem.property;
                                                          }
                                                        }
                                                      )}
                                                    </span>
                                                  )}
                                                  {thisItem.comments !==
                                                    null && (
                                                    <span class="fk-addons-table__info-text text-capitalize d-block text-left t-pb-5">
                                                      <span class="font-weight-bold mr-1">
                                                        {_t(t("instruction"))}:
                                                      </span>
                                                      {thisItem.comments}
                                                    </span>
                                                  )}
                                                </div>
                                                <div class="col-2 text-center border-right d-flex py-2">
                                                  <span class="fk-addons-table__info-text text-capitalize m-auto">
                                                    {thisItem.quantity}
                                                  </span>
                                                </div>

                                                <div class="col-1 text-center border-right py-2">
                                                  {
                                                    thisItem?.order_image && (
                                                      <div class="text-center cursor-pointer xslg-text" onClick={()=>handleOpenPopup(thisItem,item.token?.id)}><i className="fa fa-eye"></i></div>
                                                    )
                                                  }
                                                </div>  

                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-8 offset-2" data-category="1">
                        <div className="fk-order-token t-bg-white p-5 text-center text-uppercase text-primary no-order">
                          {/* No order in the kitchen */}
                          <img
                            src="/assets/img/no-order.jpg"
                            alt="no order found"
                            className="img-fluid h-100"
                          />
                        </div>
                      </div>
                    ),
                  ]
                ) : (
                  <div className="col-12" data-category="1">
                    <Skeleton
                      className="fk-order-token t-bg-white p-3 border border-2"
                      style={{ minHeight: "560px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

          {/* beep sound */}
       <audio id="myAudioPhoneRing">
          <source src="/assets/beep/notify.mp3" type="audio/mpeg" />
        </audio>

      <audio id="myAudioNew">
        <source src="/assets/beep/beep2.mp3" type="audio/mpeg" />
      </audio>

      </main>
    </>
  );
}

export default KitchenDineIn