import React, { useEffect, useState } from 'react'

const InstructionModal = ({
 cartItems,
 commentId,
 updateComments,
 updateItemImage,
 setCommentId
}) => {
    const focusPopUpInputField = input => {
        if (input) {
          setTimeout(() => {input.focus()}, 200);
        }
    };
    const [comment, setComment] = useState("");
    useEffect(()=>{
      commentId && setComment((cartItems && cartItems[commentId]?.comments) ? cartItems[commentId]?.comments : "")
    },[commentId,cartItems[commentId]?.comment])

  return (
    <div
    className={`modal fade ${commentId && "showing"}`}
    id="specialInstruction"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-md">
        <div className="modal-content">
        <div className="modal-header align-items-center">
            <div className="fk-sm-card__content"></div>
            <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => setCommentId(null)}
            ></button>
        </div>
        <div className="modal-body">
            {/* show form or show saving loading */}
            <form onSubmit={(e) => {
             e.preventDefault();
             updateComments(commentId,comment)
            }} autoComplete="off">
                <div>
                    <div>
                    <label htmlFor="name" className="form-label">
                        Special Instruction <span style={{color:"red"}}>*</span>
                    </label>
                    <input
                        ref={focusPopUpInputField}
                        type="text"
                        className="form-control"
                        placeholder="comments"
                        autoFocus
                        required
                        value={comment}
                        onChange={(e)=> {
                        setComment(e.target.value)
                        }}
                    />
                    </div>
                </div>
                        
                <div className="mt-3 mb-1">
                    
                    <div className="row my-2">
                    <div className="col-8">
                        <input
                        type="file"
                        name={`image_${commentId}`}
                        id={`uploadFile_${commentId}`}
                        accept=".png,.jpg,.jpeg,.webp"
                        className="d-none"
                        onChange={(e)=>{
                            updateItemImage(commentId,e.target.files[0])
                            if (document.getElementById(`uploadFile_${commentId}`)) {
                            document.getElementById(`uploadFile_${commentId}`).value = '';
                            }
                        }}
                        />
                        <label htmlFor={`uploadFile_${commentId}`} className="image-btn">
                        Choose File
                        </label>
                        {cartItems && cartItems[commentId]?.image &&(
                        <>
                            {cartItems[commentId]?.image.name}
                            <button type="button" onClick={(e)=>updateItemImage(commentId,"")} class="btn-close ml-2 cursor-pointer" aria-label="Close"></button>
                        </>
                        )}
                    </div>
                    </div>
                    <div className="row m-0">
                    <div className="col-2"></div>
                    <div className="col-8 row">
                        <div className="col-6">
                        <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded-25"
                            data-dismiss="modal"
                            
                        >
                            Submit
                        </button>
                        </div>
                        <div className="col-6">
                        <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded-25"
                            data-dismiss="modal"
                            onClick={(e) => {
                              updateComments(commentId,"")
                            }}
                        >
                            Remove
                        </button>
                        </div>
                    </div>
                    <div className="col-2"></div>
                    </div>
                </div>
            </form>
        </div>
        </div>
    </div>
    </div>
  )
}

export default InstructionModal