import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { customStyle } from '../../../../../../functions/Functions';

const SplitPayment = ({
  payModal,
  totalsettle,
  handleClick,
  payment,
  handleSetPayment,
  paySelected,
  paymentTypeAvailable,
  handleSetpaymentTypeMuliple,
  handleSplitPayment 
}) => {
  const [selectPayment, setSelectPayment] = useState(null);
 
  useEffect(()=>{
    const filterPayment = paymentTypeAvailable && paymentTypeAvailable.filter(item=>item.name.toLowerCase() !== "cash");
    setSelectPayment(filterPayment);
  },[paymentTypeAvailable]);


  return (
    <>
       {/*payment Modal*/}
       <div
        className={`modal fade ${payModal !== false ? "showing" : ""}`}
        id="addReason"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content font-weight-bold xxslg-text">
               Split Payment
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  handleClick()
                }}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <h4 className='m-0 mb-3 text-center'>Total: {totalsettle}</h4>
                <form onSubmit={(e) => handleSetpaymentTypeMuliple(e)}>
                  {
                    paymentTypeAvailable && paymentTypeAvailable?.map((availablePaymentType,index)=>{
                      if(availablePaymentType.name.toLowerCase() === "cash"){
                        return(
                          <div className="d-flex align-items-center mb-2" key={index}>
                            <div className='w-50 xslg-text mr-3'>
                              {availablePaymentType?.name}
                            </div>
                            <div className='w-50'>
                              <input
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                className="form-control text-right"
                                id={availablePaymentType?.id}
                                name={availablePaymentType?.name}
                                value={payment[availablePaymentType?.id] ? payment[availablePaymentType?.id] : ""}
                                onChange={(e) => handleSplitPayment(e)}
                              />
                            </div>
                          </div>
                        )
                      }  
                    })
                  }
                  <div className="d-flex align-items-center mb-2">
                      <div className='w-50 mr-3'>
                         <Select
                            options={selectPayment ? selectPayment : []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            classNamePrefix="select"
                            className="xsm-text w-100"
                            value={paySelected}
                            onChange={handleSetPayment}
                            styles={customStyle}
                            maxMenuHeight="200px"
                            placeholder={"Select payment.."}
                          />
                      </div>
                      <div className='w-50'>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                          className="form-control text-right"
                          id="select_split"
                          name="select_split"
                          value={payment["select_split"] ? payment["select_split"] : ""}
                          onChange={(e) => {
                            handleSplitPayment(e)
                          }}
                        />
                      </div>
                  </div>

                  <div className="mt-4 d-flex justify-content-center ">
                    <button type="submit" className="btn bg-primary rounded t-text-white text-capitalize">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SplitPayment
