import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import moment from "moment";

//functions
import {
  _t,
  getCookie,
  customStyle,
  paginationLoading,
  pagination,
  showingData,
  getSystemSettings,
  checkPermission,
  offlineOrderSyncUrl,
  handleGetOrders
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { useReactToPrint } from "react-to-print";
import WorkPeriodPrint from "./WorkPeriodPrint";

const WorkPeriod = () => {
  var weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //getting context values here
  const {
    //common
    loading,
    setLoading,
    // dataPaginating,
    generalSettings,
    showManageStock,
    // setshowManageStock
  } = useContext(SettingsContext);

  const { authUserInfo, adminStaffForSearch } = useContext(UserContext);

  const {
     //item
     ingredientItemForSearch,

    //branch
    branchForSearch,
    //work period
    getWorkPeriod,
    workPeriodList,
    setWorkPeriodList,
    setPaginatedWorkPeriod,
    workPeriodForSearch,
    setWorkPeriodListForSearch,
    getWorkPeriodForBranch,
    dataPaginating
   
  } = useContext(RestaurantContext);

  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };
 
  const { t } = useTranslation();
  
  const [zReportData, setZReportData] = useState(null);
  const [zCatgData, setZCatgData] = useState(null);
  const [zOrderTypeData, setZOrderTypeData] = useState(null);
  const [zProductMix, setZProductMix] = useState(null);
  const [dayProductMix, setDayProductMix] = useState(null);
  const [dayCatgMix, setDayCatgMix] = useState(null);
  const [dayCatgMixDetail, setDayCatgMixDetail] = useState(null);
  const [dayCatgMixDealDetail, setDayCatgMixDealDetail] = useState(null);
  const [cashRegisterData, setCashRegisterData] = useState(null);

  //search 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  //stock
  const [stockModal,setStockModal] = useState(false);
  const [stockDayEnd,setStockDayEnd] = useState([]);
  const [stockData,setStockData] = useState({
    branch_id: null,
    workperiod_id: null,
  })

  const [branchDayOpen,setBranchDayOpen] = useState(null)
  const [users,setUsers] = useState(null);
  const [print,setPrint] = useState(null);
  const [tillUser,setTillUser] = useState({
    id:null,
    user_name: null,
    workperiod_id: null
  });
  const cashInput = useRef();

  const [tillModal,setTillModal] =useState(false);

  const [dayEndModal,setDayEndModal]=useState(false)
  const [stockType,setStockType] = useState(null);
  const [dayEndCashModal,setDayEndCashModal]=useState(false)

  //new unit
  let [newWorkPeriod, setNewWorkPeriod] = useState({
    user_type: null,
    branch_id: null,
    opening_cash: 0,
    uploading: false,
  });
  let [branchSelected,setBranchSelected] = useState(null)
  //search result
  let [searchedWorkPeriod, setSearchedWorkPeriod] = useState({
    list: null,
    searched: false,
  });
  const [dayOpen, setDayOpen] = useState(null);

  const setOpenDay = async() =>{
    const url = BASE_URL + "/settings/get-day-open";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setDayOpen(res.data)
        setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
 
  const printType = [
    ...(getSystemSettings(generalSettings, "day_end_cash") != 1 ?
      [{
        id:1,
        name: "Z Report"
      }] : []
    ),
    {
      id:2,
      name: "Order Type"
    },
    {
      id:3,
      name: "Category"
    }, 
    ...(checkPermission(authUserInfo.permissions, "Product Mix Report") ? 
      [{
        id:4,
        name: "Product Mix"
      }] : []
    ),
    // {
    //   id:6,
    //   name: "Catg. Product Mix"
    // }, 
    {
      id:5,
      name: "View Cash"
    }
  ];


  const [startWorkPeriod,  setStartWorkPeriod] = useState(1);
  const workPeriodStatus = async(branchId) =>{
    const pendingUrl = BASE_URL + `/settings/get-day-open-status/${branchId}`;
    try {
      const res = await axios.get(pendingUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });

      setStartWorkPeriod(res.data);

    } catch (err) {
      console.error("Error fetching work period status:", err);
    }
  }

  useEffect(()=>{

    if(parseInt(getSystemSettings(generalSettings, "till_user")) === 1){
      let userList = adminStaffForSearch?.length > 0 && adminStaffForSearch?.filter((list)=>{
        if(list?.branch_id && (authUserInfo.details?.user_type === "staff" || authUserInfo.details?.user_type === "manager" || authUserInfo.details.user_type === "waiter")){
          return list?.branch_id === authUserInfo.details?.branch_id && list?.id !== authUserInfo.details?.id
        }else{
          return list?.id !== authUserInfo.details?.id
        }
      })
      setUsers(userList);
    
    }
 
    authUserInfo?.details &&
    setNewWorkPeriod({
      ...newWorkPeriod,
      branch_id: authUserInfo.details.branch_id
        ? authUserInfo.details.branch_id
        : null,
      user_type: authUserInfo.details.user_type,
    });

  },[authUserInfo,adminStaffForSearch])

  useEffect(()=>{
    if(authUserInfo && authUserInfo.details?.user_type === "staff" || authUserInfo.details?.user_type === "manager" || authUserInfo.details.user_type === "waiter"){
      let dayOpenBranch = dayOpen && dayOpen.find((branches)=> branches.branch_id === authUserInfo.details?.branch_id);
      setBranchDayOpen(dayOpenBranch ? dayOpenBranch : null)
      workPeriodStatus(authUserInfo.details?.branch_id);
    }
  },[dayOpen])

  useEffect(()=>{

    // const defaultPrintState = {};
    // if(workPeriodList?.data){
    //   workPeriodList.data.forEach((item) => {
    //     defaultPrintState[item.id] = printType[0];
    //   });
    // } 
    let defaultPrintState = {};

    if( searchedWorkPeriod?.list &&  searchedWorkPeriod.list?.data && searchedWorkPeriod.searched && searchedWorkPeriod.list.data){
      defaultPrintState = searchedWorkPeriod.list.data?.reduce((acc, item) => {
        return { ...acc, [item.id]: printType[0] };
      }, {});
    }else{
      defaultPrintState = workPeriodList?.data?.reduce((acc, item) => {
        return { ...acc, [item.id]: printType[0] };
      }, {});
    }
   
    setPrint(defaultPrintState)

  },[workPeriodList,searchedWorkPeriod])
   
  //useEffect == componentDidMount
  useEffect(() => {
    setOpenDay();
  }, []);

  let user = [];
  for (const keys in zReportData?.user) {
    user.push(
      <li>
        {keys}: {zReportData.user[keys]}
      </li>
    );
  }

  let salesDetails = [];
  for (const keys in zReportData?.sales_details) {
    salesDetails.push(
      <li>
        {keys}: {zReportData.sales_details[keys]}
      </li>
    );
  }

  let inSight = [];
  for (const keys in zReportData?.insights) {
    inSight.push(
      <li>
        {keys}: {zReportData.insights[keys]}
      </li>
    );
  }

  let categoryWiseSales = [];
  for (const keys in zReportData?.category_wise_sales) {
    categoryWiseSales.push(
      <p className="mb-0 xsm-text fk-print-text_pos text-capitalize">
        {/* {keys}: {zReportData.category_wise_sales[keys]} */}
        <span className="font-weight-bold">{keys}</span>
        <span className="float-right">
          {zReportData.category_wise_sales[keys]}
        </span>
      </p>
    );
  }

  //set branch id
  const handleSetBranchId = (branch) => {
    if(branch){
      workPeriodStatus(branch.id);
      setNewWorkPeriod({
        ...newWorkPeriod,
        branch_id: branch.id,
      });
      setBranchSelected(branch)
      let dayOpenBranch = dayOpen && dayOpen.find((branches)=> branches.branch_id === branch.id);
      setBranchDayOpen(dayOpenBranch ? dayOpenBranch : null)
    }else{
      setNewWorkPeriod({
        ...newWorkPeriod,
        branch_id: null,
      });
      setBranchSelected(null)
      setBranchDayOpen( null) 
    }
  };

  //search work periods here
  const handleSearch = () => {
    // let searchInput = e.target.value.toLowerCase();
    // if (searchInput.length === 0) {
    //   setSearchedWorkPeriod({ ...searchedWorkPeriod, searched: false });
    // } else {
    //   let searchedList = workPeriodForSearch.filter((item) => {
    //     let lowerCaseItemBranchName = item.branch_name.toLowerCase();
    //     let lowerCaseItemUserName = item.started_by.toLowerCase();
    //     let lowerCaseItemDate = item.date.toLowerCase();
    //     let lowerCaseItemUserName2 =
    //       item.ended_by && item.ended_by.toLowerCase();
    //     return (
    //       lowerCaseItemBranchName.includes(searchInput) ||
    //       lowerCaseItemUserName.includes(searchInput) ||
    //       lowerCaseItemDate.includes(searchInput) ||
    //       (lowerCaseItemUserName2 &&
    //         lowerCaseItemUserName2.includes(searchInput))
    //     );
    //   });
    //   setSearchedWorkPeriod({
    //     ...searchedWorkPeriod,
    //     list: searchedList,
    //     searched: true,
    //   });
    // }
    const apiUrl = BASE_URL + "/settings/get-filter-work-period";
    
    if(!startDate || !endDate) {
      toast.error("Please select from date and to date");
      return false;
    }
    setLoading(true)
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    const formData = {
      fromDate: fromDate,
      toDate: toDate,
      branch_id: newWorkPeriod.branch_id ? newWorkPeriod.branch_id : "",
    }

    axios
      .post(apiUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setSearchedWorkPeriod({
          ...searchedWorkPeriod,
          list: res.data[0],
          searched: true,
        });
        setLoading(false)
      }).catch((error) => {setLoading(false)});
  
  };

  //search work periods here
  const paginatedSearched = async(page) => {
    
    const apiUrl = BASE_URL + "/settings/get-filter-work-period?page=" + page;
    
    if(!startDate || !endDate) {
      toast.error("Please select from date and to date");
      return false;
    }
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    const formData = {
      fromDate: fromDate,
      toDate: toDate,
      branch_id: newWorkPeriod.branch_id ? newWorkPeriod.branch_id : "",
    }
    try{
      axios
        .post(apiUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setSearchedWorkPeriod({
            ...searchedWorkPeriod,
            list: res.data[0],
            searched: true,
          });
        }).catch((error) => {});
    }catch(err){

    }
  };

  const handleSetPrintType = (print_type,id) => {
    setPrint({...print, [id]:print_type})
  }

  const handlePrintReport = (itemId) =>{
    if (print === null){
      toast.error("Please select report type", toastObj)
      return false;
    }
    if (!print[itemId]){
      toast.error("Please select report type in row", toastObj)
      return false;
    }
    if(print[itemId].id === 1){
      handleZReport(itemId,"shift-report/");
    }else if(print[itemId].id === 2){
      handleOrderTypeReport(itemId,"shift-report-ot/");
    }else if(print[itemId].id === 3){
      handleCatgoryReport(itemId,"shift-report-cw/");
    }else if(print[itemId].id === 4){
      handleProductReport(itemId,"shift-menu-mix/");
    }else if(print[itemId].id === 5){
      handleViewReport(itemId)
    }else if(print[itemId].id === 6){
      handleCatgoryWiseReport(itemId,"shift-report-mcw/")
    }
  }

  const handleBranchCheckOnModal = () => {
    if(cashInput.current){
      setTimeout(() => {
        cashInput.current.focus()
        
      }, 300);
    }
    if (newWorkPeriod.branch_id) {
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, toastObj);
    }
  };

  const handleStockSubmit = () =>{
    let formData = {
      branch_id : stockData.branch_id,
      branch_name: stockData.branch_name,
      workperiod_id: stockData.workperiod_id,
      stock : Object.values(stockDayEnd)
    }
    //stock item
    if(!stockDayEnd || Object.keys(stockDayEnd)?.length === 0){
      toast.error(`${_t(t("Please add stock before submit"))}`, toastObj);
    }else{
      setLoading(true);
      const url = BASE_URL + `/settings/update-closing-items`;
      if(stockData?.branch_id && stockData?.workperiod_id){
      return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setWorkPeriodList(res.data[0]);
        setWorkPeriodListForSearch(res.data[1]);
        getWorkPeriodForBranch();
        setStockDayEnd([]);
        setStockModal(false);
        setStockData({});
        setLoading(false);
        toast.success(`${_t(t("Closing stock has been updated"))}`, toastObj);
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, toastObj);
      });
     }else{
      toast.error(`${_t(t("Branch can't be empty"))}`, toastObj);
     }
    }
  }

  const handleStock = (qty,item) =>{
    //stock item
    setStockDayEnd((curr)=>{
      let newData;
      if(stockDayEnd[item.id] && qty){
        newData = {
          ...curr,
          [item.id] : {
          ...stockDayEnd[item?.id],
            qty: qty,
          },
        }
      }else if(qty){
          newData = {
          ...curr,
          [item.id] : {
            id: item.id,
            name: item.name,
            qty: qty,
            unit:item.unit,
            unit_id: item.unit_id
          },
          }
      }else{
        newData = {
          ...curr,
        }
        curr && delete curr[item?.id]
      }
      return newData
    })    
  }

   // multi user pop up
   const changeUser =(e)=>{
    setTillUser({
      ...tillUser,
      id: parseInt(e.target.id),
      user_name: e.target.value
    });
  }

  const handleOrderTranfer = () =>{
    if(tillUser?.user_name && tillUser?.id){
    setLoading(true)  
    const url = BASE_URL + `/settings/update-work-period`;
    let time = new Date().getTime();
    let formData = {
      id: tillUser.workperiod_id,
      user_id: tillUser?.id,
      till_user: tillUser?.user_name,
      ended_at : time,
    };

    return axios
    .post(url, formData, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
      if(res.data === "work period not open"){
        setLoading(false);
        setTillModal(true);
        toast.error(`${_t(t(`Please start the workperiod of ${tillUser.user_name}`))}`, toastObj);
      }else{
        authUserInfo.details &&
        setNewWorkPeriod({
          branch_id: authUserInfo.details.branch_id
            ? authUserInfo.details.branch_id
            : null,
          user_type: authUserInfo.details.user_type,
          opening_cash: 0,
          uploading: false,
        });
      setWorkPeriodList(res.data[0]);
      setWorkPeriodListForSearch(res.data[1]);
      getWorkPeriodForBranch();
      setSearchedWorkPeriod({
        ...searchedWorkPeriod,
        list: res.data[1],
      });
      handleZReport(tillUser.workperiod_id,"shift-report-end/");
      localStorage.setItem("order_id", 0);
      toast.success(`${_t(t("Work period has been ended"))}`, toastObj);
      setTillModal(false);
      setLoading(false);
        setTillUser({
         id:null,
         user_name:null,
         workperiod_id: null,
        });
      }
    }).catch((err)=>{
      setLoading(false);
      setTillModal(true);
      setTillUser({
        id:null,
        user_name:null,
        workperiod_id: null
       })
      toast.error(`${_t(t("Please try again"))}`, toastObj);
    });
    }else{
      toast.error(
        `${_t(
          t("Please select user to tranfer your order")
        )}`,
        toastObj
      );
    }
    
  }
 
  //Save New work period
  const handleSaveNewWorkPeriod = (e) => {
    e.preventDefault();
    if (newWorkPeriod.branch_id) {
      setNewWorkPeriod({
        ...newWorkPeriod,
        uploading: true,
      });
      const url = BASE_URL + `/settings/new-work-period`;
      let date =
        weekday[new Date().getDay()] +
        ", " +
        new Date().getDate() +
        " " +
        month[new Date().getMonth()] +
        ", " +
        new Date().getFullYear();

        let time = new Date().getTime();

      let formData = {
        date: date,
        branch_id: newWorkPeriod.branch_id,
        started_at: time,
        opening_cash: newWorkPeriod.opening_cash,
       
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data === "exist") {
            authUserInfo.details &&
              setNewWorkPeriod({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
                opening_cash: 0,
              });
            toast.error(
              `${_t(t("Please end the started work period first/ Check day open"))}`,
              toastObj
            );
          } else {
            authUserInfo.details &&
              setNewWorkPeriod({
                branch_id: authUserInfo.details.branch_id
                  ? authUserInfo.details.branch_id
                  : null,
                user_type: authUserInfo.details.user_type,

                uploading: false,
                opening_cash: 0,
              });
            setWorkPeriodList(res.data[0]);
            setWorkPeriodListForSearch(res.data[1]);
            getWorkPeriodForBranch();
            setBranchSelected(null)
            setSearchedWorkPeriod({
              ...searchedWorkPeriod,
              list: res.data[1],
            });
            setLoading(false);
            localStorage.setItem("order_id", 0);
            toast.success(`${_t(t("Work period has been started"))}`, toastObj);
            document.getElementById('close-btn').click();
          }
        })
        .catch(() => {
          setLoading(false);
          setNewWorkPeriod({
            ...newWorkPeriod,
            uploading: false,
          });
          toast.error(`${_t(t("Please try again"))}`, toastObj);
        });
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, toastObj);
    }
  };

  //milisec to hour min
  const millisToMinutesAndHours = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var hours = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${hours} ${_t(t("hr"))} - ${minutes} ${_t(t("min"))} - ${
      seconds < 10 ? "0" : ""
    }${seconds} ${_t(t("sec"))}`;
  };

  //end confirmation modal of workPeriod
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to end now?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleEndWorkPeriod(id);
                  onClose();
                }}
              >
                {_t(t("Yes, end work period!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const zReportRef = useRef();
  const catgReport = useRef()
  const cashRegisterRef = useRef();
  const orderTypeReport = useRef();
  const productMixReport = useRef();
  const dayCatgReport = useRef();
  const catgWiseReport = useRef();
  const varianceReportRef = useRef();
  const varianceReportFGRef = useRef();

  const handleProductMixReport = useReactToPrint({
    content: () => productMixReport.current,
  });

  const handleOdTCatgReport = useReactToPrint({
    content: () => orderTypeReport.current,
  });

  // day wise catg mix
  const handleDayWiseCatgReport = useReactToPrint({
    content: () => dayCatgReport.current,
  });

  const handleCatgWiseReport = useReactToPrint({
    content: () => catgWiseReport.current,
  });
 
  const handleCatgReport = useReactToPrint({
    content: () => catgReport.current,
  });

  const handlePrintZReport = useReactToPrint({
    content: () => zReportRef.current,
  });

  const handlePrintCashRegister = useReactToPrint({
    content: () => cashRegisterRef.current,
  });

  const handleOrderTypeReport = (id,url) => {
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        setZOrderTypeData(response.data);
        handleOdTCatgReport()
      });
  };

  // product mix report
  const handleProductReport = (id,url)=>{
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setZProductMix(res.data);
        handleProductMixReport()
      });
  }

  //catg wise product mix report 
  const handleCatgoryWiseReport = (id,url)=>{
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDayProductMix(res.data);
        handleCatgWiseReport()
      });
  }

  // day wise product mix report
  const handleDayReport = () => {
    //`/settings/get-item-mix-report/`
    const apiUrl = BASE_URL + `/settings/get-item-mix-category-report/` + newWorkPeriod.branch_id;
     if (newWorkPeriod.branch_id) { 
      axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        //setDayProductMix(response.data);
        setDayCatgMix(response.data[0]);
        setDayCatgMixDetail(response.data[1]);
        setDayCatgMixDealDetail(response.data[2]);
        handleDayWiseCatgReport()
      }).catch((err)=>{
        toast.error(`${_t(t("Please try again"))}`, toastObj);
      })
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, toastObj);
    }
    
  };

  // catg wise report
  const handleCatgoryReport = (id,url) => {
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        setZCatgData(response.data);
        handleCatgReport()
      });
  };

  // day end report
  const handleZReport = (id,url) => {
    const apiUrl = BASE_URL + `/settings/`+ url + id ;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        console.log(response);
        setZReportData(response.data.data);
        handlePrintZReport();
      });
  };

  const handleDayOpenWorkPeriod = () =>{
   
    if( 
      parseInt(getSystemSettings(generalSettings, "day_open")) === 1 &&
      (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "call_center" || authUserInfo.details.user_type === "waiter")){
      toast.error(`${_t(t("Only Admin or Manger can day open"))}`, toastObj); 
      return false
    }
    setLoading(true);
    if (newWorkPeriod.branch_id) {
      const apiUrl =
      BASE_URL + `/settings/create-day-open`
     let formData = {
       branch_id :newWorkPeriod.branch_id
     } 
    axios
      .post(apiUrl,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if(res.data === "exist"){
          setLoading(false);
          toast.error(`${_t(t("Day already open"))}`, toastObj); 
        }else{
          setDayOpen(res.data);
          let dayOpenBranch = res.data.find((branches)=> branches.branch_id === newWorkPeriod.branch_id);
          setBranchDayOpen(dayOpenBranch ? dayOpenBranch : null);
          setBranchSelected(null)
          setLoading(false);
          toast.success(`${_t(t("Day open successfully"))}`, toastObj);
        }
      })
      .catch((err)=>{
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, toastObj);
      })
    } else {
      setLoading(false);
      toast.error(`${_t(t("Please select a branch"))}`, toastObj);
    }
  }
 
  const handleDayEndWorkPeriod = () => {
    if (newWorkPeriod.branch_id) {
    } else {
      toast.error(`${_t(t("Please select a branch"))}`, toastObj);
      return false
    }
    setLoading(true)
    const apiUrl =
      BASE_URL + `/settings/shift-report-day-end/` + newWorkPeriod.branch_id;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        setLoading(false);
        if (response.data !== "orderExist") {
          if (response.data !== "day_closed") {
            console.log("data response : ", response);
            setZReportData(response.data.data);
            let checkDayEndModal = false
            if(showManageStock){
              if(getSystemSettings(generalSettings, "day_end_stock") === 'both'){
                checkDayEndModal = true;
                setDayEndModal(true);
                setStockType('food');
              }else if(getSystemSettings(generalSettings, "day_end_stock") === 'raw'){
                checkDayEndModal = true;
                setDayEndModal(true);
                setStockType('raw');
              }else if(getSystemSettings(generalSettings, "day_end_stock") === 'food'){
                checkDayEndModal = true;
                setDayEndModal(true);
                setStockType('food');                              
              }
              else{
                if(parseInt(getSystemSettings(generalSettings, "day_end_cash"))!==1){
                  handlePrintZReport();
                  setBranchSelected(null)
                }
              }
            }
                        
            if(parseInt(getSystemSettings(generalSettings, "day_end_cash")) === 1 && checkDayEndModal===false){
              setDayEndCashModal(true);
            }

            if(!showManageStock && (parseInt(getSystemSettings(generalSettings, "day_end_cash"))!==1)){
              handlePrintZReport();
              setBranchSelected(null);
              toast.success(`${_t(t("Day End has benn sucessfully"))}`, toastObj);
            }
          } else {
            toast.error(`${_t(t("Day Already Closed"))}`, toastObj);
          }
        } else {
          toast.error(`${_t(t("Please close work period first"))}`, toastObj);
        }
        setOpenDay();
        setBranchDayOpen( null) 
      }).catch(err=> setLoading(false));
  };

  const handleCashRegister = (id) => {
    const apiUrl = BASE_URL + `/settings/view-cash-register/` + id;
    axios
      .get(apiUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((response) => {
        console.log("Cash Register Data ", response);
        setCashRegisterData(response.data.data);
        handlePrintCashRegister();
      });
  };

  const handleViewReport = (id) => {
    handleCashRegister(id);
  };

  //set name hook
  const handleSetOpeningCash = (e) => {
    console.log("work period", e.target.value);
    setNewWorkPeriod({
      ...newWorkPeriod,
      opening_cash: e.target.value,
    });
  };

  //free of cost order confirmation modal
  const handleWorkperiodEmail = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("You want email for this workperiod?"))}
            </p>
            <form 
            onSubmit={(e) => {
              e.preventDefault();
              if(e.target["passcode"].value === "4321"){
                handleCallApi(id,onClose);
              }else{
                toast.error("Please cordinate to your manager for admin use only",toastObj)
              }
            }}
            autoComplete="off"
            >
              <div className="form-group mb-4">
                <label
                  htmlFor="passcode"
                  className="font-weight-bold mr-4 lg-text"
                >
                  Enter passcode
                </label>
                <input
                  type="text"
                  className="form-control secure-password-field w-50 d-inline-block"
                  name="passcode"
                  id="passcode"
                  placeholder="Password"
                  required
                />
              </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                type="submit"
              >
                {_t(t("Yes"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
            </form>
            </div>
        );
      },
    });
  };
  
  //end here
  const handleCallApi = (id,onClose) =>{
    const url = BASE_URL + `/settings/shift-report-api/` + id;
    axios
    .get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((response) => {
      console.log(response);
      onClose()
    })
    .catch((err)=>{
      console.log(err)
    })
   
  }

  // work period end
  const handleEndWorkPeriod = async(id) => {
    setNewWorkPeriod({
      ...newWorkPeriod,
      uploading: true,
    });
    const url = BASE_URL + `/settings/update-work-period`;
    let time = new Date().getTime();
    let formData = {
      id: id,
      user_id: "",
      till_user: "",
      ended_at: time,
      show_stock : showManageStock ? 1 : 0,
      physical_stock : parseInt(getSystemSettings(generalSettings, "physical_stock")),
    };

    let getOrders = 0;
    if(parseInt(authUserInfo.details?.offline_enable)){
      const endPoint = "/get-offline-sync-orders";
      const localUrl = offlineOrderSyncUrl(endPoint);
      getOrders = await handleGetOrders(localUrl);
    }
    if(getOrders && getOrders?.length > 0){
      setNewWorkPeriod({
        ...newWorkPeriod,
        uploading: false,
      });
     
      toast.error("You have " + ((getOrders ? getOrders?.length : "") + " order to sync."));
      return false;
    }
    
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "orderExist" && res.data !== "addClosing") {
          authUserInfo.details &&
            setNewWorkPeriod({
              branch_id: authUserInfo.details.branch_id
                ? authUserInfo.details.branch_id
                : null,
              user_type: authUserInfo.details.user_type,
              opening_cash: 0,
              uploading: false,
            });
          setWorkPeriodList(res.data[0]);
          setWorkPeriodListForSearch(res.data[1]);
          getWorkPeriodForBranch();
          setSearchedWorkPeriod({
            ...searchedWorkPeriod,
            list: res.data[1],
          });
          setBranchSelected(null);
          setLoading(false);
          toast.success(`${_t(t("Work period has been ended"))}`, toastObj);
          handleZReport(id,"shift-report-end/");
          localStorage.setItem("order_id", 0);

        } else {
          if (res.data === "addClosing") {
            toast.error(`${_t(t("Add closing stock of ingredients"))}`, toastObj);
          } else {           
            if(parseInt(getSystemSettings(generalSettings, "till_user")) === 1){
              setTillModal(true);
              setTillUser({
                ...tillUser,
                workperiod_id: id
              });

            }else{
              toast.error(
                `${_t(
                  t("All submitted orders need to be settled to end workperiod")
                )}`,
                toastObj
              );
            }
            setLoading(false);
            setNewWorkPeriod({
              ...newWorkPeriod,
              uploading: false,
            });
          }
            }
      })
      .catch(() => {
        setLoading(false);
        setNewWorkPeriod({
          ...newWorkPeriod,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, toastObj);
      });
  };
  
  //Ing items state
  const [typeItems,setTypeItems]=useState(null);
  //radio selection state
  const [type,setType]=useState(null);
  // loader state of modal table
  const [typeLoader,settypeLoader]=useState(false);
  //Day end Raw Materail Print
  const handlePrintRawMaterial = useReactToPrint({
    content: () => varianceReportRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "day_end_cash") == 1) {
        setDayEndCashModal(true);
      } else {
        handlePrintZReport();
        setBranchSelected(null);
      }
    },
  });
  //Day end Finished Good Print
  const handlePrintFinishedGood = useReactToPrint({
    content: () => varianceReportFGRef.current,
    onAfterPrint: () => {
      if(getSystemSettings(generalSettings, "day_end_stock") === 'both'){
        setStockType('raw');
        setDayEndModal(true);
      }else{
        if (getSystemSettings(generalSettings, "day_end_cash") == 1) {
          setDayEndCashModal(true);
        } else {
          handlePrintZReport();
          setBranchSelected(null);
        }
      }
    },
  });
  //handle radio btn
  const handleGetRadioItems = async (e)=>{
    setTypeItems(null);
    let id=e.target.value    
    setType(id);    
    if(id){
      settypeLoader(true)      
      const url = BASE_URL + "/settings/get-ingredients-by-type/"+stockType+"/"+id;          
      try {
        const res = await axios
          .get(url ,{
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          if(res.data){                                  
            setTypeItems(res.data?.details);
            settypeLoader(false);         
          }          
      } catch (error) {
        settypeLoader(false);
        toast.error(
          `${_t(t("Please try again."))}`,
          toastObj                    
        );
      }
    }
  }

  const handleDayEndQty=(qty,item,index)=>{    
    item.remaining_qty = qty 
    typeItems[index] = item
    setTypeItems([...typeItems]);
  }
  const [rawMaterialStock,setRawMaterialStock]=useState(null);
  const [finishedGoodsStock,setFinishedGoodsStock]=useState(null);

  const handleDayEndStockSubmit = () => {   
    let itemsArr = typeItems ? typeItems.map((value,index)=>{
      return {
        item_id: value.item_id,
        remaining_qty: value.remaining_qty ? value.remaining_qty : 0,
        unit_id: value.unit_id,        
      }
    }) : [];              
      if (type) {
        setLoading(true);  
        const url = BASE_URL + "/settings/add-day-end-physical-stock";
        let formData = new FormData();
        formData.append("branch_id", newWorkPeriod?.branch_id);
        formData.append("ingredient_item_type", type);
        formData.append("items", JSON.stringify(itemsArr));
        formData.append("stock_type", stockType);        
  
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            if(res.data){
              toast.success(
                `${_t(t("Physical stock has been added."))}`,
                toastObj                    
              );
              if(getSystemSettings(generalSettings, "day_end_stock") === 'both' && stockType !== 'raw'){
                setFinishedGoodsStock(res.data?.data)
                setDayEndModal(false);                
                handlePrintFinishedGood();                                                    
              }else{
                setDayEndModal(false);
                if(stockType==='raw'){
                  setRawMaterialStock(res.data?.data);                                 
                  handlePrintRawMaterial();                  
                }else if(stockType==='food'){
                  setFinishedGoodsStock(res.data?.data)                  
                  handlePrintFinishedGood();
                }
                // else{
                //   if (getSystemSettings(generalSettings, "day_end_cash") == 1) {
                //     setDayEndCashModal(true);
                //   } else {
                //     handlePrintZReport();
                //     setOpenDay();
                //     setBranchSelected(null);
                //   }
                //   // handlePrintZReport();
                //   // setBranchSelected(null)
                //   // setOpenDay();
                // }
              }
              setLoading(false);                                      
              setTypeItems(null);
              setType(null)
            }            
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              `${_t(t("Please try again."))}`,
              toastObj                    
            );
          });
      } else {
        toast.error(
          `${_t(t("Please select type."))}`,
          toastObj                    
        );
      }
    
  }
  
  //cancel confirmation modal
  const handleCancelConfirmation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to cancel this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {                  
                  onClose();
                  setDayEndModal(false);
                  handlePrintZReport();                
                  setBranchSelected(null)
                  setTypeItems(null);
                  setType(null)
                }}
              >
                {_t(t("Yes, Cancel it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //Accept confirmation modal
  const handleAcceptConfirmation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to save this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {                  
                  onClose();
                  handleDayEndStockSubmit()
                }}
              >
                {_t(t("Save"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };


  const [collectedAmount,setCollectedAmount] = useState(null);
  const [dayEndCashRes,setDayEndCashRes] = useState(null)
  //day end cash save api
  const dayEndCashAxios = () => {
    settypeLoader(true)
    const url = BASE_URL + "/settings/add-day-end-collection";
    let formData = new FormData();
    formData.append("branch_id", newWorkPeriod?.branch_id);
    formData.append("collected_amount", collectedAmount);
    return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            if(res.data){              
              setDayEndCashRes(res.data);
              settypeLoader(false);
            }
          })
          .catch((error) => {
            settypeLoader(false);            
            toast.error(
              `${_t(t("Please try again."))}`,
              toastObj                    
            );
          });
  }

  let branch = newWorkPeriod.branch_id && branchForSearch && branchForSearch.find((brnch)=> newWorkPeriod.branch_id === brnch.id);
  
  return (
    <>
      <Helmet>
        <title>{_t(t("Work Periods"))}</title>
      </Helmet>

      {/* Day end modal */}
      <div
        className={`modal fade ${
          dayEndModal !== false ? "showing" : ""
        }`}
        id="stock"
        aria-hidden="true"
        style={{
          zIndex:99,
        }}
      >
        <div className="modal-dialog modal-dialog-typeItem" style={{margin:"0rem auto"}}>
          <div className="modal-content">
            <div className="modal-header align-items-center py-2">
              <div className="fk-sm-card__content" style={{fontSize:"1.2rem",fontWeight:"600"}}>
               Physical Stock {stockType==='raw' ? '(Raw Material)' : '(Finished Goods)'}
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  handleCancelConfirmation();              
                }}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form onSubmit={(e) =>{
                  e.preventDefault();
                  handleAcceptConfirmation()
                }}>
                <div className="mb-1">
                <span style={{fontSize:"0.88rem",fontWeight:"400"}}>Select Type</span>
                <div className="col-10 d-flex align-items-center">
                    <div className="d-inline mr-2">
                      <input
                        type="radio"
                        className="form-check-input pointer-cursor table-text mt-2"
                        id="Daily"
                        name="Daily"
                        value="1"
                        onChange={(e) => {
                          handleGetRadioItems(e);
                        }}
                        checked={
                          type === "1"
                            ? 1
                            : 0
                        }
                      />
                      <label
                        className="form-radio-label pointer-cursor ml-2 table-text"
                        htmlFor="Daily"
                      >
                        {_t(t("Daily"))}
                      </label>
                    </div>

                    <div className="d-inline mr-2">
                      <input
                        type="radio"
                        className="form-check-input pointer-cursor table-text mt-2"
                        id="Weekly"
                        name="Weekly"
                        value="2"
                        onChange={(e) => {
                          handleGetRadioItems(e);
                        }}
                        checked={
                          type === "2"
                            ? 1
                            : 0
                        }
                      />
                      <label
                        className="form-radio-label pointer-cursor ml-2 table-text"
                        htmlFor="Weekly"
                      >
                        {_t(t("Weekly"))}
                      </label>
                    </div>

                    <div className="d-inline mr-2">
                      <input
                        type="radio"
                        className="form-check-input pointer-cursor table-text mt-2"
                        id="Monthly"
                        name="Monthly"
                        value="3"
                        onChange={(e) => {
                          handleGetRadioItems(e);
                        }}
                        checked={
                          type === "3"
                            ? 1
                            : 0
                        }
                      />
                      <label
                        className="form-radio-label pointer-cursor ml-2 table-text"
                        htmlFor="Monthly"
                      >
                        {_t(t("Monthly"))}
                      </label>
                    </div>

                    {typeLoader && <div className="d-inline mr-2">
                      <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>  }                        
                  </div>
                </div>
                
                {typeItems !== null &&               
                <div className="table-scroll">
                  <table className="table table-bordered-sm table-hover cus-pad">
                    <thead className="align-middle">
                      <tr>
                        <th className="table-text text-capitalize align-middle text-center border-1 border">Item Name</th>
                        <th className="table-text text-capitalize align-middle text-center border-1 border">Qty</th>
                        <th className="table-text text-capitalize align-middle text-center border-1 border">Unit</th>
                      </tr>
                    </thead>
                  
                    <tbody className="align-middle">
                      {typeItems !== null && typeItems?.length > 0 ?
                        typeItems.map((item,index)=>{
                          item.remaining_qty = item?.remaining_qty 
                          return(                        
                            <tr>
                              <td className="table-text text-capitalize align-middle">{item.item_name}</td>
                              <td className="table-text text-capitalize align-middle text-center">
                                <input
                                type="number"
                                onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                name="qty"
                                className="table-text"
                                value={item.remaining_qty}
                                defaultValue={0}
                                onChange={(e)=>handleDayEndQty(e.target.value,item,index)}
                                placeholder="qty"
                                />
                              </td>
                              <td className="table-text text-capitalize align-middle text-center">{item.unit}</td>
                            </tr>
                          )
                        }) : 
                        <tr className="table-text text-capitalize align-middle text-center"><td colSpan={3}>No Data Available</td></tr>
                      }
                    </tbody>
                  </table>
                </div>
                }                

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          disabled={loading || (typeItems && typeItems.length > 0 ? false : true)}                      
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          disabled={loading}
                          onClick={(e) => {
                            handleCancelConfirmation();
                            // setStockDayEnd([]);
                            // setStockData({})
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* day end cash */}
      <div
      className={`modal fade ${
        dayEndCashModal !== false ? "showing" : ""
      }`}
      id="stock"
      aria-hidden="true"
      style={{
        zIndex:1000,
      }}
    >
      <div className="modal-dialog modal-dialog-typeItem" style={{margin:"0rem auto"}}>
        <div className="modal-content">
          <div className="modal-header align-items-center py-2">
            <div className="fk-sm-card__content" style={{fontSize:"1.2rem",fontWeight:"600"}}>
             Day End Amount
            </div>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                setDayEndCashModal(false);
                handlePrintZReport();              
                setBranchSelected(null)
                setDayEndCashRes(null);
                setCollectedAmount("");          
              }}
            ></button>
          </div>
          <div className="modal-body">
            {/* show form or show saving loading */}
            <div key="fragment-food-group-1">
              <form onSubmit={(e) =>{
                e.preventDefault();
                dayEndCashAxios();
              }}>
              <div className="mb-1">
              <div className="row"> 
                <div className="col-8">
                    <label htmlFor="name" className="form-label">
                      {_t(t("Total Amount"))}{" "}
                      <small className="text-danger">*</small>
                    </label>
                    <input
                      // ref={cashInput}
                      type="number"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter Total Amount"
                      value={collectedAmount}
                      required
                      onChange={(e) => {
                        setCollectedAmount(e.target.value)
                      }}
                    />                  
                </div>

                <div className="col-4 align-self-end mb-1">
                  <button
                    type="submit"
                    className="btn btn-success w-100 xsm-text text-uppercase t-width-max text-center"
                    // data-dismiss="modal"
                    disabled={typeLoader || dayEndCashRes !== null ? true : false}                     
                  >
                    {typeLoader ?
                      <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      :"Submit"}
                  </button>                      
                </div>                          
                </div>                
              </div>
              
              {dayEndCashRes !== null &&
              <>               
              <div className="table-scroll">
              <table className="table table-bordered-sm table-hover cus-pad">
              <thead className="align-middle">
                <tr>
                  <th className="table-text text-capitalize align-middle text-center border-1 border">Collective</th>
                  <th className="table-text text-capitalize align-middle text-center border-1 border">Receivable</th>
                  <th className="table-text text-capitalize align-middle text-center border-1 border">Variance</th>
                </tr>
              </thead>
              
              <tbody className="align-middle">
                {dayEndCashRes !== null ?                                                               
                        <tr>
                          <td className="table-text text-capitalize align-middle text-center">{dayEndCashRes?.details?.collected_amount}</td>
                          <td className="table-text text-capitalize align-middle text-center">
                            {parseFloat(dayEndCashRes?.details?.total_payable_amount).toFixed(2)}
                          </td>
                          <td className="table-text text-capitalize align-middle text-center">{parseFloat(dayEndCashRes?.details?.variance).toFixed(2)}</td>
                        </tr>                      
                    :<tr className="table-text text-capitalize align-middle text-center"><td colSpan={3}>No Data Available</td></tr>                  
                }
                </tbody>
                </table>
                </div>

                <div className="d-flex justify-content-end">
                <button                  
                  className="btn btn-success xsm-text text-uppercase t-width-max text-center"
                  style={{width:"138px"}}
                  onClick={(e) => {
                    setDayEndCashModal(false);
                    handlePrintZReport();              
                    setBranchSelected(null)
                    setDayEndCashRes(null);
                    setCollectedAmount("");          
                  }}                                    
                >
                  Close & Print
                </button>                      
                </div>
                </>
              }                
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
   
      {/* Print Z Report End */}
      <div className="modal fade" id="addOpeningCash" aria-hidden="true">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form onSubmit={handleSaveNewWorkPeriod}>
                  <div>
                    <label htmlFor="name" className="form-label">
                      {_t(t("Opening Cash"))}{" "}
                      <small className="text-danger">*</small>
                    </label>
                    <input
                      ref={cashInput}
                      type="number"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="e.g. Opening Cash"
                      value={newWorkPeriod?.opening_cash}
                      required
                      onChange={(e) => {
                        handleSetOpeningCash(e);
                      }}
                    />
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          onClick={handleSaveNewWorkPeriod}
                          data-dismiss="modal"
                        >
                          {_t(t("Add"))}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {/* {newWorkPeriod.uploading === true || loading === true ? (
                pageLoading()
              ) : ( */}
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${(newWorkPeriod.uploading === true || loading || dataPaginating) && "loadingBlur"}`}></div>
                  <div className={`${(newWorkPeriod.uploading === true || loading || dataPaginating) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedWorkPeriod.searched
                              ? _t(t("Work periods"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                      {/*
                        <button className="btn btn-primary rounded-left" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="form-file">
                          <input
                            type="text"
                            className="form-control border-0 form-control--light-1 rounded-right"
                            placeholder={
                              _t(t("Search by branch, date, user")) + ".."
                            }
                            onChange={handleSearch}
                          />
                        </div>
                          */}
                    <div className="col-md-4 col-sm-12">
                      {authUserInfo.details !== null &&
                        authUserInfo.details.user_type !== "staff" && (
                          <div className="row gx-2">
                            <div className="col-5">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={true}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center justify-content-center">
                                <span className="fk-sort__icon">
                                  <span className="fa fa-long-arrow-right"></span>
                                </span>
                            </div>
                            <div className="col-5">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={true}
                              />
                            </div>
                          </div>
                        )
                      }
                    </div>    
                    <div className="col-md-8 col-sm-12 t-mb-15 mt-3 mt-md-0 mb-md-0">
                      <div className="row gx-2 align-items-center">
                        {authUserInfo.details !== null &&
                          authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter" ? (
                          <>
                            <div className="col-md-4 col-sm-4">
                              <Select
                                options={branchForSearch && branchForSearch}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value = {branchSelected} 
                                classNamePrefix="select"
                                className="xsm-text"
                                styles={customStyle}
                                onChange={handleSetBranchId}
                                maxMenuHeight="200px"
                                placeholder={
                                  _t(t("Select a branch")) + ".."
                                }
                                isClearable
                              />
                            </div>

                            <div className="col-md-2 col-sm-2">
                              <div className="row gx-2 d-flex d-flex align-items-center justify-content-center">
                                <div className="col-6 mr-2">
                                  <button title="search" className="btn py-1 btn-success rounded" type="button" onClick={()=>handleSearch()}>
                                    Filter
                                  </button>
                                </div>
                                <div className="col-4">
                                  <button
                                    className="btn btn-secondary btn-sm rounded-sm"
                                    style={{padding: "0.25rem 0.65rem"}}
                                    title="Day Product Mix Report"
                                    onClick={(e) => {
                                      handleDayReport();
                                    }}
                                    >
                                    <i className="fa fa-print"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                           
                            {
                              branchDayOpen === null  ? (
                                <>
                                  <div className="col-md-3 col-sm-3"></div>
                                  <div className="col-md-3 col-sm-3">
                                    <ul className="t-list fk-sort align-items-center justify-content-center">
                                      <li className="fk-sort__list mb-0 flex-grow-1">
                                        <button
                                          disabled={loading || newWorkPeriod.uploading}
                                          onClick={handleDayOpenWorkPeriod}
                                          className="w-100 btn btn-success sm-text text-uppercase rounded"
                                        >
                                          {_t(t("Day Open"))}
                                        </button>
                                      </li>
                                    </ul>
                                   </div>
                                </> 
                              ) : (
                               <div className="col row">
                                <div className="col-6">
                                <ul className="t-list fk-sort align-items-center justify-content-center">
                                  <li className="fk-sort__list mb-0 flex-grow-1">
                                   {
                                    startWorkPeriod != 0 && (
                                      <button
                                        disabled={loading || newWorkPeriod.uploading}
                                        onClick={handleBranchCheckOnModal}
                                        data-toggle="modal"
                                        data-target={`${
                                          newWorkPeriod.branch_id
                                            ? "#addOpeningCash"
                                            : ""
                                        }`}
                                        className="w-100 btn btn-secondary sm-text text-uppercase rounded"
                                      >
                                        {_t(t("start work period"))}
                                      </button>
                                    )
                                   }
                                  </li>
                                </ul>
                              </div>
                             
                              <div className="col-6">
                                <ul className="t-list fk-sort align-items-center justify-content-center">
                                  <li className="fk-sort__list mb-0 flex-grow-1">
                                    <button
                                      disabled={loading || newWorkPeriod.uploading}
                                      onClick={handleDayEndWorkPeriod}
                                      className="w-100 btn btn-primary sm-text text-uppercase rounded"
                                    >
                                      {_t(t("Day End"))}
                                    </button>
                                  </li>
                                </ul>
                               </div>
                              </div>
                              )
                            }
                          </>
                        ) : (
                          <>
                          <div className="col-2">
                            <div className="row gx-2 d-flex d-flex align-items-center justify-content-center">
                             <div className="col-6 mr-2">
                              {authUserInfo.details !== null &&
                                authUserInfo.details.user_type !== "staff" && (
                                <button title="search" className="btn py-1 btn-success rounded" type="button" onClick={()=>handleSearch()}>
                                  Filter
                                </button>
                                )
                              }
                              </div>
                              <div className="col-4">
                                <button
                                  className="btn btn-secondary btn-sm rounded-sm"
                                  style={{padding: "0.25rem 0.65rem"}}
                                  title="Day Product Mix Report"
                                  onClick={(e) => {
                                    handleDayReport();
                                  }}
                                  >
                                  <i className="fa fa-print"></i>
                                </button>
                               </div> 
                            </div>
                          </div>
                          {
                            branchDayOpen === null  ? (
                              <div className="col row">
                               <div className="col-md-9 col-sm-6">
                               </div>
                                <div className="col-md-3 col-sm-6">
                                  <ul className="t-list fk-sort align-items-center justify-content-center">
                                    <li className="fk-sort__list mb-0 flex-grow-1">
                                      <button
                                        disabled={loading || newWorkPeriod.uploading}
                                        onClick={handleDayOpenWorkPeriod}
                                        className="w-100 btn btn-success sm-text text-uppercase rounded"
                                      >
                                        {_t(t("Day Open"))}
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                               </div>
                            ) : (
                             <div className="col row">
                                <div className="col-md-4 col-sm-6 ml-auto mt-md-0 rounded">
                                <ul className="t-list fk-sort align-items-center justify-content-center">
                                  <li className="fk-sort__list mb-0 flex-grow-1">
                                    {
                                      startWorkPeriod != 0 && (
                                        <button
                                          disabled={loading || newWorkPeriod.uploading}
                                        onClick={handleBranchCheckOnModal}
                                          data-toggle="modal"
                                          data-target={`${
                                            newWorkPeriod.branch_id
                                              ? "#addOpeningCash"
                                              : ""
                                          }`}
                                          className="w-100 btn btn-secondary sm-text text-uppercase"
                                        >
                                          {_t(t("start work period"))}
                                        </button>
                                      )
                                    }
                                  </li>
                                </ul>
                              </div>
                           
                              <div className="col-md-3 col-sm-6">
                                <ul className="t-list fk-sort align-items-center justify-content-center">
                                  <li className="fk-sort__list mb-0 flex-grow-1">
                                    <button
                                      disabled={loading || newWorkPeriod.uploading}
                                    onClick={handleDayEndWorkPeriod}
                                      className="w-100 btn btn-primary sm-text text-uppercase rounded"
                                    >
                                      {_t(t("Day End"))}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            )
                          }
                         </>
                        )}
                      </div>
                    </div>
                   
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Started by"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Date"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Started at"))}
                              </th>
                              {showManageStock && parseInt(getSystemSettings(generalSettings, "physical_stock"))
                                ? (
                                <th
                                  scope="col"
                                  className="sm-text align-middle text-center border-1 border"
                                >
                                  {_t(t("Stock"))}
                                </th>
                              ) : null}
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Ended at"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Ended by"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                                style= {{minWidth:"15rem"}}
                              >
                                {_t(t("Sales Report"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                               {_t(t("Status"))}
                              </th>

                              {
                                /*
                                <th
                                  scope="col"
                                  className="sm-text align-middle text-center border-1 border"
                                >
                                  {_t(t("Cash Register"))}
                                </th>
                                */
                              }

                              <th
                                scope="col"
                                className="sm-text align-middle text-center border-1 border"
                              >
                                {_t(t("Total time"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {!searchedWorkPeriod.searched
                              ? [
                                  workPeriodList && [
                                    workPeriodList.data?.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="8"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      workPeriodList.data?.map((item, index) => {
                                        const isFormat = moment(item.date, 'DD-MM-YYYY').isValid();
                                          let formattedDate = item.date;
                                          if(isFormat){
                                             formattedDate = moment(item.date, 'DD-MM-YYYY').format('dddd, DD MMM, YYYY');
                                          }
                                         
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (workPeriodList.current_page -
                                                  1) *
                                                  workPeriodList.per_page}
                                            </th>

                                            <td className="xsm-text align-middle text-center text-secondary">
                                              {item.branch_name}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.started_by}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                               {formattedDate}
                                            </td>

                                            <td className="xsm-text align-middle text-center text-green">
                                              <Moment format="LT">
                                                {new Date(item.created_at)}
                                              </Moment>
                                            </td>
                                            {/* show if manage stock is enable */}
                                            {showManageStock && parseInt(getSystemSettings(generalSettings, "physical_stock"))
                                               ? (
                                                item.is_stock ?
                                              <td className="xsm-text align-middle text-center">
                                                <button
                                                  className={`btn xsm-text btn-danger btn-sm p-1 px-2 rounded`}
                                                  onClick={()=> {
                                                    setStockData({
                                                      branch_id: parseInt(item.branch_id),
                                                      branch_name : item.branch_name,
                                                      workperiod_id: item.id
                                                    })
                                                    setStockModal(true)}}
                                                >
                                                  {_t(t("Closing Stock"))}
                                                </button>
                                              </td>
                                                 : 
                                                 <td className="xsm-text align-middle text-center"> - </td>
                                                ) : null}

                                            <td className="xsm-text align-middle text-center text-primary">
                                              {item.ended_at ? (
                                                <Moment format="LT">
                                                  {new Date(item.updated_at)}
                                                </Moment>
                                              ) : (
                                                "-"
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.ended_at ? (
                                                item.ended_by
                                              ) : (
                                                <button
                                                  disabled={loading || newWorkPeriod.uploading}
                                                  className="btn btn-primary btn-sm py-0 px-4 rounded"
                                                  onClick={(e) => {
                                                    handleDeleteConfirmation(
                                                      item.id
                                                    );
                                                  }}
                                                >
                                                  {_t(t("End"))}
                                                </button>
                                              )}
                                            </td>

                                            <td className="sm-text align-middle text-center">
                                            <div className="d-flex align-items-center justify-content-center">
                                              <Select
                                                menuPosition="fixed"
                                                options={printType && printType}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                value={print && print[item.id]}
                                                onChange={(print_type) => handleSetPrintType(print_type,item.id)}
                                                className="sm-text"
                                                placeholder="Report"
                                                styles={customStyle}
                                              />
                                              <button
                                              className="btn btn-primary btn-sm py-1 px-2 ml-2 rounded"
                                                onClick={() => {
                                                  handlePrintReport(item.id)
                                                 // handleZReport(item.id,"shift-report/");
                                                }}
                                              >
                                                {_t(t("Print"))}
                                              </button>
                                               {
                                                /*
                                              
                                                <button
                                                  className="btn btn-primary btn-sm py-0 px-2 mr-2 rounded"
                                                  onClick={() => {
                                                    handleOrderTypeReport(item.id,"shift-report-ot/");
                                                  }}
                                                >
                                                  {_t(t("Od.T"))}
                                                </button>
                                                <button
                                                className="btn btn-secondary btn-sm py-0 px-2 rounded"
                                                  onClick={() => {
                                                    handleCatgoryReport(item.id,"shift-report-cw/");
                                                  }}
                                                >
                                                  {_t(t("Catg."))}
                                                </button>
                                                */}
                                              </div>
                                            </td>
                                       
                                            {
                                              /*
                                              <td className="sm-text align-middle text-center">
                                                <button
                                                  className="btn btn-success btn-sm py-0 px-4 rounded"
                                                  onClick={() => handleViewReport(item.id)}
                                                >
                                                  {_t(t("View"))}
                                                </button>
                                              </td>
                                              */
                                            }
                                            {item.day_end_status == 0 ? 
                                              <td className="print-text align-middle text-primary text-center">
                                                Day open
                                              </td>
                                              :  
                                               <td className="print-text align-middle text-danger text-center">
                                                 Day end
                                               </td> 
                                            }

                                            <td className="xsm-text align-middle text-center" onClick={()=>{
                                              if(getSystemSettings(generalSettings, "call_api") === "yes"){
                                                handleWorkperiodEmail(item.id)
                                              }
                                            }}>
                                              {item.ended_at
                                                ? millisToMinutesAndHours(
                                                    parseInt(
                                                      item.ended_at -
                                                        item.started_at
                                                    )
                                                  )
                                                : "-"}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/
                                  searchedWorkPeriod && [
                                    searchedWorkPeriod.list?.data?.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="9"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedWorkPeriod.list.data?.map(
                                        (item, index) => {
                                          const isFormat = moment(item.date, 'DD-MM-YYYY').isValid();
                                          let formattedDate = item.date;
                                          if(isFormat){
                                             formattedDate = moment(item.date, 'DD-MM-YYYY').format('dddd, DD MMM, YYYY');
                                          }
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (searchedWorkPeriod.list.current_page -
                                                    1) *
                                                    searchedWorkPeriod.list.per_page}
                                              </th>

                                              <td className="xsm-text align-middle text-center text-secondary">
                                                {item.branch_name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.started_by}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {formattedDate}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                <Moment format="LT">
                                                  {new Date(item.created_at)}
                                                </Moment>
                                              </td>
                                              
                                              {showManageStock &&parseInt(getSystemSettings(generalSettings, "physical_stock"))
                                                ? (
                                                item.is_stock ?
                                             <td className="xsm-text align-middle text-center">
                                              <button
                                              className={`btn xsm-text btn-danger btn-sm p-1 px-2 rounded`}
                                              onClick={()=> {
                                                setStockData({
                                                  branch_id: parseInt(item.branch_id),
                                                  branch_name : item.branch_name,
                                                  workperiod_id: item.id
                                                })
                                                setStockModal(true)}}
                                              >
                                                {_t(t("Closing Stock"))}
                                              </button>
                                            </td>
                                             : 
                                             <td className="xsm-text align-middle text-center"> - </td>
                                            ) : null }
                                            <td className="xsm-text align-middle text-center">

                                                {item.ended_at ? (
                                                  <Moment format="LT">
                                                    {new Date(item.updated_at)}
                                                  </Moment>
                                                ) : (
                                                  "-"
                                                )}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.ended_at ? (
                                                  item.ended_by
                                                ) : (
                                                  <button
                                                    disabled={loading || newWorkPeriod.uploading}
                                                    className="btn btn-primary btn-sm py-0 px-4 rounded"
                                                    onClick={(e) => {
                                                      handleDeleteConfirmation(
                                                        item.id
                                                      );
                                                    }}
                                                  >
                                                    {_t(t("End"))}
                                                  </button>
                                                )}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                              <div className="d-flex align-items-center justify-content-center">
                                              <Select
                                                menuPosition="fixed"
                                                options={printType && printType}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                value={print && print[item.id]}
                                                onChange={(print_type) => handleSetPrintType(print_type,item.id)}
                                                className="sm-text"
                                                placeholder="Report"
                                                styles={customStyle}
                                              />
                                              <button
                                              className="btn btn-primary btn-sm py-1 px-2 ml-2 rounded"
                                                onClick={() => {
                                                  handlePrintReport(item.id)
                                                  //handleZReport(item.id,"shift-report/");
                                                }}
                                              >
                                                {_t(t("Print"))}
                                              </button>

                                               {
                                                /*
                                                <button
                                                  className="btn btn-primary btn-sm py-0 px-2 mr-2 rounded"
                                                  onClick={() => {
                                                    handleOrderTypeReport(item.id,"shift-report-ot/");
                                                  }}
                                                >
                                                  {_t(t("Od.T"))}
                                                </button>
                                                <button
                                                className="btn btn-secondary btn-sm py-0 px-2 rounded"
                                                  onClick={() => {
                                                    handleCatgoryReport(item.id,"shift-report-cw/");
                                                  }}
                                                >
                                                  {_t(t("Catg."))}
                                                </button>
                                                */
                                               }
                                              </div>
                                              </td>

                                              {
                                                /*
                                                <td className="sm-text align-middle text-center">
                                                  <button
                                                    className="btn btn-success btn-sm py-0 px-4 rounded"
                                                    onClick={() => handleViewReport(item.id)}
                                                  >
                                                    {_t(t("View"))}
                                                  </button>
                                                </td>
                                                */
                                              }
                                              <td className="sm-text align-middle text-center">
                                                {item.day_end_status == 0 ? "Day open" : "Day end"}
                                              </td>

                                              <td className="xsm-text align-middle text-center" onClick={()=>{
                                                if(getSystemSettings(generalSettings, "call_api") === "yes"){
                                                  handleWorkperiodEmail(item.id)
                                                }
                                               }}>
                                                {item.ended_at
                                                  ? millisToMinutesAndHours(
                                                      parseInt(
                                                        item.ended_at -
                                                          item.started_at
                                                      )
                                                    )
                                                  : "-"}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              {/* )} */}
              {/* pagination loading effect */}
              {newWorkPeriod.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedWorkPeriod.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(
                                workPeriodList,
                                setPaginatedWorkPeriod
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(workPeriodList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-3 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex align-items-center">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedWorkPeriod({
                                      ...searchedWorkPeriod,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-5">
                            <ul className="t-list d-flex align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {pagination(
                                    searchedWorkPeriod.list,
                                    paginatedSearched
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-4">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(searchedWorkPeriod.list)}
                                  </span>
                                </li>
                              </ul>    
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>

      {/*User list Modal*/}
      <div
        className={`modal fade ${
          tillModal !== false ? "showing" : ""
        }`}
        id="customerAddress"
        aria-hidden="true"
        style={{
          zIndex:14999
        }}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header align-items-center py-2">
              <div className="fk-sm-card__content" style={{fontSize:"1.2rem",fontWeight:"600"}}>
              User List 
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setTillModal(false);
                  setTillUser({
                    id:null,
                    user_name:null,
                    workperiod_id: null,
                   });
                  toast.error(
                    `${_t(
                      t("All submitted orders need to be settled to end workperiod")
                    )}`,
                    toastObj
                  );
                }}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form onSubmit={(e) =>{
                  e.preventDefault();
                  handleOrderTranfer()
                }}>
                <div className="mb-1 px-1">
                <span style={{fontSize:"0.88rem",fontWeight:"400"}}>Please cash out all submitted order or transfer all sumbited orders.</span>
                </div>
                  <div className="modal-scroll">
                    {
                      users && users?.map((user,index)=>{
                        return(
                          <label key={index} className="d-flex align-items-center add-container cursor-pointer">
                          <input
                          type="radio"
                          id={user.id}
                          name="user"
                          value={user.name}
                          checked={user.id === tillUser.id}
                          // defaultChecked={index === 0}
                          onChange={changeUser}
                          />
                          <span className="ml-2 d-flex align-items-center">
                          {user.name}
                          </span>
                          </label>
                        )
                      })
                    }
                  </div>

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"                         
                        >
                          Transfer Order
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                          onClick={(e) => {
                            setTillModal(false);
                            setTillUser({
                              id:null,
                              user_name:null,
                              workperiod_id: null,
                             });
                            toast.error(
                              `${_t(
                                t("All submitted orders need to be settled to end workperiod")
                              )}`,
                              toastObj
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
      className={`modal fade ${
        stockModal !== false ? "showing" : ""
      }`}
      id="stock"
      aria-hidden="true"
      style={{
        zIndex:14999
      }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header align-items-center py-2">
            <div className="fk-sm-card__content" style={{fontSize:"1.2rem",fontWeight:"600"}}>
             Physical Stock
            </div>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                setStockModal(false);
                setStockDayEnd([]);
                setStockData({})
              }}
            ></button>
          </div>
          <div className="modal-body">
            {/* show form or show saving loading */}
            <div key="fragment-food-group-1">
              <form onSubmit={(e) =>{
                e.preventDefault();
                handleStockSubmit()
              }}>
              <div className="mb-1">
              <span style={{fontSize:"0.88rem",fontWeight:"400"}}>Stock Items.</span>
              </div>
              <div style={{fontWeight:"500"}}  className="lg-text d-flex justify-content-between align-items-center my-2">
                <div className="mr-3" style={{flex:2}}>
                  Item Name
                </div >
                <div className="mr-3 pl-1" style={{flex:1}}>
                  Qty
                </div >
                <div className="mr-3" style={{flex:1}}>
                  Unit
                </div>
              </div>
                <div className="modal-scroll">
                  {
                    ingredientItemForSearch && ingredientItemForSearch?.map((item,index)=>{
                      return(
                        <div key={index} className="d-flex justify-content-between align-items-center my-1">
                         <div className="mr-3" style={{flex:2}}>
                         <label>
                            {item.name}
                         </label>
                         </div>
                         <div className="mr-3 " style={{flex:1}}>
                          <input
                           type="number"
                           onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                           name="qty"
                           className="form-control"
                           value={stockDayEnd[item?.id]?.qty || ""}
                           onChange={(e)=>handleStock(e.target.value,item)}
                           placeholder="qty"
                          />
                          </div >
                          <div className="mr-3" style={{flex:1}}>
                            {item.unit}
                          </div>
                         </div>
                      )
                    })
                  }
                </div>

                <div className="mt-4">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                        data-dismiss="modal"                         
                      >
                        Stock Submit
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="button"
                        className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                        data-dismiss="modal"
                        onClick={(e) => {
                          setStockModal(false);
                          setStockDayEnd([]);
                          setStockData({})
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <WorkPeriodPrint
      zReportData = {zReportData}
      zReportRef = {zReportRef}
      cashRegisterData = {cashRegisterData}
      cashRegisterRef = {cashRegisterRef}
      zCatgData = {zCatgData}
      catgReport = {catgReport}
      zOrderTypeData = {zOrderTypeData}
      orderTypeReport ={orderTypeReport}
      productMixReport = {productMixReport}
      zProductMix = {zProductMix}
      dayCatgReport = {dayCatgReport}
      dayCatgMix = {dayCatgMix}
      dayCatgMixDetail = {dayCatgMixDetail}
      dayCatgMixDealDetail = {dayCatgMixDealDetail}
      dayProductMix = {dayProductMix}
      branch={branch}
      catgWiseReport = {catgWiseReport}
      varianceReportRef = {varianceReportRef}
      varianceData = {rawMaterialStock}
      varianceReportFGRef = {varianceReportFGRef}
      varianceDataFG = {finishedGoodsStock} 
    />
    </>
  );
};

export default WorkPeriod;
