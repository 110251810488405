import React, { useContext, useState, useRef, useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../src/BaseUrl";
import {db} from '../Firebase';
import { toast } from "react-toastify";
import { SettingsContext } from "../contexts/Settings";
import { UserContext } from "../contexts/User";
import { FoodContext } from "../contexts/Food";
import { RestaurantContext } from "../contexts/Restaurant";
import { ref, off, onValue, query} from "firebase/database";
import { getAuth, signInWithCustomToken } from "firebase/auth";

//3rd party packages
import ReactPaginate from "react-paginate";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Cookies from "universal-cookie";
//importing ends
const cookies = new Cookies();

// functions starts here
//console functions, clear and dummy text like facebook
const consolee = () => {
  var cssStop = "color: Red; font-size: 50px; font-weight: bold;";
  var cssText = "color: Black; font-size: 18px; font-weight: bold;";
  console.clear();
  console.log("%cStop!", cssStop);
  console.log(
    "%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or hack someone's account, it is a scam.",
    cssText
  );
};

// get offline orders
const handleGetOrders = async (localUrl) =>{
  try {
    const response = await axios.get(localUrl, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    });
    return response.data; 
  } catch (error) {
    console.error("Error fetching orders:", error);
    return error.response?.data; 
  }
}

// net check api
const checkNetworkStatus = async () => {
  try {
    const url = BASE_URL + "/check-net";
    const response = await axios.get(url,{
      timeout: 3000, 
    });
    return parseInt(response.data) === 1
  } catch (error) {
    return false;
  }
}

 //select style
 const customStyle = {

  control: (provided) => ({
    ...provided,
    minHeight: "2rem",
    marginTop: "0",
    marginBottom: "0",
  }),
  singleValue: (provided) => ({
    ...provided,
    alignSelf: "center",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.5rem",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 0.4rem",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
   margin: "0.4rem 0",// or any other custom styling
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999, // Ensure the menu is above other elements
  }),
  clearIndicator:(provided) => ({
    ...provided,
   padding: "0 0.4rem",// or any other custom styling
  }),  

};

//translation functions
let _t = (text) => {
  // let localLang = localStorage.getItem("i18nextLng");
  // if (localLang.includes("en")) {
  //   const url = BASE_URL + `/settings/save-to-en`;
  //   let formData = {
  //     key: text,
  //   };
  //   axios.post(url, formData);
  // }
  return text;
};

//Get cookie of authentication
const getCookie = () => {
  if (cookies.get("_user") !== undefined) {
    let token =
      cookies.get("_user") +
      cookies.get("sbb") +
      cookies.get("frr") +
      cookies.get("xss");
    return token;
  }
};

const getFirebaseCookie = () => {
  if (cookies.get("_fb-user") !== undefined) {
    let token = cookies.get("_fb-user") + cookies.get("fb");
    return token;
  }
};


//get firebase auth token
const getFirebaseToken = async(endPoint)=>{
  if(endPoint && getCookie()){
    try {
      const url = BASE_URL + `/settings/fb_token/${endPoint}`;
      const res = await axios.get(url,{
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      if(res.data){
        const access_firbase = {
          _user: res.data.slice(0, 10),
          fb: res.data.slice(10),
        };
        const dateHour = new Date();
        dateHour.setMinutes(dateHour.getMinutes() + 40);
        
        cookies.set("_fb-user", access_firbase._user, {
          path: "/",
          expires: dateHour,
          sameSite: "lax",
        });
        cookies.set("fb", access_firbase.fb, {
          path: "/",
          expires: dateHour,
          sameSite: "lax",
        });
        return res.data;     
      }
    } catch (error) {
      console.log("firebase auth",error);
    };
  }
}

const toastObj = {
  autoClose: 7000,
  position: "bottom-center",
  style: {
    fontSize: "16.5px", // Set the font size in the style object
  },
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
};

//sync offline order to server
const offlineOrderSync = async(order) =>{
  const url = BASE_URL + "/settings/sync-offline-orders-to-server";
  const formData = {
    ...order
  };
  try{
    let result = await axios.post(url,formData,{
      headers: { Authorization: `Bearer ${getCookie()}` },
    });
    if(result?.data){
      return result.data;
    }else{
      return false
    }
  }catch(err){
    return false
  }    
}

//sync offline order to server url
const offlineOrderSyncUrl = (endPoint) =>{
  return `http://localhost:8081/offline_pos/api` + endPoint
}

//sync offline order to local
const offlineOrderSyncToLocal = async(orderId) =>{
  const endPoint = "/get-offline-sync-orders-update";
  const url = offlineOrderSyncUrl(endPoint);
  const formData = {
    order_id: orderId,
  };
  try{
    let result = await axios.post(url,formData,{
      headers: { Authorization: `Bearer ${getCookie()}` },
    });
    if(result?.data){
      return result.data;
    }
  }catch(err){
    return false
  }    
}

// multiple re use functions for firabse etc.
const useCustomHooks = () =>{
  const { generalSettings, setSyncOrder } = useContext(SettingsContext);
  const { authUserInfo } = useContext(UserContext);
  const { getSubmittedOrders, setOrderCounter, setBellRing, audioInstance } = useContext(RestaurantContext);
  const { getOnlineOrders,  getFoodItemsForPOS, getFoodItemsForCallCenter, getFoodPandaOrders } = useContext(FoodContext)
  const intervalRef = useRef(null);
  const onlineOrderQuery = useRef(null);

  //firebase authentication
  const firebaseAuth = useCallback(async(url, callback)=>{
    const auth = getAuth();
    if(url){
      let cookie = getFirebaseCookie();
      if(!cookie){
        cookie = await getFirebaseToken(getSystemSettings(generalSettings, "firebase_collection"));
      }
      try {
        if(cookie){
          const userCredential = await signInWithCustomToken(auth, cookie);
          console.log("Abcd45",userCredential)
          if(userCredential?.user?.uid){
            onlineOrderQuery.current = query(ref(db,url));
            // Detach any previous listeners
            off(onlineOrderQuery.current)
            onValue(
              onlineOrderQuery.current,
              (snapshot) => {
                const res = snapshot.val();
                if(callback){
                  callback(null,res); // Resolve the promise with the result
                }
              },
              (error) => {
                if(callback){
                  callback(error,null); // Resolve the promise with the result
                }
              }
            );
          }else{
            if(callback){
              callback(new Error("Authentication failed"), null);
            }
          }
        }
      } catch (error) {
        if (typeof callback === "function") callback(error, null);
      };
    }else{
      if (typeof callback === "function") callback(new Error("Url not found"), null);
    }
  },[generalSettings])

  //round off price
  const roundOffPrice = useCallback((price) =>{
    if(parseInt(getSystemSettings(generalSettings, "order_round_off")) === 1){
      return Math.round(price)
    }else{
      return Number(price).toFixed(2)
    }
  },[generalSettings]);

   //offline
   const handleSubmitOffline = useCallback(async(formData,url) =>{
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      // Show success toast if data is returned
      if (data) {
        toast.success(`${_t(_t("Order Saved To Local"))}`, toastObj);
        return true;
      }
    } catch (error) {
      if(navigator.onLine){
        toast.error(`${_t(_t("Please contact support"))}`, toastObj);
      }else{
        toast.error(`${_t(_t("Please check internet"))}`, toastObj);
      }
      return false;
    }
  },[]);

  // total cash card price acc to tax
  const totalPriceWithTax = useCallback((total,subtotal,vat,discount,branch,key) =>{
    let totalPrice = parseFloat(total);
    const foundKey = branch && branch.payment_tax && Object.keys(branch.payment_tax).find(k => k.toLowerCase() === key);
    if(foundKey && branch.payment_tax[foundKey] && branch.payment_tax[foundKey] > 0){
      const calAmount = getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after" ? (parseFloat(subtotal) - parseFloat(discount)) : parseFloat(subtotal);
      const inclusive_vat = calAmount * (parseFloat(branch.payment_tax[foundKey]) / 100);
      const previous_amount = parseFloat(total) - parseFloat(vat);
      totalPrice = previous_amount + inclusive_vat;
    }
    const isRoundOff = parseInt(getSystemSettings(generalSettings, "order_round_off")) === 1;
    const finalPrice = isRoundOff ? Math.round(totalPrice) : totalPrice.toFixed(2);
    return [finalPrice,foundKey && branch && branch.payment_tax[foundKey]]
  },[generalSettings]);
  
  //cal net bill cash card
  const renderNetBill = useCallback((total, cartSubTotal, theVat, discount, branch, key, label) => {
    const [netBill, percent] = totalPriceWithTax(
      total,
      cartSubTotal,
      theVat,
      discount,
      branch,
      key
    );
  
    return (
      <div className="d-flex align-items-center justify-content-between my-1 mx-1">
        <div className="fk-print-text genAcc-select text-capitalize">
          {label}{percent > 0 && ` (${percent}%)`}
        </div>
        <div className="fk-print-text genAcc-select text-capitalize">
          {netBill}
        </div>
      </div>
    );
  },[generalSettings]);

  //tax percent
  const taxPercent = useCallback((total,vat,discount) => {
    if(parseFloat(vat) > 0){
      if(getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after"){
       let totalAmount = parseFloat(total) - parseFloat(discount);
       let vatPercentage = ((parseFloat(vat) / totalAmount) * 100).toFixed(0);
       return vatPercentage;
      }else{
        return ((parseFloat(vat)/parseFloat(total)) * 100).toFixed(0) 
       }
    }else{
      return 0;
    }
  },[generalSettings])
 
  //get order counter
  const getOnlineOrderCount = useCallback(async(order, text = "online") =>{
    const [online, ready, foodPanda] = order;
    console.log("Abcd49",online,order)
    if (audioInstance.current) {
      audioInstance.current.pause();
      audioInstance.current.currentTime = 0;
    }

    audioInstance.current = new Audio("/assets/beep/notify-new.mp3");
    
    if(online > 0){
      toast.success(`You have recieved (${online}) ${text} order`,toastObj);
    }
    if(online > 0 || ready > 0 || foodPanda > 0) {
      if(audioInstance.current){
        audioInstance.current.loop = true;
        audioInstance.current.play();
        setBellRing(true);
      }
    }
    setOrderCounter((curr) => {
      const newData = {
        ...curr,
        online: online,
        foodPanda: foodPanda,
        ready: ready,
      }
      return newData;
    });
  },[])

  //online order data
  const onlineOrders = useCallback(async(type = "") => {
    const pendingUrl = BASE_URL + "/website/pending-order";
    try{
      console.log("Abcd46",type)
      const res = await axios.get(pendingUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      if(res){
        console.log("Abcd47",res)
        if (res.data) {
          if(res.data?.length){
            const [online, ready, fp] = res.data;
            if(type === "online"){
              if(online > 0){
                getOnlineOrderCount(res.data);
                getOnlineOrders();
              }
            }else if(type === "fp"){
              if(fp > 0){
                getOnlineOrderCount(res.data, "food panda");
                getFoodPandaOrders();
              }
            }else{
              console.log("Abcd48",res)
              getOnlineOrderCount(res.data)  
            }
          }
        }
      }
    }catch (error){
      console.log("error order pending api",error)
    }     
  },[])

  //firbase online order
  const getFirbaseOnlineOrder = useCallback((type = "") =>{
    if(authUserInfo?.details){
      let url = "pos/" + getSystemSettings(generalSettings, "firebase_collection") + "/online_orders";
      if(authUserInfo.details?.user_type === "staff" || authUserInfo.details?.user_type === "manager"){
        url = "pos/" + getSystemSettings(generalSettings, "firebase_collection") + "/online_orders/" + authUserInfo?.details?.branch_id
      }
     
      firebaseAuth(url, (error, res) => {
        console.log("Abcd",res,url,authUserInfo.details?.branch_id)
        if (error) {
          console.error("Firebase Error:", error);
          return;
        }
        if(res){
          let anyNotified = false;
          console.log("Abcd44",authUserInfo.details)
          if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
            if(res?.message === "Notified"){
              console.log("Abcd45",res.message)
              anyNotified = true;
            }
          }else{
            // Loop through the objects
            for (const key in res) {
              if (res[key]?.message === "Notified") {
                  anyNotified = true;
                  break; // Stop the loop once a "Notified" message is found
              }
            }
          }
          if(anyNotified){
            console.log("Abcd46",anyNotified)
            onlineOrders(type)
          }
        }
      })
    }
    
  },[generalSettings, authUserInfo]);

   //firbase online oder
   const getFirbaseRunningOrder = useCallback(() =>{
    if(authUserInfo?.details){
      let url = "pos/" + getSystemSettings(generalSettings, "firebase_collection") + "/newOrder";
      if(authUserInfo.details?.user_type === "staff" || authUserInfo.details?.user_type === "manager"){
        url = "pos/" + getSystemSettings(generalSettings, "firebase_collection") + "/newOrder/" + authUserInfo?.details?.branch_id
      }
      
      firebaseAuth(url, (error, res) => {
        if (error) {
          console.error("Firebase Error:", error);
          return;
        }
        if(res){
          let anyNotified = false;
          if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
            if(res?.message === "Update"){
              anyNotified = true;
            }
          }else{
            // Loop through the objects
            for (const key in res) {
              if (res[key]?.message === "Update") {
                anyNotified = true;
                break; // Stop the loop once a "Notified" message is found
              }
            }
          }
          if(anyNotified){
            getSubmittedOrders()
          }
        }
      })
    }
    
  },[generalSettings, authUserInfo]);


  const checkOnlineOrders = useCallback((type = "") =>{
    if(authUserInfo?.details){
      if(getSystemSettings(generalSettings, "firebase_collection")){
        getFirbaseOnlineOrder(type);
      }else{
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
          onlineOrders(type);
        }, 90000);
      }  
    }
  },[generalSettings, authUserInfo])

  // stock qty from firebase
  const fetchStock = useCallback((branch = null) => {
    if(authUserInfo?.details){
      let stockUrl;
      if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
        stockUrl = 'pos/' + getSystemSettings(generalSettings, "firebase_collection") + '/finished_goods/' + authUserInfo.details?.branch_id;
      }else if(branch !== null){
        stockUrl = 'pos/' + getSystemSettings(generalSettings, "firebase_collection") + '/finished_goods/' + branch.id;
      }
      
      firebaseAuth(stockUrl, (error, data) => {
        if (error) {
          console.error("Error in fetchDataAndProcess:", error);
          return;
        }
        if(data){
          if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager"){
            getFoodItemsForPOS();
          }else{
            getFoodItemsForCallCenter(branch?.id)
          }
        }
      })
    };  
   
  },[generalSettings, authUserInfo]);

  const getOfflineApiUrl = useCallback((endpoint) => {
    if(authUserInfo?.details){
      // Check if offline mode is enabled
      const isOfflineEnabled = parseInt(authUserInfo.details?.offline_enable) === 1;
      if (isOfflineEnabled) {
        return `http://localhost:8081/offline_pos/api` + endpoint;
      } else {
        return BASE_URL + endpoint;
      }
    }
   },[authUserInfo]);

  //sync local order
  const syncLocalOrders = useCallback(async (type = "") => {
    setSyncOrder(true);
    try{
      const endPoint = "/get-offline-sync-orders"
      const localUrl = offlineOrderSyncUrl(endPoint);
      const getOrders = await handleGetOrders(localUrl);
      if(!getOrders || getOrders.length === 0){
        if(type === "sync"){
          toast.error("No offline orders to sync", toastObj);
        }
        setSyncOrder(false);
        return;
      }
      let count = 0;
      if(getOrders && getOrders?.length){
        for (const order of getOrders) {
          await new Promise(resolve => setTimeout(resolve, 3000));
          try {
            const onlineSync = await offlineOrderSync(order);
            console.log("abcd43",order, onlineSync)
            if(onlineSync){
              count++;
              await offlineOrderSyncToLocal(onlineSync)
            }else{
              toast.error("Error occured during sync", toastObj)
              break;
            }
          } catch (error) {
            console.error("Error:", error);
          }
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
        if(count >= getOrders?.length){
          toast.success("Local settle order has been resorted",toastObj)
        }
        setSyncOrder(false);
      }
    }catch{
      setSyncOrder(false);
      toast.error("Can't get orders", toastObj)
    }
  },[]);

  return {syncLocalOrders, getOfflineApiUrl, getFirbaseOnlineOrder, getFirbaseRunningOrder, fetchStock, firebaseAuth, onlineOrderQuery, intervalRef, roundOffPrice, taxPercent, totalPriceWithTax, onlineOrders, handleSubmitOffline, renderNetBill, checkOnlineOrders}
}

 export const getApiUrl = (endpoint, authUserInfo) => {
  if(authUserInfo?.details){
    // Check audioInstance, if offline mode is enabled
    const isOfflineEnabled = parseInt(authUserInfo.details?.offline_enable) === 1;
    if (isOfflineEnabled) {
      return `http://localhost:8081/offline_pos/api` + endpoint;
    } else {
      return BASE_URL + endpoint;
    }
  }
 }

const checkInstallOffline = async () => {
  const url = BASE_URL + "/check-net";
    try{
      let result = await axios.get(url,{
        timeout: 3000,
      });
      if(result.data){
        return false
      }
    }catch(err){
        return true
    } 
}    

//check permission
const checkPermission = (authUserPermissions, permission) => {
  if (authUserPermissions !== false)
    if (authUserPermissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
};

// show property price
const showPropertyPrice=(qty,propQty,price,taxType,branchTax)=>{
  let propPrice = 0;
  if (taxType === "inclusive") {
    propPrice = qty * propQty * price;
    let finalPrice = propPrice / (1 + branchTax / 100);
    return formatPrice(finalPrice);
  }else{
    propPrice = qty * propQty * price;
    return formatPrice(propPrice);
  }
}

 //show price of each item in print
 const showPriceOfEachOrderItemPrint = (thisItem, taxType, branchTax) => {
  let price = 0;
  let tempPropertyPrice = 0;
  if (thisItem.properties !== null && thisItem.properties !== "") {
    let propertyItems = JSON.parse(thisItem.properties);
    propertyItems.forEach((propertyItem, thisIndex) => {
      let temp =
        propertyItem.quantity *
        propertyItem.price_per_qty *
        thisItem.quantity;
      tempPropertyPrice = tempPropertyPrice + temp;
    });
  }
  price = thisItem.price - tempPropertyPrice;
  if (taxType === "inclusive") {
    let finalPrice = price / (1 + branchTax / 100);
    return formatPrice(finalPrice);
  }else{
    return formatPrice(price);
  }
};

//currency format price
const formatPrice = (price) => {
  let localCurrency = JSON.parse(localStorage.getItem("currency"));
  if (localCurrency !== null) {
    return (localCurrency.rate * price).toFixed(2);
  } else {
    return 0;
  }
};
const currencySymbolLeft = () => {
  let localCurrency = JSON.parse(localStorage.getItem("currency"));
  if (localCurrency !== null) {
    if (localCurrency.alignment === "left") {
      return localCurrency.symbol;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
const currencySymbolRight = () => {
  let localCurrency = JSON.parse(localStorage.getItem("currency"));
  if (localCurrency !== null) {
    if (localCurrency.alignment === "right") {
      return localCurrency.symbol;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

//general settings
const getSystemSettings = (settingsArray, checkType) => {
  let tempItem = settingsArray !== null &&
      settingsArray[checkType];
    // settingsArray.find((item) => {
    //   return item.name === checkType;
    // });
  if (tempItem) {
    return tempItem;
  } else {
    return null;
  }
};

const getBeforeTaxPricePayment = (price, theTax) => {
  return (Number(price) - (Number(price) / (1 + (theTax / 100)))).toFixed(2);
};

// cal vat percent on print
const vatPercent = (total, vat)=>{
  if(parseFloat(vat) > 0){
    return ((parseFloat(vat)/parseFloat(total)) * 100).toFixed(0)
  }else{
    return 0;
  }
}

//Delete cookie of authentication
const deleteCookie = async() => {
  const url = BASE_URL + `/auth/logout`;
  try {
     const res = await axios.get(url, {
       headers: { Authorization: `Bearer ${getCookie()}` },
     })
      if(res.data){
        if (cookies.get("_user") !== undefined) {
          cookies.remove("_user", { path: "/" });
        }
        if (cookies.get("sbb") !== undefined) {
          cookies.remove("sbb", { path: "/" });
        }
        if (cookies.get("frr") !== undefined) {
          cookies.remove("frr", { path: "/" });
        }
        if (cookies.get("xss") !== undefined) {
          cookies.remove("xss", { path: "/" });
        }
        if (cookies.get("_fb-user") !== undefined) {
          cookies.remove("_fb-user", { path: "/" });
        }
        if (cookies.get("fb") !== undefined) {
          cookies.remove("fb", { path: "/" });
        }
        window.location.reload(true);
      }
  } catch(error){
    if (cookies.get("_user") !== undefined) {
      cookies.remove("_user", { path: "/" });
    }
    if (cookies.get("sbb") !== undefined) {
      cookies.remove("sbb", { path: "/" });
    }
    if (cookies.get("frr") !== undefined) {
      cookies.remove("frr", { path: "/" });
    }
    if (cookies.get("xss") !== undefined) {
      cookies.remove("xss", { path: "/" });
    }
    if (cookies.get("_fb-user") !== undefined) {
      cookies.remove("_fb-user", { path: "/" });
    }
    if (cookies.get("fb") !== undefined) {
      cookies.remove("fb", { path: "/" });
    }
    window.location.reload(true);
  }  
};

//restaurant dashboard menu links
const restaurantMenuLink = (
  img,
  imgAlt,
  icon,
  infoTextColor,
  info,
  title,
  redirectTo
) => {
  return (
    <div className="col-md-6 col-lg-4 t-mb-15">
      <NavLink to={redirectTo} className="t-link product-card t-bg-white w-100">
        <div className="product-card__head w-100 text-center">
          <img src={img} alt={imgAlt} className="img-fluid" />
        </div>
        <div className="product-card__body w-100">
          <div className="product-card__add">
            <span className="product-card__add-icon">
              <span className="las la-plus"></span>
            </span>
          </div>
          <span
            className={`product-card__sub-title ${infoTextColor} text-uppercase`}
          >
            <span className={icon}></span> {info}
          </span>
          <span className="product-card__title text-capitalize">{title}</span>
        </div>
      </NavLink>
    </div>
  );
};

//manage page mobile screen link
const managePageHrefLinkMobile = (redirectTo, menuName) => {
 // if(authUserInfo.permissions !== null && checkPermission(authUserInfo.permissions, menuName)) {
  if (window.location.pathname === redirectTo) {
    return (
      <li
        className={`fk-sm-nav__list  ${
          window.location.href.includes(redirectTo) && "active"
        }`}
      >
        <NavLink
          to={{ pathname: "/refresh", state: redirectTo }}
          exact
          className={`t-link fk-sm-nav__link`}
        >
          {menuName}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li
        className={`fk-sm-nav__list  ${
          window.location.href.includes(redirectTo) && "active"
        }`}
      >
        <NavLink to={redirectTo} exact className={`t-link fk-sm-nav__link`}>
          {menuName}
        </NavLink>
      </li>
    );
  } 
  // }else{
  //   ""

  // }

};

//manage page navlink
const managePageHrefLink = (redirectTo, menuName) => {
  //if(authUserInfo.permissions !== null && checkPermission(authUserInfo.permissions, menuName)) {
  if (window.location.pathname === redirectTo) {
    return (
      <li className="fk-pos-nav__sub-list border-bottom">
        <NavLink
          to={{ pathname: "/refresh", state: redirectTo }}
          exact
          className={`w-100 sm-text t-text-dark btn font-weight-bold text-capitalize rounded-0 text-left ${
            window.location.href.includes(redirectTo) && "active"
          }`}
        >
          - {menuName}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className="fk-pos-nav__sub-list border-bottom">
        <NavLink
          to={redirectTo}
          exact
          className={`w-100 sm-text t-text-dark btn font-weight-bold text-capitalize rounded-0 text-left ${
            window.location.href.includes(redirectTo) && "active"
          }`}
        >
          - {menuName}
        </NavLink>
      </li>
    );
  }
// }else{
//   ""
// }
};

//pagination
const pagination = (data, customFunction) => {
  return (
    <ReactPaginate
      pageCount={data && data.last_page}
      initialPage={0}
      marginPagesDisplayed={5}
      pageRangeDisplayed={2}
      onPageChange={(page) => {
        customFunction(page.selected + 1);
      }}
      breakLabel={". . ."}
      breakClassName={"px-2"}
      containerClassName={"t-list d-flex"}
      pageClassName={"t-list__item mr-0"}
      previousLabel={<i className="las la-angle-double-left"></i>}
      nextLabel={<i className="las la-angle-double-right"></i>}
      pageLinkClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      previousClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      nextClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      activeClassName={"pagination-active"}
      activeLinkClassName={"text-white"}
    />
  );
};

// pagination loading
const paginationLoading = () => {
  return <Skeleton style={{ height: "40px" }} className="card bg-white" />;
};

// modal loading ff7675
const modalLoading = (count) => {
  return (
    <SkeletonTheme color="#0dd19d" highlightColor="#dfe4ea">
      <p>
        <Skeleton count={count} />
      </p>
    </SkeletonTheme>
  );
};

// manage page table loading
const tableLoading = () => {
  return (
    <SkeletonTheme color="#f1f2f6" highlightColor="#dfe4ea">
      <p>
        <Skeleton style={{ height: `calc(100vh - 222px)` }} />
      </p>
    </SkeletonTheme>
  );
};

// table loading
const pageLoading = () => {
  return (
    <SkeletonTheme color="#f1f2f6" highlightColor="#dfe4ea">
      <p>
        <Skeleton style={{ height: `calc(100vh - 187px)` }} />
      </p>
    </SkeletonTheme>
  );
};

// data count details of tables
const showingData = (data) => {
  return (
    <>
      {/* todo:: translation function call */}
      Showing {data && data.from} - {data && data.to} of {data && data.total}
    </>
  );
};

// searched data count details of table
const searchedShowingData = (data, allData) => {
  return (
    <>
      {/* todo:: translation function call */}
      Showing {data && data.list.length} of {allData && allData.length}
    </>
  );
};

//pagination of order history
const paginationOrderHistory = (data, customFunction) => {
  return (
    <ReactPaginate
      pageCount={data && data.meta.last_page}
      initialPage={0}
      marginPagesDisplayed={5}
      pageRangeDisplayed={2}
      onPageChange={(page) => {
        customFunction(page.selected + 1);
      }}
      breakLabel={". . ."}
      breakClassName={"px-2"}
      containerClassName={"t-list d-flex"}
      pageClassName={"t-list__item mr-0"}
      previousLabel={<i className="las la-angle-double-left"></i>}
      nextLabel={<i className="las la-angle-double-right"></i>}
      pageLinkClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      previousClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      nextClassName={
        "t-link t-pt-5 t-pb-5 t-pl-10 t-pr-10 paginav__link paginav__link--light border text-capitalize sm-text"
      }
      activeClassName={"pagination-active"}
      activeLinkClassName={"text-white"}
    />
  );
};

// data count details of tables of order history
const showingDataOrderHistory = (data) => {
  return (
    <>
      {/* todo:: translation function call */}
      Showing {data && data.meta.from} - {data && data.meta.to} of{" "}
      {data && data.meta.total}
    </>
  );
};

// searched data count details of table of order history
const searchedShowingDataOrderHistory = (data, allData) => {
  return (
    <>
      {/* todo:: translation function call */}
      Showing {data && data.list.length} of {allData && allData.data.length}
    </>
  );
};

// get date pattern for save api
const getDate = (date) => {
  let date_for_api =
  new Date(date).getFullYear() +
  "-" +
  (new Date(date).getMonth() + 1) +
  "-" +
  new Date(date).getDate();
  return date_for_api;
}

//get offline status
const getOffline = async(url) => {
  const network = await checkNetworkStatus();
  return !network && localStorage.getItem(url) != null;
};

//export here
export {
  checkInstallOffline,
  // common & necessary
  _t,
  consolee,
  getOffline,
  getCookie,
  deleteCookie,
  checkPermission,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
  getSystemSettings,
  useCustomHooks,
  // common & necessary

  //navLink
  restaurantMenuLink,
  managePageHrefLinkMobile,
  managePageHrefLink,
  //navLink

  //loading
  pageLoading,
  modalLoading,
  tableLoading,
  paginationLoading,
  //loading

  //pagination and datacounting
  pagination,
  paginationOrderHistory,
  showingData,
  searchedShowingData,
  showingDataOrderHistory,
  searchedShowingDataOrderHistory,
  //pagination and datacounting
  vatPercent,
  //sync order
  offlineOrderSyncUrl,
  // print price
  showPropertyPrice,
  showPriceOfEachOrderItemPrint,
  getBeforeTaxPricePayment,
  handleGetOrders,
  // firebase authentication
  getFirebaseToken,

  //select style
  customStyle,
  //net api
  checkNetworkStatus,

  //get api date
  getDate,
};
/*
----------------------------------------------
                      Ends
----------------------------------------------
*/
