import React, { useState, useContext, useEffect } from "react";
import { NavLink, Link, withRouter, useHistory } from "react-router-dom";
import  Expense  from "../restaurant/public/expense-icon.png";
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import axios from "axios";
//base url
import { BASE_URL } from "../../BaseUrl";
import { confirmAlert } from "react-confirm-alert";
// //media css
// import "./navmedia.css";


//pages, functions
import {
  _t,
  getCookie,
  deleteCookie,
  getSystemSettings,
  checkPermission,
  checkNetworkStatus,
  useCustomHooks,
  offlineOrderSyncUrl,
  handleGetOrders
} from "../../functions/Functions";

//context consumer
import { SettingsContext } from "../../contexts/Settings";
import { UserContext } from "../../contexts/User";
import { FoodContext } from "../../contexts/Food";

//3rd party packages
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
const Navbar = (props) => {
  const cookies = new Cookies();
  const { firebaseAuth } = useCustomHooks();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isOnline, setIsOnline] = useState(true);

  //check if offline helper
  const offline = async(url) => {
    const network = !(await checkNetworkStatus())
    return network && localStorage.getItem(url) != null;
  };

  useEffect(() => {
    const checkIfOffline = async () => {
      const url = BASE_URL + "/auth/user";
      const isOffline = await offline(url);
      setIsOnline(isOffline);
    };
    checkIfOffline();
  }, []);

  //getting context values here
  let { navLanguageList, navCurrencyList, generalSettings } = useContext(
    SettingsContext
  );
  let { authUserInfo } = useContext(UserContext);
  
  let {
    setLoading,
    //food group
    foodGroupForSearch,
  } = useContext(FoodContext);

  // States hook  here
  const [defaultLang, setDefaultLang] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(document.fullscreenElement != null);
  
  const handleResize = () => {
    if (isFullscreen) {
      // if (document.fullscreenElement) {
      //   const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
      //   document.documentElement.style.height = `${viewportHeight}px`;
      // }
      const activeElement = document.activeElement;
      if (activeElement && (activeElement.tagName.toUpperCase() === 'INPUT' || activeElement.tagName.toUpperCase() === 'SELECT' || activeElement.classList.contains('css-b62m3t-container'))) {
        const elementRect = activeElement.getBoundingClientRect();
        const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
        if ((elementRect.top > viewportHeight) && viewportHeight / 2) {
          activeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: "nearest" });
        } else {
          activeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: "nearest" });
        }
      }
    }
  };

  useEffect(() => {

    const handleFullscreenChange = () => {     
      if (document.fullscreenElement) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    };

    window.visualViewport.addEventListener('resize', handleResize);
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFullscreen]);

  useEffect(() => {
    handleOnLoadDefaultLang();
    handleOnLoadDefaultCurrency();
  }, [navLanguageList, navCurrencyList]);


  //set default language on site load
  const handleOnLoadDefaultLang = () => {
    let localLang = localStorage.i18nextLng;
    if (localLang) {
      if (localLang === undefined || localLang.includes("en-")) {
        navLanguageList &&
          navLanguageList.map((item) => {
            if (item.is_default === true) {
              i18n.changeLanguage(item.code);
              setDefaultLang(item);
            }
            return true;
          });
      } else {
        const temp =
          navLanguageList &&
          navLanguageList.find((item) => {
            return item.code === localLang;
          });
        setDefaultLang(temp);
        i18n.changeLanguage(localLang);
      }
    }
  };

  //change language to selected
  const handleDefaultLang = (lang) => {
    i18n.changeLanguage(lang.code);
    setDefaultLang(lang);
    toast.success(`${_t(t("Language has been switched!"))}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };

  //set default currency on site load
  const handleOnLoadDefaultCurrency = () => {
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    if (localCurrency === null) {
      navCurrencyList &&
        navCurrencyList.map((item) => {
          if (item.is_default === true) {
            setDefaultCurrency(item);
            localStorage.setItem("currency", JSON.stringify(item));
          }
          return true;
        });
    } else {
      const temp =
        navCurrencyList &&
        navCurrencyList.find((item) => {
          return item.code === localCurrency.code;
        });
      setDefaultCurrency(temp);
    }
  };

  //change currency to selected
  const handleDefaultCurrency = (item) => {
    setLoading(true);
    localStorage.setItem("currency", JSON.stringify(item));
    setDefaultCurrency(item);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    toast.success(`${_t(t("Currency has been changed!"))}`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };
   
  //logout
  const handleLogout = async(network) => {
    let checkOffline = true;
    if(network === null){
      checkOffline = await checkNetworkStatus();
    }
    if(!checkOffline){
      history.push("/");
    }
    deleteCookie();
  };

  const handlePasscode = () =>{
    const url = BASE_URL + "/settings/new-passcode";
    let formData = {
      id:authUserInfo.details?.id,
      name:authUserInfo.details?.name,
      branch_id: authUserInfo.details?.branch_id,
    }
    return axios
    .post(url, formData)
    .then((res) => {
      handleManagerPasscode(res)
    })
    .catch((error) => {
      toast.error("Please try again")
     })
  }

   //manager passcode
   const handleManagerPasscode = (res) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body p-4">
            <h3 className="text-center my-0">Manager</h3>
            <p className="text-center mt-2">Passcode: {res.data}</p>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success py-1 px-2" onClick={onClose}>
                {_t(t("cancel"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  // clear cache
  const handleClearCache = async() => {
    const cacheClean = async () =>{
      let checkOffline = await checkNetworkStatus();
      if(checkOffline){
        handleLogout(checkOffline);
      }else{
        localStorage.clear();
        handleLogout(checkOffline)
      }
    }
    cacheClean();
  }

  //dynamic style
  const style = {
    logo: {
      backgroundColor: generalSettings &&
        getSystemSettings(generalSettings, "type_background"),
      backgroundImage: generalSettings &&
        `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
    currency: {
      backgroundColor: generalSettings && getSystemSettings(generalSettings, "type_clock"),
      color: generalSettings && getSystemSettings(generalSettings, "type_color"),
    },
  };

  const [stock,setStock]=useState([]);
  const [showNotify,setShowNotify] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  
  //stock notify firebase and api
  const fetchStockQty = async () => {    
    const itemStockRef = 'pos/' + getSystemSettings(generalSettings, "firebase_collection") + '/raw_material';

    firebaseAuth(itemStockRef, (error, data) => {
      if (error) {
        console.error("Firebase Error:", error);
        return;
      }
      if(data){
        getStockData();
      }
    })
  } 

  useEffect(()=>{
    if(authUserInfo?.details && getCookie()){
      fetchStockQty();
    }
  },[authUserInfo]);

  const handleQB = () => {
    const url = BASE_URL + '/settings/QB-Connecting'; 
    return axios
    .get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
      setLoading(false);
      toast.success(`${_t(t(res.data?.message))}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    })
    .catch((err) => {
      toast.error(`${_t(t(err.response.data?.message))}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      setLoading(false)
    })
  }

  const getStockData = async () => {      
    const url =
    BASE_URL +
    "/settings/get-low-stock-items"
    try {
    const res = await axios
    .get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    });
    if(res && res.data){
     setShowNotify(true);
    if(res.data.length > 0){
      setShowBadge(true);
    }
      res.data.sort((a,b)=>a.stockQty-b.stockQty);
      setStock(res.data)
    }
              // setLoading(false);
    } catch {
    // setLoading(false);
    }
  }

  //fullscreen and back
  var elem = document.documentElement;
  const fullExitScreen = () => {
    if(!document.fullscreenElement){
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen().then(()=>{
          setIsFullscreen(document.fullscreenElement)
        }).catch((err) => {
          console.log(`${err.message} : (${err.name})`);
        });
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen().then(() =>{
          setIsFullscreen(document.fullscreenElement)
        }).catch((err) => {
          console.log(`${err.message} : (${err.name})`);
        });
      }
    }else{
      if (document.exitFullscreen) {
        document.exitFullscreen().then(()=>{
          setIsFullscreen(document.fullscreenElement)
        });
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen().then(()=>{
          setIsFullscreen(document.fullscreenElement)
        });
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen().then(() =>{
          setIsFullscreen(document.fullscreenElement)
        });
      }
    }
  }
  
  return (
    <>
      {props.location.pathname !== "/" &&
        props.location.pathname !== "/profile" &&
        props.location.pathname !== "/profile/" &&
        props.location.pathname !== "/my-orders" &&
        props.location.pathname !== "/my-orders/" &&
        props.location.pathname !== "/login" &&
        props.location.pathname !== "/login/" &&
        props.location.pathname !== "/sign-up" &&
        props.location.pathname !== "/sign-up/" &&
        props.location.pathname !== "/delivery-man-registration" &&
        props.location.pathname !== "/delivery-man-registration/" &&
        props.location.pathname !== "/installation" &&
        props.location.pathname !== "/installation/" &&
        props.location.pathname !== "/installation/permission-chcek" &&
        props.location.pathname !== "/installation/permission-chcek/" &&
        props.location.pathname !== "/installation/database-setup" &&
        props.location.pathname !== "/installation/database-setup/" &&
        props.location.pathname !== "/installation/import-database" &&
        props.location.pathname !== "/installation/import-database/" &&
        props.location.pathname !== "/installation/add-admin-user" &&
        props.location.pathname !== "/installation/add-admin-user/" &&
        props.location.pathname !== "/installation/congratulation" &&
        props.location.pathname !== "/installation/congratulation/" &&
        props.location.pathname !== "/dashboard/pos" &&
        props.location.pathname !== "/dashboard/pos/" &&
        props.location.pathname !== "/reset-password" &&
        props.location.pathname !== "/reset-password/" &&
        props.location.pathname !== "/sales-listing" &&
        !props.location.pathname.includes("/order-details/") &&
        !props.location.pathname.includes("/set-new-password") ? (
        <header id="header" className="sticky-top">
          <div
            className={`${props.location.pathname.includes("/dashboard/kitchen") ||
              props.location.pathname.includes("/dashboard/orders") ||
              props.location.pathname.includes("/dashboard/online-orders")
              ? "container-fluid"
              : "container"
              }`}
          >
            <div className="row align-items-center">
              <div className="col-6 col-lg-2">
                <div className="fk-brand fk-brand--sr-lg">
                  {getCookie() !== undefined || isOnline
                    ? [
                      window.location.pathname === "/dashboard" ? (
                        <NavLink
                        to={{ pathname: "/refresh", state: "/dashboard" }}
                        exact
                        className="t-link w-100"
                        key="logokey"
                        >
                          <span
                            className="fk-brand__img fk-brand__img--fk"
                            style={style.logo}
                          ></span>
                        </NavLink>
                      ) : (
                        <NavLink
                          to="/dashboard"
                          exact
                          className="t-link w-100"
                          key="logokey"
                        >
                          <span
                            className="fk-brand__img fk-brand__img--fk"
                            style={style.logo}
                          ></span>
                        </NavLink>
                      ),
                    ]
                    : [
                      window.location.pathname === "/" ? (
                        <NavLink
                          to={{ pathname: "/refresh", state: "/" }}
                          exact
                          className="t-link w-100"
                        >

                          <span
                            className="fk-brand__img fk-brand__img--fk"
                            style={style.logo}
                          ></span>
                        </NavLink>
                      ) : (
                        <NavLink to="/" exact className="t-link w-100">
                          <span
                            className="fk-brand__img fk-brand__img--fk"
                            style={style.logo}
                          ></span>
                        </NavLink>
                      ),
                    ]}
                </div>
              </div>

              <div className="col-6 col-lg-7 col-xl-6 col-xxl-5 ml-lg-auto">
                <div className="fk-phn-nav text-right d-lg-none">
                  <span className="fk-phn-nav__icon xlg-text">
                    <i className="fa fa-bars"></i>
                  </span>
                </div>
                <div className="fk-phn-nav__menu">
                  <ul className="t-list config-list d-flex flex-column flex-sm-row align-items-sm-center flex-wrap justify-content-md-center justify-content-lg-between justify-content-xl-end">

                    <li className="config-list__item">
                      <button className="text-capitalize sm-text nav-link pl-2 bg-transparent border-0" onClick={()=>fullExitScreen()}>
                        
                        {
                          isFullscreen ? <i class="fa fa-compress xxslg-text text-primary"></i>
                          : <i class="fa fa-expand xxslg-text text-primary"></i>
                        }
                       
                      </button>
                    </li>

                    {showNotify && <li className="config-list__item">
                        <Dropdown title="Stock alert" className="stock-notify-dropdown" onToggle={()=>setShowBadge(false)}>
                          {showBadge && <p className="stock-notify-badge">
                            <Badge bg="btn-success">{stock && stock.length}</Badge>
                          </p>}
                          <Dropdown.Toggle className="custom-toggle" id="dropdown-basic">
                            <i className={`fa fa-bell`} style={{fontSize:"18px"}} aria-hidden="true"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="stock-notify-dropdown-menu">
                            {stock.length > 0 && stock.map((item,index) => {
                              return <Dropdown.Item key={index} className="stock-notify-dropdown-list" disabled>
                                <div style={{ fontSize: "13px" }}>
                                  <div className={`stock-notify-dropdown-item ${parseInt(item.stockQty) < 10 ? 'stock-items-red' : 'stock-items-green'}`}>
                                    <span><strong>Name:</strong> {item?.iiName}</span>
                                    <span><strong>Remaining Qty:</strong> {item?.stockQty}</span>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            })
                            }                        
                          </Dropdown.Menu>
                        </Dropdown>
                    </li>}

                    <li className="config-list__item">
                      <NavLink
                        to={"/dashboard"}
                        className="text-capitalize sm-text nav-link pl-2"
                        title="home"
                      > 
                        <i className="fa fa-home xlg-text text-primary"></i>
                      </NavLink>
                    </li>

                    <li className="config-list__item">
                      <NavLink
                        to={{
                          pathname:"/dashboard/pos",
                        }}
                        className="text-capitalize sm-text nav-link pl-2"
                        title="pos"
                      >
                        <i className="fa fa-cutlery text-primary" style={{fontSize: "20px"}}></i>
                      </NavLink>
                    </li>

                    
                    {authUserInfo.permissions !== null &&
                      checkPermission(authUserInfo.permissions, "Show Expense List") && authUserInfo.details.user_type !== "waiter" ?
                      <li className="config-list__item">
                      <NavLink
                      to={{
                        pathname:"/dashboard/expense",
                        state: {from: history.location.pathname}
                      }}
                      className="text-capitalize sm-text nav-link pl-2"
                      title="expense"
                      >
                      <img
                       height={25}
                       src={Expense} className="text-primary" alt=""/>
                      
                      </NavLink>
                    </li>:null}

                    {/* <li className="config-list__item">
                      <div className="fk-language d-flex align-items-center">
                        <div
                          className="fk-language__flag"
                          style={{
                            backgroundImage: `${defaultLang && `url(${defaultLang.image})`
                              }`,
                          }}
                        ></div>
                        
                        
                        <div className="dropdown">
                          <a
                            className="text-capitalize sm-text nav-link dropdown-toggle pl-2"
                            href="#"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            rel="noopener noreferrer"
                          >
                            {defaultLang ? defaultLang.name : "Language"}
                          </a>
                          <ul className="dropdown-menu">
                            {navLanguageList &&
                              navLanguageList.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <button
                                      type="button"
                                      className={`dropdown-item sm-text text-capitalize ${defaultLang &&
                                        item.code === defaultLang.code
                                        ? "active"
                                        : ""
                                        }`}
                                      onClick={() => handleDefaultLang(item)}
                                    >
                                      {item.name}
                                    </button>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </li> */}
                    {
                      // currency
                    }
                   <li className="config-list__item">
                      <div className="d-flex align-items-center">
                        <div
                          className="circle circle--sm rounded-circle border"
                          style={style.currency}
                        >
                          {defaultCurrency ? defaultCurrency.symbol : "$"}
                        </div>
                        <div className="dropdown">
                          <a
                            className="sm-text nav-link dropdown-toggle pl-2"
                            href="#"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {defaultCurrency
                              ? defaultCurrency.code
                              : "Currency"}
                          </a>
                          <ul className="dropdown-menu">
                            {navCurrencyList &&
                              navCurrencyList.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <button
                                      type="button"
                                      className={`dropdown-item sm-text text-capitalize ${defaultCurrency &&
                                        item.code === defaultCurrency.code
                                        ? "active"
                                        : ""
                                        }`}
                                      onClick={() =>
                                        handleDefaultCurrency(item)
                                      }
                                    >
                                      {item.name}
                                    </button>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="config-list__item">
                      <div className="d-flex align-items-center ">
                        <div className="circle circle--sm rounded-circle avatar">
                          <img
                            src={
                              authUserInfo.details !== null &&
                                authUserInfo.details.image !== null
                                ? [BASE_URL + authUserInfo.details.image]
                                : "/assets/img/user.jpg"
                            }
                            alt=""
                            className="img-fluid avatar__img"
                          />
                        </div>
                        <div className="dropdown">
                          <a
                            className="font-weight-bold text-capitalize sm-text nav-link dropdown-toggle pl-2"
                            href="#"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {authUserInfo.details !== null &&
                              authUserInfo.details.name}
                          </a>
                          <ul className="dropdown-menu dropdown-menu-right">
                              <li>
                                <Link
                                  to="/dashboard"
                                  className="dropdown-item sm-text text-capitalize"
                                >
                                  {_t(t("Dashboard"))}
                                </Link>
                              </li>
                            {/* {window.location.pathname === "/" ? (
                              <li key={'sdf'}>
                                <Link
                                  to="/dashboard"
                                  className="dropdown-item sm-text text-capitalize"
                                >
                                  {_t(t("Dashboard"))}
                                </Link>
                              </li>
                            ) : (
                              <li>
                                <Link
                                  to="/dashboard"
                                  className="dropdown-item sm-text text-capitalize"
                                >
                                  {_t(t("Dashboard"))}
                                </Link>
                              </li>
                            )} */}
                            

                            {
                              !isOnline &&
                              <>
                            <li>
                              <Link
                                to="/update-user-profile"
                                className="dropdown-item sm-text"
                              >
                                {_t(t("Change password"))}
                              </Link>
                            </li>
                            <hr className="my-1" />
                            <li>
                              <button
                              className="dropdown-item sm-text"
                              onClick={handleQB}
                              >
                                {_t(t("Connect to QB"))}
                              </button>
                            </li>
                            <hr className="my-1" />
                            {
                              authUserInfo.details?.user_type === "manager" && (
                                <> 
                                <li>
                                  <button
                                    className="dropdown-item sm-text"
                                    onClick={handlePasscode}
                                  >
                                    {_t(t("Manager Passcode"))}
                                  </button>
                                </li>
                                <hr className="my-1" />
                                </> 
                              )
                            }
                              <li>
                                <button
                                  className="dropdown-item sm-text text-capitalize"
                                  onClick={handleClearCache}
                                >
                                  {_t(t("Clear Cache"))}
                                </button>
                              </li>
                              </>
                            }
                            <hr className="my-1" />
                            <li key={'yyq'}>
                              <button
                                className="dropdown-item sm-text text-capitalize"
                                onClick={()=>handleLogout(null)}
                              >
                                {_t(t("Logout"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        [
          props.location.pathname.includes("/dashboard/pos") &&
          props.location.pathname !== "/" &&
          props.location.pathname !== "/profile" &&
          props.location.pathname !== "/my-orders" &&
          props.location.pathname !== "/delivery-man-registration" && (
            <header id="header" className="sticky-top">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-6 col-lg-2">
                    <div className="fk-brand fk-brand--sr-lg">
                      {getCookie() !== undefined || isOnline
                        ? [
                          window.location.pathname === "/dashboard" ? (
                            <NavLink
                              to={{
                                pathname: "/refresh",
                                state: "/dashboard",
                              }}
                              exact
                              className="t-link w-100"
                            >
                              <span
                                className="fk-brand__img fk-brand__img--fk"
                                style={style.logo}
                              ></span>
                            </NavLink>
                          ) : (
                            <NavLink
                              to="/dashboard"
                              exact
                              className="t-link w-100"
                              key="logokey"
                            >
                              <span
                                className="fk-brand__img fk-brand__img--fk"
                                style={style.logo}
                              ></span>
                            </NavLink>
                          ),
                        ]
                        : [
                          window.location.pathname === "/" ? (
                            <NavLink
                              to={{ pathname: "/refresh", state: "/" }}
                              exact
                              className="t-link w-100"
                            >
                              <span
                                className="fk-brand__img fk-brand__img--fk"
                                style={style.logo}
                              ></span>
                            </NavLink>
                          ) : (
                            <NavLink to="/" exact className="t-link w-100">
                              <span
                                className="fk-brand__img fk-brand__img--fk"
                                style={style.logo}
                              ></span>
                            </NavLink>
                          ),
                        ]}
                    </div>
                  </div>

                  <div className="col-6 col-lg-7 col-xl-6 col-xxl-5 ml-lg-auto">
                    <div className="fk-phn-nav text-right d-lg-none">
                      <span className="fk-phn-nav__icon xlg-text">
                        <i className="fa fa-bars"></i>
                      </span>
                    </div>
                    <div className="fk-phn-nav__menu">
                      <ul className="t-list config-list d-flex flex-column flex-sm-row align-items-sm-center flex-wrap justify-content-md-center justify-content-lg-between justify-content-xl-end">

                      <li className="config-list__item">
                        <button className="text-capitalize sm-text nav-link pl-2 bg-transparent border-0" onClick={()=>fullExitScreen()}>
                          {
                            isFullscreen ? <i class="fa fa-compress xxslg-text text-primary"></i>
                            : <i class="fa fa-expand xxslg-text text-primary"></i>
                          }
                        
                        </button>
                      </li>

                      {showNotify && <li className="config-list__item">
                        <Dropdown className="stock-notify-dropdown" onToggle={()=>setShowBadge(false)}>
                          {showBadge && <p className="stock-notify-badge">
                            <Badge bg="btn-success">{stock && stock.length}</Badge>
                          </p>}
                          <Dropdown.Toggle className="custom-toggle" id="dropdown-basic">
                            <i className={`fa fa-bell`} style={{fontSize:"18px"}} aria-hidden="true"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="stock-notify-dropdown-menu">
                            {stock.length>0 && stock.map((item,index) => {
                              return <Dropdown.Item key={index} className="stock-notify-dropdown-list" disabled>
                                <div style={{ fontSize: "13px" }}>
                                  <div className={`stock-notify-dropdown-item ${parseInt(item.stockQty) < 10 ? 'stock-items-red' : 'stock-items-green'}`}>
                                    <span><strong>Name:</strong> {item?.iiName}</span>
                                    <span><strong>Remaining Qty:</strong> {item?.stockQty}</span>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            })
                            }                        
                          </Dropdown.Menu>
                        </Dropdown>
                    </li>}
                        <li className="config-list__item">
      
                          <NavLink
                            to={"/dashboard"}
                            className="text-capitalize sm-text nav-link pl-2"
                            title="home"
                          >
                            <i className="fa fa-home xlg-text text-primary"></i>
                          </NavLink>
                        </li>
                        <li className="config-list__item">
                          <NavLink
                            to={{
                              pathname:"/dashboard/pos",
                              state: {from: history.location.pathname}
                            }}
                            className="text-capitalize sm-text nav-link pl-2"
                            title="pos"
                          >
                            <i className="fa fa-cutlery text-primary" style={{fontSize: "20px"}}></i>
                          </NavLink>
                        </li>

                        {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Show Expense List") && authUserInfo.details.user_type !== "waiter" ?
                          <li className="config-list__item">
                          <NavLink
                          to={{
                            pathname:"/dashboard/expense",
                          }}
                          className="text-capitalize sm-text nav-link pl-2"
                          title="expense"
                          >
                          <img
                          height={25}
                          src={Expense} className="text-primary" alt=""/>
                          </NavLink>
                        </li>:null}
                        {/* <li className="config-list__item">
                          <div className="fk-language d-flex align-items-center">
                            <div
                              className="fk-language__flag"
                              style={{
                                backgroundImage: `${defaultLang && `url(${defaultLang.image})`
                                  }`,
                              }}
                            ></div>
                            <div className="dropdown">
                              <a
                                className="text-capitalize sm-text nav-link dropdown-toggle pl-2"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                rel="noopener noreferrer"
                              >
                                {defaultLang ? defaultLang.name : "Language"}
                              </a>
                              <ul className="dropdown-menu">
                                {navLanguageList &&
                                  navLanguageList.map((item, index) => {
                                    return (
                                      <li key={index}>
                                        <button
                                          type="button"
                                          className={`dropdown-item sm-text text-capitalize ${defaultLang &&
                                            item.code === defaultLang.code
                                            ? "active"
                                            : ""
                                            }`}
                                          onClick={() =>
                                            handleDefaultLang(item)
                                          }
                                        >
                                          {item.name}
                                        </button>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                        </li> */}
                        {
                          // currency
                        }
                         <li className="config-list__item">
                          <div className="d-flex align-items-center">
                            <div
                              className="circle circle--sm rounded-circle border"
                              style={style.currency}
                            >
                              {defaultCurrency ? defaultCurrency.symbol : "$"}
                            </div>
                            <div className="dropdown">
                              <a
                                className="sm-text nav-link dropdown-toggle pl-2"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {defaultCurrency
                                  ? defaultCurrency.code
                                  : "Currency"}
                              </a>
                              <ul className="dropdown-menu">
                                {navCurrencyList &&
                                  navCurrencyList.map((item, index) => {
                                    return (
                                      <li key={index}>
                                        <button
                                          type="button"
                                          className={`dropdown-item sm-text text-capitalize ${defaultCurrency &&
                                            item.code === defaultCurrency.code
                                            ? "active"
                                            : ""
                                            }`}
                                          onClick={() =>
                                            handleDefaultCurrency(item)
                                          }
                                        >
                                          {item.name}
                                        </button>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="config-list__item">
                          <div className="d-flex align-items-center "> 
                              <div className="circle circle--sm rounded-circle ">
                              <img
                                src={
                                  authUserInfo.details !== null &&
                                    authUserInfo.details.image !== null
                                    ? [
                                      BASE_URL +
                                      authUserInfo.details.image,
                                    ]
                                    : "/assets/img/user.jpg"
                                }
                                alt=""
                                className="img-fluid avatar__img"
                              />
                            </div> 
                            <div className="dropdown">
                                <a
                                className="font-weight-bold text-capitalize sm-text nav-link dropdown-toggle pl-2"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {authUserInfo.details !== null &&
                                  authUserInfo.details.name}
                              </a> 
                              <ul className="dropdown-menu dropdown-menu-right">
                                <li>
                                    <Link
                                      to="/dashboard"
                                      className="dropdown-item sm-text text-capitalize"
                                    >
                                      {_t(t("Dashboard"))}
                                    </Link>
                                </li> 
                              {/* window.location.pathname === "/" ? (
                                  <li>
                                    <Link
                                      to="/dashboard"
                                      className="dropdown-item sm-text text-capitalize"
                                    >
                                      {_t(t("Dashboard"))}
                                    </Link>
                                  </li>
                                ) : (
                                  <li key="homepage">
                                    <Link
                                      to="/dashboard"
                                      className="dropdown-item sm-text text-capitalize"
                                    >
                                      {_t(t("Dashboard"))}
                                    </Link>
                                  </li>
                                )} */}
                                {
                                  !isOnline &&
                                  <>
                                  <li key="homepage">
                                    <Link
                                      to="/update-user-profile"
                                      className="dropdown-item sm-text"
                                    >
                                      {_t(t("Change password"))}
                                    </Link>
                                  </li>
                                  <hr className="my-1" />
                                  <li>
                                    <button
                                    className="dropdown-item sm-text"
                                    onClick={handleQB}
                                    >
                                      {_t(t("Connect to QB"))}
                                    </button>
                                  </li>
                                  <hr className="my-1" />
                                  {
                                    authUserInfo.details?.user_type === "manager" && (
                                      <> 
                                      <li>
                                        <button
                                          className="dropdown-item sm-text"
                                          onClick={handlePasscode}
                                        >
                                          {_t(t("Manager Passcode"))}
                                        </button>
                                      </li>
                                      <hr className="my-1" />
                                      </> 
                                    )
                                  }
                                  <li>
                                    <button
                                      className="dropdown-item sm-text text-capitalize"
                                      onClick={handleClearCache}
                                    >
                                      {_t(t("Clear Cache"))}
                                    </button>
                                  </li>
                                  </>
                                }
                                <hr className="my-1" />
                                <li>
                                  <button
                                    className="dropdown-item sm-text text-capitalize"
                                    onClick={()=>handleLogout(null)}
                                    rel="noopener noreferrer"
                                  >
                                    {_t(t("Logout"))}
                                  </button>
                                </li> 
                              </ul>
                            </div>
                          </div>
                        </li> 
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="d-md-none d-sm-none">
                  <div className="row">
                    <div className="col-12">
                      <div className="fk-sm-nav" data-simplebar>
                          {foodGroupForSearch &&
                            foodGroupForSearch.map((groupItem, index) => {
                              return (
                                <li key={index}
                                  className={`fk-sm-nav__list ${index === 0 && "active"
                                    }`}
                                >
                                  <a
                                    href={`#card-${index + 1}`}
                                    className="t-link fk-sm-nav__link"
                                    rel="noopener noreferer"
                                  >
                                    {groupItem.name}
                                  </a>
                                </li>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          ),
        ]
      )}

    </>
  );
};

export default withRouter(Navbar);
