import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";

const SalesReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch, orderTypeList, paymentTypeForSearch } =
    useContext(RestaurantContext);
  const { foodForPOS, foodGroupForSearch } = useContext(FoodContext);
  const { partyForSearch, authUserInfo } = useContext(UserContext);

  let [newSalesReport, setNewSalesReport] = useState(null);

  //all data
  const [reportData, setReportData] = useState(null);

  const componentRef = useRef();
  const componentRef1 = useRef();

  const groupByArray = [
    { id: "Branch", name: "Branch" },
    { id: "Order Types", name: "Order Types" },
    { id: "Payment Types", name: "Payment Types" },
  ];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [detailReport, setDetailReport] = useState(false);
  const [saleDetailReport, setSaleDetailReport] = useState(null);
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()

  const [branchSearch, setBranchSearch] = useState(null);

  useEffect(() => {
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }

    setSiteName(getSystemSettings(generalSettings, "siteName"));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  const handleBranch = (branch) => {
    setNewSalesReport({ ...newSalesReport, branch });
    // setBranch(branch);
  };
  const handleGroup = (group) => {
    setNewSalesReport({ ...newSalesReport, group });
    //setGroup(group);
  };
  const handleItem = (item) => {
    setNewSalesReport({ ...newSalesReport, item });
    //setItem(item);
  };
  const handleParty = (party) => {
    setNewSalesReport({ ...newSalesReport, party });
    //setParty(party);
  };
  const handleOrderTypes = (orderTypes) => {
    setNewSalesReport({ ...newSalesReport, orderTypes });
    //setOrderTypes(orderTypes);
  };
  const handlePaymentTypes = (paymentTypes) => {
    setNewSalesReport({ ...newSalesReport, paymentTypes });
    //setPaymentTypes(paymentTypes);
  };
  const handleGroupBy = (groupBy) => {
    setNewSalesReport({ ...newSalesReport, groupBy });
    //setGroupBy(groupBy);
  };

  //sales detail report 
  const getDetailReport = () =>{
    setReportData(null);

    if (
      (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") &&
      newSalesReport?.branch?.id === undefined
    ) {
      toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }

    if (
      // newSalesReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null &&
      newSalesReport?.groupBy?.id
    ) {
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-sales-report-details";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", newSalesReport?.branch?.id ? newSalesReport?.branch?.id : "");
      formData.append("group_id", newSalesReport?.group?.name ? newSalesReport?.group?.name : "");
      formData.append("item_id", newSalesReport?.item?.name ? newSalesReport?.item?.name : "");
      formData.append("order_type_id", newSalesReport?.orderTypes?.id ? newSalesReport?.orderTypes?.id : "");
      formData.append("party_id", newSalesReport?.party?.id ? newSalesReport?.party?.id : "");
      formData.append("payment_type_name", newSalesReport?.paymentTypes?.name ? newSalesReport?.paymentTypes?.name : "");
      formData.append("group_by", newSalesReport?.groupBy?.id);

      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setLoading(false);
          setSaleDetailReport(res.data[0])
          setDetailReport(true);
          componentRef1.current.handleDownload();
          setNewSalesReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date, branch and report group to generate report"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  }

  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    setSaleDetailReport(null);
    console.log("newSalesReport ", newSalesReport);
    if (
      (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") &&
      newSalesReport?.branch?.id === undefined
    ) {
      toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }

    if (
      // newSalesReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null &&
      newSalesReport?.groupBy?.id
    ) {
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-sales-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", newSalesReport?.branch?.id ? newSalesReport?.branch?.id : "");
      formData.append("group_id", newSalesReport?.group?.name ? newSalesReport?.group?.name : "");
      formData.append("item_id", newSalesReport?.item?.name ? newSalesReport?.item?.name : "");
      formData.append("order_type_id", newSalesReport?.orderTypes?.id ? newSalesReport?.orderTypes?.id : "");
      formData.append("party_id", newSalesReport?.party?.id ? newSalesReport?.party?.id : "");
      formData.append("payment_type_name", newSalesReport?.paymentTypes?.name ? newSalesReport?.paymentTypes?.name : "");
      formData.append("group_by", newSalesReport?.groupBy?.id ? newSalesReport?.groupBy?.id : "");

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("SalesReportResult", res);
          setReportData(res.data[0]);

          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewSalesReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date, branch and report group to generate report"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };
  let total_price = 0;
  let grand_total = 0;
  let GrandAmount = 0;
  let GrandTotal = 0

  return (
    <>
      <Helmet>
        <title>{_t(t("Sales report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="sales report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="detail-to-xls"
        filename="sales detail report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef1}
      />


      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {"\
          @page { size: landscape; }\
        "}
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          {console.log("reportData", reportData)}
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="26" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Sales Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                  ></th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Created Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Token"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Punching Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Dispatch Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Branch Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Type"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Party Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Food Groups"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Food Items"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Variations"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Property"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Property Qty"))}
                                  </th>

                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Qty"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Rate"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                                                    
                                  <th
                                  scope="col"
                                  >
                                  {_t(t("Payment Type"))}
                                  </th>
                                  <th
                                  scope="col"
                                  >
                                  {_t(t("Payment Card Number"))}
                                  </th>
                                  <th
                                  scope="col"
                                  >
                                  {_t(t("Customer Name"))}
                                  </th>
                                  <th
                                  scope="col"
                                  >
                                  {_t(t("Ref No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Comments"))}
                                  </th>
                                 {/* <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Discount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Vat"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Total"))}
                                  </th>*/}
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("User Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Status"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let sum = 0;
                                    let g_total = 0;
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportData[group_name].map(
                                          (item, i) => {
                                            let price = item.price > 0 ? item.price / item.quantity : 0;
                                            sum += parseFloat(item.amount);
                                            total_price += parseFloat(
                                              item.amount
                                            );
                                            g_total += parseFloat(item.total);
                                            grand_total += parseFloat(
                                              item.total
                                            );
                                            return (
                                              <>
                                              <tr
                                              >
                                                <td></td>
                                                <td
                                                  scope="row"
                                                >
                                                  {i + 1}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  <Moment format="LL">
                                                    {item.order_date}
                                                  </Moment>
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  <Moment format="DD-MM-YYYY">
                                                    {item.created_at}
                                                  </Moment>
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.token.id}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.punching_time}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.ready_time}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.branch_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_type_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.party_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.food_group}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.food_item}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.variation}
                                                </td>

                                                <td
                                                scope="row"
                                                >
                                                  {(item?.properties && JSON.parse(item.properties)?.length > 0) ? JSON.parse(item.properties)[0].property : "-"}
                                                </td>

                                                <td
                                                scope="row"
                                                >
                                                  {(item?.properties && JSON.parse(item.properties)?.length > 0) ? JSON.parse(item.properties)[0].quantity : "-"}
                                                </td>
                                               
                                                <td
                                                  scope="row"
                                                >
                                                  {item.quantity}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {formatPrice(price)}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {formatPrice(item.price)}
                                                </td>
                                                 
                                                <td
                                                 scope="row"
                                                >
                                                  {item.bill_distribution
                                                    ? item.bill_distribution
                                                    : ""}
                                                </td>  
                                                   
                                                <td
                                                  scope="row"
                                                >
                                                  {item.payment_card_number}
                                              </td>                                          
                                                <td
                                                  scope="row"
                                                >
                                                  {item.customer_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.ref_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.comments}
                                                </td>
                                                {/*<td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.item_discount}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.vat_value}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.total}
                                                  </td>*/}
                                                <td
                                                  scope="row"
                                                >
                                                  {item.user_name}
                                                </td>
                                                <td>
                                                  {parseInt(
                                                    item.is_cancelled
                                                  ) === 0 ? (
                                                    [
                                                      parseInt(
                                                        item. is_ready
                                                      ) === 1 &&
                                                      parseInt(
                                                        item.is_refund
                                                      ) === 0 ? (
                                                        <span>
                                                          {_t(t("Ready"))}
                                                        </span>
                                                      ) : parseInt(
                                                          item.is_refund
                                                        ) === 1 ? (
                                                        <span >
                                                          {_t(t("Refund"))}
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          {_t(t("processing"))}
                                                        </span>
                                                      ),
                                                    ]
                                                  ) : (
                                                    <span>
                                                      {_t(t("Cancelled"))}
                                                    </span>
                                                  )}
                                                </td>
                                              </tr>
                                              {
                                                JSON.parse(item.properties)?.length > 1 && (
                                                  JSON.parse(item.properties).slice(1).map((prp)=>(
                                                    <tr>
                                                      <td colSpan={14}></td>
                                                      <td
                                                      scope="row"
                                                      >
                                                        {prp.property}
                                                      </td>
      
                                                      <td
                                                      scope="row"
                                                      >
                                                        {prp.quantity}
                                                      </td>
                                                    </tr>
                                                  ))
                                                ) 
                                              }
                                            </> 
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th colspan="17"></th>
                                          <th>
                                            Total
                                          </th>
                                          <td>
                                            {formatPrice(sum)}
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {/*formatPrice(g_total)*/}
                                          </td>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                
                                <tr>
                                  <th colspan="17"></th>
                                  <th>
                                    Grand Total
                                  </th>
                                  <td>
                                    {formatPrice(total_price)}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {/*formatPrice(grand_total)*/}
                                  </td>
                                </tr>
                                
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="8"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Print Button */}
      <div className="d-none">
       <div>
         <style type="text/css" media="print">
           {"\
         @page { size: landscape; }\
       "}
         </style>
         <div className="fk-print">
           <div className="container">
             <div className="row">
               <div className="col-12">
                 {saleDetailReport !== null &&
                   saleDetailReport !== undefined &&
                   detailReport === true && (
                     <>
                       <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                         <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                           <table
                             className="table table-bordered table-hover min-table-height mt-3"
                             id="detail-to-xls"
                           >
                             <div className="page-break"></div>
                             <thead className="align-middle">
                               <tr style={{ border: "0px" }}>
                                 <th colspan="29" style={{ border: "0px" }}>
                                   <div className="row">
                                     <div className="col-12">
                                       <h3 className="text-center mb-0">
                                         {siteName}
                                       </h3>
                                       <h3 className="text-center mt-0">
                                         Sales Detail Report
                                       </h3>
                                       <p className="text-center">
                                         FROM&nbsp;
                                         <Moment format="LL">
                                           {startDate}
                                         </Moment>
                                         &nbsp;TO&nbsp;
                                         <Moment format="LL">{endDate}</Moment>
                                       </p>
                                     </div>
                                   </div>
                                 </th>
                               </tr>
                               <tr>
                                 <th
                                   scope="col"
                                 ></th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("S/L"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Order Date"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Created Date"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Token"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Order No"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Punching Time"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Dispatch Time"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Branch Name"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Order Type"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Customer Category"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Customer Name"))}
                                 </th>
                                 <th
                                 scope="col"
                                 >
                                 {_t(t("Payment Type"))}
                                 </th>
                                 <th
                                 scope="col"
                                 >
                                 {_t(t("Card Auth Code"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Ref No"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Catrgory"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Item Code"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Item"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Item Qty"))}
                                 </th>

                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Rate"))}
                                 </th>
                                 
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Amount"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Discount"))}
                                 </th>

                                 <th
                                 scope="col"
                                 >
                                 {_t(t("Net Sales"))}
                                 </th>
                                 <th
                                 scope="col"
                                 >
                                 {_t(t("Tax Amount"))}
                                 </th>
                                 <th
                                 scope="col"
                                 >
                                 {_t(t("Delivery Charges"))}
                                 </th>
                                 <th
                                 scope="col"
                                 >
                                 {_t(t("Total"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Comments"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("User Name"))}
                                 </th>
                                 <th
                                   scope="col"
                                 >
                                   {_t(t("Status"))}
                                 </th>
                               </tr>
                             </thead>
                             <tbody className="align-middle">
                               {/* loop here*/}
                               {Object.keys(saleDetailReport).map(
                                 (group_name, index) => {
                                   let amountTotal = 0;
                                   let discountTotal = 0;
                                   let taxTotal = 0;
                                   let total = 0;
                                   return (
                                     <div key={index}>
                                       <tr>
                                         <th
                                           scope="row"
                                         >
                                           {group_name}
                                         </th>
                                       </tr>
                                       {saleDetailReport[group_name].map(
                                         (item, i) => {
                                          const amount = item.is_deal === 1 ? (parseFloat(item.order_bill) / parseFloat(item.item_count)) : parseFloat(item.order_bill);
                                          const rate = amount / parseFloat(item.quantity);
                                          const netSale = amount - parseFloat(item.discount_amount);
                                          amountTotal += amount;
                                          discountTotal += parseFloat(item.discount_amount);
                                          taxTotal += parseFloat(item.vat);
                                          total += parseFloat(item.total_payable);
                                          GrandAmount += amount;
                                          GrandTotal +=  parseFloat(item.total_payable);
                                          
                                           return (
                                            <>
                                             <tr>
                                               <td></td>
                                               <td
                                                 scope="row"
                                               >
                                                 {i + 1}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 <Moment format="LL">
                                                   {item.order_date}
                                                 </Moment>
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 <Moment format="DD-MM-YYYY">
                                                   {item.created_at}
                                                 </Moment>
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.token.id}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.order_no}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.punching_time}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.ready_time}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.branch_name}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.order_type_name}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.party_name}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.customer_name}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.bill_distribution && item.bill_distribution}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.payment_card_number ? item.payment_card_number: "-"}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.ref_no ? item.ref_no : "-"}
                                               </td>

                                               <td
                                               scope="row"
                                               >
                                                 {item.food_group}
                                               </td>

                                               <td
                                               scope="row"
                                               >
                                                 {item.item_code}
                                               </td>
                                              
                                               <td
                                                 scope="row"
                                               >
                                                 {item.food_item}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.quantity}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {formatPrice(rate)}
                                               </td>
                                                    
                                               <td
                                                scope="row"
                                              >
                                                {formatPrice(amount)}
                                              </td> 

                                               <td
                                                scope="row"
                                               >
                                                 {item.discount_amount}
                                               </td>  
                                                                                       
                                               <td
                                                 scope="row"
                                               >
                                                 {formatPrice(netSale)}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.vat}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.delivery_charges}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.total_payable}
                                               </td>
                                               <td
                                                 scope="row"
                                               >
                                                 {item.comments}
                                               </td>
                                               
                                               <td
                                                 scope="row"
                                               >
                                                 {item.user_name}
                                               </td>
                                               <td>
                                                 {parseInt(
                                                   item.is_cancelled
                                                 ) === 0 ? (
                                                   [
                                                     parseInt(
                                                       item. is_ready
                                                     ) === 1 &&
                                                     parseInt(
                                                       item.is_refund
                                                     ) === 0 ? (
                                                       <span>
                                                         {_t(t("Ready"))}
                                                       </span>
                                                     ) : parseInt(
                                                         item.is_refund
                                                       ) === 1 ? (
                                                       <span >
                                                         {_t(t("Refund"))}
                                                       </span>
                                                     ) : (
                                                       <span>
                                                         {_t(t("processing"))}
                                                       </span>
                                                     ),
                                                   ]
                                                 ) : (
                                                   <span>
                                                     {_t(t("Cancelled"))}
                                                   </span>
                                                 )}
                                               </td>
                                             </tr>
                                            </> 
                                           );
                                         }
                                       )}
                                       <tr>
                                         <th colspan="19"></th>
                                         <th>
                                           Total
                                         </th>
                                         <td>
                                           {formatPrice(amountTotal)}
                                         </td>
                                         <td>
                                           {formatPrice(discountTotal)}
                                         </td>
                                         <td></td>
                                         <td>
                                           {formatPrice(taxTotal)}
                                         </td>
                                         <td></td>
                                         <td>
                                           {formatPrice(total)}
                                         </td>
                                       </tr>
                                     </div>
                                   );
                                 }
                               )}
                               <tr></tr>
                               <tr>
                                <th colspan="19"></th>
                                <th>
                                  Grand Total
                                </th>
                                <td>
                                  {formatPrice(GrandAmount)}
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                  {formatPrice(GrandTotal)}
                                </td>
                              </tr>
                             </tbody>
                             <tfoot style={{ border: "0px" }}>
                               <tr style={{ border: "0px" }}>
                                 <td
                                   colspan="8"
                                   className="text-center"
                                   style={{ border: "0px" }}
                                 >
                                   <h5 className="mt-3">
                                     Powered by indolj.pk
                                   </h5>
                                 </td>
                               </tr>
                             </tfoot>
                           </table>
                         </div>
                       </div>
                     </>
                   )}
               </div>
             </div>
           </div>
         </div>
       </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading <div className={`${dataPaginating && "loading"}`}></div>*/}
                    
                    {/* spin loading ends */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* Loading effect 
                    {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Sales report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={branchSearch && branchSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              value={newSalesReport?.branch}
                              key={newSalesReport?.branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleBranch}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={foodGroupForSearch && foodGroupForSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              value={newSalesReport?.group}
                              key={newSalesReport?.group}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleGroup}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a food group")) + ".."}
                            />
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={foodForPOS && foodForPOS}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={newSalesReport?.item}
                              key={newSalesReport?.item}
                              isClearable
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleItem}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a food items")) + ".."}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={partyForSearch && partyForSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={newSalesReport?.party}
                              key={newSalesReport?.party}
                              isClearable
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleParty}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a party")) + ".."}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={orderTypeList.data && orderTypeList.data}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={newSalesReport?.orderTypes}
                              key={newSalesReport?.orderTypes}
                              isClearable
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleOrderTypes}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a order types")) + ".."}
                            />
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={
                                paymentTypeForSearch && paymentTypeForSearch
                              }
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={newSalesReport?.paymentTypes}
                              key={newSalesReport?.paymentTypes}
                              isClearable
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handlePaymentTypes}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Select a payment type")) + ".."
                              }
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={groupByArray && groupByArray}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={newSalesReport?.groupBy && newSalesReport?.groupBy}
                              key={newSalesReport?.groupBy}
                              isClearable
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleGroupBy}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Select a report group")) + ".."
                              }
                            />
                          </div>
                          <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                                className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                                onClick={(e) => getBranchWiseSelected(e)}
                              >
                                Print Sales Report
                              </button>
                          </div>

                          <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getDetailReport(e)}
                            >
                               Sales Detail Report
                            </button>
                        </div>

                          {/* <div className="col-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2"
                              onClick={getBranchWiseSelected}
                            >
                              {_t(t("Generate Report"))}
                            </button>
                          </div> */}
                        </div>
                        <>
                          {/* <div className="row gx-2 justify-content-center t-pt-15">
                            <div className="col-12 mb-md-0">
                              <Chart
                                options={amountChart.options}
                                series={amountChart.series}
                                type="bar"
                                width="100%"
                                height="350px"
                              />
                            </div>
                          </div> */}

                          {/* {reportData !== null &&
                            reportData !== undefined &&
                            reportData.length > 0 && ( */}
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15">
                            {/* Action Button */}
                            
                          </div>
                          {/* )} */}
                        </>
                      </div>
                   
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default SalesReport;
