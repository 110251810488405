import React, { useState, useContext, useEffect } from "react";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datetime/css/react-datetime.css';

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import moment from 'moment';
import Select from "react-select";
//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const MdJv = () => {
  const { t } = useTranslation();
  //getting context values here

  let {
    //item
    loading,
    setLoading,
    //md integration
    branchForSearch,

    //pagination
    dataPaginating,
  } = useContext(RestaurantContext);

  //search result
  const [mdIntegrationJv, setMdIntegrationJv] = useState({
    date: null,
    branch: null,
  });

  const [mdIntegrationLedger, setMdIntegrationLedger] = useState([])


  const handleSetBranch = (branch) =>{
    setMdIntegrationJv({...mdIntegrationJv, branch });
  } 

  //search md
  const handleGetMd = (e) =>{
    e.preventDefault();
    if(!mdIntegrationJv.date || !mdIntegrationJv.branch){
      toast.error("Please select date and branch",{
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false;
    }

    const date =  moment(mdIntegrationJv.date).format("YYYY-MM-DD");

    const url = BASE_URL + "/settings/get-md-jv";
    let formData = new FormData();
    formData.append("date", date);
    formData.append("branch_id", mdIntegrationJv.branch?.id);

    setLoading(true);

    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        setMdIntegrationLedger(res.data);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Please try again",{
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
      });
  }

  let debit = 0;
  let credit = 0;

  // save md jv 
  const handleSaveMdJv = (e) =>{
    e.preventDefault();
    if(!mdIntegrationJv.date || !mdIntegrationJv.branch){
      toast.error("Please select date and branch",{
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false;
    }

    if(!mdIntegrationLedger?.length){
        toast.error("cant save empty ledger data",{
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
        return false;
    }

    if(debit !== credit){
        toast.error("Debit and credit are not equal",{
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
        return false;
    }

    const date =  moment(mdIntegrationJv.date).format("YYYY-MM-DD");
    const url = BASE_URL + "/settings/md-MakeMDData/" + mdIntegrationJv.branch?.id + "/" + date;
    
    setLoading(true);

    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if(res.data){
          setLoading(false);
          setMdIntegrationJv({
            date: null,
            branch: null,
          })
          setMdIntegrationLedger([])
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Please try again",{
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
      }); 
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("MD Integration Jv"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-menu table-bottom-info-hide" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(loading) && "loadingBlur"}`}></div>
                        <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 col-lg-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb d-flex justify-content-between align-items-center">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  { _t(t("MD Integration Jv"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="row gx-2 mt-2">
                          <div className="col-2 d-md-block">
                            <DatePicker
                              selected={mdIntegrationJv.date}
                              onChange={(date) => setMdIntegrationJv({...mdIntegrationJv, date})}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("Date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-3 t-mb-15 mb-md-0">
                            <Select
                              options={branchForSearch ? branchForSearch : []}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              classNamePrefix="select"
                              className="sm-text"
                              styles={customStyle}
                              value={mdIntegrationJv && mdIntegrationJv.branch}
                              onChange={handleSetBranch}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Select a branch")) + ".."
                              }
                            />
                          </div>
                          <div className="col-5 t-mb-15 mb-md-0">
                          </div>
                          <div className="col-2 t-mb-15 mb-md-0">
                            <button className="btn btn-primary table-text rounded float-right" onClick={(e) => handleGetMd(e)}> Search </button>
                          </div>
                        </div>
                        {/* Table */}
                        {

                        }
                        <div className="table-responsive mt-4 mb-2">
                          <table className="table table-bordered table-hover" style={{height:"350px"}}>
                            {
                              mdIntegrationLedger?.length ? (
                                <>
                                  <thead className="align-middle">
                                    <tr>

                                        <th
                                        style={{minWidth:"180px"}}
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                        {_t(t("Account No"))}
                                        </th>

                                        <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                        {_t(t("Debit"))}
                                        </th>

                                        <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                        {_t(t("Credit"))}
                                        </th>
                                    </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                    {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                    {mdIntegrationLedger && mdIntegrationLedger.map((item, index) => {
                                          debit += item.debit;
                                          credit += item.credit;
                                          return (
                                            <tr
                                                className="align-middle"
                                                key={index}
                                            >
                                              <td
                                              scope="row"
                                              className="table-text text-capitalize align-middle text-center"
                                              >
                                                {item.account_no}
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {item.debit}
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {item.credit}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    }
                                    <tr
                                      className="align-middle"
                                    >
                                      <td
                                      >
                                      </td>

                                      <th className="table-text text-capitalize align-middle text-center border-1 border">
                                        {debit}
                                      </th>

                                      <td className="table-text text-capitalize align-middle text-center border-1 border">
                                        {credit}
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              ) : ""
                            }
                          </table>
                        </div>
                        {
                          mdIntegrationLedger?.length ? (
                            <div className="t-mb-15 mb-md-0 float-right">
                              <button className="btn btn-primary px-3 rounded" onClick={(e) => handleSaveMdJv(e)}> Save </button>
                            </div>
                          ) : ""
                        }
                      </div>                    
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default MdJv;
