import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Cookies from "universal-cookie";

import { db } from "./Firebase";
import { ref, off, onValue, query} from "firebase/database";
//axios and base url
import axios from "axios";
import {
  BASE_URL,
  SAAS_APPLICATION,
  saas_apiUrl,
  saas_apiParams,
  saas_form_data,
} from "./BaseUrl";
//routes
import RestaurantRoute from "./routes/RestaurantRoute";
import CustomerRoute from "./routes/CustomerRoute";
import PosOrderRoute from "./routes/PosOrderRoute";

//functions
import {
  checkPermission,
  getSystemSettings,
} from "./functions/Functions";

//3rd party packages
import { ToastContainer } from "react-toastify";

//pages & includes
import {
  //installation
  Welcome,
  InstallPermission,
  DbSetup,
  ImportDb,
  InstallationUser,
  InstallationCongratulation,
  //landing
  RestaurantLanding,
  CustomerProfile,
  MyOrders,

  //common
  Refresh,
  Login,
  SignUp,
  BeDeliveryMan,
  NotFound,
  ForgetPw,
  SetNewPw,
  NoPermission,

  //dashboard
  RestaurantHome,
  UpdateProfile,
  WorkPeriod,
  OpeningClosingStock,

  //delivery
  AssignedOrders,
  DeliveredOrders,

  //pos
  Pos,
  Submitted,
  FoodPanda,
  Settled,
  OnlineOrders,
  EditSubmittedOrder,
  //customers
  Customers,
  OnlineCustomerList,
  //
  OnlineOrderHistories,
  OrderHistories,
  Kitchen,
  KitchenOnline,
  //Reports
  Dashboard,
  AdminDashboard,
  ConsolitedData,
  ConsolitedReport,
  Daily,
  Monthly,
  // Yearly,
  ItemWise,
  GroupWise,
  BranchWise,
  UserWise,
  DeptWise,
  ServiceChargeWise,
  DiscountReport,
  SalesTenderReport,
  StockDashboard,
  FoodStockReport,
  IngredientStockReport,
  OpeningClosingStockIngredientReport,
  OpeningClosingStockFoodReport,
  WorkPeriodReport,
  SalesReport,
  OrderTracking,
  SalesSummaryReport,
  GrossSalesReport,
  SalesSyncReport,
  SourceWiseReport,
  CustomerOrderHistoryReport,
  FoodPandaReport,
  RiderReport,
  WaiteReport,
  CancelOrderReport,
  CancelItemReport,
  MeezanReport,
  HourlySalesReport,
  IncomeStatementReport,
  DailyCashSalesReport,
  ExpenseReport,
  StockTransferReport,
  //manage->food
  GroupCrud,
  UnitCrud,
  VariationCrud,
  PropertyCrud,
  PropertyItemCrud,
  FoodItemCrud,
  AllItemList,
  //manage->website
  HeroSection,
  Promotions,
  //manage->settings
  Currency,
  Lang,
  Translation,
  Smtp,
  PosScreen,
  OrderDelivery,
  General,
  WebMapping,
  UpdateSystem,

  //manage->stock
  FoodPurchase,
  FoodPurchaseEdit,
  FoodPurchaseHistory,
  FoodReturn,
  IngredientGroup,
  IngredientItem,
  IngredientLimitAction,
  IngredientItemLimit,
  IngredientPurchase,
  IngredientPurchaseEdit,
  IngredientPurchaseHistory,
  PurchaseOrder,
  PurchaseOrderEdit,
  PurchaseOrderHistory,
  GoodsReceivedNote,
  GoodsReceivedNoteEdit,
  GoodsReceivedHistory,
  IngredientUsage,
  IngredientReturn,
  ManageSupplier,
  SupplierHistory,
  StockOutFood,
  StockOutIngredient,
  RecipeEdit,
  ProductionAction,
  ProductionList,
  FinishGoods,
  EditFinishGoods,
  FinishGoodsHistory,

  //manage->user
  AdminStaffCrud,
  UserTill,
  UserTillAction,
  DeliveryMen,
  NewDeliveryMen,
  Waiter,
  Permissions,
  CustomerCrud,

  //manage->restaurantDetails
  BranchCrud,
  MDIntegrationAction,
  MDIntegrationList,
  MdJv,
  Zone,
  ZoneAction,
  OrderTypeCrud,
  TableFloor,
  TableCrud,
  MenuTagCrud,
  PaymentTypeCrud,
  KotCrud,
  CreateRecipe,
  ViewRecipe,
  IngredientUnit,
  //accounts
  AccountSettings,
  ChartAccount1,
  ChartAccount2,
  ChartAccount3,
  Expense,
} from "./imports/Pages";

import { Navbar, Footer } from "./imports/Components";
import ExpenseList from "./resources/restaurant/auth/expense/ExpenseList";
//context consumer
import { SettingsContext } from "./contexts/Settings";
import { UserContext } from "./contexts/User";
import Showdeliveryman from "./resources/restaurant/auth/manage/settings/ShowDeliveryman";
import NotPermitted from "./saasHelper/NotPermitted";
import { SaasContext } from "./contexts/SaasHelper";
import SaasProfile from "./resources/restaurant/auth/saasInfo/SaasProfile";
import SaasApiFailure from "./saasHelper/saasApiFailure";
import ShowManageStock from "./resources/restaurant/auth/manage/settings/ShowManageStock";
import UpdateSettings from "./resources/restaurant/auth/manage/settings/UpdateSettings";
import Blocked from "./saasHelper/Blocked";
import TopBarOffline from "./TopBarOffline";
import OnlinePayment from "./resources/restaurant/auth/manage/settings/OnlinePayment";
import PartyHistory from "./resources/restaurant/auth/manage/restaurantDetails/PartyHistory";
import Party from "./resources/restaurant/auth/manage/restaurantDetails/PartyCrud";
import SchemesCrud from "./resources/restaurant/auth/manage/food/SchemesCrud";
import CardsCrud from "./resources/restaurant/auth/manage/food/CardsCrud";
import AddonRestrictionsCrud from "./resources/restaurant/auth/manage/food/AddonRestrictionsCrud";
import StockTransfer from "./resources/restaurant/auth/manage/stock/StockTransfer";
import StockLedgerReport from "./resources/restaurant/auth/reports/StockLedgerReport";
import SupplierLedgerReport from "./resources/restaurant/auth/reports/SupplierLedgerReport";
import StockVarianceReport from "./resources/restaurant/auth/reports/StockVarianceReport";
import StockConsumption from "./resources/restaurant/auth/reports/StockConsumption";
import EmailTemplate from './resources/restaurant/auth/manage/settings/Email';
import FoodItemEdit from "./resources/restaurant/auth/manage/food/FoodItemEdit";
import BankPayment from "./resources/restaurant/auth/manage/transactions/BankPayment";
import CashPayment from "./resources/restaurant/auth/manage/transactions/CashPayment";
import JournalVoucher from "./resources/restaurant/auth/manage/transactions/JournalVoucher";
import OpeningAccount from "./resources/restaurant/auth/manage/transactions/OpeningAccount";
import AddBankPayment from "./resources/restaurant/auth/manage/transactions/AddBankPayment";
import EditBankPayment from "./resources/restaurant/auth/manage/transactions/EditBankPayment";
import AddCashPayment from "./resources/restaurant/auth/manage/transactions/AddCashPayment";
import AddJournalVoucher from "./resources/restaurant/auth/manage/transactions/AddJournalVoucher";
import EditCashPayment from "./resources/restaurant/auth/manage/transactions/EditCashPayment";
import EditJournalVoucher from "./resources/restaurant/auth/manage/transactions/EditJournalVoucher";
import StockTransferAdd from "./resources/restaurant/auth/manage/stock/StockTransferAdd";
import OpeningStock from "./resources/restaurant/auth/manage/stock/OpeningStock";
import OpeningStockEdit from "./resources/restaurant/auth/manage/stock/OpeningStockEdit";
import OpeningStockAdd from "./resources/restaurant/auth/manage/stock/OpeningStockAdd";
import StockTransferView from "./resources/restaurant/auth/manage/stock/StockTransferView";
import StoreRequisition from "./resources/restaurant/auth/manage/stock/StoreRequisition";
import StoreRequisitionAdd from "./resources/restaurant/auth/manage/stock/StoreRequisitionAdd";
import StoreRequisitionEdit from "./resources/restaurant/auth/manage/stock/StoreRequisitionEdit";
import StockAdjustment from "./resources/restaurant/auth/manage/stock/StockAdjustment";
import StockAdjustmentAdd from "./resources/restaurant/auth/manage/stock/StockAdjustmentAdd";
import BranchAction from "./resources/restaurant/auth/manage/restaurantDetails/BranchAction";
import SchemeCrudAction from "./resources/restaurant/auth/manage/food/SchemeCrudAction";
import PartyAction from "./resources/restaurant/auth/manage/restaurantDetails/PartyAction";
import AddOpeningAccount from "./resources/restaurant/auth/manage/transactions/AddOpeningAccount";
import EditOpeningAccount from "./resources/restaurant/auth/manage/transactions/EditOpeningAccount";
import LedgerReport from "./resources/restaurant/auth/manage/accountsReport/LedgerReport";
import FinishGoodTransfer from "./resources/restaurant/auth/manage/stock/FinishGoodTransfer";
import FinishGoodTransferAdd from "./resources/restaurant/auth/manage/stock/FinishGoodTransferAdd";
import FinishGoodTransferEdit from "./resources/restaurant/auth/manage/stock/FinishGoodTransferEdit";
import FinishedGoodsDiscardEdit from "./resources/restaurant/auth/manage/stock/FinishedGoodsDiscardEdit";
import FinishedGoodsDiscardAdd from "./resources/restaurant/auth/manage/stock/FinishedGoodsDiscardAdd";
import FinishedGoodsDiscard from "./resources/restaurant/auth/manage/stock/FinishedGoodsDiscard";
import FinishGoodsAcceptance from "./resources/restaurant/auth/manage/stock/FinishGoodsAcceptance";
import FinishGoodsAcceptanceView from "./resources/restaurant/auth/manage/stock/FinishGoodsAcceptanceView";
import FinishedGoodsReport from "./resources/restaurant/auth/reports/FinishedGoodsReport";
import InventoryReport from "./resources/restaurant/auth/reports/InventoryReport";
import DealReport from "./resources/restaurant/auth/reports/DealReport";
import CompleteSales from "./resources/restaurant/auth/reports/CompleteSales";
import AdminReport from "./resources/restaurant/auth/reports/AdminReport";
import CallCenterReport from "./resources/restaurant/auth/reports/CallCenterReport";
import DayEndSetting from "./resources/restaurant/auth/manage/stock/DayEndSetting";
import DayEndSettingAdd from "./resources/restaurant/auth/manage/stock/DayEndSettingAdd";
import FgPackageHistory from "./resources/restaurant/auth/manage/stock/FgPackageHistory";
import FgPackingAction from "./resources/restaurant/auth/manage/stock/FgPackingAction";
import GiftHampers from "./resources/restaurant/auth/manage/stock/GiftHampers";
import GiftHampersAction from "./resources/restaurant/auth/manage/stock/GiftHampersAction";
import FinishGoodsAdjustment from "./resources/restaurant/auth/manage/stock/FinishGoodsAdjustment";
import FinishGoodsAdjustmentAdd from "./resources/restaurant/auth/manage/stock/FinishGoodsAdjustmentAdd";
import FinishGoodsAdjustmentView from "./resources/restaurant/auth/manage/stock/FinishGoodsAdjustmentView";
import MenuMixReport from "./resources/restaurant/auth/reports/MenuMixReport";
import Settlement from "./resources/restaurant/auth/manage/restaurantDetails/Settlement";
import SettlementAdd from "./resources/restaurant/auth/manage/restaurantDetails/SettlementAdd";
import SettlementView from "./resources/restaurant/auth/manage/restaurantDetails/SettlementView";
import StockIssuance from "./resources/restaurant/auth/manage/stock/StockIssuance";
import StockIssuanceView from "./resources/restaurant/auth/manage/stock/StockIssuanceView";
import IngredientItemReport from "./resources/restaurant/auth/reports/IngredientItemReport";
import FgReport from "./resources/restaurant/auth/reports/FgReport";
import FgTransferReport from "./resources/restaurant/auth/reports/FgTransferReport";
import FgDiscardReport from "./resources/restaurant/auth/reports/FgDiscardReport";
import SrbReport from "./resources/restaurant/auth/reports/SrbReport";
import PurchaseInvoiceReport from "./resources/restaurant/auth/reports/PurchaseInvoiceReport";
import BarcodePrint from "./resources/restaurant/auth/manage/stock/BarcodePrint";
import ProductionReport from "./resources/restaurant/auth/reports/ProductionReport";
import KitchenDineIn from "./resources/restaurant/auth/kitchen/KitchenDineIn";
import KitchenTakeaway from "./resources/restaurant/auth/kitchen/KitchenTakeaway";
import KdsSettingList from "./resources/restaurant/auth/manage/restaurantDetails/KdsSettingList";
import KdsSettingAdd from "./resources/restaurant/auth/manage/restaurantDetails/KdsSettingAdd";
import KdsSettingEdit from "./resources/restaurant/auth/manage/restaurantDetails/KdsSettingEdit";
import SalesListing from "./resources/restaurant/auth/SalesListing";
import WastageFood from "./resources/restaurant/auth/manage/stock/WastageFood";
import WastageFoodAdd from "./resources/restaurant/auth/manage/stock/WastageFoodAdd";
import WastageFoodView from './resources/restaurant/auth/manage/stock/WastageFoodView'
import FgMomentReport from "./resources/restaurant/auth/reports/FgMomentReport";
import ProductionDashboard from "./resources/restaurant/auth/reports/ProductionDashboard";
import FoodItemReport from "./resources/restaurant/auth/reports/FoodItemReport";
import IngredientReport from "./resources/restaurant/auth/reports/IngredientReport";
import SupplierPayment from "./resources/restaurant/auth/manage/stock/SupplierPayment";
import SupplierPaymentAdd from "./resources/restaurant/auth/manage/stock/SupplierPaymentAdd";
import SupplierPaymentEdit from "./resources/restaurant/auth/manage/stock/SupplierPaymentEdit";
import RawStockTransferReport from "./resources/restaurant/auth/reports/RawStockTranferReport";
import CustomerReport from "./resources/restaurant/auth/reports/CustomerReport";
import FgPackingReport from "./resources/restaurant/auth/reports/FgPackingReport";
import GiftHamperReport from "./resources/restaurant/auth/reports/GiftHamperReport";
import RecipeReport from "./resources/restaurant/auth/reports/RecipeReport";
import BuildModal from "./resources/restaurant/auth/modal/BuildModal";

function SaasApp() {
  // check saas subscruption expiry
  const cookies = new Cookies();
  const [expiry, setExpiry] = useState(true);
  const [saasBlock, setSaasBlock] = useState(false);
  //state hooks here
  const [credentials, setCredentials] = useState({
    install_no: false,
  });
  const [isOnline, setIsOnline] = useState(true);
  
  //getting context values here
  const { loading, setLoading, generalSettings } = useContext(SettingsContext);
  let { authUserInfo } = useContext(UserContext);
  let [apiFailed, setApiFailed] = useState(false);
  const [restaurantModal, setRestaurantModal] = useState(false);

  //notify firebase and update build api
  const fetchUpdatedBuild = async () => {    
    const url = 'pos/ALL/update-build';

    const buildQuery =  query(ref(db,url));
    // Detach any previous listeners
    off(buildQuery)
    onValue(buildQuery,(snapshot) => {
        const data = snapshot.val();
        if(data && data.status?.toLowerCase() === "notify"){
          setRestaurantModal(true)
        }
      }
    );
  }

  useEffect(()=>{
    if(authUserInfo?.details){
      fetchUpdatedBuild();
    }
  },[authUserInfo])

  // check expirary function
  const handleSassExpiry = () => {
    const url = saas_apiUrl + "/api/check-expiry";
    axios
      .post(url, saas_form_data)
      .then((res) => {
        if (res.data == "YES") {
          setExpiry(true);
        } else {
          setExpiry(false);
        }
      })
      .catch(() => {
        setApiFailed(true);
      });
  };

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  // localStorage helper
  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };

  // check block function
  const checkSaasBlock = () => {
    // check how many orders are left
    const url = saas_apiUrl + "/api/user-restriction?" + saas_apiParams;
    axios
      .get(url, saas_form_data)
      .then((res) => {
        setDataToLocal(url, res);
        setSaasBlock(res.data);
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setSaasBlock(parseRes.data);
        }
        // return "No data found check api";
      });
  };

  const setCredential = (res) => {
    if (res.data === "NO") {
      setCredentials({
        ...credentials,
        install_no: true,
      });
    }
  };
  //useEffect == componentDidMount()
  useEffect(() => {
    // (async () => {
    //   setLoading(false);
    //   const url = BASE_URL + "/check-install";
    //   return axios
    //     .get(url)
    //     .then((res) => {
    //       setDataToLocal(url, res);
    //       setCredential(res);
    //     })
    //     .catch((error) => {
    //       if (offline(url)) {
    //         let parseRes = getDataFromLocal(url);
    //         setCredential(parseRes);
    //       }
    //     });
    // })();

    if (generalSettings) {
      const favicon = document.getElementById("favicon");
      favicon.href = BASE_URL + getSystemSettings(generalSettings, "favicon");
    }

    // check expiry
    if (SAAS_APPLICATION == "YES") {
      const saasExpiryToken = setInterval(handleSassExpiry, 14400000);
      handleSassExpiry();

      const saasBlockToken = setInterval(checkSaasBlock, 14400000);
      checkSaasBlock();
      return () => {
        clearInterval(saasExpiryToken, saasBlockToken);
      };
    }
  }, [authUserInfo, expiry]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Set the initial online status based on the window.navigator.onLine property.
      setIsOnline(window.navigator.onLine);

      // Add online/offline event listeners.
      const onlineHandler = () => {
        setIsOnline(true);
      };

      const offlineHandler = () => {
        setIsOnline(false);
      };

      window.addEventListener("online", onlineHandler);
      window.addEventListener("offline", offlineHandler);

      return () => {
        // Remove event listeners when the component unmounts.
        window.removeEventListener("online", onlineHandler);
        window.removeEventListener("offline", offlineHandler);
      };
    }
  }, []);


  return (
    <>
      <ToastContainer />
      <BuildModal
        restaurantModal={restaurantModal}
        setRestaurantModal={setRestaurantModal}
      />
      <TopBarOffline online={isOnline}/>
      {SAAS_APPLICATION == "YES"
        ? [
            // apiFailed ? [<SaasApiFailure />] : [
            expiry ? (
              [
                saasBlock == false ? (
                  [
                    <Router>                                                               
                      <Navbar />
                      <Switch>
                                 
                        {/* installation */}
                        {credentials.install_no && (
                          <Route path="/installation" exact>
                            <Welcome />
                          </Route>
                        )}

                        {credentials.install_no && (
                          <Route path="/installation/permission-chcek" exact>
                            <InstallPermission />
                          </Route>
                        )}

                        {credentials.install_no && (
                          <Route path="/installation/database-setup" exact>
                            <DbSetup />
                          </Route>
                        )}

                        {credentials.install_no && (
                          <Route path="/installation/import-database" exact>
                            <ImportDb />
                          </Route>
                        )}

                        {credentials.install_no && (
                          <Route path="/installation/add-admin-user" exact>
                            <InstallationUser />
                          </Route>
                        )}

                        {credentials.install_no && (
                          <Route path="/installation/congratulation" exact>
                            <InstallationCongratulation />
                          </Route>
                        )}

                        {/* common */}
                        <Route path="/refresh" exact>
                          <Refresh />
                        </Route>

                        <Route path="/login" exact>
                          <Login />
                        </Route>

                        {/* <Route path="/sign-up" exact>
                          <SignUp />
                        </Route> */}

                        <Route path="/delivery-man-registration" exact>
                          <BeDeliveryMan />
                        </Route>

                        <Route path="/reset-password" exact>
                          <ForgetPw />
                        </Route>

                        <Route path="/set-new-password/:token" exact>
                          <SetNewPw />
                        </Route>

                        {credentials.install_no ? (
                          <Route path="/" exact>
                            <Login />
                          </Route>
                        ) : (
                          <Route path="/" exact>
                            <Login />
                            {/* <RestaurantLanding /> */}
                          </Route>
                        )}

                        {/* Customer routes */}
                        <CustomerRoute path="/profile" exact>
                          <CustomerProfile />
                        </CustomerRoute>
                        <CustomerRoute path="/my-orders" exact>
                          <MyOrders />
                        </CustomerRoute>

                        {/* restaurant dashboard pages */}
                        <RestaurantRoute path="/dashboard" exact>
                          <RestaurantHome />
                        </RestaurantRoute>

                        {/* {authUserInfo && authUserInfo?.details !== null ? */}
                        <Route path="/sales-listing" exact>
                          <SalesListing />
                        </Route>

                        <RestaurantRoute path="/update-user-profile" exact>
                          <UpdateProfile />
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/work-periods" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Work period"
                          ) ? (
                            <WorkPeriod />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/closing-stock/:started_at"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Work period"
                          ) ? (
                            <OpeningClosingStock />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <Route path="/order-details/:id" exact>
                          <OrderDelivery />
                        </Route>

                        <PosOrderRoute path="/dashboard/pos" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <Pos />
                          ) : (
                            <NoPermission />
                          )}
                        </PosOrderRoute>

                        <PosOrderRoute path="/dashboard/epos/:order_id" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <Pos />
                          ) : (
                            <NoPermission />
                          )}
                        </PosOrderRoute>

                        {/* <RestaurantRoute path="/dashboard/pos" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <Pos />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute> */}

                        {/* <RestaurantRoute path="/dashboard/epos/{:editId}" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <EditSubmittedOrder />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute> */}

                        {/* jtd */}
                        {/* <RestaurantRoute
                          path="/dashboard/edit-submit-order/:editId"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <EditSubmittedOrder />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute> */}

                        <PosOrderRoute path="/dashboard/pos/submitted" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <Submitted />
                          ) : (
                            <NoPermission />
                          )}
                        </PosOrderRoute>
                        
                        <PosOrderRoute path="/dashboard/pos/foodpanda" exact>
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "POS") ? (
                          <FoodPanda />
                        ) : (
                          <NoPermission />
                        )}
                       </PosOrderRoute>

                        <PosOrderRoute path="/dashboard/pos/settled" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <Settled />
                          ) : (
                            <NoPermission />
                          )}
                        </PosOrderRoute>

                        <PosOrderRoute
                          path="/dashboard/pos/online-orders"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <OnlineOrders />
                          ) : (
                            <NoPermission />
                          )}
                        </PosOrderRoute>

                        <RestaurantRoute path="/dashboard/orders" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Order history"
                          ) ? (
                            <OrderHistories />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/online-orders" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Order history"
                          ) ? (
                            <OnlineOrderHistories />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/customers" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Customer"
                          ) ? (
                            <Customers />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/online-customers"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Customer"
                          ) ? (
                            <OnlineCustomerList />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/kitchen" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Kitchen"
                          ) ? (
                            <Kitchen />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/kitchen/online" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Kitchen"
                          ) ? (
                            <KitchenOnline />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/kitchen/dinein" exact>
                          {authUserInfo.permissions !== null &&
                            checkPermission(authUserInfo.permissions, "Kitchen") ? (
                            <KitchenDineIn />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/kitchen/takeaway" exact>
                          {authUserInfo.permissions !== null &&
                            checkPermission(authUserInfo.permissions, "Kitchen") ? (
                            <KitchenTakeaway />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <Dashboard />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/admin-reports" exact>
                        {authUserInfo.permissions !== null &&
                          (authUserInfo.details.user_type === "superAdmin" || authUserInfo.details.user_type === "admin") &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <AdminDashboard />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/production-dashboard" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <ProductionDashboard />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/consolidated-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <ConsolitedData />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/consolidate-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <ConsolitedReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/saas-profile" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Saas profile"
                          ) ? (
                            <SaasProfile />
                          ) : (
                            // <Valoto />
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* test purpose */}
                        {/* <Route path='/valoto' exact>
                      <Valoto />
                    </Route> */}

                        <RestaurantRoute path="/dashboard/daily-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <Daily />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/monthly-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <Monthly />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* <RestaurantRoute path="/dashboard/yearly-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                          <Yearly />
                          ) : (
                          <NoPermission />
                          )}
                          </RestaurantRoute> */}

                        <RestaurantRoute
                          path="/dashboard/food-items-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <ItemWise />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/all-item-report"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <FoodItemReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/ingredient-report"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <IngredientReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>


                        <RestaurantRoute
                          path="/dashboard/food-group-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <GroupWise />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/branch-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <BranchWise />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/work-period-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <WorkPeriodReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/sales-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <SalesReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/order-tracking-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <OrderTracking />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/sales-summary-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <SalesSummaryReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/gross-sales-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <GrossSalesReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/tax-sales-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <SalesSyncReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/stock-transfer-raw-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <RawStockTransferReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/menu-mix-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <MenuMixReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/ingredient-item-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <IngredientItemReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        

                        <RestaurantRoute path="/dashboard/call-center-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <CallCenterReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/source-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <SourceWiseReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/customer-order-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <CustomerOrderHistoryReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        <RestaurantRoute
                          path="/dashboard/food-panda-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <FoodPandaReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        <RestaurantRoute
                        path="/dashboard/rider-reports"
                        exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <RiderReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        <RestaurantRoute
                        path="/dashboard/waiter-reports"
                        exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <WaiteReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        <RestaurantRoute path="/dashboard/expense-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <ExpenseReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        <RestaurantRoute path="/dashboard/stock-transfer-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <StockTransferReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        <RestaurantRoute path="/dashboard/meezan-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <MeezanReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/cancel-order-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <CancelOrderReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        <RestaurantRoute
                          path="/dashboard/cancel-made-unmade-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <CancelItemReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/hourly-sales-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <HourlySalesReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/income-statement-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <IncomeStatementReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                   
                        <RestaurantRoute
                          path="/dashboard/daily-cash-sales-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <DailyCashSalesReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/pos-user-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <UserWise />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/dept-tag-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <DeptWise />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/service-charge-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <ServiceChargeWise />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/discount-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <DiscountReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/sales-tender-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <SalesTenderReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/finished-goods-ledger-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <FinishedGoodsReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/purchase-invoice-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <PurchaseInvoiceReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>   

                        <RestaurantRoute path="/dashboard/fg-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <FgReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/fg-movement-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <FgMomentReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        

                        <RestaurantRoute path="/dashboard/fg-transfer-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <FgTransferReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/customer-report" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <CustomerReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/fg-discard-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <FgDiscardReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/inventory-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <InventoryReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/deal-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <DealReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/complete-sales" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <CompleteSales />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/stock-ledger-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <StockLedgerReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/supplier-ledger-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <SupplierLedgerReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/stock-variance-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <StockVarianceReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/stock-consumption-reports"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <StockConsumption />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/srb-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <SrbReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/production-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <ProductionReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/fg-packing-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <FgPackingReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/gift-hamper-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <GiftHamperReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/recipe-reports" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <RecipeReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/dataverify" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Report") ? (
                            <AdminReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/stock" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <StockDashboard />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/food-stock" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <FoodStockReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/ingredient-stock"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <IngredientStockReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/food-stock/:started_at"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <OpeningClosingStockFoodReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/ingredient-stock/:started_at"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Report"
                          ) ? (
                            <OpeningClosingStockIngredientReport />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* Manage routes */}
                        {/* food */}
                        <RestaurantRoute
                          path="/dashboard/manage/food/groups"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <GroupCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* <RestaurantRoute path="/dashboard/manage/food/units" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                          <UnitCrud />
                          ) : (
                          <NoPermission />
                          )}
                          </RestaurantRoute> */}

                        <RestaurantRoute
                          path="/dashboard/manage/food/variations"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <VariationCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/food/properties"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <PropertyCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          //path="/dashboard/manage/food/properties/:slug"
                          path="/dashboard/manage/food/properties-item"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <PropertyItemCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/food/add-new"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <FoodItemCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/food/addon-restriction"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <AddonRestrictionsCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/food/add-menu"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <MenuTagCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/food/add-schemes"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <SchemesCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/food/add-schemes-add" exact>
                          {authUserInfo.permissions !== null &&
                            checkPermission(authUserInfo.permissions, "Manage") ? (
                            <SchemeCrudAction/>
                            ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/food/add-schemes-edit/:id" exact>
                          {authUserInfo.permissions !== null &&
                            checkPermission(authUserInfo.permissions, "Manage") ? (
                            <SchemeCrudAction/>
                            ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/food/add-cards"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <CardsCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/food/all-items"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <AllItemList />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/food/edit-item/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "Manage"
                        ) ? (
                          <FoodItemEdit />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>


                        {/* Stock */}
                        <RestaurantRoute
                          path="/dashboard/manage/stock/food-purchase"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <FoodPurchase />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "Manage"
                        ) ? (
                          <FinishGoods />
                        ) : (
                          <NoPermission />
                        )}
                       </RestaurantRoute>

                       <RestaurantRoute
                       path="/dashboard/manage/stock/finished-goods-edit/:id"
                       exact
                     >
                       {authUserInfo.permissions !== null &&
                       checkPermission(
                         authUserInfo.permissions,
                         "Manage"
                       ) ? (
                         <EditFinishGoods />
                       ) : (
                         <NoPermission />
                       )}
                       </RestaurantRoute>

                       <RestaurantRoute
                       path="/dashboard/manage/stock/finished-goods-history"
                       exact
                     >
                       {authUserInfo.permissions !== null &&
                       checkPermission(
                         authUserInfo.permissions,
                         "Manage"
                       ) ? (
                         <FinishGoodsHistory />
                       ) : (
                         <NoPermission />
                       )}
                       </RestaurantRoute>

                       <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-transfer"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodTransfer />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-transfer-add"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodTransferAdd />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-transfer-edit/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodTransferEdit />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-discard"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishedGoodsDiscard />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-discard-add"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishedGoodsDiscardAdd />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-discard-edit/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishedGoodsDiscardEdit />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-acceptance"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodsAcceptance />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-acceptance-edit/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodsAcceptanceView />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-acceptance-view/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodsAcceptanceView />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-adjustment"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodsAdjustment />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/fg-packing"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FgPackageHistory />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/fg-packing-add"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FgPackingAction />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/fg-packing/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FgPackingAction />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      
                        <RestaurantRoute
                        path="/dashboard/manage/stock/gift-hampers"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <GiftHampers />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/gift-hampers-add"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <GiftHampersAction />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/gift-hampers/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <GiftHampersAction />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-adjustment-add"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodsAdjustmentAdd />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods-adjustment-view/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <FinishGoodsAdjustmentView />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                      path="/dashboard/manage/stock/wastage-food"
                      exact
                    >
                      {authUserInfo.permissions !== null &&
                      checkPermission(authUserInfo.permissions, "Manage") ? (
                        <WastageFood />
                      ) : (
                        <NoPermission />
                      )}
                    </RestaurantRoute>

                    <RestaurantRoute
                      path="/dashboard/manage/stock/wastage-food-add"
                      exact
                    >
                      {authUserInfo.permissions !== null &&
                      checkPermission(authUserInfo.permissions, "Manage") ? (
                        <WastageFoodAdd />
                      ) : (
                        <NoPermission />
                      )}
                    </RestaurantRoute>

                    <RestaurantRoute
                      path="/dashboard/manage/stock/wastage-food-view/:id"
                      exact
                    >
                      {authUserInfo.permissions !== null &&
                      checkPermission(authUserInfo.permissions, "Manage") ? (
                        <WastageFoodView />
                      ) : (
                        <NoPermission />
                      )}
                    </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-unit"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientUnit />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/create-recipe"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <CreateRecipe />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/view-recipe"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <ViewRecipe />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        

                        <RestaurantRoute
                          path="/dashboard/manage/stock/purchase-history-food"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <FoodPurchaseHistory />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/purchase-history-food-edit/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <FoodPurchaseEdit />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/food-return"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <FoodReturn />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-group"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientGroup />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-item"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientItem />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-limit"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientItemLimit />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-limit-add"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientLimitAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-limit-edit/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientLimitAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/purchase-invoice-add"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientPurchase />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/stock/purchase-order-add"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <PurchaseOrder />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/purchase-invoice"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientPurchaseHistory />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/purchase-invoice-edit/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientPurchaseEdit />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/stock/purchase-order"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <PurchaseOrderHistory />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>
            
                      <RestaurantRoute
                        path="/dashboard/manage/stock/purchase-order-edit/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <PurchaseOrderEdit />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                      path="/dashboard/manage/stock/purchase-goods-add"
                      exact
                    >
                      {authUserInfo.permissions !== null &&
                      checkPermission(authUserInfo.permissions, "Manage") ? (
                        <GoodsReceivedNote />
                      ) : (
                        <NoPermission />
                      )}
                    </RestaurantRoute>
            
                    <RestaurantRoute
                      path="/dashboard/manage/stock/purchase-goods-edit/:id"
                      exact
                    >
                      {authUserInfo.permissions !== null &&
                      checkPermission(authUserInfo.permissions, "Manage") ? (
                        <GoodsReceivedNoteEdit />
                      ) : (
                        <NoPermission />
                      )}
                    </RestaurantRoute>
            
                      <RestaurantRoute
                        path="/dashboard/manage/stock/purchase-goods"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <GoodsReceivedHistory />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>            

                        <RestaurantRoute
                          path="/dashboard/manage/stock/recipe-edit/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <RecipeEdit />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/stock/production"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "Manage"
                        ) ? (
                          <ProductionList />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                      path="/dashboard/manage/stock/add-production"
                      exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "Manage"
                        ) ? (
                          <ProductionAction />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                      path="/dashboard/manage/stock/edit-production/:id"
                      exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "Manage"
                        ) ? (
                          <ProductionAction />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/day-end-setting"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <DayEndSetting />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/day-end-setting-add"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <DayEndSettingAdd />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/day-end-setting-edit/:stock/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <DayEndSettingAdd />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/barcode-print-add"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <BarcodePrint />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-return"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientReturn />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-usage"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientUsage />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/manage-supplier"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <ManageSupplier />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/store-requisition" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StoreRequisition/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/store-requisition-add" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StoreRequisitionAdd/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/store-requisition-edit/:id" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StoreRequisitionEdit/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/stock-issuance" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StockIssuance/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/stock-issuance-view/:id" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StockIssuanceView/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/stock-issuance-edit/:id" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StockIssuanceView/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/stock-transfer" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StockTransfer />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/stock-transfer-add" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StockTransferAdd/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/stock-transfer-view/:id" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                          <StockTransferView/>
                          ) : (
                          <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/opening-stock" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <OpeningStock/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/opening-stock-add" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <OpeningStockAdd/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/opening-stock-edit/:id" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <OpeningStockEdit/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/stock-adjustment" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StockAdjustment/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/stock/stock-adjustment-add" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <StockAdjustmentAdd/>
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/supplier-history"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <SupplierHistory />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/stock-out-food"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <StockOutFood />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/stock-out-ingredient"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <StockOutIngredient />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/stock/supplier-payment"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <SupplierPayment />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/supplier-payment-add"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <SupplierPaymentAdd />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                      <RestaurantRoute
                        path="/dashboard/manage/stock/supplier-payment-edit/:id"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <SupplierPaymentEdit />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                        {/* Users */}
                        <RestaurantRoute
                          path="/dashboard/manage/user/customers"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <CustomerCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/user/admin-staff"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <AdminStaffCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/user/till"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <UserTill />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/user/add-till"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <UserTillAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/user/edit-till/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <UserTillAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/user/delivery-men"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <DeliveryMen />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/user/delivery-request"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <NewDeliveryMen />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/user/waiters"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Waiter />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/roles-and-permissions"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Permissions />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* Restaurant */}
                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/branches"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <BranchCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/md-jv"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <MdJv />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/md-integration"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <MDIntegrationList />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/md-integration-add"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <MDIntegrationAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/md-integration-edit/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <MDIntegrationAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/zones"
                          exact
                          >
                            {authUserInfo.permissions !== null &&
                            checkPermission(authUserInfo.permissions, "Manage") ? (
                              <Zone />
                            ) : (
                              <NoPermission />
                            )}
                          </RestaurantRoute>

                          <RestaurantRoute
                          path="/dashboard/manage/restaurant/zone-add"
                          exact
                          >
                            {authUserInfo.permissions !== null &&
                            checkPermission(authUserInfo.permissions, "Manage") ? (
                              <ZoneAction />
                            ) : (
                              <NoPermission />
                            )}
                          </RestaurantRoute>

                          <RestaurantRoute
                          path="/dashboard/manage/restaurant/zone-edit/:id"
                          exact
                          >
                            {authUserInfo.permissions !== null &&
                            checkPermission(authUserInfo.permissions, "Manage") ? (
                              <ZoneAction />
                            ) : (
                              <NoPermission />
                            )}
                          </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/restaurant/branches-add" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <BranchAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/restaurant/branches-edit/:id" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <BranchAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/kds-setting"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <KdsSettingList />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/kds-setting-add"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <KdsSettingAdd />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/kds-setting-edit/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <KdsSettingEdit />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/order-types"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <OrderTypeCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/restaurant/table-floor"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <TableFloor />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/tables"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <TableCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* <RestaurantRoute
                          path="/dashboard/manage/restaurant/dept-tags"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <DeptTagCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute> */}

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/payment-type"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <PaymentTypeCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/restaurant/settlement" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <Settlement />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/restaurant/settlement-add" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <SettlementAdd />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/dashboard/manage/restaurant/settlement-view/:id" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <SettlementView />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/manage-party"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Party />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/manage-party-add"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <PartyAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/manage-party-edit/:id"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <PartyAction />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                          
                        {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Show Expense List") && <RestaurantRoute
                        path="/dashboard/expense"
                        exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "POS") ? (
                            <ExpenseList />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>}

                        <RestaurantRoute
                        path="/dashboard/manage/restaurant/manage-expense"
                        exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Expense />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/party-history"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <PartyHistory />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/restaurant/manage-kot"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <KotCrud />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* Website */}
                        <RestaurantRoute
                          path="/dashboard/manage/website/hero-section"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <HeroSection />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/website/promotions"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Promotions />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* Settings */}
                        <RestaurantRoute
                          path="/dashboard/manage/settings/currencies"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Currency />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/settings/languages"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Lang />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/settings/languages/:code"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Translation />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/settings/smtp-settings"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Smtp />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/settings/email-settings"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "Manage"
                        ) ? (
                          <EmailTemplate />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/settings/pos-screen"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <PosScreen />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/settings/general-settings"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <General />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/settings/web-mapping"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(authUserInfo.permissions, "Manage") ? (
                            <WebMapping />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>
                        
                        <RestaurantRoute
                          path="/dashboard/manage/settings/show-delivery-menu"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <Showdeliveryman />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute path="/hellno" exact>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <OnlinePayment />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/settings/show-manage-stock-menu"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <ShowManageStock />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/settings/update-settings"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(authUserInfo.permissions, "Manage") ? (
                          <UpdateSettings />
                        ) : (
                          <NoPermission />
                        )}
                      </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/manage/settings/update-system"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <UpdateSystem />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* accounts */}

                        <RestaurantRoute
                          path="/dashboard/manage/accounts/gl-setting"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <AccountSettings />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                      <RestaurantRoute
                      path="/dashboard/manage/accounts/level-1"
                      exact
                    >
                      {authUserInfo.permissions !== null &&
                      checkPermission(authUserInfo.permissions, "Manage") ? (
                        < ChartAccount1 />
                      ) : (
                        <NoPermission />
                      )}
                    </RestaurantRoute>

                    <RestaurantRoute
                    path="/dashboard/manage/accounts/level-2"
                    exact
                  >
                    {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Manage") ? (
                      <ChartAccount2 />
                    ) : (
                      <NoPermission />
                    )}
                  </RestaurantRoute>

                  <RestaurantRoute
                  path="/dashboard/manage/accounts/level-3"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <ChartAccount3 />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                {/* transaction routes */}
          <RestaurantRoute
            path="/dashboard/manage/transaction/bank-payment"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <BankPayment />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/cash-payment"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <CashPayment />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/journal-voucher"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <JournalVoucher />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/opening-account"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <OpeningAccount />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/bank-payment-add"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddBankPayment/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/bank-payment-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EditBankPayment/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/cash-payment-add"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddCashPayment/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/cash-payment-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EditCashPayment/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/journal-voucher-add"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddJournalVoucher/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/journal-voucher-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EditJournalVoucher/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/opening-account-add"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddOpeningAccount/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/opening-account-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EditOpeningAccount/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          
          {/* transaction route end */}

          {/* Accounts report route start */}
          <RestaurantRoute
            path="/dashboard/manage/reports/ledger-report"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <LedgerReport/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          {/* Accounts report route end */}

                        <RestaurantRoute
                          path="/dashboard/delivery/assigned-orders"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Delivery"
                          ) ? (
                            <AssignedOrders />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                          path="/dashboard/delivery/delivered-orders"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Delivery"
                          ) ? (
                            <DeliveredOrders />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        {/* Error Routing */}
                        <Route component={NotFound} />
                        {/* Error Routing */}
                      </Switch>
                      <Footer />
                    </Router>,
                  ]
                ) : (
                  <Blocked />
                ),
              ]
            ) : (
              <NotPermitted />
            ),
            // ]
          ]
        : [
            <Router>                  
              <Navbar />
              <Switch>
                 
                {/* installation */}
                {credentials.install_no && (
                  <Route path="/installation" exact>
                    <Welcome />
                  </Route>
                )}

                {credentials.install_no && (
                  <Route path="/installation/permission-chcek" exact>
                    <InstallPermission />
                  </Route>
                )}

                {credentials.install_no && (
                  <Route path="/installation/database-setup" exact>
                    <DbSetup />
                  </Route>
                )}

                {credentials.install_no && (
                  <Route path="/installation/import-database" exact>
                    <ImportDb />
                  </Route>
                )}

                {credentials.install_no && (
                  <Route path="/installation/add-admin-user" exact>
                    <InstallationUser />
                  </Route>
                )}

                {credentials.install_no && (
                  <Route path="/installation/congratulation" exact>
                    <InstallationCongratulation />
                  </Route>
                )}

                {/* common */}
                <Route path="/refresh" exact>
                  <Refresh />
                </Route>

                <Route path="/login" exact>
                  <Login />
                </Route>

                {/* <Route path="/sign-up" exact>
                  <SignUp />
                </Route> */}

                <Route path="/delivery-man-registration" exact>
                  <BeDeliveryMan />
                </Route>

                <Route path="/reset-password" exact>
                  <ForgetPw />
                </Route>

                <Route path="/set-new-password/:token" exact>
                  <SetNewPw />
                </Route>

                <Route path="/" exact>
                  <Login />
                </Route>
                {/* {credentials.install_no ? (
                  <Route path="/" exact>
                    <Login />
                  </Route>
                ) : (
                  <Route path="/" exact>
                    <RestaurantLanding />
                  </Route>
                )} */}

                {/* Customer routes */}
                <CustomerRoute path="/profile" exact>
                  <CustomerProfile />
                </CustomerRoute>
                <CustomerRoute path="/my-orders" exact>
                  <MyOrders />
                </CustomerRoute>

                {/* restaurant dashboard pages */}
                <RestaurantRoute path="/dashboard" exact>
                  <RestaurantHome />
                </RestaurantRoute>

                {/* {authUserInfo && authUserInfo?.details !== null ? */}
              <Route path="/sales-listing" exact>
                <SalesListing />
              </Route>

                <RestaurantRoute path="/update-user-profile" exact>
                  <UpdateProfile />
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/work-periods" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Work period") ? (
                    <WorkPeriod />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/closing-stock/:started_at"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Work period") ? (
                    <OpeningClosingStock />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <Route path="/order-details/:id" exact>
                  <OrderDelivery />
                </Route>

                <PosOrderRoute path="/dashboard/pos" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "POS") ? (
                    <Pos />
                  ) : (
                    <NoPermission />
                  )}
                </PosOrderRoute>

                <PosOrderRoute path="/dashboard/epos/:order_id" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "POS") ? (
                    <Pos />
                  ) : (
                    <NoPermission />
                  )}
                </PosOrderRoute>

                {/* jtd */}
                {/* <RestaurantRoute
                  path="/dashboard/edit-submit-order/:editId"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "POS") ? (
                    <EditSubmittedOrder />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute> */}

                <PosOrderRoute path="/dashboard/pos/submitted" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "POS") ? (
                    <Submitted />
                  ) : (
                    <NoPermission />
                  )}
                </PosOrderRoute>


                <PosOrderRoute path="/dashboard/pos/foodpanda" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "POS") ? (
                    <FoodPanda />
                  ) : (
                    <NoPermission />
                  )}
               </PosOrderRoute>

                <PosOrderRoute path="/dashboard/pos/settled" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "POS") ? (
                    <Settled />
                  ) : (
                    <NoPermission />
                  )}
                </PosOrderRoute>

                <PosOrderRoute path="/dashboard/pos/online-orders" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "POS") ? (
                    <OnlineOrders />
                  ) : (
                    <NoPermission />
                  )}
                </PosOrderRoute>

                <RestaurantRoute path="/dashboard/orders" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Order history") ? (
                    <OrderHistories />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/online-orders" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Order history") ? (
                    <OnlineOrderHistories />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/customers" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Customer") ? (
                    <Customers />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/online-customers" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Customer") ? (
                    <OnlineCustomerList />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/kitchen" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Kitchen") ? (
                    <Kitchen />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/kitchen/online" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Kitchen") ? (
                    <KitchenOnline />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/kitchen/dinein" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Kitchen") ? (
                    <KitchenDineIn />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/kitchen/takeaway" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Kitchen") ? (
                    <KitchenTakeaway />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <Dashboard />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/admin-reports" exact>
                  {authUserInfo.permissions !== null &&
                    (authUserInfo.details.user_type === "superAdmin" || authUserInfo.details.user_type === "admin") &&
                    checkPermission(
                      authUserInfo.permissions,
                      "Report"
                    ) ? (
                      <AdminDashboard />
                    ) : (
                      <NoPermission />
                    )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/production-dashboard" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <ProductionDashboard />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/consolidated-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Report"
                  ) ? (
                    <ConsolitedData />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/consolidate-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Report"
                  ) ? (
                    <ConsolitedReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/daily-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <Daily />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/monthly-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <Monthly />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                {/* <RestaurantRoute path="/dashboard/yearly-reports" exact>
{authUserInfo.permissions !== null &&
checkPermission(authUserInfo.permissions, "Report") ? (
<Yearly />
) : (
<NoPermission />
)}
</RestaurantRoute> */}

                <RestaurantRoute path="/dashboard/food-items-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <ItemWise />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/all-item-report" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FoodItemReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/ingredient-report" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <IngredientReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/food-group-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <GroupWise />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/branch-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <BranchWise />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/work-period-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <WorkPeriodReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/sales-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <SalesReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/order-tracking-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Report"
                  ) ? (
                    <OrderTracking />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/sales-summary-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <SalesSummaryReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/gross-sales-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <GrossSalesReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/tax-sales-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <SalesSyncReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/stock-transfer-raw-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <RawStockTransferReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/menu-mix-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <MenuMixReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/ingredient-item-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <IngredientItemReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/call-center-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <CallCenterReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/daily-cash-sales-reports"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <DailyCashSalesReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/source-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <SourceWiseReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                <RestaurantRoute path="/dashboard/customer-order-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <CustomerOrderHistoryReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/food-panda-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FoodPandaReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                <RestaurantRoute path="/dashboard/rider-reports" exact>
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Report") ? (
                  <RiderReport />
                ) : (
                  <NoPermission />
                )}
               </RestaurantRoute>
               <RestaurantRoute path="/dashboard/waiter-reports" exact>
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Report") ? (
                  <WaiteReport />
                ) : (
                  <NoPermission />
                )}
               </RestaurantRoute>
                <RestaurantRoute path="/dashboard/expense-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <ExpenseReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                <RestaurantRoute path="/dashboard/stock-transfer-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <StockTransferReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                <RestaurantRoute path="/dashboard/meezan-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <MeezanReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/cancel-order-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <CancelOrderReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                <RestaurantRoute
                  path="/dashboard/cancel-made-unmade-reports"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <CancelItemReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                <RestaurantRoute path="/dashboard/hourly-sales-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <HourlySalesReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/income-statement-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <IncomeStatementReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>                

                <RestaurantRoute path="/dashboard/pos-user-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <UserWise />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/dept-tag-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <DeptWise />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/service-charge-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <ServiceChargeWise />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/discount-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <DiscountReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/sales-tender-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <SalesTenderReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/finished-goods-ledger-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FinishedGoodsReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/purchase-invoice-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <PurchaseInvoiceReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute> 

                <RestaurantRoute path="/dashboard/fg-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FgReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/fg-movement-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FgMomentReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/fg-transfer-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FgTransferReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/customer-report" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <CustomerReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/fg-discard-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FgDiscardReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/inventory-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <InventoryReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/deal-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <DealReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/complete-sales" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <CompleteSales />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/stock-ledger-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <StockLedgerReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/supplier-ledger-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <SupplierLedgerReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/stock-variance-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <StockVarianceReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/stock-consumption-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <StockConsumption />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/srb-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <SrbReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/production-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <ProductionReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/fg-packing-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FgPackingReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/gift-hamper-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <GiftHamperReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/recipe-reports" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <RecipeReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/dataverify" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <AdminReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/stock" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <StockDashboard />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/food-stock" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <FoodStockReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/ingredient-stock" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <IngredientStockReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/food-stock/:started_at" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <OpeningClosingStockFoodReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/ingredient-stock/:started_at"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Report") ? (
                    <OpeningClosingStockIngredientReport />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                {/* Manage routes */}
                {/* food */}
                <RestaurantRoute path="/dashboard/manage/food/groups" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <GroupCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                {/* <RestaurantRoute path="/dashboard/manage/food/units" exact>
{authUserInfo.permissions !== null &&
checkPermission(authUserInfo.permissions, "Manage") ? (
<UnitCrud />
) : (
<NoPermission />
)}
</RestaurantRoute> */}

                <RestaurantRoute path="/dashboard/manage/food/variations" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <VariationCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/properties" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <PropertyCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  //path="/dashboard/manage/food/properties/:slug"
                  path="/dashboard/manage/food/properties-item"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <PropertyItemCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/add-new" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FoodItemCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/addon-restriction" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <AddonRestrictionsCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/add-menu" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <MenuTagCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/food/add-schemes"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <SchemesCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/add-schemes-add" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <SchemeCrudAction/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/add-schemes-edit/:id" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <SchemeCrudAction/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/add-cards" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <CardsCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/all-items" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <AllItemList />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/food/edit-item/:id" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FoodItemEdit />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>


                {/* Stock */}
                
                <RestaurantRoute
                  path="/dashboard/manage/stock/create-recipe"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <CreateRecipe />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/view-recipe"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <ViewRecipe />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                
                <RestaurantRoute
                path="/dashboard/manage/stock/wastage-food"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <WastageFood />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

              <RestaurantRoute
                path="/dashboard/manage/stock/wastage-food-add"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <WastageFoodAdd />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

              <RestaurantRoute
                path="/dashboard/manage/stock/wastage-food-view/:id"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <WastageFoodView />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

                <RestaurantRoute
                          path="/dashboard/manage/stock/ingredient-unit"
                          exact
                        >
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Manage"
                          ) ? (
                            <IngredientUnit />
                          ) : (
                            <NoPermission />
                          )}
                        </RestaurantRoute>

                        <RestaurantRoute
                        path="/dashboard/manage/stock/finished-goods"
                        exact
                      >
                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "Manage"
                        ) ? (
                          <FinishGoods />
                        ) : (
                          <NoPermission />
                        )}
                       </RestaurantRoute>

                       <RestaurantRoute
                       path="/dashboard/manage/stock/finished-goods-edit/:id"
                       exact
                     >
                       {authUserInfo.permissions !== null &&
                       checkPermission(
                         authUserInfo.permissions,
                         "Manage"
                       ) ? (
                         <EditFinishGoods />
                       ) : (
                         <NoPermission />
                       )}
                       </RestaurantRoute>

                       <RestaurantRoute
                       path="/dashboard/manage/stock/finished-goods-history"
                       exact
                     >
                       {authUserInfo.permissions !== null &&
                       checkPermission(
                         authUserInfo.permissions,
                         "Manage"
                       ) ? (
                         <FinishGoodsHistory />
                       ) : (
                         <NoPermission />
                       )}
                       </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/food-purchase"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FoodPurchase />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/purchase-history-food"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FoodPurchaseHistory />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/purchase-history-food-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FoodPurchaseEdit />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/food-return"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FoodReturn />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/ingredient-group"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientGroup />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/ingredient-item"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientItem />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/ingredient-limit"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientItemLimit />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/ingredient-limit-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientLimitAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>


                <RestaurantRoute
                  path="/dashboard/manage/stock/ingredient-limit-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientLimitAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/purchase-invoice-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientPurchase />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/stock/purchase-order-add"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <PurchaseOrder />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/purchase-invoice"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientPurchaseHistory />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                

                <RestaurantRoute
                  path="/dashboard/manage/stock/purchase-invoice-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientPurchaseEdit />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/stock/purchase-order"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <PurchaseOrderHistory />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>
    
              <RestaurantRoute
                path="/dashboard/manage/stock/purchase-order-edit/:id"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <PurchaseOrderEdit />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

              <RestaurantRoute
              path="/dashboard/manage/stock/purchase-goods-add"
              exact
            >
              {authUserInfo.permissions !== null &&
              checkPermission(authUserInfo.permissions, "Manage") ? (
                <GoodsReceivedNote />
              ) : (
                <NoPermission />
              )}
            </RestaurantRoute>
    
            <RestaurantRoute
              path="/dashboard/manage/stock/purchase-goods-edit/:id"
              exact
            >
              {authUserInfo.permissions !== null &&
              checkPermission(authUserInfo.permissions, "Manage") ? (
                <GoodsReceivedNoteEdit />
              ) : (
                <NoPermission />
              )}
            </RestaurantRoute>
    
              <RestaurantRoute
                path="/dashboard/manage/stock/purchase-goods"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <GoodsReceivedHistory />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>    

                <RestaurantRoute
                  path="/dashboard/manage/stock/recipe-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <RecipeEdit />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/stock/production"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Manage"
                ) ? (
                  <ProductionList />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

              <RestaurantRoute
              path="/dashboard/manage/stock/add-production"
              exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Manage"
                ) ? (
                  <ProductionAction />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

              <RestaurantRoute
              path="/dashboard/manage/stock/edit-production/:id"
              exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(
                  authUserInfo.permissions,
                  "Manage"
                ) ? (
                  <ProductionAction />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/day-end-setting"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <DayEndSetting />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/day-end-setting-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <DayEndSettingAdd />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                
                <RestaurantRoute
                  path="/dashboard/manage/stock/day-end-setting-edit/:stock/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <DayEndSettingAdd />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/barcode-print-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <BarcodePrint />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/ingredient-return"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientReturn />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/ingredient-usage"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <IngredientUsage />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/manage-supplier"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <ManageSupplier />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/store-requisition" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StoreRequisition/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/store-requisition-add" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StoreRequisitionAdd/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/store-requisition-edit/:id" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StoreRequisitionEdit/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/stock-issuance" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockIssuance/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/stock-issuance-view/:id" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockIssuanceView/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/stock-issuance-edit/:id" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockIssuanceView/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/stock-transfer" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockTransfer />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/stock-transfer-add" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockTransferAdd/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/stock-transfer-view/:id" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockTransferView/>
                    ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/opening-stock" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Manage") ? (
                    <OpeningStock/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/opening-stock-add" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Manage") ? (
                    <OpeningStockAdd/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/opening-stock-edit/:id" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Manage") ? (
                  <OpeningStockEdit/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/stock-adjustment" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockAdjustment/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/stock/stock-adjustment-add" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockAdjustmentAdd/>
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/supplier-history"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <SupplierHistory />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/stock-out-food"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockOutFood />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/stock-out-ingredient"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <StockOutIngredient />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-transfer"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodTransfer />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-transfer-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodTransferAdd />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-transfer-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodTransferEdit />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-discard"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishedGoodsDiscard />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-discard-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishedGoodsDiscardAdd />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-discard-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishedGoodsDiscardEdit />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-acceptance"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodsAcceptance />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-acceptance-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodsAcceptanceView />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-acceptance-view/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodsAcceptanceView />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-adjustment"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodsAdjustment />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/fg-packing"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FgPackageHistory />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/fg-packing-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FgPackingAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/gift-hampers"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <GiftHampers />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/gift-hampers-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <GiftHampersAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/gift-hampers/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <GiftHampersAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/fg-packing/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FgPackingAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-adjustment-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodsAdjustmentAdd />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>
                
                <RestaurantRoute
                  path="/dashboard/manage/stock/finished-goods-adjustment-view/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <FinishGoodsAdjustmentView />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/stock/supplier-payment"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <SupplierPayment />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

              <RestaurantRoute
                path="/dashboard/manage/stock/supplier-payment-add"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <SupplierPaymentAdd />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

              <RestaurantRoute
                path="/dashboard/manage/stock/supplier-payment-edit/:id"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <SupplierPaymentEdit />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

                {/* Users */}
                <RestaurantRoute path="/dashboard/manage/user/customers" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <CustomerCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/user/admin-staff"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <AdminStaffCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/user/till"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Manage"
                  ) ? (
                    <UserTill />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/user/add-till"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Manage"
                  ) ? (
                    <UserTillAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/user/edit-till/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Manage"
                  ) ? (
                    <UserTillAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/user/delivery-men"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <DeliveryMen />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/user/delivery-request"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <NewDeliveryMen />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/user/waiters" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Waiter />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/roles-and-permissions"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Permissions />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                {/* Restaurant */}
                <RestaurantRoute
                  path="/dashboard/manage/restaurant/branches"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <BranchCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/md-jv"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <MdJv />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/md-integration"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <MDIntegrationList />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/md-integration-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <MDIntegrationAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/md-integration-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <MDIntegrationAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/restaurant/zones"
                exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Zone />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/restaurant/zone-add"
                exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <ZoneAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/restaurant/zone-edit/:id"
                exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <ZoneAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/restaurant/branches-add" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Manage") ? (
                      <BranchAction />
                    ) : (
                      <NoPermission />
                    )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/restaurant/branches-edit/:id" exact>
                  {authUserInfo.permissions !== null &&
                    checkPermission(authUserInfo.permissions, "Manage") ? (
                      <BranchAction />
                    ) : (
                      <NoPermission />
                    )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/kds-setting"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <KdsSettingList />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/kds-setting-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <KdsSettingAdd />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/kds-setting-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <KdsSettingEdit />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/order-types"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <OrderTypeCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/table-floor"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <TableFloor />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/tables"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <TableCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                {/* <RestaurantRoute
                  path="/dashboard/manage/restaurant/dept-tags"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <DeptTagCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute> */}

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/payment-type"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <PaymentTypeCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/restaurant/settlement" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Settlement />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/restaurant/settlement-add" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <SettlementAdd />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute path="/dashboard/manage/restaurant/settlement-view/:id" exact>
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <SettlementView />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/manage-party"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Party />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/manage-party-add"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <PartyAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/manage-party-edit/:id"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <PartyAction />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                  
                {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Show Expense List") && <RestaurantRoute
                path="/dashboard/expense"
                exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "POS") ? (
                    <ExpenseList />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>}


                <RestaurantRoute
                path="/dashboard/manage/restaurant/manage-expense"
                exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Expense />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/party-history"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <PartyHistory />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/restaurant/manage-kot"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <KotCrud />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                {/* Website */}
                <RestaurantRoute
                  path="/dashboard/manage/website/hero-section"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <HeroSection />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/website/promotions"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Promotions />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                {/* Settings */}
                <RestaurantRoute
                  path="/dashboard/manage/settings/currencies"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Currency />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/languages"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Lang />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/languages/:code"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Translation />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/smtp-settings"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Smtp />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/settings/email-settings"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <EmailTemplate />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/pos-screen"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <PosScreen />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/general-settings"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <General />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/web-mapping"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <WebMapping />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/show-delivery-menu"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <Showdeliveryman />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/setup-payment"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <OnlinePayment />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/show-manage-stock-menu"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <ShowManageStock />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/update-settings"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <UpdateSettings />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/settings/update-system"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <UpdateSystem />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/manage/accounts/gl-setting"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Manage") ? (
                    <AccountSettings />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                path="/dashboard/manage/accounts/level-1"
                exact
              >
                {authUserInfo.permissions !== null &&
                checkPermission(authUserInfo.permissions, "Manage") ? (
                  <ChartAccount1 />
                ) : (
                  <NoPermission />
                )}
              </RestaurantRoute>

              <RestaurantRoute
              path="/dashboard/manage/accounts/level-2"
              exact
            >
              {authUserInfo.permissions !== null &&
              checkPermission(authUserInfo.permissions, "Manage") ? (
                <ChartAccount2 />
              ) : (
                <NoPermission />
              )}
            </RestaurantRoute>

            <RestaurantRoute
            path="/dashboard/manage/accounts/level-3"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <ChartAccount3 />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>

          {/* transaction routes */}
          <RestaurantRoute
            path="/dashboard/manage/transaction/bank-payment"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <BankPayment />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/cash-payment"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <CashPayment />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/journal-voucher"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <JournalVoucher />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/opening-account"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <OpeningAccount />
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/bank-payment-add"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddBankPayment/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/bank-payment-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EditBankPayment/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/cash-payment-add"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddCashPayment/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/cash-payment-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EditCashPayment/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/journal-voucher-add"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddJournalVoucher/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/journal-voucher-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EditJournalVoucher/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/opening-account-add"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <AddOpeningAccount/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          <RestaurantRoute
            path="/dashboard/manage/transaction/opening-account-edit/:id"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <EditOpeningAccount/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          {/* transaction route end */}

          {/* Accounts report route start */}
          <RestaurantRoute
            path="/dashboard/manage/reports/ledger-report"
            exact
          >
            {authUserInfo.permissions !== null &&
            checkPermission(authUserInfo.permissions, "Manage") ? (
              <LedgerReport/>
            ) : (
              <NoPermission />
            )}
          </RestaurantRoute>
          {/* Accounts report route end */}        

                <RestaurantRoute
                  path="/dashboard/delivery/assigned-orders"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Delivery") ? (
                    <AssignedOrders />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                <RestaurantRoute
                  path="/dashboard/delivery/delivered-orders"
                  exact
                >
                  {authUserInfo.permissions !== null &&
                  checkPermission(authUserInfo.permissions, "Delivery") ? (
                    <DeliveredOrders />
                  ) : (
                    <NoPermission />
                  )}
                </RestaurantRoute>

                {/* Error Routing */}
                <Route component={NotFound} />
                {/* Error Routing */}
              </Switch>
              <Footer />
            </Router>,
          ]}
    </>
  );
}

export default SaasApp;
