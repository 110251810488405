import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

//importing context consumer here
import { UserContext } from "../contexts/User";

//functions
import { _t, getCookie, getOffline } from "../functions/Functions";
import { BASE_URL } from "../BaseUrl";

const checkAuthorization = () => {
  const userUrl = BASE_URL + "/auth/user";
  return getOffline(userUrl);
};
//3rd party packages
const RestaurantRoute = ({ children, ...rest }) => {

  //getting context values here
  const { authUserInfo } = useContext(UserContext);
  const [isAuthorized, setIsAuthorized] = useState(null); // null means loading

  useEffect(() => {
    const fetchAuthorization = async () => {
      const authorizedUser = await checkAuthorization();
      setIsAuthorized(authorizedUser);
    };
  
    fetchAuthorization();
  }, []);

  if(getCookie() === undefined && isAuthorized === null){
    return(
      <>
        <div className="loadingBlur"></div>
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <Route
          {...rest}
          render={()=>children}
        ></Route>
      </>
    )
  }
  //redirect if customer
  if (authUserInfo.details && authUserInfo.details.user_type === "customer") {
    return (
      <Route
        render={() => {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }}
      ></Route>
    );
  }

  return (
    <Route
      {...rest}
      render={() => {
        return getCookie() !== undefined || isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { alert: "You need to login first!" },
            }}
          />
        );
      }}
    ></Route>
  );
};
export default RestaurantRoute;
