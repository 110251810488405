import React from "react";
import "./pos.css";
import { useParams, useHistory } from "react-router-dom";

//functions
import {
  _t,
  currencySymbolLeft,
  currencySymbolRight,
  // restaurantMenuLink,
} from "../../../../../functions/Functions";

const suggestQty = [100,125,250,375,500,,750,875,1000]

const CartItem = ({
  itemId,
  item,
  deleteItem,
  editItem,
  getPrice,
  addToCart,
  deleteOneCartItem,
  deletedItemsOnEdit,
  setAddReasonModalShowing,
  orderScheme,
  itemAdd,
  isLocked,
  authenticateStaffUser,
  isOpenQty,
  // removeComments,
  handleSetItemQty,
  setCommentId,
  animate,
}) => {
  //edit order
  const { order_id } = useParams();
 
  const itemProperty = [];
  if (parseInt(item.item.has_property) === 1 && Object.keys(item.properties)?.length > 0) {
    Object.values(item.properties).forEach((item) => {
      const properties = Object.values(item);
      properties.forEach((property) => {
        const propertyName = property.item.name;
        itemProperty.push(
          <span className="item_property" key={propertyName}>
           {property.quantity} - {propertyName}
          </span>
        );
      });
      // for (const key in item) {
      //   itemProperty.push(
      //     <span className="item_property" key={key}>
      //       {item[key].item.name}
      //     </span>
      //   );
      // }
    });
  }

 
  return (
    <div key={itemId} id={itemId ? "cart_Item" : ""} className="pt-2 pb-1 d-flex flex-row justify-content-between border-bottom position-relative pos--cart-item">
      <span className="text-capitalize total-bill d-inline-block col-5">
        {item.item.name}
        {parseInt(item.item.has_variation) === 1 ? (
          <span className="item_variation">
            ({item?.variation?.variation_name}){" "}
          </span>
        ) : (
          <></>
        )}
        <br />
        {parseInt(item.item.has_property) === 1 && [itemProperty]}
      </span>
      <span className="text-capitalize total-bill position-relative d-inline-block col-3 text-center">
        {item.quantity > 1 ? (
          <button
            disabled = {order_id && !item.new && isLocked("item_remove_qty_cp")}
            className="mx-1 itemQtyBtn cart-decrement"
            onClick={() => {
              animate.current = "del";
              if(item.offer_type === "bogo" && item.item_discount > 0){
                  
              }else{
                if (order_id) {
                    item.new === true ||
                    (deletedItemsOnEdit && deletedItemsOnEdit[itemId]?.status) ||
                    (itemAdd && itemAdd[itemId] && itemAdd[itemId] !== undefined)
                      ? deleteOneCartItem(itemId)
                      : setAddReasonModalShowing({
                          action: "decrease",
                          params: [itemId],
                        });
                } else {
                  deleteOneCartItem(itemId);
                }
              }
            }}
          >
            <i class="fa fa-minus"></i>
          </button>
        ) : (
          <button className="mx-1 itemQtyBtn cart-decrement">
            <i class="fa fa-minus"></i>
          </button>
        )}
        {
          isOpenQty ? (
            <>
              <input disabled={(order_id && item.new === false) || (item.offer_type === "bogo" && item.item_discount > 0)} type="number" style={{width:"40%",minHeight:"1.4rem",textAlign:"center"}} className="form-control cartInputQty rounded-25 d-inline-block px-1" value={item.quantity} onChange={(e)=>handleSetItemQty(item,itemId,parseInt(e.target.value))} />

              {
                (order_id && item.new === false) || (item.offer_type === "bogo" && item.item_discount > 0) ? (
                ""
                ) : (
                  <div className="qty_suggestion">
                    {
                      suggestQty.map((qty) => (
                        <div
                          key={qty}
                          className={`mx-1 mb-1 ${qty === item.quantity? "active" : ""}`}
                          onClick={() => handleSetItemQty(item, itemId, qty)}
                        >
                          {qty}
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </>
          ) : (
            item.quantity
          )
        }
        
        <button
        disabled = {order_id && !item.new ? isLocked("item_remove_qty_cp") : ""}
          className="mx-1 itemQtyBtn cart-increment"
          onClick={() =>{
            animate.current = "add";
            if(item.offer_type === "bogo" && item.item_discount > 0){
            }else{
              addToCart(itemId, item)
            }
          }}
        >
          <i class="fa fa-plus"></i>
        </button>
      </span>

      <span
        className="text-capitalize total-bill d-inline-block font-weight-bold col-4 pl-1 text-right text-break"
        style={{ position: "relative" }}
      >
        { 
          item.item_discount > 0 && orderScheme?.cap_amount <= 0 ? (
           <span> 
            <span style={{color:"#e43b53"}}>
           {currencySymbolLeft()}
           {(getPrice(item) - item.item_discount.toFixed(2)).toFixed(2)}
           {currencySymbolRight()}
           </span>
           <del className="ml-1">
           {currencySymbolLeft()}
           {getPrice(item)}
           {currencySymbolRight()}
           </del> 
           </span>      
           )
           :
           <span>
           {currencySymbolLeft()}
           {getPrice(item)}
           {currencySymbolRight()}
           </span>
        }

        <div
          className="position-absolute left-0 bottom-0 right-0 justify-content-end align-items-center pos--cart-actions-menu"
          style={{ background: "white" }}
        >
        
        {
         ((order_id && item.new) || !order_id) && (
          <button onClick={(e)=>{
            if(item && item !== undefined){
              setCommentId(itemId);
            } 
            
          }
          }
          className="badge badge-secondary btn-info item_comment_icon" 
          
          >
           <i className="fa fa-info" aria-hidden="true"></i>
          </button>
         )
        }
         
         {
          (parseInt(item.item.has_property) === 1 || parseInt(item.item.has_variation) === 1) &&
          ((order_id && item.new) || !order_id) && (
            <button onClick={() => editItem(itemId)} className="item_edit_icon">
              <i className="fa fa-pencil"></i>
            </button>
          )
         }
          {order_id && (item.new !== true) &&
            isLocked("item_remove_qty_cp") ? (
            <span
              className="pointer-cursor mx-1 d-flex align-items-center"
              onClick={(e) => authenticateStaffUser("item_remove_qty_cp")}
            >
              <i className="fa fa-lock lock_icon"></i>
            </span>
          ) : (
            <button
              className="item_delete_icon"
              onClick={() => {
                animate.current = false;
                if (order_id) {
                  (item.new === true || (deletedItemsOnEdit && deletedItemsOnEdit[itemId]?.status))
                    ? deleteItem(itemId)
                    : setAddReasonModalShowing({
                        action: "remove",
                        params: [itemId],
                      });
                } else {
                  deleteItem(itemId);
                }
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
        </div>
      </span>
    </div>
    
  );
};

export default CartItem;
