import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import {
  BASE_URL,
  SAAS_APPLICATION,
  saas_apiUrl,
  saas_apiParams,
  saas_form_data,
} from "../../../../../BaseUrl";
import FoodDeal from "./FoodDeal";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  getSystemSettings,
  checkPermission,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";
import Expired from "../../../../../saasHelper/Expired";
import SaasApiFailure from "../../../../../saasHelper/saasApiFailure";
import Switch from "react-switch";
import { UserContext } from "../../../../../contexts/User";

const FoodItemCrud = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let { authUserInfo } = useContext(UserContext);
  //getting context values here
  let { loading, setLoading, dataPaginating,generalSettings,showManageStock } = useContext(SettingsContext);
  let [apiFailed, setApiFailed] = useState(false);

  let {
    setFoodForSearch,
    foodGroupForSearch,
    propertyGroupForSearch,
    variationForSearch,
    // getFoodItemsForPOS,
  } = useContext(FoodContext);

  let {
    //dept-tag
    // getDeptTag,
    // deptTagList,
    // setDeptTagList,
    // setPaginatedDeptTag,
    deptTagForSearch,
    // setDeptTagForSearch,

    // branchForSearch,
    // orderTypeForSearch,
  } = useContext(RestaurantContext);
  let [priceForItem, setPriceForItem] = useState({});
  let [priceForMenuVariation, setPriceForMenuVariation] = useState({});
  const menuWisePrice = useRef(0);
  // States hook here
  //new item
  let [newItem, setNewItem] = useState({
    itemGroup: null,
    sku: "",
    name: "",
    item_description: "",
    price: 0,
    cost_price: 0,
    cost_price_percent: 0,
    image: null,
    hasProperty: false,
    properties: null,
    hasVariation: false,
    isSpecial: false,
    isDeal: false,
    variations: null,
    status: true
  });

  let [priceForVariations, setPriceForVariations] = useState(null);
  let [costForVariations, setCostForVariations] = useState(null);
  let [selectedMenu, setSelectedMenu] = useState([]);
  // saas integration
  let [hasSaasLimit, setHasSaasLimit] = useState(true);
  let [createDeal,setCreateDeal] = useState({
    id: null,
    name:"",
    uploading:false,
    modal: false
  });
  // const SaasContext = React.createContext();

  const decrementSaasItem = () => {
    // after send api req decremeny by one
    const url = saas_apiUrl + "/api/user-item-limit-decrement"; // replace with base url (prince.thetestserver.xyz)
    axios
      .post(url, saas_form_data)
      .then((res) => {
        console.log(res);
      })
      .catch(() => {
        return "NO data";
      });
  };

  // saas integration
  useEffect(() => {
    if (SAAS_APPLICATION == "YES") {
      // check  saas limit item
      const checkHaslimit = () => {
        const url =
          saas_apiUrl + "/api/user-item-limit-check?" + saas_apiParams; // replace with base url (prince.thetestserver.xyz)
        axios
          .get(url)
          .then((res) => {
            {
              res.data == "HAS-LIMIT"
                ? setHasSaasLimit(true)
                : setHasSaasLimit(false);
            }
            // console.log('this is from state value : ', hasSaasLimit);
          })
          .catch(() => {
            setApiFailed(true);
          });
      };

      const saastoken = setInterval(checkHaslimit, 14400000);
      checkHaslimit();
      return () => {
        //  cleanup the interval when this effect is loaded.
        clearInterval(saastoken);
      };
    }

    if (
      authUserInfo.details?.user_type !== "superAdmin" ||  authUserInfo.details?.user_type !== "admin"
    ) {
     if(checkPermission(authUserInfo.permissions, "Manage Food"))
       history.push("/dashboard/manage/food/add-new");
     else if (showManageStock)
        history.push("/dashboard/manage/stock/finished-goods-history");
      else if (checkPermission(authUserInfo.permissions, "Manage User"))
        history.push("/dashboard/manage/user/admin-staff");
      else if (checkPermission(authUserInfo.permissions, "Manage Restaurant"))
        history.push("/dashboard/manage/restaurant/branches");
      else if (checkPermission(authUserInfo.permissions, "Manage Settings"))
        history.push("/dashboard/manage/settings/currencies");
        else {
          history.push("/dashboard");
          toast.error("You cannot access any reports.", {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
    }
    
  }, []);

  useEffect(()=>{

    if(getSystemSettings(generalSettings, "menu_wise_rate") == 1){
      menuWisePrice.current = 1
     }else{
      menuWisePrice.current = 0
     }

  },[generalSettings]);

  const handleEditDeal = (item) =>{
 
    setCreateDeal({
      ...createDeal,
      modal: true
    })
  }


  const handleSelectedMenu = (menu) => {
    // setNewParty({ ...newParty, menu });
    setSelectedMenu(menu);
    if(menuWisePrice.current === 1){ 
      const updatedPriceForVariation = {};
      const updatedPriceForItem = {};
      let updatePriceVariation = {};
      let costPriceVariation = {};
      menu.forEach((menuItem)=>{
        updatedPriceForVariation[menuItem.id] = newItem.variations;
        updatedPriceForVariation[menuItem?.id] && updatedPriceForVariation[menuItem?.id].forEach((variationItem)=>{
          updatePriceVariation[menuItem?.id] = { 
            ...updatePriceVariation[menuItem?.id], 
            [variationItem.id]: (priceForVariations && priceForVariations[menuItem?.id] && priceForVariations[menuItem?.id][variationItem?.id]) ? priceForVariations[menuItem?.id][variationItem?.id] : ""
         }
         costPriceVariation[menuItem?.id] = { 
          ...costPriceVariation[menuItem?.id], 
          [variationItem.id]: (costForVariations && costForVariations[menuItem?.id] && costForVariations[menuItem?.id][variationItem?.id]) ? costForVariations[menuItem?.id][variationItem?.id] : 0
         }
        })
        updatedPriceForItem[menuItem.id] = {
          price: priceForItem && priceForItem[menuItem?.id] ? priceForItem[menuItem?.id].price : "",
          menu_name: menuItem.name,
          menu_id : menuItem.id
        }
      })
      setPriceForVariations(updatePriceVariation);
      setCostForVariations(costPriceVariation);
      setPriceForMenuVariation(updatedPriceForVariation)
      setPriceForItem(updatedPriceForItem);
    }
  };
 
  //on change menu wise price
  const handleMenuPrice = (e,key) =>{

    setPriceForItem({
      ...priceForItem,
      [key]:{
        ...priceForItem[key],
        [e.target.name]: e.target.value
      }
    })

  }
  
  //on change input field
  const handleChange = (e) => {
    const key = Object.keys(priceForItem)[0]
    if (e.target.name === "cost_price") {

      const price = menuWisePrice.current ? priceForItem[key].price : newItem.price;
      let cost_price = parseFloat(e.target.value) > parseFloat(price) ? price : e.target.value;
      if (price === "") {
        cost_price = 0;
      } else {
          let  generate_percent =
          (parseInt(cost_price) / parseInt(price)) * 100;
        
        setNewItem({
          ...newItem,
          cost_price_percent: generate_percent,
          cost_price: cost_price,
        });
      }
    } else if (e.target.name === "cost_price_percent") {

      let cost_price_percent = parseFloat(e.target.value) > 100 ? 100 : e.target.value;
      const price = menuWisePrice.current ? priceForItem[key].price : newItem.price;
      if (price === "") {
        cost_price_percent = 0;
      } else {
        let generate_amount =
          (parseInt(price) / 100) * parseInt(cost_price_percent);
        setNewItem({
          ...newItem,
          cost_price_percent: cost_price_percent,
          cost_price: generate_amount,
        });
      }
    } else {
      setNewItem({ ...newItem, [e.target.name]: e.target.value });
    }
  };

  //set image hook
  const handleItemImage = (e) => {
    setNewItem({
      ...newItem,
      [e.target.name]: e.target.files[0],
    });
  };

  //set properties hook
  const handleSetPropertes = (properties) => {
    setNewItem({ ...newItem, properties });
  };
  
  //set variations hook
  const handleSetVariations = (variations) => {
    if(menuWisePrice.current){
      let variation = {};
      let updatePriceVariation = {}
      let costPriceVariation = {};
      selectedMenu.forEach((menu)=>{
          variation[menu.id] = variations;
          variations && variations.forEach((variation)=>{
            updatePriceVariation[menu?.id] = { 
               ...updatePriceVariation[menu?.id], 
              [variation.id]: priceForVariations && priceForVariations[menu?.id] && priceForVariations[menu?.id][variation?.id] ? priceForVariations[menu?.id][variation.id] : ""
              
            }
            costPriceVariation[menu?.id]={
              ...costPriceVariation[menu?.id], 
              [variation.id]: costForVariations && costForVariations[menu?.id] && costForVariations[menu?.id][variation?.id] ? costForVariations[menu?.id][variation.id] : 0
              
            }
        });
      })
      setPriceForMenuVariation(variation);
      setPriceForVariations(updatePriceVariation);
      setCostForVariations(costPriceVariation);
    }else{
      let updatePriceVariations = {};
      let updateCostVariation = {};
      variations && variations.forEach((variation)=>{
        updatePriceVariations[variation?.id] = priceForVariations && priceForVariations[variation?.id] ? priceForVariations[variation?.id] : "";
        updateCostVariation[variation?.id] = costForVariations && costForVariations[variation?.id] ? costForVariations[variation?.id] : 0;
      });
      setPriceForVariations(updatePriceVariations);
      setCostForVariations(updateCostVariation);
    }
    setNewItem({ ...newItem, variations });

  };

  //set each variation price
  const handleVariationPrice = (e, menu = null ) => {
    if(menuWisePrice.current){
      let updatePriceVariation = {...priceForVariations}
      updatePriceVariation[menu] = { 
        ...updatePriceVariation[menu], 
        [e.target.name]: e.target.value,
      }
      setPriceForVariations(updatePriceVariation);
    }else{
      setPriceForVariations({
        ...priceForVariations,
        [e.target.name]: e.target.value,
      });
    }
  };
 
  const handleVariationCost = (e, menu) => {
    const name = e.target.name.replace(/(-cost)/g, '')
    if(menuWisePrice.current){
      let updateCostVariation = {...costForVariations}
      // auto cost price update
      // selectedMenu.forEach((menu)=>{
      //   updateCostVariation[menu.id] = {
      //     ...updateCostVariation[menu.id],
      //     [name]: e.target.value,
      //   }
      // })
      updateCostVariation[menu] = {
        ...updateCostVariation[menu], 
        [name]: e.target.value,
      }
      setCostForVariations(updateCostVariation);
    }else{
      setCostForVariations({
        ...costForVariations,
        [name]: e.target.value,
      });
    }
  };

  //handle Set item group hook
  const handleSetItemGroup = (itemGroup) => {
    setNewItem({ ...newItem, itemGroup });
  };
  //has Property
  const handlePropertyCheckboxChange = (e) => {
    if (newItem.hasProperty === true) {
      setNewItem({
        ...newItem,
        properties: null,
        hasProperty: !newItem.hasProperty,
      });
    } else {
      setNewItem({ ...newItem, hasProperty: !newItem.hasProperty });
    }
  };

  //has variations
  const handleVariationCheckboxChange = (e) => {
    if (newItem.hasVariation === true) {
      setNewItem({
        ...newItem,
        variations: null,
        hasVariation: !newItem.hasVariation,
      });
    } else {
      setNewItem({ ...newItem, hasVariation: !newItem.hasVariation });
    }
  };

  //is special
  const handleSpecialCheckboxChange = (e) => {
    setNewItem({
      ...newItem,
      isSpecial: !newItem.isSpecial,
    });
    setCreateDeal({
      ...createDeal,
      modal: newItem.isSpecial ? false : true
    });
  };

  const handleDealCheckboxChange = () =>{
    setNewItem({
      ...newItem,
      isDeal: !newItem.isDeal,
    });
  }

  //is stutus
  const handleStatus = (e) => {
    setNewItem({
      ...newItem,
      status: !newItem.status
    })
    console.log("e", e);
  }

   console.log("abcd44",priceForMenuVariation,costForVariations)
  //post req of food item add
  const foodItemAxios = () => {
    setLoading(true);
    if (SAAS_APPLICATION == "YES") {
      decrementSaasItem();
    }

    let formData = new FormData();
    formData.append("food_group_id", newItem.itemGroup.id);
    formData.append("name", newItem.name);
    formData.append("item_description", newItem.item_description);
    formData.append("sku", newItem.sku);
    formData.append("hasProperty", newItem.hasProperty === true ? 1 : 0);
    if (newItem.hasProperty === true) {
      formData.append("hasProperty", 1);
      let tempArray = [];
      newItem.properties.map((pItem) => {
        tempArray.push(pItem.id);
      });
      formData.append("properties", tempArray);
    }

    formData.append("hasVariation", newItem.hasVariation === true ? 1 : 0);
    formData.append("isSpecial", newItem.isSpecial === true ? 1 : 0);
    formData.append("is_deal", newItem.isDeal === true ? 1 : 0);
    formData.append("item_status", newItem.status === true ? 1 : 0);
    formData.append("menu_wise_price", menuWisePrice.current ? 1 : 0);

    if(menuWisePrice.current){
      if (newItem.hasVariation === false) {
        formData.append("menu_price", JSON.stringify(priceForItem));
        formData.append("cost_price", newItem.cost_price ? newItem.cost_price : 0);
        formData.append("cost_price_percent", newItem.cost_price_percent ? newItem.cost_price_percent : 0);
      } else {
        // let tempData = Object.entries(priceForVariations);
        // tempData.map((item) => {
        //   formData.append("variations[]", item);
        // });
        let variationObj = {}
        const tempKeys = priceForVariations && Object.keys(priceForVariations);

        tempKeys && tempKeys.forEach((key) => {
          Object.keys(priceForVariations[key]).map((keys)=>{
            let price =  priceForVariations[key][keys];
            let costVariation = costForVariations[key] && costForVariations[key][keys];
            let cost = (costForVariations && costForVariations[key] && costVariation) ? costVariation : "0"
            variationObj[key] = {
              ...variationObj[key],
              [keys] : {
                cost_price : cost,
                price : price,
                menu_id : key,
              }
            }
          })
        })
        const payloadString = JSON.stringify(variationObj);
        formData.append("variations", payloadString);
     }
    }else{
      if (newItem.hasVariation === false) {
        formData.append("price", newItem.price);
        formData.append("cost_price", newItem.cost_price ? newItem.cost_price : 0);
        formData.append("cost_price_percent", newItem.cost_price_percent ? newItem.cost_price_percent : 0);
      } else {
        //converting variations and prices to array
        let slugArray = [];
        newItem.variations.map((newVarItem) => {
          slugArray.push(newVarItem.slug);
        });
        slugArray.map((slugItem) => {
          formData.append("slugOfVariations[]", slugItem);
        });
        // let tempData = Object.entries(priceForVariations);
        // tempData.map((item) => {
        //   formData.append("variations[]", item);
        // });
    
        const tempKeys = Object.keys(priceForVariations)
        tempKeys.forEach((key) => {
          const price = priceForVariations[key]
          const cost = costForVariations[key] ? costForVariations[key] : "0"
          formData.append("variations[]", [key, price, cost])
        })
     }


    }

    formData.append("image", newItem.image);

    //for menu_group
    let tempArray = [];
    selectedMenu.map((Item) => {
      tempArray.push(Item.id);
    });
    formData.append("menu_ids", tempArray.toString());

    const url = BASE_URL + "/settings/new-food-item";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFoodForSearch(res.data[1]);
        setNewItem({
          itemGroup: null,
          sku: "",
          name: "",
          item_description: "",
          price: "",
          cost_price: 0,
          cost_price_percent: 0,
          image: null,
          hasProperty: false,
          properties: null,
          hasVariation: false,
          isSpecial: false,
          isDeal: false,
          variations: null,
          status: true,
        });
        setSelectedMenu([]);
        setPriceForMenuVariation({})
        setPriceForItem({});
        setPriceForVariations(null)
        setCostForVariations(null);
        // getFoodItemsForPOS();
        setLoading(false);
        toast.success(`${_t(t("Food item has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.errors.image) {
          error.response.data.errors.image.forEach((item) => {
            if (item === "Please select a valid image file") {
              toast.error(`${_t(t("Please select a valid image file"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
            if (item === "Please select a file less than 5MB") {
              toast.error(`${_t(t("Please select a file less than 5MB"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
          });
        }
      });
  };

  //send to server
  const handleSubmit = (e) => {
    e.preventDefault();
   
    //check item group selected
    if (newItem.itemGroup !== null) {
      //check menu grp 
      if(selectedMenu == null || selectedMenu?.length === 0){
        toast.error(`${_t(t("Please select menu group"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        return false
      }
      //check property is selected or not if property checkbox is checked
      if (newItem.hasProperty === true && newItem.properties === null) {
        toast.error(`${_t(t("Please select properties"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      } else {
        //if property checkbox is not selected
        if (newItem.hasProperty === false) {
          //check variation is selected or not if variation checkbox is checked
          if (newItem.hasVariation === true && newItem.variations === null) {
            toast.error(`${_t(t("Please select variations"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          } else {
            //if variation checkbox is not selected
            if (newItem.hasVariation === false) {
              foodItemAxios();
            } else {
              //if variation checkbox is selected, options selected, but deleted all selected options at once
              if (newItem.variations.length > 0) {
                foodItemAxios();
              } else {
                toast.error(`${_t(t("Please select variations"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              }
            }
          }
        } else {
          //if property checkbox is selected, options selected, but deleted all selected options at once
          if (newItem.properties.length > 0) {
            if (newItem.hasVariation === true && newItem.variations === null) {
              toast.error(`${_t(t("Please select variations"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            } else {
              //if variation checkbox is not selected
              if (newItem.hasVariation === false) {
                foodItemAxios();
              } else {
                //if variation checkbox is selected, options selected, but deleted all selected options at once
                if (newItem.variations.length > 0) {
                  foodItemAxios();
                } else {
                  toast.error(`${_t(t("Please select variations"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  });
                }
              }
            }
          } else {
            toast.error(`${_t(t("Please select properties"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        }
      }
    } else {
      //if item group not selected
      toast.error(`${_t(t("Please select a Food Group for this item"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Add New Item"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true
                      ? tableLoading()
                      : apiFailed
                      ? [<SaasApiFailure />]
                      : [
                          hasSaasLimit
                            ? [
                                // start here
                                <div key="smtp-form">
                                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                    <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                      <ul className="t-list fk-breadcrumb">
                                        <li className="fk-breadcrumb__list">
                                          <span className="t-link fk-breadcrumb__link text-capitalize">
                                            {_t(t("Add new item"))}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-md-6 col-lg-7">
                                      <div className="row gx-3 align-items-center"></div>
                                    </div>
                                  </div>

                                  {/* Form starts here */}
                                  <form
                                    className="row card p-2 mx-3 mb-5 sm-text"
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                  >
                                    <div className="col-12">
                                      {deptTagForSearch && (
                                        <div className="form-group mt-2 mb-2">
                                          <div className="mb-2">
                                            <label
                                              htmlFor="itemGroup"
                                              className="control-label"
                                            >
                                              {_t(t("Menu group"))}
                                              <span className="text-danger mr-1">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          <Select
                                            options={deptTagForSearch}
                                            components={makeAnimated()}
                                            value={selectedMenu}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            getOptionValue={(option) =>
                                              option.name
                                            }
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            maxMenuHeight="200px"
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            onChange={handleSelectedMenu}
                                            placeholder={
                                              _t(t("Please select menu")) + ".."
                                            }
                                          />
                                        </div>
                                      )}

                                      {foodGroupForSearch && (
                                        <div className="form-group mt-2">
                                          <div className="mb-2">
                                            <label
                                              htmlFor="itemGroup"
                                              className="control-label"
                                            >
                                              {_t(t("Food group"))}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          <Select
                                            options={foodGroupForSearch}
                                            components={makeAnimated()}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            getOptionValue={(option) =>
                                              option.name
                                            }
                                            classNamePrefix="select"
                                            onChange={handleSetItemGroup}
                                            maxMenuHeight="200px"
                                            placeholder={
                                              _t(
                                                t("Please select a food group")
                                              ) + ".."
                                            }
                                          />
                                        </div>
                                      )}
                                      <div className="row">
                                        <div className="form-group mt-3 col-md-6">
                                          <div className="mb-2">
                                            <label
                                              htmlFor="name"
                                              className="control-label"
                                            >
                                              {_t(t("Name"))}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          <div className="mb-2">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="name"
                                              name="name"
                                              onChange={handleChange}
                                              value={newItem.name}
                                              placeholder="e.g. Spicy chicken burger"
                                              required
                                              autoComplete="off"
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group mt-3 col-md-6">
                                          <div className="mb-2">
                                            <label
                                              htmlFor="sku"
                                              className="control-label"
                                            >
                                              {_t(t("SKU"))}
                                            </label>
                                          </div>
                                          <div className="mb-2">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="sku"
                                              name="sku"
                                              onChange={handleChange}
                                              value={newItem.sku}
                                              placeholder="e.g. 12345678"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="form-group mt-3 col-md-12">
                                          <div className="mb-2">
                                            <label
                                              htmlFor="item_description"
                                              className="control-label"
                                            >
                                              {_t(t("Item Description"))}
                                            </label>
                                          </div>
                                          <div className="mb-2">
                                            <textarea
                                              type="text"
                                              rows="2"
                                              className="form-control"
                                              id="item_description"
                                              name="item_description"
                                              onChange={handleChange}
                                              value={newItem.item_description}
                                              placeholder="e.g. 1 beef burger, 2 drinks"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-check mt-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="propertyCheck"
                                          checked={newItem.hasProperty}
                                          onChange={
                                            handlePropertyCheckboxChange
                                          }
                                        />
                                        <label
                                          className="form-check-label pointer-cursor"
                                          htmlFor="propertyCheck"
                                        >
                                          {_t(t("Has properties?"))}
                                        </label>
                                      </div>
                                      {propertyGroupForSearch && [
                                        newItem.hasProperty && (
                                          <div className="form-group mt-2 ml-4">
                                            <div className="mb-2">
                                              <label className="control-label">
                                                {_t(t("Add properties"))}
                                              </label>
                                            </div>
                                            <Select
                                              options={propertyGroupForSearch}
                                              components={makeAnimated()}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              getOptionValue={(option) =>
                                                option.name
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              isMulti
                                              closeMenuOnSelect={false}
                                              maxMenuHeight="200px"
                                              onChange={handleSetPropertes}
                                              placeholder={
                                                _t(
                                                  t(
                                                    "Please select property groups"
                                                  )
                                                ) + ".."
                                              }
                                            />
                                          </div>
                                        ),
                                      ]}

                                      <div className="form-check mt-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="variationCheck"
                                          checked={newItem.hasVariation}
                                          onChange={
                                            handleVariationCheckboxChange
                                          }
                                        />
                                        <label
                                          className="form-check-label pointer-cursor"
                                          htmlFor="variationCheck"
                                        >
                                          {_t(t("Has variations?"))}
                                        </label>
                                      </div>

                                      {newItem.hasVariation &&
                                        variationForSearch && (
                                          <div className="form-group mt-2 ml-4">
                                            <div className="mb-2">
                                              <label className="control-label">
                                                {_t(t("Add variations"))}
                                              </label>
                                            </div>
                                            <Select
                                              options={variationForSearch}
                                              components={makeAnimated()}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              getOptionValue={(option) =>
                                                option.name
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              isMulti
                                              closeMenuOnSelect={false}
                                              maxMenuHeight="200px"
                                              onChange={handleSetVariations}
                                              placeholder={
                                                _t(
                                                  t("Please select variations")
                                                ) + ".."
                                              }
                                            />
                                          </div>
                                        )}
                                      {newItem.variations !== null && [
                                        newItem.variations.length > 0 && (
                                          <div className="card ml-4 mt-1 p-3 custom-bg-secondary">
                                          {
                                            menuWisePrice.current === 0 && (
                                              <div className="card-header t-bg-epsilon text-white rounded-sm text-center">
                                                {_t(
                                                  t(
                                                    "Please enter price for each variation"
                                                  )
                                                )}
                                               </div>
                                            )
                                          }
                                            {
                                              menuWisePrice.current ? (
                                                Object.keys(priceForMenuVariation).map((variationKey)=>{
                                                  let menuName = deptTagForSearch.find((menu)=> menu.id == variationKey);
                                                  return(
                                                    <div className="mt-2">
                                                    <div className="card-header t-bg-epsilon text-white rounded-sm text-center">
                                                    {_t(
                                                      t(
                                                        "Please enter price for each variation for " + menuName.name
                                                        )
                                                        )}
                                                        </div>
                                                        {
                                                          priceForMenuVariation[variationKey].map((variationItem)=>{
                                                          return (
                                                            <div
                                                            className="row m-0 mt-4"
                                                            key={variationItem.id}
                                                          >
                                                          <div className="form-group col-6 p-0 pr-2">
                                                            <div className="mb-2">
                                                              <label
                                                                htmlFor={
                                                                  variationItem.id
                                                                }
                                                                className="control-label sm-text"
                                                              >
                                                                {_t(
                                                                  t("Total price of")
                                                                )}{" "}
                                                                <span className="text-primary text-bold">
                                                                  {variationItem.name}
                                                                </span>{" "}
                                                                {_t(t("variation"))}
                                                                <span className="text-danger">
                                                                  *{" "}
                                                                </span>
                                                               
                                                              </label>
                                                            </div>
                                                            <div className="mb-2">
                                                              <input
                                                                type="number"
                                                                step="0.01"
                                                                min="0"
                                                                className="form-control"
                                                                onKeyDown={(evt) =>
                                                                  ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                                  evt.preventDefault()
                                                                }
                                                                onWheel={(e) => e.target.blur()}  
                                                                id={variationItem.id}
                                                                name={
                                                                  variationItem.id
                                                                }
                                                                value={priceForVariations && priceForVariations[variationKey] && priceForVariations[variationKey][variationItem.id]}
                                                                onChange={
                                                                 (e) => handleVariationPrice(e,variationKey)
                                                                }
                                                                placeholder="e.g. Type price of this item"
                                                                required
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="form-group col-6 p-0 pl-2">
                                                            <div className="mb-2">
                                                              <label
                                                                htmlFor={
                                                                  variationItem.id
                                                                }
                                                                className="control-label sm-text"
                                                              >
                                                                {_t(
                                                                  t("Total cost of")
                                                                )}{" "}
                                                                <span className="text-primary text-bold">
                                                                  {variationItem.name}
                                                                </span>{" "}
                                                                {_t(t("variation "))}
                                                               
                                                              </label>
                                                            </div>
                                                            <div className="mb-2">
                                                              <input
                                                                type="number"
                                                                step="0.01"
                                                                min="0"
                                                                className="form-control"
                                                                onKeyDown={(evt) =>
                                                                  ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                                  evt.preventDefault()
                                                                }
                                                                onWheel={(e) => e.target.blur()} 
                                                                id={variationItem.id}
                                                                name={
                                                                  variationItem.id +
                                                                  "-cost"
                                                                }
                                                                value={costForVariations && costForVariations[variationKey] && costForVariations[variationKey][variationItem.id]}
                                                                onChange={
                                                                 (e) => handleVariationCost(e,variationKey)
                                                                }
                                                                placeholder="e.g. Cost Price"
                                                                required
                                                              />
                                                            </div>
                                                          </div>
                                                          </div>
                                                          )
                                                        })
                                                      }
                                                    </div>
                                                  )
                                                })
                                                 
                                              ): (
                                                newItem.variations.map(
                                                  (variationItem) => {
                                                    return (
                                                      <div
                                                        className="row m-0 mt-4"
                                                        key={variationItem.id}
                                                      >
                                                      <div className="form-group col-6 p-0 pr-2">
                                                        <div className="mb-2">
                                                          <label
                                                            htmlFor={
                                                              variationItem.id
                                                            }
                                                            className="control-label sm-text"
                                                          >
                                                            {_t(
                                                              t("Total price of")
                                                            )}{" "}
                                                            <span className="text-primary text-bold">
                                                              {variationItem.name}
                                                            </span>{" "}
                                                            {_t(t("variation"))}
                                                            <span className="text-danger">
                                                              *{" "}
                                                            </span>
                                                           
                                                          </label>
                                                        </div>
                                                        <div className="mb-2">
                                                          <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            className="form-control"
                                                            onKeyDown={(evt) =>
                                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                              evt.preventDefault()
                                                            }
                                                            onWheel={(e) => e.target.blur()} 
                                                            id={variationItem.id}
                                                            name={
                                                              variationItem.id
                                                            }
                                                            onChange={
                                                              handleVariationPrice
                                                            }
                                                            placeholder="e.g. Type price of this item"
                                                            required
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="form-group col-6 p-0 pl-2">
                                                        <div className="mb-2">
                                                          <label
                                                            htmlFor={
                                                              variationItem.id
                                                            }
                                                            className="control-label sm-text"
                                                          >
                                                            {_t(
                                                              t("Total cost of")
                                                            )}{" "}
                                                            <span className="text-primary text-bold">
                                                              {variationItem.name}
                                                            </span>{" "}
                                                            {_t(t("variation "))}
                                                           
                                                          </label>
                                                        </div>
                                                        <div className="mb-2">
                                                          <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0"
                                                            className="form-control"
                                                            onKeyDown={(evt) =>
                                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                              evt.preventDefault()
                                                            }
                                                            onWheel={(e) => e.target.blur()} 
                                                            id={variationItem.id}
                                                            name={
                                                              variationItem.id +
                                                              "-cost"
                                                            }
                                                            onChange={
                                                              handleVariationCost
                                                            }
                                                            placeholder="e.g. Cost Price"
                                                            required
                                                          />
                                                        </div>
                                                      </div>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              )
                                            }
                                            
                                          </div>
                                        ),
                                      ]}

                                       {
                                          !newItem.hasVariation && (
                                            <div className="row">
                                              {
                                                menuWisePrice.current ? (
                                                priceForItem &&
                                                Object.keys(priceForItem)?.map((item)=> (
                                                  <div className="form-group mt-3 col-md-3">
                                                      <div className="mb-2">
                                                        <label
                                                          htmlFor="price"
                                                          className="control-label table-text"
                                                        >
                                                          {priceForItem[item].menu_name + _t(t(" Price"))}
                                                          <span className="text-danger">
                                                            *{" "}
                                                          </span>
                                                          {/* <small className="text-secondary">
                                                            ({_t(t("Enter price in USD"))})
                                                          </small> */}
                                                        </label>
                                                      </div>
                                                      <div className="mb-2">
                                                        <input
                                                          id="price"
                                                          type="number"
                                                          step="0.01"
                                                          min="0"
                                                          className="form-control"
                                                          onKeyDown={(evt) =>
                                                            ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                            evt.preventDefault()
                                                          }
                                                          onWheel={(e) => e.target.blur()} 
                                                          name="price"
                                                          value={priceForItem && priceForItem[item].price}
                                                          onChange={(e)=>handleMenuPrice(e,item)}
                                                          placeholder="e.g. Type price of this item"
                                                          required
                                                        />
                                                      </div>
                                                    </div> 
                                                   )) 
                                                ) : (
                                                  <div className="form-group mt-4 col-md-6">
                                                    <div className="mb-2">
                                                      <label
                                                        htmlFor="price"
                                                        className="control-label"
                                                      >
                                                        {_t(t("Price"))}
                                                        <span className="text-danger">
                                                          *{" "}
                                                        </span>
                                                        {/* <small className="text-secondary">
                                                          ({_t(t("Enter price in USD"))})
                                                        </small> */}
                                                      </label>
                                                    </div>
                                                    <div className="mb-2">
                                                      <input
                                                        id="price"
                                                        type="number"
                                                        step="0.01"
                                                        min="0"
                                                        className="form-control"
                                                        onKeyDown={(evt) =>
                                                          ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                          evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()} 
                                                        name="price"
                                                        value={newItem.price}
                                                        onChange={handleChange}
                                                        placeholder="e.g. Type price of this item"
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                )  
                                              }
                                             <div className= "row">
                                              <div className="form-group mt-4 col-md-6">
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="mb-2">
                                                      <label
                                                        htmlFor="cost_price_percent"
                                                        className="control-label"
                                                      >
                                                        {_t(t("Item Cost Percent"))}
                                                      </label>
                                                    </div>
                                                    <div className="mb-2">
                                                      <input
                                                        id="cost_price_percent"
                                                        type="number"
                                                        max="100"
                                                        className="form-control"
                                                        onKeyDown={(evt) =>
                                                          ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                          evt.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()} 
                                                        name="cost_price_percent"
                                                        value={
                                                          newItem.cost_price_percent
                                                        }
                                                        onChange={handleChange}
                                                        placeholder="e.g. 10%"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-2">
                                                      <label
                                                        htmlFor="cost_price"
                                                        className="control-label"
                                                      >
                                                        {_t(t("Item Cost"))}
                                                      </label>
                                                    </div>
                                                    <div className="mb-2">
                                                      <div class="input-group mb-3">
                                                        <span
                                                          class="input-group-text"
                                                          id="basic-addon1"
                                                        >
                                                          Rs
                                                        </span>
                                                        <input
                                                          id="cost_price"
                                                          type="number"
                                                          //max={newItem.price}
                                                          className="form-control"
                                                          onKeyDown={(evt) =>
                                                            ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                            evt.preventDefault()
                                                          }
                                                          onWheel={(e) => e.target.blur()} 
                                                          name="cost_price"
                                                          value={newItem.cost_price}
                                                          onChange={handleChange}
                                                          placeholder="e.g. 100"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                             </div>
                                            </div>
                                        )
                                      }

                                      <div className="form-check mt-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="specialCheck"
                                          checked={newItem.isSpecial}
                                          onChange={handleSpecialCheckboxChange}
                                        />
                                        <label
                                          className="form-check-label pointer-cursor"
                                          htmlFor="specialCheck"
                                        >
                                          {_t(t("Is Special?"))}
                                        </label>
                                      </div>

                                      <div className="form-check mt-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="dealCheck"
                                        checked={newItem.isDeal}
                                        onChange={handleDealCheckboxChange}
                                      />
                                      <label
                                        className="form-check-label pointer-cursor"
                                        htmlFor="dealCheck"
                                      >
                                        {_t(t("Is Deal?"))}
                                      </label>
                                    </div>
                                      <div className="form-group mt-4">
                                        <div className="mb-2">
                                          <label
                                            htmlFor="image"
                                            className="control-label"
                                          >
                                            {_t(t("Image"))}
                                            {/* <span className="text-danger">
                                              *
                                            </span>{" "} */}
                                            <small className="text-secondary">
                                              ({_t(t("300 x 300 Preferable"))})
                                            </small>
                                          </label>
                                        </div>
                                        <div className="mb-2">
                                          <input
                                            type="file"
                                            className="form-control sm-text"
                                            id="image"
                                            name="image"
                                            onChange={handleItemImage}
                                          />
                                        </div>
                                      </div>
                                      <div className="form-check mt-4 pl-0">
                                        <Switch
                                            checked={newItem.status}
                                            name="status"
                                            onChange={(e) => {
                                                handleStatus(e);
                                            }}
                                            height={22}
                                            width={44}
                                            offColor="#ee5253"
                                            className="float-left"
                                        />
                                        <label
                                          className="form-check-label pointer-cursor ml-2"
                                          htmlFor="status"
                                        >
                                          {_t(t("Status"))}
                                        </label>
                                      </div>
                                      <div className="form-group mt-5 pb-2">
                                        <div className="col-lg-12">
                                          <button
                                            className="btn btn-primary px-5 rounded"
                                            type="submit"
                                          >
                                            {_t(t("Save"))}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>,
                                // end here
                              ]
                            : [<Expired />],
                        ]}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
      <FoodDeal
        createDeal = {createDeal}
        setCreateDeal = {setCreateDeal}
        setSearchedFoodItem = {""}
        searchedFoodItem = {""}
      />
    </>
  );
};

export default FoodItemCrud;
