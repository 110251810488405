import React, { useState, useContext, useEffect, useRef } from "react";
import Select from "react-select";
import ItemModal from "./ItemModal/ItemModal";
import { Flip, toast } from "react-toastify";
import moment from "moment";
import CustomerModal from "./ItemModal/CustomerModal";
import TableModal from "./ItemModal/TableModal";
import { Button } from "react-bootstrap";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { BASE_URL } from "../../../../../BaseUrl";
import { OrderPrint } from "./OrderPrint";
import PasscodeAuthenticator from "../PasscodeAuthenticator/PasscodeAuthenticator";
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ReactComponent as TriangleDown } from "../../../public/caret-down.svg";
import { ReactComponent as FP } from "../../../public/fp.svg";
import CallCenterModal from "./ItemModal/CallCenterModal";
import CallCenterOrdertype from "./ItemModal/CallCenterOrdertype";
import InstructionModal from "./ItemModal/InstructionModal";
import { ReactComponent as Dinner } from "../../../public/dinner.svg";
//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  currencySymbolRight,
  getSystemSettings,
  checkPermission,
  useCustomHooks,
  customStyle,
  checkNetworkStatus,
  // restaurantMenuLink,
} from "../../../../../functions/Functions";

// custom styles
import "./pos.css";

import { NavLink, useParams, useHistory } from "react-router-dom";
import SettleOrder from "./settleOrder/SettleOrder";
import Calculator from "../calc/Calculator";
import PosSelect from "./PosSelect";
import DropdownButton from "./DropdownButton";
import { FoodContext } from "../../../../../contexts/Food";
import {
  calculatePriceForCartItem,
  calculateSubTotal,
  createHash,
  getTax,
  getTaxedTotal,
  parseFoodItems,
  parseParties,
  getServiceCharges,
  setItemOnEdit,
  calculatePriceForCartVariation,
  getInitialItemDiscount,
  getItemDiscount,
} from "./helpers";
import axios from "axios";
import CartItem from "./CartItem";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import PosModal from "./ItemModal/PosModal";
import CustomPosModal from "./ItemModal/CustomPosModal";

gsap.registerPlugin(MotionPathPlugin);

function Pos() {

  // CONTEXTS
  const {
    //submitted orders
    // submittedOrders,
    // setWorkPeriodList,
    // workPeriodList,
    getRunningOrders,
    runningOrders,
    //work period
    workPeriodForSearch,
    setWorkPeriodListForSearch,
    workPeriodListForBranch,
    setWorkPeriodListForBranch,

    //branch
    branchForSearch,
    branchForListSearch,
    //table
    getTable,
    //dept-tag
    deptTagForSearch,
    //payment-type
    paymentTypeForSearch,
    getWorkPeriod,

    orderTypeList,
    //Scheme
    schemeForSearchObj,
    schemeForSearch,
    cardForSearch,
    orderCounter
  } = useContext(RestaurantContext);

  const { loading, setLoading, syncOrder, generalSettings, showManageStock } = useContext(SettingsContext);

  const { authUserInfo, partyForSearch, managerPasscode } = useContext(UserContext);
 
  const {
    //food
    foodGroupById,
    foodForPOS,
    itemImage,
    setItemImage,
    setGetOrderDetailsState,
    getOrderDetails,
    //property group
    propertyGroupForSearch,

    //food grop with menu
    availableFoodGroups,
  } = useContext(FoodContext);

  const {
    //customer
    getWaiter,
    // getParty,
    // partyList,
  } = useContext(UserContext);

  const defaultImg = "https://menu.indolj.pk/upload/placholde-general2.png";

  const { t } = useTranslation();

  //edit order
  const { order_id } = useParams();

  const { fetchStock, roundOffPrice, totalPriceWithTax, handleSubmitOffline, taxPercent, getOfflineApiUrl, syncLocalOrders } = useCustomHooks();

  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };
  let history = useHistory();
  const paymentOptions = ["Cash", "Card", "Online"];
  // STATES
  const [menu, setMenu] = useState({});
  const inputSearch = useRef();
  const flyStart = useRef(null);
  const imageAnimate = useRef(null);
  const cardAnimate = useRef([]);
  const animate = useRef(null);

  let order_hold = useRef(0);
  const [menuWisePrice, setMenuWisePrice] = useState(0);
  // overflow height style
  const scrollHeight = useRef();
  const taxHeight = useRef();
  const [heightScroll, setHeightScroll] = useState({
    actualHeight: "",
    overflowHeight: "",
  });
  const taxScroll = useRef()
  const token_No = useRef("");
  //print bills
  const componentRef = useRef();
  const component2Ref = useRef();
  const cardModal = useRef();
  const con_Kot = useRef("");
  const syncSettle = useRef(false);
  const onlineOrder = useRef(false);
  const [workPeriodScreen,setWorkPeriodScreen] = useState(1)
  //payment type for call center
  const [selectedSwitch, SetSelectedSwitch] = useState();
  const currentNavPill = (element) => {
    SetSelectedSwitch(element);
  };
  const [callCenterPayment, setCallCenterPayment] = useState({
    payment_type : "Cash",
    transaction_no: "",
    transactionModal: false,
  });

  //add comments
  const [commentId, setCommentId] = useState();

  //const [selectedProperties, setSelectedProperties] = useState([]);
  //print states
  const [foodGroupsToPrint, setFoodGroupsToPrint] = useState(null);

  //FBR Invoice Num
  // const [fbrInvoiceNum, setFbrInvoiceNum] = useState(null);
  // //FBR Invoice Nums
  // const [srbInvoiceNum, setSrbInvoiceNum] = useState(null);
  const fbrInvoiceNum = useRef(null);
  const srbInvoiceNum = useRef(null);
  const [userLock, setUserLock] = useState([]);
  const [kotPrintIdx, setKotPrintIdx] = useState(0);
  //card offer
  
  // edit delete states
  const [deleteReason, setDeleteReason] = useState("");
  const [addReasonModalShowing, setAddReasonModalShowing] = useState(null);
  const [deletedItemsOnEdit, setDeletedItemsOnEdit] = useState({});
  const [itemAdd, setItemAdd] = useState();
  const [editKot, setEditKot] = useState();
  //edit order state
  const [editOrder, setEditOrder] = useState(false);
  //print end
  // authenticate user
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [componentUnlock, setComponentUnlock] = useState("");
  const [managerId,setManagerId] = useState(null)
  //back btn modal
  const [backModal, setBackModal] = useState(false);
  //note rider btn modal
  const [noteRiderModal, setNoteRiderModal] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [selectedFoodItem, setSelectedFoodItem] = useState(null);
  const [selectedFoodItemId, setSelectedFoodItemId] = useState(null);
  //const [availableFoodGroups, setAvailableFoodGroups] = useState({});
  const [activeFoodGroup, setActiveFoodGroup] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [availableDeliveryTypes, setAvailableDeliveryTypes] = useState([]);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState();
  const [availableDeliveryParties, setAvailableDeliveryParties] = useState({});
  const [selectedParty, setSelectedParty] = useState();
  const [totalsettle, setTotalSettle] = useState(0);
  const [theVatSettle, setTheVatSettle] = useState(0);
  const [theVat, setTheVat] = useState(0);
  const [newOrder, setNewOrder] = useState(null);
  const [showSettle, setShowSettle] = useState(false);
  const [calculatorMenu, setCalculatorMenu] = useState(false);
  const [modal9Show, setModal9Show] = useState(false);
  const [modal10Show, setModal10Show] = useState(false);
  // set order type modal in call center zone
  const [modalOrderType,setModalOrderType] = useState(false)
  //cart calculation states
  const [cartItems, setCartItems] = useState({});
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [taxType, setTaxType] = useState("");
  const [selectedKot, setSelectedKot] = useState([]);
  const [paidMoney, setPaidMoney] = useState(0);
  const [deptCommission, setDeptCommission] = useState(0);
  const [newSettings, setNewSettings] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const isSettle = useRef(0);
  const [theTax, setTheTax] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [serviceCharges, setServiceCharges] = useState(0);
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);
 // const [deliveryTypeList, setDeliveryTypeList] = useState([]);
 // const [filteredDept, setFilteredDept] = useState(null);
  const [paymentTypeAvailable, setPaymentTypeAvailable] = useState(paymentTypeForSearch);
  const [tempSchemeForSearch, setTempSchemeForSearch] = useState(null);
  //table popup
  const [guestSelected, setGuestSelected] = useState(1);
  const [selectTable, setSelectTable] = useState({});
  const [tableId, setTableId] = useState(null);
  //zone address
  const [zoneArea, setZoneArea] = useState(null);
  const [area,setArea] = useState(null);
  //end
  const [searchedFoodItem, setSearchedFoodItem] = useState({
    searched: false,
    list: null,
  });

  // for bogo offers
  const [bogo, setBogo] = useState(0);

   // cash cheque pay in payout
  const [cashDetails, setCashDetails] = useState({
    work_period_id: null,
    branch_id: null,
    branch_name: null,
    party_id: null,
    party_name: null,
    payment_type: null,
    cheque_no: 0,
    cheque_date: null,
    payment_status: null,
    remarks: "",
    amount: 0,
  });
  // order details
  const [orderDetails, setOrderDetails] = useState({
    branch: null,
    table: null,
    waiter: null,
    dept_tag: null,
    card_number: "",
    card_number_settle:"",
    payment_type: null,
    payment_amount: null,
    total_guest: 1,
    table_id: null,
    table_name: null,
    service_amount: 0,
    note_to_rider:"",
    order_tip: 0,
    newCustomerInfo: {
      id: null,
      name: "",
      email: "",
      phn_no: "",
      address: "",
      ref_no: "",
      ntn_no: "",
      nearest_landmark: "",
      alt_phn_no:"",
      is_confirm: false,
    },
    token: null,
    order_No: null,
    serviceCharge: 0,
    is_reservation: 0,
    discount: 0,
    scheme: {
      scheme_id: null,
      scheme_name: "",
      cap_amount: 0,
      discount_amount: 0,
      sharing_with_party: 0,
      open_discount: null,
      discount_type: "",
      how_much_discount: null,
      discount_addon: "",
      discount_tax: 0,
    },
    orderTypeDetails: {
      orderTypeId: null,
      orderTypeName: "",
    },
    partyDetails: {
      partyId: null,
      partyName: "",
      partyPercent: null,
    },
  });

  // State hooks here
  // payment type tax modal
  const [paymentTypeModalState, setPaymentTypeModalState] = useState({
    showModal: false,
    taxCalSelect: false,
    subTotal: 0,
    vat: 0,
    vatAmount: 0,
    serviceCharge: 0,
    serviceChargeAmount: 0,
    deliveryCharges: 0,
    discount: 0,
    totalPayable: 0,
    payment_type_name: "",
  });
  const [availableDeptTags, setAvailableDeptTags] = useState([]);
  const [selectedDeptTag, setSelectedDeptTag] = useState();
  console.log("kot1", orderDetails, newOrder);

  const workPeriodStatus = async(branchId) =>{
    const pendingUrl = BASE_URL + `/settings/get-day-open-status/${branchId}`;
    try {
      const res = await axios.get(pendingUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });

      setWorkPeriodScreen(res.data);

      if (res.data === 0) {
        toast.error(
          "Please close your previous day and start a new day",
          toastObj
        );
      }
    } catch (err) {
      console.error("Error fetching work period status:", err);
    }
  }

  useEffect(() => {
    if(authUserInfo && authUserInfo?.details){
      getRunningOrders();
     if(authUserInfo?.details.user_type === "staff" || authUserInfo.details.user_type === "waiter" || authUserInfo?.details.user_type === "manager"){
       let branchId = authUserInfo.details?.branch_id
       workPeriodStatus(branchId)
     }
    }
  }, [authUserInfo]);

  useEffect(() => {
    let heightScroll = scrollHeight.current?.offsetHeight;
    let actualHeight = scrollHeight.current?.scrollHeight;
    setHeightScroll({
      overflowHeight: actualHeight,
      actualHeight: heightScroll,
    });
  }, [editOrder, runningOrders]);

  useEffect(() => {
    let details = selectedSwitch?.getBoundingClientRect();
    const root = document.querySelector(":root");
    root.style.setProperty("--delivery-switcher-width", details?.width + "px");
    // root.style.setProperty(
    //   "--delivery-switcher-height",
    //   details?.height + "px"
    // );
    root.style.setProperty(
      "--delivery-switcher-left",
      selectedSwitch?.offsetLeft + "px"
    );
  }, [callCenterPayment?.payment_type, selectedSwitch]);

  // PACKAGES SETTINGS OBJECT
  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  const toastObj1 = {
    backgroundColor:"#0a8d6e",
    autoClose: 10000,
    style: {
      fontSize: "16.5px", // Set the font size in the style object
    },
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  // check internet
  let networkStatus = true;
 
  const emptyCardEdit = () => {

    setCartItems({});
    setTheVat(0);
    setTheTax(orderDetails.branch?.branch_tax ? orderDetails.branch?.branch_tax : 0);
    setServiceCharges(0);
    setSelectedParty();
    setSelectedDeliveryType();
    setZoneArea(null);
    setArea(null);
    setSelectTable({});
    setUserLock([]);
    setManagerId(null);
    setGuestSelected(1);
    setOrderDetails({
      ...orderDetails,
      token: null,
      order_No: null,
      orderTypeDetails: {
        orderTypeId: null,
        orderTypeName: "",
      },
      partyDetails: {
        partyId: null,
        partyName: "",
        partyPercent: null,
      },
      card_number: "",
      card_number_settle:"",
      note_to_rider:"",
      order_tip: 0,
      newCustomerInfo: {
        id: null,
        name: "",
        email: "",
        phn_no: "",
        address: "",
        ref_no: "",
        ntn_no: "",
        nearest_landmark: "",
        alt_phn_no:"",
        is_confirm: false,
      },
      serviceCharge: 0,
      discount: 0,
      scheme: {
        scheme_id: null,
        scheme_name: "",
        cap_amount: 0,
        discount_amount: 0,
        sharing_with_party: 0,
        open_discount: null,
        discount_type: "",
        how_much_discount: null,
        discount_addon: "",
        discount_tax: 0,
      },
      total_guest: 1,
      is_reservation: 0,
      table: null,
      waiter: null,
      table_id: null,
      table_name: null,
    });
    setSearchedFoodItem({
      searched: false,
      list: null,
    });
    setSearchInput("");
    setEditOrder(false);
    if (inputSearch.current !== undefined) inputSearch.current.value = "";
     
  }


  useEffect(() => {
    emptyCardEdit();
    if(!order_id && orderDetails?.branch){
      setTheTax(orderDetails.branch.branch_tax)
    }
    if (
      order_id &&
      (authUserInfo?.details?.user_type === "admin" ||
        authUserInfo?.details?.user_type === "superAdmin")
    ) {
      setSelectedBranch(null);
      setNewSettings({
        vat: null,
        workPeriod: null,
      });
    }
    

    if (authUserInfo.permissions !== null) {
      if (checkPermission(authUserInfo.permissions, "Order edit")) {
        if (order_id && partyForSearch) {
          getOrderDetails(order_id).then((data) => {
            partyForSearch && handleEditOrder(data);
          });
        }
      }
    }
  }, [order_id, authUserInfo, partyForSearch]);

  useEffect(() => {

    // let theDefaultMenu =
    //   deptTagForSearch &&
    //   deptTagForSearch.find((menu) => {
    //     return menu.is_default_menu;
    //   });

    // if (!order_id) {
    //   theDefaultMenu &&
    //     setOrderDetails({
    //       ...orderDetails,
    //       dept_tag: theDefaultMenu,
    //     });
    // }

    if (authUserInfo.details?.branch_id) {
      let theBranch = branchForListSearch && branchForListSearch[authUserInfo.details.branch_id];
      //set work period
      //let theWorkPeriod = null;
      let theWorkPeriod = newSettings?.workPeriod;
      if (theBranch !== undefined && theBranch !== null) {
        theWorkPeriod =
         workPeriodListForBranch &&
         workPeriodListForBranch.find((tempWorkPeriod) => {
            return (theBranch.id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null;
          });
       
        setTimeout(() => {
          // set branch for staff
          setOrderDetails({
            ...orderDetails,
            branch: theBranch,
          });
          setTheTax(theBranch.branch_tax);
          setCashDetails({
            ...cashDetails,
            branch_id: theBranch && theBranch.id,
            branch_name: theBranch && theBranch?.name,
            work_period_id: theWorkPeriod && theWorkPeriod?.id,
          });
        }, 30);
      }
      // call center if already branch
      if (authUserInfo?.details?.user_type === "call_center") {
        theBranch && setSelectedBranch(theBranch);
      }
      //set work period here
  
      setNewSettings({
        ...newSettings,
        //vat: generalSettings && getSystemSettings(generalSettings, "type_vat"),
        vat: theTax !== 0 ? theTax : theBranch?.branch_tax,
        workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
      });
    } else {
      // user type admin
      let theBranch = branchForListSearch && branchForListSearch[authUserInfo.details.branch_id];
      // call center if already branch
      if (authUserInfo?.details?.user_type === "call_center") {
        theBranch && setSelectedBranch(theBranch);
      }
      setNewSettings({
        ...newSettings,
        vat: theTax !== 0
            ? theTax
            : theBranch?.branch_tax ? theBranch.branch_tax : 0,
        workPeriod: null,
      });
    }

  }, [
    authUserInfo,
    branchForListSearch,
    workPeriodListForBranch,
    orderTypeList,
  ]);
 
   // empty card 
  const emptyCardOnChange = () =>{
     // cant empty card if card has item on edit
  if (order_id) {
    if(!buttonDisabled && cartItems && Object.keys(cartItems)?.length > 0){
      toast.error("Please complete the current order");
    }
  } else {
    setOrderDetails({
      ...orderDetails,
      orderTypeDetails: {
        orderTypeId: selectedDeliveryType ? selectedDeliveryType.id : null,
        orderTypeName: selectedDeliveryType ? selectedDeliveryType.name : "",
      },
      partyDetails: {
        partyId: selectedParty ? selectedParty.id : null,
        partyName: selectedParty ? selectedParty.name : "",
        partyPercent: selectedParty ? selectedParty.receivable_amount : "",
        partyRefNo: selectedParty ? selectedParty.show_ref_no : "",
        associate_with_payment_type: selectedParty ? selectedParty.associate_with_payment_type : "",
      },
      note_to_rider:"",
      order_tip: 0,
      card_number:"",
      card_number_settle:"",
      token: null,
      order_No: null,
      table: null,
      waiter: null,
      total_guest: 1,
      is_reservation: 0,
      table_id: null,
      table_name: null,
      serviceCharge: 0,
      discount: 0,
      scheme: {
        scheme_id: null,
        scheme_name: "",
        cap_amount: 0,
        discount_amount: 0,
        sharing_with_party: 0,
        open_discount: null,
        discount_type: "",
        how_much_discount: null,
        discount_addon: "",
        discount_tax: 0,
      },
    });
    setTheVat(0);
    setCartSubTotal(0);
    setServiceCharges(0);
    setCartItems({});
    setSelectTable({});
    setGuestSelected(1);
    setBogo(0);
  }
  setSearchedFoodItem({
    searched: false,
    list: null,
  });
  setSearchInput("");
  if (inputSearch.current !== undefined) inputSearch.current.value = "";
  }

  useEffect(() => {
    //set branch tax
    if (orderDetails.branch) setTheTax(orderDetails.branch.branch_tax);
    else setTheTax(0);
    // empty card
    emptyCardOnChange();
      
  }, [selectedBranch]);

  // const getTableWithStatus1 = () => {
  //   const pendingUrl = BASE_URL + "/settings/get-table-status";
  //   return axios
  //     .get(pendingUrl, {
  //       headers: { Authorization: `Bearer ${getCookie()}` },
  //     })
  //     .then((res) => {
  //       let tempTables = res.data.filter((eachTable) => {
  //         //let isReserved = !order_id ? eachTable.status === "0" : 1;
  //         return (
  //           !order_id &&
  //           parseInt(eachTable.branch_id) === orderDetails.branch.id &&
  //           eachTable.status === "0"
  //         );
  //       });
  //       setGetTable(tempTables);
  //     })
  //     .catch((err) => {});
  // };

  //get current scheme obj for scheme

  // set states on editorder
  function setStatesOnEditOrder(data) {
    console.log("Editdata : ", data);

    setGetOrderDetailsState(data);
    const formatTime = data?.token?.time && moment(data.token.time).format('DD-MMM-YYYY hh:mm A');
    token_No.current = {
      id: data.token?.id,
      time: formatTime
    }
    setManagerId(data.manager_id)
    // add one extra field in formdata if card offer
    setOrderDetails((curr)=>({
      ...curr,
      branch: data.branch,
      discount: data.discount,
      card_number: data.card_number,
      card_number_settle:"",
      orderTypeDetails: data.orderTypeDetails,
      partyDetails: data.partyDetails,
      note_to_rider: orderDetails.note_to_rider,
      order_tip: 0,
      table: data?.table && data.table?.length !== 0 ? data.table : null,
      waiter: data?.waiter_id ?  {
        id: data.waiter_id,
        name: data.waiter_name,
      } : null,
      dept_tag: data.dept_tag,
      dept_commission: data.dept_commission,
      payment_type: data.payment_type.length !== 0 ? data.payment_type : null,
      payment_amount: data.payment_amount
        ? parseFloat(data.payment_amount)
        : data.payment_amount,
      total_guest: parseInt(data.total_guest),
      is_reservation: parseInt(data?.is_reservation),
      table_id: data?.table_id ? JSON.parse(data.table_id) : data?.table_id,
      table_name: data?.table_name,
      newCustomerInfo: {
        ...data.customerInfo,
        is_confirm: true,
      },
      token: data.token,
      serviceCharge: parseFloat(data.serviceCharge),
      service_amount: data.service_amount,
      discount_amount: data.discount_amount,
      scheme: data.scheme?.id ? {
        ...data.scheme,
        cap_amount: parseFloat(data.scheme.cap_amount),
        discount_amount: parseFloat(data.scheme.discount_amount),
      } : (data.scheme || orderDetails.scheme),
    }));
   
    if(data.scheme && data.scheme.discount_type === "bogo" && data.scheme.discount_amount){
      setBogo(parseFloat(data.scheme.discount_amount))
    }
   
    setDeliveryCharges(data.serviceCharge);
   
    // set data on cartItems
    const items = setItemOnEdit(data,schemeForSearchObj);
    setCartItems(items);
    setEditKot(items);

    if (
      history.location && history.location !== "" &&
      (history.location?.search === "?waiter=edit" ||
       history.location?.search?.startsWith("?table=") ||
       history.location?.search === "?table_merge=edit")
    ) {
      setModal10Show(true);
    }
    setButtonDisabled(false);
  }

  // //axios request for submit
  // const axiosForItemGroupByDept = (orderData) => {
  //   const pendingUrl = BASE_URL + "/settings/get-food-group-with-menu";
  //   return axios
  //     .get(pendingUrl, {
  //       headers: { Authorization: `Bearer ${getCookie()}` },
  //     })
  //     .then((res) => {
  //       // save data to local
  //       setAvailableFoodGroups(res.data);
  //       setDataToLocal(pendingUrl, res.data);

  //       if (order_id) {
  //         //set New Food Group Menu Wise
  //         //let tempFoodGroup = res.data[orderData.dept_tag.id];
  //         // setShowFilterFoodGroupsByDept(tempFoodGroup);

  //         // // setInitialFoodItem();
  //         handleFilterFoodGroup(orderData.dept_tag);
  //         //handleSetGroupAndItem(tempFoodGroup[0], orderData.dept_tag);
  //       }
  //     })
  //     .catch((err) => {
  //       if (offline(pendingUrl)) {
  //         let parseRes = getDataFromLocal(pendingUrl);
  //         setAvailableFoodGroups(parseRes);
  //       }
  //     });
  // };

  //dynamic style
  const style = {
    logo: {
      backgroundColor:
        generalSettings &&
        getSystemSettings(generalSettings, "type_background"),
      backgroundImage:
        generalSettings &&
        `url(${getSystemSettings(generalSettings, "type_logo")})`,
    },
    currency: {
      backgroundColor:
        generalSettings && getSystemSettings(generalSettings, "type_clock"),
      color:
        generalSettings && getSystemSettings(generalSettings, "type_color"),
    },
  };

  useEffect(() => {

    if(authUserInfo && (authUserInfo.details.user_type === "call_center") && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1 ){
      setModalOrderType(true);
    }
    if (parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1) {
      fetchStock();
    }
   
  }, [generalSettings]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
      
  //     let api =  authUserInfo.details.user_type === "call_center"
  //                ? "/settings/new-callcenter-order"
  //                : "/settings/new-order";

  //     let url = BASE_URL + api;
  //     let pendingOrder = JSON.parse(localStorage.getItem("orderplaced"));
  //     if (navigator.onLine && pendingOrder && pendingOrder?.length > 0) {
  //       setLoading(true);
  //       syncLocalOrders(url);
  //     }
  //   }, 9000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // validate addon Qty //
  const handleEditOrder = (data) => {
    console.log("sh-log data", data);
    setButtonDisabled(true);
    const branchFull = branchForListSearch && branchForListSearch[data.branch.id];
    handleSetBranchOnEdit(branchFull);

    const orderTypeId = parseInt(data.orderTypeDetails.orderTypeId);
    if (orderTypeId && orderTypeList) {
      const deliveryType = orderTypeList.data.find((delivery) => {
        return delivery.id === orderTypeId && delivery.status === "On";
      });
      setSelectedDeliveryType(deliveryType);
    }
    
    const partyId = parseInt(data.partyDetails.partyId);
    if (partyId && partyForSearch) {
      const partyType = partyForSearch?.find((party) => {
        return party.id === partyId;
      });
      partyType && setSelectedParty(partyType);
    }

    const deptTagId = parseInt(data.dept_tag_id);
    if (deptTagId && deptTagForSearch) {
      const dept_tag = deptTagForSearch?.find((dept) => {
        return dept.id === deptTagId;
      });
      dept_tag && setSelectedDeptTag(dept_tag);
    }
    const workPeriod = workPeriodListForBranch && workPeriodListForBranch.find((tempWorkPeriod) => {
      return (parseInt(data.work_period_id) === parseInt(tempWorkPeriod.id)) && tempWorkPeriod.ended_at === null;
    });
    
    //set vat percent
    setNewSettings({
      ...newSettings,
      vat: branchFull.branch_tax,
      workPeriod: workPeriod,
    });
  
    setTheTax(branchFull.branch_tax);
    setTimeout(() => {
      setStatesOnEditOrder(data);      
    }, 0);

  };
  
  useEffect(() => {
    // when partyForSearch is recieved from context and
    // sets all the party types whose show header is '1' and filters them according to the ids it is
    // associated with
    if (
      partyForSearch &&
      partyForSearch.length &&
      !Object.keys(availableDeliveryParties).length
    ) {
    let filterParties = partyForSearch;
      if(authUserInfo.details.user_type === "call_center"){
        filterParties = partyForSearch.filter((party)=> party.for_cc === 1)
      }else if(authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager" || authUserInfo.details.user_type === "waiter"){
        filterParties = partyForSearch.filter((party)=> party.for_cc === 0)
      }
      const parties = parseParties(filterParties);
      setAvailableDeliveryParties(parties);
    }
  }, [partyForSearch]);

  useEffect(() => {
    // when orderTypeList is recieved from context and
    // sets all the deliver types whose status is 'on'
    if (
      orderTypeList &&
      orderTypeList.data &&
      orderTypeList.data.length &&
      !availableDeliveryTypes.length
    ) {
      const filteredDeliveryTypes = orderTypeList.data.filter(
        (order) => {
          const isisOrderTypeActive = order.status === "On";
          if(authUserInfo.details.order_type_id && typeof(authUserInfo.details.order_type_id) === "string" && JSON.parse(authUserInfo.details.order_type_id)?.length > 0){
            const orderType = isisOrderTypeActive && JSON.parse(authUserInfo.details.order_type_id).includes(order.id);
            if(orderType && (order.name.toLowerCase() === "delivery" || order.name === "takeaway")){
              onlineOrder.current = true
            }
            return orderType
          }
          if(isisOrderTypeActive && (order.name.toLowerCase() === "delivery" || order.name === "takeaway")){
            onlineOrder.current = true
          }
          return isisOrderTypeActive
        }
      );
      if (filteredDeliveryTypes.length > 0) {
        setAvailableDeliveryTypes(filteredDeliveryTypes);
      }

    }
  }, [orderTypeList]);

  const handleFilterDept = (branch_id = null, order_type = null, type=null) =>{
    if (deptTagForSearch && orderDetails.branch && branch_id) {
      let deptartmentTag;
      if(type && type.match(/order(.*)Type/i) && branch_id){
        deptartmentTag = deptTagForSearch?.filter((deptTag) => {
          return (
            JSON.parse(deptTag.branch_id).includes(branch_id) &&
            JSON.parse(deptTag.associate_with).includes(order_type)
          );
        });
      }else if(order_type && branch_id){
         deptartmentTag = deptTagForSearch.filter((deptTag) => {
          return (
            JSON.parse(deptTag.branch_id).includes(branch_id) &&
             JSON.parse(order_type).includes(deptTag.id)
          );
        });
      }else if(branch_id){
          deptartmentTag = deptTagForSearch.filter((deptTag) => {
          return (
            JSON.parse(deptTag.branch_id).includes(branch_id)
          );
        });
      }
      const deptartmentTag1 = deptartmentTag?.find(
        (deptTag) => deptTag.is_default_menu === 1
      );
      const finalDeptTag = deptartmentTag1 ? deptartmentTag1 : deptartmentTag[0];
      setAvailableDeptTags(deptartmentTag);
      !buttonDisabled && setSelectedDeptTag(finalDeptTag);
    } else {
      const deptartmentTag = deptTagForSearch && deptTagForSearch?.filter(
        (deptTag) => deptTag.is_default_menu === 1
      );
      deptartmentTag && setAvailableDeptTags(deptartmentTag);
      !buttonDisabled && setSelectedDeptTag(deptartmentTag && deptartmentTag[0]);
    }
  }

  const handleFilteScheme = () =>{
    setTempSchemeForSearch(
      schemeForSearch && schemeForSearch.length
        ? schemeForSearch.filter((scheme) => {
            return (
              JSON.parse(scheme.branch_id).includes(orderDetails.branch.id) &&
              JSON.parse(scheme.order_type_id).includes(
                selectedDeliveryType.id
              ) &&
              scheme.status == "1" &&
              scheme.scheme_type == "manual_apply"
            );
          })
        : []
    );
  }

  useEffect(() => {
    // once delivery type is set, sets all the available dept tags which are associated with the delivery type
    // or if delivery type is not set, only branch is, sets all the available dept tags which are default
    // selects the first dept tag as a default
    // console.log(orderDetails);
    if (deptTagForSearch && deptTagForSearch.length) {
      if (
        selectedDeliveryType &&
        selectedDeliveryType.id &&
        orderDetails.branch
      ) {
         handleFilterDept(orderDetails.branch.id,selectedDeliveryType.id,"order type");
         handleFilteScheme();
      }
    }

    // when delivery type is changed(if selectedParty exists, it does after delivery type is set), sets party to null
    if (selectedDeliveryType && selectedParty) setSelectedParty(null);
    let deliveryCharge = parseFloat(getSystemSettings(generalSettings, "delivery_charges"));
    // set delivery charges if applicable
    if(authUserInfo && (authUserInfo.details.user_type === "call_center") && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1 ){
      setZoneArea(null);
      setArea(null);
      setDeliveryCharges(0);
    }else{
      if (selectedDeliveryType && (selectedDeliveryType.name.toLowerCase() === "delivery")) {
        setDeliveryCharges(deliveryCharge);
      } else {
        setDeliveryCharges(0);
      }
    }
    // cant empty card if card has item on edit
    emptyCardOnChange()
    if (
      selectedDeliveryType && selectedDeliveryType.name.match(/dine(.*)in/i)
    ) {
      getTable();
      getWaiter();
    }
  }, [deptTagForSearch, selectedDeliveryType]);
 
  useEffect(() => {
    if(orderDetails.branch && selectedParty){
      handleFilterDept(orderDetails.branch?.id,selectedParty?.associate_with_menu)
    }else if(!order_id && orderDetails.branch){
      handleFilterDept(orderDetails.branch?.id); 
    }
     
    if (selectedParty && selectedParty?.show_header === "1") {
      
      if (selectedParty?.associate_with_payment_type !== null) {
        let tempAvailablePaymentType =
          paymentTypeForSearch &&
          paymentTypeForSearch.filter((paymentType) => {
            return JSON.parse(selectedParty?.associate_with_payment_type).includes(
              paymentType.id
            );
          });
        setPaymentTypeAvailable(tempAvailablePaymentType);
      }
     
    }
    if(selectedParty){
      // cant empty card if card has item on edit
      emptyCardOnChange()
    }
    // open table pop auto if dine in
    if(!order_id && selectedParty && selectedDeliveryType && selectedDeliveryType.name.match(/dine(.*)in/i) &&
      parseInt(getSystemSettings(generalSettings, "table_waiter")) === 1 ){
      setModal10Show(true);
    }else if(!order_id && selectedParty && (selectedDeliveryType && selectedDeliveryType.name.toLowerCase() === "delivery") && (parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1 ? (authUserInfo.details.user_type !== "call_center") : true)){
      setModal9Show(true)
    }

    if (
      order_id &&
      schemeForSearch &&
      selectedDeliveryType &&
      orderDetails?.branch
    ) {
      handleFilteScheme();
    }

  }, [selectedParty, orderDetails?.branch]);


  useEffect(() => {
    // parses and sets the menu when all food items are available
    if (foodForPOS && foodForPOS.length 
      //&& !Object.keys(menu).length
      ) {
      setMenu(parseFoodItems(foodForPOS));
    }
  }, [foodForPOS]);

  //set search if group change
  // useEffect(() => {
  //   setSearchedFoodItem({
  //     searched: false,
  //     list: null,
  //   });
  //   setSearchInput("");

  //   if (inputSearch.current !== undefined) inputSearch.current.value = "";
  // }, [activeFoodGroup]);

  const handleFoodGroup = (foodGroup) => {
    if(foodGroup?.is_schedule && foodGroup.food_schedules){
      const currTime = moment(new Date(), "HH:mm");
      const day = moment(new Date()).format("dddd");
      const findDay = foodGroup.food_schedules.find(item => item.day_name === day);
      if(findDay && findDay.is_schedule){
        const startTime = moment(findDay.from_time,"HH:mm:ss");
        const endTime = moment(findDay.to_time,"HH:mm:ss");
        if(!currTime.isBetween(startTime,endTime)){
          toast.error(`${foodGroup.name} unavailable right now.`,toastObj)
          return false
        }
      }
    }
    setActiveFoodGroup(foodGroup.id);
    setSearchedFoodItem({
      searched: false,
      list: null,
    });
    setSearchInput("");
  };

  //set food group
  useEffect(() => {
    if (
      !selectedDeptTag ||
      !selectedDeptTag.id ||
      !Object.keys(availableFoodGroups).length
    )
      return;
    availableFoodGroups[selectedDeptTag?.id] &&
      setActiveFoodGroup(availableFoodGroups[selectedDeptTag.id][0]?.id);

    let tempCommission = 0;
    if (selectedDeptTag) {
      tempCommission = selectedDeptTag.commission;
      setDeptCommission(tempCommission);
    }

    // cant empty card if card has item on edit
    emptyCardOnChange()
  }, [selectedDeptTag]);


  useEffect(() => {
    if (
      !selectedDeptTag ||
      !selectedDeptTag.id ||
      !Object.keys(availableFoodGroups).length
    )
      return;
    availableFoodGroups[selectedDeptTag?.id] &&
      setActiveFoodGroup(availableFoodGroups[selectedDeptTag.id][0]?.id);
  }, [availableFoodGroups, orderDetails?.branch]);

  useEffect(() => {
    // cal tax subtotal and total
    let serviceCharge = 0;
    let isServiceCharge = orderDetails?.branch && orderDetails.branch?.service_fees &&
    orderDetails?.total_guest >= orderDetails.branch.service_fees_person && selectedDeliveryType && selectedDeliveryType.name.match(/dine(.*)in/i)
    if (
      isServiceCharge
    ) {
      let subtotalTax = parseFloat(
        (parseFloat(cartSubTotal) + parseFloat(theVat)).toFixed(2)
      );
      if(parseInt(getSystemSettings(generalSettings, "service_charges_type")) === 1){
        subtotalTax = parseFloat((parseFloat(cartSubTotal) - parseFloat(orderDetails.scheme.discount_amount)).toFixed(2));
      }
      serviceCharge = getServiceCharges(
        subtotalTax,
        orderDetails.branch.service_fees
      );
    }
    setServiceCharges(serviceCharge);
    let delivery = deliveryCharges ? parseFloat(deliveryCharges) : 0;
  
    const subTotalVat = parseFloat(cartSubTotal) + parseFloat(theVat);
    const carttotal = subTotalVat + delivery + parseFloat(serviceCharge) - orderDetails.scheme.discount_amount;
   
    setCartTotal(roundOffPrice(carttotal))
    // subtTotal + vat
    //getTaxedTotal(calculateSubTotal(cartItems), theTax, taxType)
    if(authUserInfo.details.user_type === "call_center" && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1){
      if (zoneArea && zoneArea?.min_for_free_delivery > 0 && (carttotal - delivery) >= zoneArea?.min_for_free_delivery) {
        setDeliveryCharges(0)
      }else if(zoneArea && zoneArea.free_delivery === 0 && deliveryCharges == 0 && zoneArea.fee > 0){
        setDeliveryCharges(zoneArea.fee)
      }
    }

  }, [
    cartSubTotal,
    theVat,
    orderDetails?.total_guest,
    orderDetails.scheme?.discount_amount,
    deliveryCharges,
  ]);

  //item add/delete animate
  const Cartanimation = (id) =>{
    
    let blink = cardAnimate.current[id];
    if(animate.current === true && blink){
      const parentPos = flyStart.current?.getBoundingClientRect();
       const elementImage = imageAnimate.current; 
       const childPos = blink?.getBoundingClientRect();
       if(childPos && parentPos){
        const childY = childPos.top > 410 ? 410 : childPos.top;
        const distanceX = (childPos.left) - parentPos.left;
        const distanceY = (childY - 7) - parentPos.top;
        const virtualDiv = document.createElement('div');
        virtualDiv.style.position = 'absolute';
        virtualDiv.style.top = `${parentPos.top}px`;
        virtualDiv.style.left = `${parentPos.left}px`;
        virtualDiv.style.width = `${parentPos.width}px`;
        virtualDiv.style.height = `${parentPos.height}px`;
        const image = new Image();
        image.src = elementImage; // Replace with the path to your image
        image.style.height = '3rem';
        image.style.borderRadius = '50%';
        virtualDiv.appendChild(image);
        document.body.appendChild(virtualDiv);
        gsap.to(virtualDiv, {
          motionPath: {
            path: [
              { x: 0, y: 0 },
              { x: -35, y: -30 },
              { x: `${distanceX}px`, y: `${distanceY}px` },
            ],
            curviness: 1.5,
          },
          duration: .7,
        });

        setTimeout(() => {        
          blink?.classList && blink.classList.add("blink");
        }, 300);
        // gsap.to(virtualDiv, { x: distanceX, y: distanceY, duration: .8 });
        
        setTimeout(() => {
          blink?.classList && blink.classList.remove("blink");
          document.body.removeChild(virtualDiv);
          gsap.set(flyStart.current, { x: 0, y: 0 });
        }, 600);
      }
    }else{

      if(animate.current == "add"){
        blink?.classList && blink.classList.add("blink");
      }else if(animate.current == "del"){
        blink?.classList && blink.classList.add("delBlink");
      }  

       setTimeout(() => {
         if(animate.current == "add"){
           blink.classList.remove("blink");
          }else if(animate.current == "del"){
            blink.classList.remove("delBlink");
          }
        }, 350);
    }
  }
     
     
   //cal dis total 
   const calculateDiscount = (cartItem) =>{
    let itemDiscount = Object.values(cartItem).reduce(
      (total, item) => item.item_discount + total,
      0
    );
    if(orderDetails.scheme?.cap_amount && orderDetails.scheme.cap_amount > 0 && orderDetails.scheme.cap_amount < itemDiscount){
      itemDiscount = orderDetails.scheme.cap_amount;
    }
    const discountPercent = orderDetails.scheme?.how_much_discount;

    if(!orderDetails.scheme.open_discount){
      itemDiscount = roundOffPrice(itemDiscount,generalSettings);
    }
    setOrderDetails((curr)=>{
      const newData = {
        ...curr,
        discount: orderDetails.scheme && discountPercent ? (orderDetails.scheme.discount_type?.toLowerCase() === "fixed")
            ? 0 : discountPercent
            : orderDetails.discount,
        scheme: {
          ...orderDetails.scheme,
          discount_amount: orderDetails.scheme.open_discount ? orderDetails.scheme.discount_amount : itemDiscount,
        },
      }
      return newData;
    });
    return parseFloat(itemDiscount);
  } 

  const calculateTax = (subtotal,dis) =>{
    const taxTypeDiscount = getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after";
    const beforeTaxSubtotal = taxTypeDiscount ? (subtotal - dis) : subtotal;
    const roundedSubTotal = getTax(theTax, beforeTaxSubtotal);
    setTheVat(roundOffPrice(roundedSubTotal));
  }   

  useEffect(() => {
    // whenever cart items change, update all cart totals
    let dis = 0;
    if (!Object.keys(cartItems).length) {
      setCartSubTotal(0);
      setCartTotal(0);
      setTheVat(0);
      setOrderDetails((curr)=>({
        ...orderDetails,
        scheme: {
          ...curr.scheme,
          discount_amount: 0,
        }
      }))
    } else {
      const total = calculateSubTotal(cartItems);
      let subtotal = getBeforeTaxPrice(total);
      dis = calculateDiscount(cartItems);
      if(orderDetails.scheme?.cap_amount && orderDetails.scheme.cap_amount > 0 && orderDetails.scheme.cap_amount < dis){
        dis = orderDetails.scheme.cap_amount;
      }
      setCartSubTotal(roundOffPrice(subtotal));

      if(orderDetails.scheme.open_discount && orderDetails.scheme.how_much_discount){
        hanldeDiscountPercent(subtotal, orderDetails.scheme.how_much_discount)
      }else{
        calculateTax(subtotal, dis);
      }
      
    }

    //order items set
    const orderItem = [];
    let finalItem = {};
    for (const itemHash in cartItems) {
      const originalItem = cartItems[itemHash];
      const item = JSON.parse(JSON.stringify(originalItem));
      // Destructure and exclude `property` and `variation` from `item.item`
      const { variations, properties, variation_recipe, property_groups_validation, food_group_status, slug, image, group_qty, sku, food_group_name, menu_ids, kot_branch_id, ...updatedItem } = item.item;

      // Update the original object
      item.item = updatedItem;
      // parse properties for 'new order' state
      const pickedProperty = [];
      if (item.hasOwnProperty("properties") && item.properties) {
        const items =
          item.properties[
            Object.values(item.properties).forEach((itm) => {
              for (const key in itm) {
                pickedProperty.push({
                  item: itm[key].item,
                  quantity: itm[key].quantity,
                });
              }
            })
          ];
      }

       // check if current item qty > previous on edit
    if (order_id) {
    
      const kot = editKot && editKot[itemHash];

      // for (const key in editKot) {
      const isKotItem =  (!item.new && kot && item.item.has_property === kot.item.has_property &&
                         item.item.has_variation === kot.item.has_variation);
        if (
          isKotItem && item.quantity > kot.quantity
        ) {
            finalItem = {
              ...finalItem,
              [itemHash]: {
                ...item,
                quantity: item.quantity - kot.quantity,
                print_status: "added",
                properties: pickedProperty ? pickedProperty : null,
              },
            };
          } else if (
            isKotItem && item.quantity === kot.quantity
          ) {
            setDeletedItemsOnEdit((curr)=>{
              const {[itemHash]: _, ...rest} = curr
              return rest
            });
          }
      }
      
      // discount
      // dis += parseFloat(item.item_discount);
      // for bogo offers

      if (
        (bogo === 0 && item.discount_type === "bogo" && (parseInt(orderDetails.scheme.discount_amount) === 0 || parseInt(dis) === 0))
      ) {
        if((order_id && item.offer_type === "bogo") || !order_id){
          setBogo(parseFloat(getBeforeTaxPrice(calculatePriceForCartVariation(item, 1)).toFixed(2)));
          setCartItems((curr) => {
            const newData = {
              ...curr,
              [itemHash]: {
                ...originalItem,
                offer_type: order_id && item.offer_type === "bogo" ? "bogo" : !order_id && "bogo",
              },
            };
            return newData;
          });
        }else{
          orderItem.push({
            ...item,
            properties: pickedProperty,
            print_status: "new",
            ...((item.offer_type === "bogo" && { offer_type: "bogo",}))
          });
        }
      } else {
          orderItem.push({
            ...item,
            properties: pickedProperty,
            print_status: "new",
            ...((item.offer_type === "bogo" && { offer_type: "bogo",}))
          });
      }
    }
    setItemAdd(finalItem);
    setNewOrder(orderItem);

  }, [cartItems]);
  
  useEffect(() => {

    if (!generalSettings || !Object.keys(generalSettings)?.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));

    if(getSystemSettings(generalSettings, "menu_wise_rate") == 1){
      setMenuWisePrice(1)
    }

  }, [generalSettings]);
 
  //switcher for call center payment
  const handlePaymentType = (payment_type) => {
    setCallCenterPayment({
      transaction_no: payment_type === "Online" ? callCenterPayment.transaction_no : "",
      payment_type,
      transactionModal: payment_type === "Online",
    });
  };

  // HELPERS
  const handleCloseItemModal = () => {
    setShowModal(false);
  };
  const handleShowItemModal = () => setShowModal(true);

  const handleFoodItem = (foodItem) =>{
    // check if item has properties or variations
    if (foodItem.has_property === "1" || foodItem.has_variation === "1") {
      setSelectedFoodItemId(foodItem.id);
      let foodItm = {
        ...foodItem,
        price: menuWisePrice && foodItem.has_variation === "0" ? foodItem.price[selectedDeptTag.id] : foodItem.price,
        variations: menuWisePrice && foodItem.has_variation === "1" ? foodItem.variations[selectedDeptTag.id] : foodItem.variations,
      }
      setSelectedFoodItem({ item: foodItm });
      (foodItm.properties || (foodItm.variations && foodItm.variations[0])) &&
      handleShowItemModal();
    } else {
      // quickAddToCart
      let foodItm = {
        ...foodItem,
        price: menuWisePrice ? foodItem.price[selectedDeptTag.id] : foodItem.price
      }
      addToCart(foodItem.id, { item: foodItm });
    }
  }

  const handleFoodItemClicked = (foodItem,e) => {
    animate.current = true;
    flyStart.current = e.target;
    imageAnimate.current = foodItem.image;
    if (validateIfCanAddToCart()) return;
    const tableValidation = selectedDeliveryType && selectedDeliveryType.name.match(/dine(.*)in/i) &&
      parseInt(getSystemSettings(generalSettings, "table_waiter")) === 1;
    const customerValidation =  order_id || (orderDetails.newCustomerInfo.ref_no?.length && orderDetails.newCustomerInfo.ref_no?.length  >= selectedParty.ref_min &&
      orderDetails.newCustomerInfo.ref_no?.length <= selectedParty.ref_max && orderDetails.newCustomerInfo.is_confirm);
    const customerDeliveryValidation = (orderDetails.newCustomerInfo?.name !== "" && orderDetails.newCustomerInfo.phn_no !== "") && orderDetails.newCustomerInfo.is_confirm;
    // if del is food panda then show customer popup
    const tableSelected = Boolean(orderDetails.table);
    const waiterOd = getSystemSettings(generalSettings, "waiter_order_type") ? JSON.parse(getSystemSettings(generalSettings, "waiter_order_type")) : null;
    const checkCallCenter = orderDetails.newCustomerInfo?.name !== "" && orderDetails.newCustomerInfo.is_confirm
    
    // check table waiter or customer idf required
    if (tableValidation && !tableSelected) {
      setModal10Show(true);
    } else if (waiterOd && waiterOd.length && (waiterOd.includes(selectedDeliveryType.id) && !orderDetails.waiter)) {
      setModal10Show(true);
    } else if (selectedParty && parseInt(selectedParty.show_ref_no) === 1 && !customerValidation) {
      setModal9Show(true);
    } else if ((selectedDeliveryType?.name.toLowerCase() === "delivery") && !customerDeliveryValidation) {
      setModal9Show(true);
    } else if (parseInt(getSystemSettings(generalSettings, "customer_validation")) === 1 && !customerDeliveryValidation) {
      setModal9Show(true);
    } else if(authUserInfo.details.user_type === "call_center" && !checkCallCenter){
      setModal9Show(true);
    } else {
      handleFoodItem(foodItem)
    }
   
  };

  // check offer validation
  const checkOfferValidation = (scheme,item) =>{
    if (scheme.item_type === "Group") {
      const foodGroupMatch = !JSON.parse(scheme.groups_id).some((element) => item.item.food_groups.includes(element))
      if (scheme.menu_id && scheme.menu_id !== "" && scheme.menu_id !== "0"){
        //if (!JSON.parse(scheme.groups_id).includes(item.item.food_groups[0]))
        if(foodGroupMatch || (parseInt(scheme.menu_id) !== selectedDeptTag.id)){
          return false;
        } 
      }else if(foodGroupMatch){
          return false
      }
      return true
    } else if (scheme.item_type === "Item") {
      const foodItemMatch = !JSON.parse(scheme.items_id).includes(item.item.id)
     if (scheme.menu_id && scheme.menu_id !== "0" && scheme.menu_id !== ""){
       if (foodItemMatch || (parseInt(scheme.menu_id) !== selectedDeptTag.id)){
         return false;
       }
      }else if (foodItemMatch){
        return false;
      }
      return true;
    }
  }

  // check if any offer is on the item/ group
  const checkScheme = (item) => {
    if (orderDetails.scheme?.scheme_id) {
     if(!orderDetails.scheme?.open_discount){
        const schemeToApply = schemeForSearchObj[parseInt(orderDetails.scheme.scheme_id)];
        const valid = checkOfferValidation(schemeToApply,item);
        if(!valid){
          return false;
        }
        return schemeToApply;
     }
    } else {
      const schemeToApply = schemeForSearch.find((scheme) => {
        if (!scheme.branch_id.includes(orderDetails.branch.id)) return false;
        if (!scheme.order_type_id.includes(selectedDeliveryType.id))
          return false;
        if (scheme.status !== "1") return false;
        if (scheme.scheme_type !== "enable_by_default") return false;
        const valid = checkOfferValidation(scheme,item);
        if(!valid){
          return false;
        }
        const currDate = moment(new Date(), "DD/MM/YYYY HH:mm");
        const startDate = moment(scheme.start_date).format("LLL");
        const currTime = moment(new Date(), "HH:mm");
        const endDate = moment(scheme.end_date).format("LLL");
        const day = moment(currDate).format("ddd");

        // Specify the time format when parsing
        let fromTime =  moment(scheme.from_time, 'HH:mm');
        let toTime = moment(scheme.to_time, 'HH:mm');  
        const scheduling = JSON.parse(scheme.schedule);
        if(!currDate.isBetween(startDate, endDate)){
          return false;
        }else if(scheduling && scheduling?.length > 0 && !scheduling.includes(day)){
          return false;
        }else if(scheme.time_schedule == 1 && !currTime.isBetween(fromTime,toTime)){
          return false;
        }
        
        // if (!currDate.isBetween(startDate, endDate)) return false;
        return true;
      });
      return schemeToApply;
    }
  };
  // party and order type validity 
  const validateIfCanAddToCart = () => {
    let error = "";
    if (!selectedParty || !selectedParty.id) error = "party";
    if (!selectedDeliveryType || !selectedDeliveryType.id)
      error = "delivery type";
    if (error) {
      toast.error(`Please select ${error} first.`);
    }

    return Boolean(error);
  };
  
  const handleNewItem = (item,id,hashId,edit) => {
    let cartId = hashId;
    if(parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1 && item.item?.is_deal === 0 && cartItems && cartItems[hashId]?.quantity === item.item.stock_qty
    ){
      toast.error("Stock limit has been exceed",toastObj);
      return false
    }
    let schemeToApply = checkScheme(item);
    if(schemeToApply?.card_required){
      schemeToApply = {
        ...schemeToApply,
        how_much_discount: orderDetails.scheme.how_much_discount
      }
    }
 
    //check bogo offer cart edit
    if(item.item_discount === 0 && item?.offer_type === "bogo" && edit && bogo > 0 && getFormattedPrice(getBeforeTaxPrice(calculatePriceForCartVariation(item, 1))) < orderDetails.scheme.discount_amount){
      toast.error("You can't select less price item than bogo discount");
      return false;
    }
    if(item.item_discount > 0 && item?.offer_type === "bogo" && edit && bogo > 0 && getFormattedPrice(getBeforeTaxPrice(calculatePriceForCartVariation(item, 1))) > bogo){
      toast.error("You can't select less price item than bogo discount");
      return false;
    }
    // set bogo price if edit
    if(item?.offer_type === "bogo" && item.item_discount === 0 && edit){
      setBogo(getFormattedPrice(getBeforeTaxPrice(calculatePriceForCartVariation(item, 1))));
    }
    
    if (
      bogo > 0 &&
      orderDetails.scheme.discount_type === "bogo" &&
      parseInt(orderDetails.scheme.discount_amount) === 0 && !edit && !item?.item_discount
      ) {
      const afterTaxPrice = getFormattedPrice(getBeforeTaxPrice(calculatePriceForCartItem(item, 1)));
      if (afterTaxPrice > bogo) {
        toast.error("You can't select item more than bogo price");
        return false
      } else {
        cartId = hashId + "x";
        setCartItems((curr) => {
          const newData = {
            ...curr,
            [hashId + "x"]: {
              ...item,
              quantity: 1,
              after_tax_price: afterTaxPrice,
              item_discount: afterTaxPrice,
              discount_type: schemeToApply
                ? schemeToApply.discount_type
                : "",
              scheme_id: schemeToApply ? schemeToApply.id : "",
              offer_type: "bogo",
              comments: "",
              image:"",
              seq: Object.keys(cartItems)?.length + 1,
              new: order_id ? true : false,
              discount: schemeToApply
                ? parseFloat(schemeToApply.how_much_discount)
                : 0,
            },
          };
          return newData;
        });
        setTimeout(() => {
          Cartanimation(cartId)        
        }, 0);
        setShowModal(false);
        return false;
      }
    }
   
    if (cartItems[hashId]) {
 
      // add cart item already exist
      setCartItems((curr) => {
        const currQuantity = (edit && hashId !== id) ? (item.quantity + curr[hashId].quantity) : edit ? (item.quantity) : (curr[hashId].quantity + 1);
        // const currQuantity = curr[hashId].quantity + 1;
        const currItem = (edit && hashId !== id) ? curr[hashId] : edit ? item : curr[hashId];
        const afterTaxPrice = getFormattedPrice(getBeforeTaxPrice(calculatePriceForCartItem(currItem, currQuantity)));
        let schemePrice = schemeToApply?.discount_addon === "discount_without_addon" ? getBeforeTaxPrice(calculatePriceForCartVariation(currItem, currQuantity)) : afterTaxPrice;
        
        if(schemeToApply?.discount_tax){
          schemePrice += getTaxPrice(afterTaxPrice)
        }
        const itemDiscount = getItemDiscount(
          schemePrice,
          curr[hashId],
          currQuantity
        );
        
        item.after_tax_price = afterTaxPrice;
        item.item_discount = itemDiscount;
        const newData = {
          ...curr,
          [hashId]: {
            ...curr[hashId],
            quantity: currQuantity,
            after_tax_price: afterTaxPrice,
            item_discount: bogo !== 0 && orderDetails.scheme.discount_type === "bogo"
                ? (curr[hashId]?.item_discount
                  ? curr[hashId].item_discount
                  : 0)
                : (itemDiscount),
            discount_type: schemeToApply
              ? schemeToApply.discount_type
              : "",
            scheme_id: schemeToApply ? schemeToApply.id : "",
          },
        };
        if (edit) newData[id] = item;
        if (edit && hashId != id) delete newData[id];
        return newData;
      });
    
   }else{
      //new cart item
      let schemeToApply = checkScheme(item);
      if(schemeToApply?.card_required){
        schemeToApply = {
          ...schemeToApply,
          how_much_discount: orderDetails.scheme.how_much_discount
        }
      }
    
      const afterTaxPrice = parseFloat(getBeforeTaxPrice(calculatePriceForCartItem(item, 1)).toFixed(2));

      let schemePrice = schemeToApply?.discount_addon === "discount_without_addon" ? getBeforeTaxPrice(calculatePriceForCartVariation(item, 1)) : afterTaxPrice ;
      if(schemeToApply?.discount_tax){
        schemePrice += getTaxPrice(afterTaxPrice)
      }

      if (
        bogo > 0 && orderDetails.scheme.discount_type === "bogo" &&
        parseInt(orderDetails.scheme.discount_amount) === 0
      ) {
        if (afterTaxPrice > bogo) {
          toast.error("You can't select item more than bogo price");
        } else {
          setCartItems((curr) => {
            const newData = {
              ...curr,
              [hashId + "x"]: {
                ...item,
                quantity: 1,
                after_tax_price: afterTaxPrice,
                new: order_id ? true : false,
                discount: schemeToApply
                  ? parseFloat(schemeToApply.how_much_discount)
                  : 0,
                item_discount: afterTaxPrice,
                discount_type: schemeToApply
                  ? schemeToApply.discount_type
                  : "",
                comments: "",
                image:"",
                scheme_id: schemeToApply ? schemeToApply.id : "",
                offer_type: "bogo",
                seq: Object.keys(cartItems)?.length + 1,
              },
            };
            if (edit) delete newData[id];
            return newData;
          });
        }
      } else {
        setCartItems((curr) => {
          const newData = {
            ...curr,
            [hashId]: {
              ...item,
              quantity: 1,
              after_tax_price: afterTaxPrice,
              new: order_id ? true : false,
              discount: schemeToApply
                ? parseFloat(schemeToApply.how_much_discount)
                : 0,
              item_discount: bogo !== 0 && orderDetails.scheme.discount_type === "bogo"
                  ? (curr[hashId]?.item_discount
                    ? curr[hashId].item_discount
                    : 0)
                  : (schemeToApply
                  ? getInitialItemDiscount(schemePrice , schemeToApply)
                  : 0),
              discount_type: schemeToApply
                ? schemeToApply.discount_type
                : "",
              comments: "",
              image:"",
              scheme_id: schemeToApply ? schemeToApply.id : "",
              seq: Object.keys(cartItems)?.length + 1,
            },
          };
          if (edit) delete newData[id];
          return newData;
        });
      }
    }
  }

   // new item added ot delete
   const handleSetItemQty = (item,id,qty) =>{
    animate.current = "";
    const hashId = id;
    if(qty > 0 && parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1 && cartItems && cartItems[hashId]?.quantity === item.item.stock_qty){
      toast.error("Stock limit has been exceed",toastObj);
      return false
    }
    if (cartItems[hashId] && qty > 0) {
      let schemeToApply = checkScheme(item);
      
      // add cart item already exist
      setCartItems((curr) => {
        const afterTaxPrice = parseFloat(
          getBeforeTaxPrice(
            calculatePriceForCartItem(curr[hashId], qty)
          ).toFixed(2)
        );
        let schemePrice = schemeToApply?.discount_addon === "discount_without_addon" ? getBeforeTaxPrice(calculatePriceForCartVariation(curr[hashId], qty)) : afterTaxPrice;
        if(schemeToApply?.discount_tax){
          schemePrice += getTaxPrice(afterTaxPrice)
        }

        const newData = {
          ...curr,
          [hashId]: {
            ...curr[hashId],
            quantity: qty,
            after_tax_price: afterTaxPrice,
            item_discount: bogo !== 0 && orderDetails.scheme.discount_type === "bogo"
                ? curr[hashId]?.item_discount
                  ? curr[hashId].item_discount
                  : 0
                : getItemDiscount(
                    schemePrice,
                    curr[hashId],
                    qty
                  ),
            discount_type: schemeToApply
              ? schemeToApply.discount_type
              : "",
            scheme_id: schemeToApply ? schemeToApply.id : "",
          },
        };
        return newData;
      });
    }
  }

  //check if add in delelte item already
  const checkDeleteItem = (hashId) => {
    if (
      order_id &&
      deletedItemsOnEdit[hashId] &&
      Object.keys(deletedItemsOnEdit).includes(String(hashId))
    ) {
      const deleteItem = deletedItemsOnEdit[hashId];
     
      setDeletedItemsOnEdit((curr) => ({
        ...curr,
        [hashId]: {
          ...deleteItem,
          quantity: deletedItemsOnEdit[hashId].quantity - 1,
        },
      }));
    }
  }

  // add to cart 
  const addToCart = (
    id,
    item,
    edit = false,
  ) => {
    console.log("[POS LOG]", id, item, edit);

    // stock validation
    //  - Validate
    //  - Check scheme
    //  - Check stock
    //  - Add To Cart

    // token add
    if (!order_id && !orderDetails.token?.id) {
      tokenNumber();
    }
    
    if (item.item.has_property === "1" || item.item.has_variation === "1") {
      const hashId = createHash(item);
      checkDeleteItem(hashId)
      handleNewItem(item,id,hashId,edit);
    }
    //  Item can be added to cart immediately
    else {
      //  Cart has Item
      checkDeleteItem(item.item.id)
      handleNewItem(item,id,item.item.id,edit);
    }

    setShowModal(false);
    // } else {
    //   toast.error(
    //     "You have to pick atleast one item from required " + groupName
    //   );
    //   setShowModal(true);
    // }
  };

  //remove offer
  const removeScheme = () => {
    setOrderDetails((curr) =>{
      const newData = {
      ...curr,
      discount: 0,
      card_number: "",
      card_number_settle:"",
      scheme: {
        scheme_id: null,
        scheme_name: "",
        cap_amount: 0,
        discount_amount: 0,
        sharing_with_party: 0,
        open_discount: null,
        discount_type: "",
        how_much_discount: null,
        discount_addon: "",
        discount_tax: 0,
      },
    }
    return newData
    });
  };
  // card offer
  const handleCardSubmit = (e) => {
    if (orderDetails.card_number === "") {
      toast.error(`${_t(t("Please enter the card number"))}`, toastObj);
    } else if (orderDetails.card_number.length > 16) {
      toast.error(
        `${_t(t("The card number should not be longer than 16 digits"))}`,
        toastObj
      );
    } else if (orderDetails.card_number.length < 8) {
      toast.error(
        `${_t(t("The card number should not be shorter than 8 digits"))}`,
        toastObj
      );
    } else {
      const cardsInfoIds = JSON.parse(
        schemeForSearchObj[parseInt(orderDetails.scheme.scheme_id)]
          ?.card_info_ids
      );

      const applicableIbans = [];
      if (cardsInfoIds && cardsInfoIds.length) {
        for (const cardInfo of cardForSearch) {
          if (cardsInfoIds.includes(cardInfo.id))
            applicableIbans.push(cardInfo);
        }
        if (applicableIbans.length) {
          for (const applicableIban of applicableIbans) {
            if (
              orderDetails.card_number.startsWith(applicableIban.iban_number)
            ) {
              handleSetOffer(
                schemeForSearchObj[parseInt(orderDetails.scheme.scheme_id)],
                applicableIban
              );
              cardModal.current.classList.remove("showing");
              return;
            }
          }
          toast.error(`${_t(t("Offer is not valid on your card."))}`, toastObj);
          removeScheme();
        }
      } else {
        toast.error(`${_t(t("Offer is not valid."))}`, toastObj);
        removeScheme();
      }
      cardModal.current.classList.remove("showing");
    }
  }

  const hanldeDiscountPercent = (subtotal, value) =>{
    let discountPercent = Number(value);
    if (discountPercent > 100) discountPercent = 100;
    const discountAmount = roundOffPrice((subtotal / 100) * discountPercent);
    setOrderDetails((curr)=>({
      ...curr,
      discount: discountPercent,
      scheme:{
        ...curr.scheme,
        discount_amount: discountAmount,
        how_much_discount: discountPercent
      }
    }));
    calculateTax(subtotal, discountAmount)
    
  }

  // mannual offer selection
  const handleSetOffer = (scheme, cardInfo = null) => {
    const currDate = moment(new Date().toISOString());
    const currTime = moment(new Date(), 'HH:mm');
    const startDate = moment(scheme.start_date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)").toISOString();
    const endDate = moment(scheme.end_date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)").toISOString();
    const day = moment(currDate).format("ddd");
    const scheduling = JSON.parse(scheme.schedule);
 
    // Specify the time format when parsing
    let fromTime =  moment(scheme.from_time, 'HH:mm');
    let toTime = moment(scheme.to_time, 'HH:mm'); 
    
    if(!currDate.isBetween(startDate, endDate)){
      toast.error("Offer is not valid", toastObj);
      return false;
    }else if(scheduling && scheduling?.length > 0 && !scheduling.includes(day)){
      toast.error("Offer day is not valid", toastObj);
      return false;
    }else if(scheme.time_schedule == 1 && !currTime.isBetween(fromTime,toTime)){
      toast.error("Offer time is not valid", toastObj);
      return false;
    }
   
    // offer bogo
    if (scheme.discount_type === "bogo" && Object.keys(cartItems).length > 0) {
      setCartItems((currCartItems) => {
        let bogoOffer = true;
        const newData = { ...currCartItems };
        Object.keys(currCartItems).sort((a, b) =>
        cartItems[a].seq > cartItems[b].seq ? 1 : -1).forEach((index)=>{
           const item = currCartItems[index];        
                    
           let valid = checkOfferValidation(scheme,item);
           
           if (valid && bogoOffer) {
             newData[index] = {
               ...item,
               item_discount: 0,
               scheme_id: scheme.id,
               discount: parseFloat(scheme.how_much_discount),
               discount_type: scheme.discount_type,
               offer_type: "bogo",
             };
             bogoOffer = false;
           } else {
             newData[index] = {
               ...item,
               item_discount: 0,
               discount: 0,
               discount_type: "",
               scheme_id: "",
             };
           }
        });
        return newData;
      });
    } else {
      let discountPercent = scheme
      if(cardInfo !== null){
         discountPercent = {
          ...scheme,
          how_much_discount : cardInfo.discount
          ? parseFloat(cardInfo.discount)
          : orderDetails.discount,
        }
      }
     
      setCartItems((currCartItems) => {
        const newData = {};
        for (const hash in currCartItems) {
          const item = currCartItems[hash];
          let valid = checkOfferValidation(scheme,item);
          if (valid) {
            let schemePrice = scheme?.discount_addon === "discount_without_addon" ? getBeforeTaxPrice(calculatePriceForCartVariation(item, item.quantity)) : item.after_tax_price ;
            if(scheme?.discount_tax){
              schemePrice += getTaxPrice(item.after_tax_price)
            }

            newData[hash] = {
              ...item,
              item_discount: getInitialItemDiscount(
                schemePrice,
                discountPercent,
                item.quantity
              ),
              scheme_id: scheme.id,
              discount: parseFloat(discountPercent.how_much_discount),
              discount_type: scheme.discount_type,
            };
            delete newData[hash].offer_type;
          } else {
            newData[hash] = {
              ...item,
              item_discount: 0,
              discount: 0,
              discount_type: "",
              scheme_id: "",
            };
            delete newData[hash].offer_type;
          }
        }
        return newData;
      });
    }

    // in case of card and discount exceeding cap_amount
    // the cap amount is the new discount amount
    // (adding key 'capAmountMet' to orderDetails object to remove discount easily)
    // let capAmountMet = false;

    let discount = 0;
    if (scheme) {
      if (scheme.discount_type.toLowerCase() === "fixed") {
        discount = 0;
      } else if(cardInfo !== null) {
        const cardDiscount = parseFloat(cardInfo.discount);
        discount =  cardDiscount ? cardDiscount : orderDetails.discount;
      }else{
        discount = scheme.how_much_discount ? parseFloat(scheme.how_much_discount) : orderDetails.discount;
      }
    }

    let howMuchDis = parseFloat(scheme.how_much_discount);
    const discountAmount = scheme.open_discount ? "" : 0;
    if(cardInfo !== null){
      howMuchDis = cardInfo.discount
      ? parseFloat(cardInfo.discount)
      : orderDetails.discount;
    }
   
    setOrderDetails((curr) => {
      const newData = {
      ...curr,
      discount: discount,
      card_number: cardInfo !== null ? orderDetails.card_number : "",
      scheme: {
        scheme_id: scheme.id,
        scheme_name: scheme.name,
        cap_amount: parseFloat(scheme.cap_amount),
        discount_type: scheme.discount_type,
        open_discount: scheme.open_discount,
        sharing_with_party: scheme.party_sharing_discount,
        how_much_discount: howMuchDis,
        discount_amount: discountAmount,
        discount_addon: scheme.discount_addon,
        discount_tax: 0,
      },
     }
     return newData;
    });

    if (scheme.card_required && !scheme?.discount) {
      cardModal.current.classList += " showing";
      return;
    }
    setBogo(0);
  };

  // change item cart item if variartion/property
  const editCartItem = (itemId) => {
    const itemObj = cartItems[itemId];
    if (
      itemObj.item.has_property === "1" ||
      itemObj.item.has_variation === "1"
    ) {
      setSelectedFoodItem(itemObj);
      setSelectedFoodItemId(itemId);
      setShowModal(true);
    }
  };

  // empty all items selected
  const emptyCard = () => {

    emptyCardEdit();
    setBackModal(false);
    if (order_id) {
      history.push("/dashboard/pos");
    }
    if(!order_id && authUserInfo.details.user_type === "call_center" && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1 ){
      setModalOrderType(true)
    }
  };

  // item comments 
  const updateComments = (itemId, value = "", modal = false) => {
    animate.current = false;
    setCartItems((prevCartItems) => ({
      ...prevCartItems,
      [itemId]: {
        ...prevCartItems[itemId],
        comments: value,
      },
    }));
    setCommentId(null)
  }

  const convertToBase64 = (file) =>{
   return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
   })
  };

  // item image 
  const updateItemImage = async (itemId,value) => {
    animate.current = false;
    let image = "";
    if(value){
      const base64String = value && await convertToBase64(value);
      image = {
        url: base64String,
        name: value.name,
      }
    }
    setCartItems((prevCartItems) => ({
      ...prevCartItems,
      [itemId]: {
        ...prevCartItems[itemId],
        image: image,
      },
    }));
  }

  const getBeforeTaxPrice = (price) => {
    if (taxType === "inclusive") {
      return price / (1 + (theTax / 100));
    }
    return price;
  };

  const getFormattedPrice = (price) => {
    return Number(parseFloat(price).toFixed(2));
  };

  // delete property item
  const deletePrpItem = (deletedItemFromEdit) =>{
    const propertyDelete = [];
    if (
      deletedItemFromEdit.hasOwnProperty("properties") &&
      deletedItemFromEdit.properties
    ) {
        Object.values(deletedItemFromEdit.properties).forEach((itm) => {
          for (const key in itm) {
            propertyDelete.push(itm[key]);
          }
        })
    }
    return propertyDelete;
  }

  //delete cart item
  const deleteCartItem = (itemId, deleteMade) => {

    if(!buttonDisabled) {
      const newObj = { ...cartItems };
  
      const bogoDltItem = newObj[itemId].offer_type === "bogo" && (newObj[itemId].item_discount === 0);
      const bogoDlt = () =>{
        for (let key in cartItems) {
          if (
            cartItems[key].offer_type === "bogo" &&
            bogo >=
            getFormattedPrice(getBeforeTaxPrice(calculatePriceForCartVariation(cartItems[key], 1)))
          ) {
            delete newObj[key];
            setBogo(0);
          }
        }
      }
      if (order_id) {
        const deletedItemFromEdit = newObj[itemId];
        const pickedPropertyDelete = deletePrpItem(deletedItemFromEdit);
         // edit made unmade delete item
        if (newObj[itemId].new === false) {
          if (deletedItemFromEdit) {
            const updateItem = {
              ...deletedItemFromEdit,
              reason: deleteReason,
              status: deleteMade ? "made" : "unmade",
              print_status: "deleted",
              properties: pickedPropertyDelete ? pickedPropertyDelete : null,
            }
            const existingItemAdd = itemAdd && itemAdd[itemId];
            if (deletedItemsOnEdit[itemId]) {
              setDeletedItemsOnEdit((curr) => ({
                ...curr,
                [itemId]: {
                  ...updateItem,
                  quantity:
                    deletedItemsOnEdit[itemId].quantity +
                    deletedItemFromEdit.quantity,
                },
              }));
            
            } else if (
              existingItemAdd
            ) {
              setDeletedItemsOnEdit((curr) => ({
                ...curr,
                [itemId]: {
                  ...updateItem,
                  quantity:
                    deletedItemFromEdit.quantity - itemAdd[itemId].quantity,
                },
              }));
            } else {
              setDeletedItemsOnEdit((curr) => ({
                ...curr,
                [itemId]: {
                  ...updateItem,
                },
              }));
            }
            // reset
            setDeleteReason("");
          }
        }
        
        if (
          bogoDltItem &&
          !newObj[itemId].new
        ) {
          for (let key in cartItems) {
            if (
              cartItems[key].offer_type === "bogo" &&
              bogo >=
              getFormattedPrice(getBeforeTaxPrice(calculatePriceForCartVariation(cartItems[key], 1)))
                ) {
            
              if (newObj[itemId] !== cartItems[key]) {
                setDeletedItemsOnEdit((curr) => ({
                  ...curr,
                  [key]: {
                    ...cartItems[key],
                    reason: deleteReason,
                    status: deleteMade ? "made" : "unmade",
                    print_status: "deleted",
                    properties: pickedPropertyDelete
                      ? pickedPropertyDelete
                      : null,
                  },
                }));
              }
              delete newObj[key];
              setBogo(0);
            }
          }
        } else {
          if (
            newObj[itemId].new &&
            bogoDltItem
          ) {
            bogoDlt()
          }
        }
      }
      if (
        !order_id &&
        bogoDltItem
      ) {  
        // delete bogo offer
        bogoDlt()
      } else {
        delete newObj[itemId];
      }
  
      setCartItems(newObj);
    }
  };

  // - minus functionality
  const deleteOneCartItem = (itemId, status) => {
    if(!buttonDisabled) {
      const newObj = { ...cartItems };
      const deleteItem = newObj[itemId];
      Cartanimation(itemId)
      const afterTaxPrice = getFormattedPrice(
        getBeforeTaxPrice(
          calculatePriceForCartItem(deleteItem, deleteItem.quantity - 1)
        ));
      let schemePrice = afterTaxPrice;
      if(orderDetails.scheme.scheme_id && orderDetails.scheme.discount_addon === "discount_without_addon" ||
        (deleteItem.scheme_id && schemeForSearchObj[parseInt(deleteItem.scheme_id)] && schemeForSearchObj[parseInt(deleteItem.scheme_id)].discount_addon === "discount_without_addon")){
         schemePrice = getBeforeTaxPrice(calculatePriceForCartVariation(deleteItem, deleteItem.quantity - 1));
      }
     
    if((orderDetails?.scheme.scheme_id && orderDetails?.scheme.discount_tax) || (deleteItem.scheme_id && schemeForSearchObj[parseInt(deleteItem.scheme_id)] && schemeForSearchObj[parseInt(deleteItem.scheme_id)]?.discount_tax)){
      schemePrice += getTaxPrice(afterTaxPrice)
    }

      const finalObj = {
        ...newObj,
        [itemId]: {
          ...deleteItem,
          after_tax_price: afterTaxPrice,
          item_discount: getItemDiscount(
            schemePrice,
            deleteItem,
            deleteItem.quantity - 1
          ),
          quantity: deleteItem.quantity - 1,
        },
      };
      if (order_id) {
        // edit made unmade with/ without property delete item
        const pickedPropertyDelete = deletePrpItem(deleteItem);
       
        if (newObj[itemId].new === false) {
          const updatedItem = {
            ...deleteItem,
            reason:  deletedItemsOnEdit[itemId] ? deletedItemsOnEdit[itemId].reason : deleteReason,
            status: status ? "made" : "unmade",
            print_status: "deleted",
            properties: pickedPropertyDelete ? pickedPropertyDelete : null,
            
          }
          const deleteQty = deletedItemsOnEdit[itemId] ? deletedItemsOnEdit[itemId].quantity + 1 : 1;
           if (
            itemAdd &&
            itemAdd[itemId]
          ) {
            setDeletedItemsOnEdit((curr) => ({
              ...curr,
            }));
          } else {
            setDeletedItemsOnEdit((curr) => ({
              ...curr,
              [itemId]: {
                ...updatedItem,
                quantity: deleteQty,
              },
            }));
          }
          // reset
          setDeleteReason("");
        }
      }
      setCartItems(finalObj);
     } 
  };

  //get tax price
  const getTaxPrice = (price) => {
    return Number((price * (theTax / 100)).toFixed(2));
  };

  // tax cal on change tax type
  const handleTaxOnPaymentTypeChange = (payment_type) => {
    let paymentTypeTax = orderDetails.branch.payment_tax && orderDetails.branch.payment_tax[payment_type.name];
    const vat = getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after" ? (parseFloat(cartSubTotal) - parseFloat(orderDetails.scheme.discount_amount)) : parseFloat(cartSubTotal);
    const discount = orderDetails.scheme?.discount_amount ? orderDetails.scheme.discount_amount : 0
    // if payment type tax exists
    if (paymentTypeTax) {
      paymentTypeTax = parseFloat(paymentTypeTax);
      const newState = {
        subTotal: cartSubTotal,
        vat: paymentTypeTax,
        vatAmount: paymentTypeTax > 0  ? vat ? vat * (paymentTypeTax / 100) : 0 : theVat,
        serviceCharge : orderDetails.branch.service_fees,
        serviceChargeAmount: serviceCharges,
        deliveryCharges: deliveryCharges,
        discount: discount,
        totalPayable: cartTotal - theVat + vat * (paymentTypeTax / 100),
        showModal: true,
        payment_type_name: payment_type.name,
        taxCalSelect:false
      };
      setPaymentTypeModalState(newState);
    }else{
      const newState = {
        subTotal: cartSubTotal,
        vat: orderDetails.branch.branch_tax,
        vatAmount: theVat,
        serviceCharge : orderDetails.branch.service_fees,
        serviceChargeAmount: serviceCharges,
        deliveryCharges: deliveryCharges,
        discount: discount,
        totalPayable: cartTotal,
        showModal: true,
        payment_type_name: payment_type.name,
        taxCalSelect:false
      };
      setPaymentTypeModalState(newState); 
    }
  }; 

  //settle button

  const handleSettleOrderButton = (e) => {
    
    if (newOrder && newOrder.length > 0) {
      if (orderDetails && selectedDeptTag !== null) {
        if (
          orderDetails.orderTypeDetails === undefined ||
          orderDetails.orderTypeDetails === null
        ) {
          toast.error("Please select order type", toastObj);
        } else {
          
          if(parseInt(getSystemSettings(generalSettings, "customer_validation")) === 1){
            if(orderDetails.newCustomerInfo.name == "" || orderDetails.newCustomerInfo.phn_no == "" || !orderDetails.newCustomerInfo?.is_confirm){
              setModal9Show(true)
              toast.error(`Please fill customer ${orderDetails.newCustomerInfo.name == "" ? "name" : ""} ${orderDetails.newCustomerInfo.name == "" && orderDetails.newCustomerInfo.phn_no == "" ? "&" : ""} ${orderDetails.newCustomerInfo.phn_no == "" ? "phone number" : ""} & press confirm`);
              return false
            }
          }
          if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 || orderDetails.branch.fbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
            const tax = roundOffPrice(theVat);
            const total = roundOffPrice((parseFloat(cartTotal) + 1));
            setTheVatSettle(tax);
            setTotalSettle(total);
            // setPaidMoney(0);
          }else{
            const tax = roundOffPrice(theVat);
            const total = roundOffPrice(cartTotal);
            setTheVatSettle(tax);
            setTotalSettle(total);
            // setPaidMoney(0);
          }
          setShowSettle(true);
        }
      } else {
        toast.error("Please select a department tag", toastObj);
      }
    } else {
      toast.error("Please add items in order first", toastObj);
    }
  };

  //call after successful order submit and settle
  const handleOrderSubmitSuccessful = () => {
    let partyName = "";
    let phone = "";
    if(selectedParty.name.match(/food(.*)panda/i)){
       partyName = "Food Panda";
       phone = "00000000000"
    }
    let branch = null;
    let OrderType = null;
    let party = null;
    if(authUserInfo && (authUserInfo.details.user_type === "call_center") && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1 ){
      setSelectedBranch(null);
      setSelectedDeliveryType(null);
      setSelectedParty(null);
      setZoneArea(null);
      setArea(null);
      setModalOrderType(true);
      setDeliveryCharges(0);
      branch = null;
      OrderType = null;
      party = null;
    }else{
      setSelectedBranch(selectedBranch);
      setSelectedDeliveryType(selectedDeliveryType);
      setSelectedParty(selectedParty);
      if ((selectedDeliveryType && selectedDeliveryType.name.toLowerCase() === "delivery")) {
        setDeliveryCharges(
          parseFloat(getSystemSettings(generalSettings, "delivery_charges"))
        );
      } else {
        setDeliveryCharges(0);
      }
      branch = orderDetails.branch;
      OrderType = orderDetails.orderTypeDetails;
      party = orderDetails.partyDetails;
    }
 
    setNewOrder([]);
    setFoodGroupsToPrint({});
    setSelectTable({});
    setKotPrintIdx(0);
    setSelectedKot({});
    fbrInvoiceNum.current = null;
    srbInvoiceNum.current = null;
    // setSrbInvoiceNum(null);
    // setFbrInvoiceNum(null);
    setCartSubTotal(0);
    setTheVat(0);
    setCartTotal(0);
    setReturnMoneyUsd(0);
    setPaidMoney(0);
    isSettle.current = 0;
    setBogo(0);
    setGuestSelected(1);
    setServiceCharges(0);
    setOrderDetails({
      branch: branch,
      table: null,
      waiter: null,
      dept_tag: orderDetails.dept_tag,
      payment_type: null,
      card_number: "",
      card_number_settle:"",
      payment_amount: null,
      total_guest: 1,
      is_reservation: 0,
      table_id: null,
      table_name: null,
      discount_voucher: "",
      note_to_rider:"",
      order_tip: 0,
      newCustomerInfo: {
        id: null,
        name: partyName,
        email: "",
        phn_no: phone,
        address: "",
        ref_no: "",
        ntn_no: "",
        nearest_landmark: "",
        alt_phn_no:"",
        is_confirm: false,
      },
      scheme:{
        scheme_id: null,
        scheme_name: "",
        cap_amount: 0,
        discount_amount: 0,
        sharing_with_party: 0,
        open_discount: null,
        discount_type: "",
        how_much_discount: null,
        discount_addon: "",
        discount_tax: 0,
      },
      orderTypeDetails: OrderType,
      partyDetails: party,
      token: null,
      order_No: null,
      serviceCharge: 0,
      discount: 0,
      service_amount: 0,
    });
    setCartItems({});
    order_hold.current = 0;
    // settle order settle reset
    setShowSettle(false);
    setTotalSettle(0);
    setTheVatSettle(0);
    setPaidMoney(0);
    setSearchInput("");
    if (inputSearch.current !== undefined) inputSearch.current.value = "";
    if (navigator.onLine) {
      toast.success(`${_t(t("Order has been taken"))}`, toastObj);
    }
   
    setShowSettle(false);
    setLoading(false);
    setUserLock([]);
    setManagerId(null);
    setSearchedFoodItem({ list: null, searched: false })
    if (
      selectedDeliveryType &&
      selectedDeliveryType.name.match(/dine(.*)in/i)
    ) {
      getTable();
      getWaiter();
    }

    //set token
    if(networkStatus && parseInt(authUserInfo.details?.offline_enable) !== 1){
      localStorage.setItem("order_id", newSettings.workPeriod.token);
    }else{
      if (localStorage.getItem("order_id")) {
        localStorage.setItem("order_id", parseInt(localStorage.getItem("order_id")) + 1);
      } else {
        localStorage.setItem("order_id", newSettings.workPeriod.token);
      }
    }

    if(authUserInfo.details.user_type === "call_center"){
      setCallCenterPayment({
        payment_type:"Cash",
        transaction_no:"",
        transactionModal: false,
      });
    }
    //sound
    if (!order_id) {
      if (getSystemSettings(generalSettings, "play_sound") === "1") {
        let beep = document.getElementById("myAudio");
        beep.play();
      }
    } else {
      history.push("/dashboard/pos/submitted");
    }
    setButtonDisabled(false);
  };
  
  const isLocked = (type = "") => {
    console.log("typetype", type, userLock,);
    // getSystemSettings(generalSettings, "offers_lock_status") == 1 &&
    return (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "waiter") &&
      (authUserInfo.details.user_passcode || managerPasscode) &&
      !userLock.includes(type)
      ? true
      : false;
  };
  // handle auth user
  const authenticateStaffUser = (componentType) => {
    console.log("componentType", componentType, userLock);
    if (!userLock.includes(componentType)) {
      setShowPasswordPopup(true);
      setComponentUnlock(componentType);
    }
  };
  const hanleCancelAuthenticate = () => {
    setShowPasswordPopup(false);
    setComponentUnlock("");
  };
  const onSuccessAuthenticationStaff = () => {
    setShowPasswordPopup(false);
    setUserLock([...userLock, componentUnlock]);
  };

  //after order submit or settle
  //All Receipt Control from this handle
  const getCurrentSelectedKots = useRef();
  getCurrentSelectedKots.current = selectedKot;
  const getCurrentKotPrintIndex = useRef();
  getCurrentKotPrintIndex.current = kotPrintIdx;

  const handleRemoveItemFromOrderListOnEdit = (deleteMade, index) => {
    setAddReasonModalShowing(null);
    deleteCartItem(index, deleteMade);
  };

  const handleOrderItemQtyOnEdit = (id, status, action, activeitem) => {
    if (!(id in deletedItemsOnEdit) && status === null)
      setAddReasonModalShowing({
        action: "decrease",
        params: [action, activeitem, id],
      });
    else deleteOneCartItem(id, status);
  };

  const addReasonOnSubmit = (status) => {
    if (!addReasonModalShowing || !addReasonModalShowing.action) return;
    if (!deleteReason) {
      toast.error("Reason cannot be empty.", toastObj);
      return;
    }
    if (addReasonModalShowing.action === "remove") {
      setAddReasonModalShowing(null);
      return handleRemoveItemFromOrderListOnEdit(
        status,
        ...addReasonModalShowing.params
      );
    }
    if (addReasonModalShowing.action === "decrease") {
      setAddReasonModalShowing(null);
      return handleOrderItemQtyOnEdit(...addReasonModalShowing.params, status);
    }
  };
  
  // kot for kitchen
  const handleKotItems = () => {
    const kotObj = { ...selectedKot };
    
    let orderItems = [...newOrder];
    // add  new and deleted items on edit
    if (order_id) {
      const addedItems = [];
      orderItems.forEach((item) => {
        if (item.new && item.new) {
          addedItems.push({
            ...item,
            print_status: "added",
          });
        }
      });
      orderItems = [
        ...Object.values(deletedItemsOnEdit),
        ...addedItems,
        ...Object.values(itemAdd),
      ];
    }
    
    //kotObj[orderDetails.branch.consolidated_ip] = { ...orderItems };
 
    orderItems.forEach((obj) => {
      const kotKey = obj.item.kot
        ? obj.item.kot === "No"
          ? obj.item.kot
          : obj.item.kot_ip
        : "No";
      const group = foodGroupById[obj.item.food_groups[0]];
      const groupKey = group ? group.name : "None";
      const consolidatedKot = orderDetails.branch.consolidated_kot === "true" || orderDetails.branch.consolidated_kot === 1;
      const branchKot = orderDetails.branch.consolidated_ip;

      // branch wise consolidated kot
      if(consolidatedKot){
        if(
          kotObj.hasOwnProperty(branchKot) &&
          kotObj[branchKot] &&
          Object.keys(kotObj[branchKot])?.length
        ){
          if (
            kotObj[branchKot].hasOwnProperty(groupKey) &&
            kotObj[branchKot][groupKey] &&
            kotObj[branchKot][groupKey]?.length
          ){
            kotObj[branchKot] = {
              ...kotObj[branchKot],
              [groupKey]: [...kotObj[branchKot][groupKey], { ...obj }],
            };
          }else{
            kotObj[branchKot] = {
              ...kotObj[branchKot],
              [groupKey]: [{ ...obj }],
           }
          }          
         }else{
            kotObj[branchKot] = {
              // ...kotObj[branchKot],
              [groupKey]: [{ ...obj }],
            };
         }
      }

     if(obj.item.is_special == "1" && obj?.properties){
      obj.properties.forEach((prop)=>{
        const kotProp = prop.item?.kot_ip
        ? prop.item.kot_ip : "No";
        if (
          kotObj[kotProp] &&
          kotObj.hasOwnProperty(kotProp) &&
          Object.keys(kotObj[kotProp])?.length
        ) {
          if (
            kotObj[kotProp].hasOwnProperty(groupKey) &&
            kotObj[kotProp][groupKey] &&
            kotObj[kotProp][groupKey]?.length
          ){
            kotObj[kotProp] = {
              ...kotObj[kotProp],
              [groupKey]: [...kotObj[kotProp][groupKey], { ...obj , property: {...prop} }],
            };
          
          }else{
            kotObj[kotProp] = {
              ...kotObj[kotProp],
              [groupKey]: [{ ...obj , property: {...prop}  }],
            };
          }
        } else {
          kotObj[kotProp] = {
            [groupKey]: [{ ...obj , property: {...prop} }],
          };
        }
      });
     }else{
       // item wise kot
       if (
         kotObj.hasOwnProperty(kotKey) &&
         kotObj[kotKey] &&
         Object.keys(kotObj[kotKey])?.length
        ) {
          if (
            kotObj[kotKey].hasOwnProperty(groupKey) &&
            kotObj[kotKey][groupKey] &&
            kotObj[kotKey][groupKey]?.length
          ){
                      
            kotObj[kotKey] = {
              ...kotObj[kotKey],
              [groupKey]: [...kotObj[kotKey][groupKey], { ...obj }],
            };
            
          }else{
              
            kotObj[kotKey] = {
              ...kotObj[kotKey],
              [groupKey]: [{ ...obj }],
            };
          }
        } else {
          // let prop1 = {};
          // if(obj.item.has_property == "1" && obj.properties?.length > 0){
          //   obj.item.property_groups.map((propertyGrpName)=>{
          //     let theGroup =
          //     propertyGroupForSearch &&
          //     propertyGroupForSearch.find(
          //       (theItem) => {
          //         return propertyGrpName === (theItem?.id) ;
          //       }
          //     );
          //     let propertWithGroup = []
          //     obj.properties.forEach(prop => {
          //       const propertyGroupIds = JSON.parse(prop.item.property_group_id);
          //       if(propertyGroupIds.includes(propertyGrpName)){
          //          propertWithGroup.push(prop)
          //       }
          //     });
          //     if(propertWithGroup && propertWithGroup?.length > 0){
          //       prop1[theGroup.name] = propertWithGroup
          //     }
               
          //     console.log("abcd44",prop1,propertWithGroup)
          //   })
          // }   
          kotObj[kotKey] = {
            [groupKey]: [{ ...obj }],
          };
        }
      }
    });
    setSelectedKot(kotObj);
    return kotObj;
  };
  
  //for Customer & kithcen
  const handlePrintAll = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        let kots = getCurrentSelectedKots.current;
        if (kots && Object.keys(kots)?.length) {
          setNextKotPrint();
        } else {
          handleOrderSubmitSuccessful();
        }
      } else {
        handleOrderSubmitSuccessful();
      }
    },
  });

  /* KOT PRINTING FUNCTION */
  const setNextKotPrint = () => {
    let currentKotIdx = getCurrentKotPrintIndex.current;
    let allKots = getCurrentSelectedKots.current;
    setKotPrintIdx((curr) => curr + 1);
    if (currentKotIdx === Object.keys(allKots).length) {
      handleOrderSubmitSuccessful();
    } else {
      const newNewOrder = Object.values(allKots)[currentKotIdx];
      if(Object.keys(allKots)[currentKotIdx] == orderDetails.branch.consolidated_ip){
        con_Kot.current = "Consolidated Kot"
      }else{
        con_Kot.current = ""
      }
      
      setFoodGroupsToPrint(newNewOrder);
      handlePrintKitchen2();
    }
  };

  const handlePrintKitchen2 = useReactToPrint({
    content: () => component2Ref.current,
    onAfterPrint: () => {
      setNextKotPrint();
    },
  });

  const handleReceiptPrint = () => {
    switch (getSystemSettings(generalSettings, "print_pos_setting")) {
      case "Customer":
        handlePrintCustomer();
        break;

      case "Kitchen":
        setNextKotPrint();
        break;

      default:
        handlePrintAll();
        break;
    }
  };

  //for Customer
  const handlePrintCustomer = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      handleOrderSubmitSuccessful();
    },
  });

  const axiosRequest = async() => {
    let api = order_id
      ? "/settings/order-update"
      : authUserInfo.details.user_type === "call_center"
      ? "/settings/new-callcenter-order"
      : "/settings/new-order";

    const url = getOfflineApiUrl(api);
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let formData = getOrderObject(localCurrency);
    setLoading(true);
    networkStatus = await checkNetworkStatus();
    createOrUpdateOrder(url, formData);
  };
  
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  // save order to local when offline
  const saveOrderToLocal = (formData) => {
    formData.local = true;
    let localData = getDataFromLocal("orderplaced");
    if (localData != null) {
      localData.push(formData);
      setDataToLocal("orderplaced", localData);
    } else {
      let data = [];
      data.push(formData);
      setDataToLocal("orderplaced", data);
    }
    setLoading(false);
  };
  // form data for direct print to printer

  const formDataReturn = () => {
    const refNo = orderDetails.newCustomerInfo?.ref_no ? orderDetails.newCustomerInfo.ref_no : "";
    const taxAmount =  (isSettle.current === 1 && theVatSettle > 0) ? theVatSettle : theVat;
    const totalAmount =  (isSettle.current === 1 && totalsettle > 0) ? totalsettle : cartTotal;
    const returnAmount = (Number(paidMoney) - Number(totalAmount)) > 0 ? (Number(paidMoney) - Number(totalAmount)).toFixed(2) : 0;
    //cash card tax wise total 
    const [cardAmount, cardPercent] = totalPriceWithTax(totalAmount,cartSubTotal, taxAmount, orderDetails.scheme.discount_amount, orderDetails.branch, "card");
    const [cashAmount, cashPercent] = totalPriceWithTax(totalAmount,cartSubTotal, taxAmount, orderDetails.scheme.discount_amount, orderDetails.branch, "cash");

    return {
      site_name: getSystemSettings(generalSettings, "siteName"),
      branch: orderDetails.branch,
      type_print_heading: getSystemSettings(
        generalSettings,
        "type_print_heading"
      ),
      party_name:
        orderDetails.orderTypeDetails.orderTypeName +
        "-" +
        orderDetails.partyDetails.partyName,
      partyDetails: orderDetails.partyDetails,  
      guest: orderDetails && orderDetails.total_guest,
      isSettle: isSettle.current,
      table: orderDetails.table,
      waiter: orderDetails.waiter,
      dept_tag: selectedDeptTag,
      payment_type: orderDetails.payment_type,
      payment_amount: orderDetails.payment_amount,
      card_number: orderDetails.card_number,
      newCustomerInfo: orderDetails.newCustomerInfo,
      token: token_No.current,
      total_guest: orderDetails.total_guest,
      table_id: orderDetails.table_id,
      table_name: orderDetails.table_name,
      discount_voucher: orderDetails?.discount_voucher,
      tax_type: taxType,
      rider_name: "",
      rider_notes: orderDetails.note_to_rider ? orderDetails.note_to_rider : "",
      //customer print
      deliveryIp: orderDetails.branch.customer_counter_ip != null && orderDetails.branch.customer_counter_ip !== "" ? orderDetails.branch.customer_counter_ip : null,
      customerIp: orderDetails.branch.kitchen_printer_ip != null && JSON.parse(orderDetails.branch.kitchen_printer_ip)?.[authUserInfo.details.id] ? JSON.parse(orderDetails.branch.kitchen_printer_ip)[authUserInfo.details.id] : null,
      is_online : 0,
      customerOrder: newOrder,
      orderItems:
        !Object.keys(selectedKot).length === 0 ? selectedKot : handleKotItems(),
      serviceCharge: deliveryCharges,
      service_amount: serviceCharges,
      order_tip: orderDetails.order_tip,
      discount: orderDetails.scheme.discount_amount,
      scheme: orderDetails.scheme,
      subTotal: cartSubTotal,
      totalPayable: totalAmount,
      view_cash_card: parseInt(getSystemSettings(generalSettings,"view_cash_card")),
      cash: cashAmount,
      card: cardAmount,
      cash_percent: cashPercent,
      card_percent: cardPercent,
      paidMoney: paidMoney,
      theVat: taxAmount,
      tax_percent : taxPercent(cartSubTotal,taxAmount,orderDetails.scheme.discount_amount),
      print_tax : orderDetails.branch?.print_tax,
      dept_commission: deptCommission,
      workPeriod: newSettings.workPeriod,
      order_id: order_id ? order_id : null,
      orderTypeDetails: orderDetails.orderTypeDetails,
      orderFoodGroups: null,
      propertyGroupForSearch: propertyGroupForSearch,
      RefNo: orderDetails &&
        orderDetails.orderTypeDetails &&
        orderDetails.partyDetails.partyName +
          " - ref no :" +
          refNo,
      return_amount: returnAmount,
      print_footer: getSystemSettings(generalSettings, "type_print_footer"),
      print_bill_footer: getSystemSettings(generalSettings, "print_bill_footer"),
      user_name: authUserInfo && authUserInfo.details && authUserInfo.details.name,
      fbr_num: fbrInvoiceNum.current,
      srb_num: srbInvoiceNum.current,
    };
  };

  //table transfer print
  const handleLocalTablePrint = (formData) => {
    const params = new URLSearchParams(history.location?.search);
    const tableName = params.get("table");
    formData.old_table = tableName; 
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post("http://localhost:8081/example-app/api/table", formData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        if (order_id) {
          history.push("/dashboard/pos/submitted");
          setButtonDisabled(false);
        } else handleOrderSubmitSuccessful();
      })
      .catch((err) => {
        if (order_id) {
          history.push("/dashboard/pos/submitted");
          setButtonDisabled(false);
        }
      });
  };

  const handleLocalPrint = (local = 0) => {
    let formData = formDataReturn();
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      // timeout: 4000,
    };

    axios.post(`http://localhost:8081/example-app/api/test`,formData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        if (order_id) {
          if(history.location?.search?.startsWith("?table=")){
            handleLocalTablePrint(formData)
          }else{
            history.push("/dashboard/pos/submitted");
          }
          setButtonDisabled(false);
        } else handleOrderSubmitSuccessful();
      })
      .catch((err) => {
        if (!local) {
          if (authUserInfo.details.user_type === "call_center") {
            setNextKotPrint();
          } else {
            handleReceiptPrint();
            if(order_id && history.location?.search?.startsWith("?table=")){
              handleLocalTablePrint(formData)
            }
          }
        } else if (order_id) {
          history.push("/dashboard/pos/submitted");
          setButtonDisabled(false);
        }
      });
  };

  const createOrUpdateOrder = async(url, formData) => {

    if(parseInt(authUserInfo.details?.offline_enable) === 1 && authUserInfo.details.user_type !== "call_center"){
      formData.offline_status = 0;
      const offlineData = await handleSubmitOffline(formData,url);
      if(offlineData){
        let newRes = {};
        let workperiod = [];
        workperiod.push(workPeriodListForBranch);
        newRes.data = workperiod;   
        handleCreateOrUpdateOrder(newRes);
      }else{
        setButtonDisabled(false)
      }
      setLoading(false);
    }else{
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {    
          if(res.data === "exists"){
            toast.error(`${_t(_t("Order already submit, You have click submit button again & again"))}`, toastObj);
            setSelectedKot({});
            setButtonDisabled(false);
            setLoading(false);
            return false
          }    
          if (formData.local) {
            handleCreateOrUpdateOrder(res, formData.local);
          } else {
            handleCreateOrUpdateOrder(res);
          }
          setLoading(false);
        
        })
        .catch((error) => {
          if(parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1 && error.response.data?.message !== ""){
            toast.error(<p style={{whiteSpace:"pre-line"}}>{error.response.data.message}</p>, toastObj);
            setSelectedKot({});
            setButtonDisabled(false);
            setLoading(false);
            return false
          }  
            setFoodGroupsToPrint({});
            setKotPrintIdx(0);
            setSelectedKot({});
            let data = [];
            data.push(workPeriodListForBranch);
            //set work period
            let theWorkPeriod = null;
            if (
              orderDetails.branch !== undefined &&
              orderDetails.branch !== null &&
              (authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter" )
            ) {
              theWorkPeriod =
                data[0] &&
                data[0].find((tempWorkPeriod) => {
                  return (
                    (orderDetails.branch.id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null
                  );
                });
            } else {
              theWorkPeriod = data && data[0][0];
            }
            setLoading(false);
            setTimeout(() => {
              setNewSettings({
                ...newSettings,
                vat: orderDetails.branch.branch_tax,
                workPeriod:
                theWorkPeriod !== undefined ? theWorkPeriod : null,
              });
            }, 1000);
            if(error.response?.data?.status == 1){
            toast.error(`${_t(_t(error.response.data?.message))}`, toastObj);
          }else if(networkStatus){
              toast.error(`${_t(_t("Please contact support"))}`, toastObj);
            }else{
              toast.error(`${_t(_t("Please check your internet connection"))}`, toastObj);
            }
            setButtonDisabled(false);
        });
    }
  };

  const handleCreateOrUpdateOrder = (res, local = false) => {

    if(syncSettle.current === true){
      isSettle.current = 1;
      fbrInvoiceNum.current = res.data[2];
      srbInvoiceNum.current = res.data[3];
      // setFbrInvoiceNum(res.data[2]);
      // setSrbInvoiceNum(res.data[3]);
    }else{
      isSettle.current = 0;
    }
    
    if (res.data !== "ended") {
      if(local == false && !order_id){
        let formatTime = moment(res.data[3]).format('DD-MMM-YYYY hh:mm A');
        token_No.current = parseInt(getSystemSettings(generalSettings, "order_no")) === 1 ? {id : res.data[2] ? res.data[2] : orderDetails.token?.id, time:formatTime} :  {id: res.data[1] ? res.data[1] : orderDetails.token?.id, time:formatTime};
        setOrderDetails({
          ...orderDetails,
          token: {
            ...orderDetails.token,
            id: res.data[1] ? res.data[1] : orderDetails.token?.id,
          },
          order_No: res.data[2] ? res.data[2] : orderDetails.token?.id,
        })
      }
      // setWorkPeriodList(res.data[0][0]);
      setWorkPeriodListForBranch(res.data[0]);
      const directPrint = parseInt(getSystemSettings(generalSettings, "direct_ip_print")) === 1;
      // print on submit
      if (authUserInfo.details.user_type !== "call_center" && !order_hold.current) {
        if(res.data[0] && local == false){
          directPrint ? handleLocalPrint(local) : handleReceiptPrint();
        }
      } else {
        if (
          getSystemSettings(generalSettings, "print_callcenter_kot") === "1" && !order_hold.current
        ) {
          if(res.data[0] && local == false){
            directPrint ? handleLocalPrint(local) : setNextKotPrint();
          }
        } else if(local == false){
          res.data[0] && handleOrderSubmitSuccessful();
        }
      }
      
      //set work period
      let theWorkPeriod = null;
      if (
        orderDetails.branch !== undefined &&
        orderDetails.branch !== null &&
        (authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter")
      ) {
        theWorkPeriod =
          res.data[0] &&
          res.data[0].find((tempWorkPeriod) => {
            return (
              orderDetails.branch.id === parseInt(tempWorkPeriod.branch_id)
            );
          });
      } else {
        theWorkPeriod = res.data[0][0];
      }
      
      //set work period here
      if(authUserInfo && (authUserInfo.details.user_type === "call_center") && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1){
        setNewSettings({
          ...newSettings,
          workPeriod: null
        })
      }else{
        setNewSettings({
          ...newSettings,
          vat: orderDetails.branch.branch_tax,
          workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
        });
      }

      setTheTax(orderDetails.branch.branch_tax);

      getRunningOrders();
      setLoading(false);

      //check sales limit target
      // if(authUserInfo.details.user_type === "staff" && authUserInfo?.details?.sales_limit > 0){
      //   if(res?.data[4] > authUserInfo?.details?.sales_limit){
      //     toast.success('Congratulations🎉, you have achieved your target!', {
      //       position: "top-left",
      //       autoClose: 7000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       hideProgressBar: true,
      //       // draggable: true,
      //       // progress: undefined,
      //       transition: Flip,
      //       style:{backgroundColor:"#ed8153"}
      //       });
      //   }
      // }
    } else {
      toast.error(`${_t(_t("Please restart the work period"))}`, toastObj);
      setLoading(false);
      setButtonDisabled(false);
    }
  };

  const tokenNumber = () => {
    if (!orderDetails.token?.id || typeof(orderDetails.token) !== "object") {
      let tokenTemp;

      if (localStorage.getItem("order_id")) {
        tokenTemp = parseInt(localStorage.getItem("order_id")) + 1;
      } else {
        tokenTemp = newSettings.workPeriod.token;
      }

     let userWiseToken = getSystemSettings(generalSettings, "user_wise_token") == 1;
      setOrderDetails({
        //set token here on first order item add,
        ...orderDetails,
        token: {
          time: new Date().getTime(),
          id: userWiseToken ? (
            authUserInfo?.details &&
            authUserInfo.details?.name?.charAt(0).toUpperCase() +
              authUserInfo.details?.id +
              "-" +
              tokenTemp) : tokenTemp, //token#
          prefix : userWiseToken ? ( authUserInfo?.details && authUserInfo.details?.name?.charAt(0).toUpperCase() +
                   authUserInfo.details?.id + "-" ) : "",
        },
      });

     } 
  };

  const handleSubmitOrder = (e) => {
    e.preventDefault();
     
    if (authUserInfo.details.user_type === "call_center" && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1 && zoneArea && zoneArea?.minimum > 0 && (parseFloat(cartTotal) - parseFloat(deliveryCharges)) < zoneArea?.minimum) {
      toast.error(`${_t(t(`Minimum order for this zone is ${zoneArea.minimum}`))}`, toastObj);
      return false; 
    }

    if(authUserInfo.details.user_type === "call_center"){
      if(callCenterPayment.payment_type === "Online" && !callCenterPayment.transaction_no){
        toast.error(`${_t(_t("Please enter transaction id"))}`, toastObj);
        setCallCenterPayment({
          ...callCenterPayment,
          transactionModal: true
        })
        return false;
      }
    }
    

    if(authUserInfo.details.user_type === "call_center"){
      if(orderDetails.newCustomerInfo.name == "" && !orderDetails.newCustomerInfo?.is_confirm){
        setModal9Show(true);
        toast.error(`Please ${orderDetails.newCustomerInfo.name == "" ? "fill customer name &" : ""} press confirm`);
        return false;
      }
    }
  
    if (!orderDetails.token?.id || typeof(orderDetails.token) !== "object") {
      tokenNumber();
      return false;
    }

    
    if (newOrder && newOrder.length > 0) {
         if (
        orderDetails.orderTypeDetails === undefined ||
        orderDetails.orderTypeDetails === null
      ) {
        toast.error(`${_t(_t("Submit order to select order type"))}`, toastObj);
        return false;
      }

      if ( selectedDeliveryType &&
        selectedDeliveryType?.name?.match(/dine(.*)in/i) &&
        parseInt(getSystemSettings(generalSettings, "table_waiter")) === 1 &&
        (orderDetails?.table_id === null ||
          (orderDetails?.table_id && orderDetails.table_id?.length === 0))
      ) {
        toast.error(`${_t(t("Please confirm table first"))}`, toastObj);
        setModal10Show(true);
        return false;
      }

      if(parseInt(getSystemSettings(generalSettings, "customer_validation")) === 1){
        if(orderDetails.newCustomerInfo.name == "" ||  orderDetails.newCustomerInfo.phn_no == "" || !orderDetails.newCustomerInfo?.is_confirm){
          setModal9Show(true);
          toast.error(`Please fill customer ${orderDetails.newCustomerInfo.name == "" ? "name" : ""} ${ orderDetails.newCustomerInfo.name == "" && orderDetails.newCustomerInfo.phn_no == "" ? "&" : ""} ${orderDetails.newCustomerInfo.phn_no == "" ? "phone number" : ""} & press confirm`);
          return false;
        }
      } 

      if (
        // authUserInfo.details.user_type === "call_center" &&
        selectedParty && selectedParty?.name.match(/food(.*)panda/i) &&
        parseInt(selectedParty.show_ref_no) === 1 &&
        orderDetails.newCustomerInfo.ref_no?.length < selectedParty.ref_min &&
        !order_id
      ) {
        toast.error(
          `${_t(t(`Please enter ref no between ${selectedParty.ref_min}-${selectedParty.ref_max} digits`))}`,
          toastObj
        );
        return false;
      }


      if (isNaN(cartTotal)) {
        toast.error(`${_t(_t("Total amount can't be NaN"))}`, toastObj);
        return false;
      }

      if (paidMoney !== 0) {
        toast.error(
          `${_t(
            _t("Settle order to add payments, or remove payment's amount")
          )}`,
          toastObj
        );
      } else {
        setButtonDisabled(true);
        axiosRequest();
      }
    } else {
      toast.error(`${_t(_t("Please add items in order list"))}`, toastObj);
      setButtonDisabled(false);
    }
  };

  const getOrderObject = (localCurrency) => {
    // add one extra field in formdata if callcenter
    let partyFinal = {
      partyId: selectedParty && selectedParty.id,
      partyName: selectedParty && selectedParty.name,
      partyPercent: selectedParty && selectedParty.receivable_amount,
      partyRefNo: selectedParty && selectedParty.show_ref_no,
      associate_with_payment_type: selectedParty && selectedParty.associate_with_payment_type
    };

    let orderTypeFinal = {
      orderTypeId: selectedDeliveryType && selectedDeliveryType.id,
      orderTypeName: selectedDeliveryType && selectedDeliveryType.name,
    };

    if (
      (!orderTypeFinal && !orderTypeFinal?.orderTypeId) ||
      (!partyFinal && !partyFinal?.partyId)
    ) {
      let error = "";
      if (
        !selectedParty ||
        !selectedParty.id ||
        (!partyFinal && !partyFinal.partyId)
      )
        error = "party";
      if (
        !selectedDeliveryType ||
        !selectedDeliveryType.id ||
        (!orderTypeFinal && !orderTypeFinal?.orderTypeId)
      )
        error = "delivery type";
      if (error) {
        toast.error(`Please select ${error} first.`);
        return false;
      }
    }

    let formData = {
      type: "submit",
      branch: orderDetails.branch,
      table: orderDetails.table,
      table_id: orderDetails.table_id,
      table_name: orderDetails.table_name,
      waiter_id: orderDetails.waiter ? orderDetails.waiter.id : "",
      waiter_name: orderDetails.waiter ? orderDetails.waiter.name : "",
      dept_tag_id: selectedDeptTag?.id,
      dept_tag_name: selectedDeptTag?.name,
      card_number: orderDetails.card_number,
      ...(authUserInfo.details.user_type !== "call_center" && {
          manager_id: managerId ? managerId : null,
        }
       ),
      ...(authUserInfo.details.user_type === "call_center" && {
          payment_method: callCenterPayment.payment_type,
          transaction_no: callCenterPayment.transaction_no,
          foodPanda_ref_no : orderDetails.newCustomerInfo.ref_no,
         }
      ),
      payment_type: orderDetails.payment_type,
      payment_amount: orderDetails.payment_amount,
      customerInfo: orderDetails.newCustomerInfo,
      note_to_rider: orderDetails.note_to_rider,
      token: orderDetails.token,
      total_guest: orderDetails.total_guest,
      discount: orderDetails.discount,
      orderItems: newOrder,
      ...(order_id && {delete_items: Object.values(deletedItemsOnEdit)}),
      serviceCharge: deliveryCharges,
      is_hold: order_hold.current ? 1 : 0,
      scheme: orderDetails.scheme,
      subTotal: cartSubTotal,
      totalPayable: cartTotal,
      paidMoney: paidMoney,
      theVat: theVat,
      tax_percent: theTax > 0 ? theTax : orderDetails.branch.branch_tax,
      service_amount: serviceCharges,
      dept_commission: deptCommission,
      localCurrency: localCurrency,
      workPeriod: newSettings.workPeriod,
      order_id: order_id ? order_id : null,
      orderTypeDetails: orderTypeFinal?.orderTypeId
        ? orderTypeFinal
        : orderDetails.orderTypeDetails,
      partyDetails: partyFinal?.partyId
        ? partyFinal
        : orderDetails.partyDetails,
    };

    handleKotItems();

    return formData;

    // newOrder.map((key, value) => {
    //   // console.log("2866items", value, key);
    //   selectedKot[key.item.kot] = key;
    //   setSelectedKot(selectedKot);
    // });
    // console.log("2866items", selectedKot);
  };

  //set branch on edit
  const handleSetBranchOnEdit = (branch) => {
    setLoading(true);
    if (parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1) {
      fetchStock(branch);
    }
    setSelectedBranch(branch);
    setLoading(false);
  };
  
  const handleSetBranch = (branch) => {
    setLoading(true);
    if (parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1) {
      fetchStock(branch);
    }
    if(authUserInfo.details.user_type === "call_center"){
      workPeriodStatus(branch.id);
    }
    let theWorkPeriod = null;
    theWorkPeriod = workPeriodListForBranch &&
     workPeriodListForBranch.find((tempWorkPeriod) => {
        return (branch.id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null;
      });
    setCashDetails({
      ...cashDetails,
      branch_id: branch.id,
      branch_name: branch?.name,
      work_period_id: branch?.id,
    });
    setNewSettings({
      ...newSettings,
      workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
      vat: branch.branch_tax,
    });
 
    if (!order_id && authUserInfo.details.user_type !== "call_center") {
      setSelectedDeliveryType();
      setSelectedParty();
    }
    setOrderDetails({
      ...orderDetails,
      branch: branch,
    });
    setSelectedBranch(branch);
    setLoading(false);
  };

  //search food here
  // const inputsearch = useRef();
  // inputsearch.current = searchInput;
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    let searchinput = e.target.value;
    if (searchinput.length === 0) {
      setSearchedFoodItem({ list: null, searched: false });
    } else {
      const deptFood =
        foodForPOS &&
        foodForPOS?.filter((item) => {
          const lowerCaseItemName = item?.name && item.name.toLowerCase();
          const sku = item?.sku && item.sku.toLowerCase();
          return (
            (lowerCaseItemName.includes(searchinput.toLowerCase()) ||
            (sku && sku.includes(searchinput.toLowerCase()))) &&
            JSON.parse(item.menu_ids).includes(selectedDeptTag?.id) && 
            item.food_group_status
          );
        });
      // for (const key in menu[selectedDeptTag?.id]) {
      //   deptFood.push(...menu[selectedDeptTag?.id][key]);
      // }
      // let searchedList = deptFood && deptFood.filter((item) => {
      //   let lowerCaseItemName = item.name.toLowerCase();
      //   return lowerCaseItemName.includes(searchinput);
      // });

      setSearchedFoodItem({
        ...searchedFoodItem,
        list: deptFood,
        searched: true,
      });
    }
  };

  //show price of each item in order list without discount
  // const showPriceOfEachOrderItemWithoutDiscount = (itemIndex) => {
  //   if (newOrder) {
  //     let price = 0;
  //     let dis = 0;
  //     let inclusive_vat = 0;
  //     let after_tax_price = 0;
  //     let orderItem = newOrder[itemIndex];
  //     //check price * quantity (variation price / item price)
  //     if (parseInt(orderItem.item.has_variation) === 1) {
  //       price = parseFloat(orderItem.variation.food_with_variation_price);
  //     } else {
  //       price = parseFloat(orderItem.item.price);
  //     }
  //     //calculate total price of properties
  //     if (orderItem.properties) {
  //       if (orderItem.properties.length > 0) {
  //         orderItem.properties.map((getEachItemPrice) => {
  //           let totalPropertyPrice =
  //             parseFloat(getEachItemPrice.item.extra_price) *
  //             getEachItemPrice.quantity;
  //           price = price + totalPropertyPrice;
  //         });
  //       }
  //     }

  //     if (getSystemSettings(generalSettings, "tax_type") === "inclusive") {
  //       if (newSettings) {
  //         inclusive_vat = (parseFloat(newSettings.vat) + 100) / 100;
  //         after_tax_price = price / inclusive_vat;
  //         inclusive_vat = (price - after_tax_price) * orderItem.quantity;
  //         price = after_tax_price;
  //       }
  //     }
  //     let formattedPrice = formatPrice(price * orderItem.quantity);
  //     return formattedPrice;
  //   }
  // };

  //show price of each item in print
  // const showPriceOfEachOrderItemPrint = (itemIndex) => {
  //   if (newOrder) {
  //     let price = 0;
  //     let dis = 0;
  //     let dis_amount = 0;

  //     let orderItem = newOrder[itemIndex];

  //     if (orderItem.discount !== undefined) {
  //       dis = parseFloat(orderItem.discount);
  //     } else {
  //       dis = 0;
  //     }

  //     //check price * quantity (variation price / item price)
  //     if (parseInt(orderItem.item.has_variation) === 1) {
  //       const tempPrice = parseFloat(
  //         orderItem.variation.food_with_variation_price
  //       );
  //       price = getFormattedPrice(getBeforeTaxPrice(tempPrice));
  //     } else {
  //       const temprice = parseFloat(orderItem.item.price);
  //       price = getFormattedPrice(getBeforeTaxPrice(temprice));
  //     }

  //     let formattedPrice = 0;
  //     if (orderItem.discount_type === "percentage") {
  //       dis_amount = (price / 100) * dis;
  //       formattedPrice = formatPrice(
  //         price * orderItem.quantity - dis_amount * orderItem.quantity
  //       );
  //     } else {
  //       formattedPrice = formatPrice(
  //         price * orderItem.quantity - dis * orderItem.quantity
  //       );
  //     }

  //     //let formattedPrice = formatPrice(price * orderItem.quantity - 10);
  //     return formattedPrice;
  //   }
  // };

  return (
    <>
      <div
        className={`pos--main-container bg-white row m-0 p-0 ${!showSettle ? "" : "d-none"}`}
      >
      <div className={`${(buttonDisabled) && "loadingBlur"}`}></div>
      <div className={`${(buttonDisabled) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      
        {/* category sidebar */}
        <div className="category-box mt-0 bg-white col col-2 d-flex flex-column px-0 py-2 mh-inherit shadow">
          <PosSelect
            isSearchable={false}
            options={availableDeptTags && availableDeptTags}
            value={selectedDeptTag}
            getOptionLabel={(a) => a.name}
            getOptionValue={(a) => a.name}
            placeholder="Select Menu"
            className="mt-2 mb-2 mx-2"
            onChange={(e) => {
              if (order_id) {
                toast.error(
                  "Can't change order type/party on edit complete the current order"
                );
              } else {
                setSelectedDeptTag(e);
              }
            }}
          />
          <div className="pos--left-inner-addon mx-2">
            <input
              type="search"
              className="form-control pr-4"
              placeholder="Search"
              width="100"
              ref={inputSearch}
              value={searchInput}
              onChange={(e) => handleSearch(e)}
            />
            <i
              className="fa fa-search search pt-2"
              onClick={() => {
                setSearchedFoodItem({ list: null, searched: false });
                setSearchInput("");
                if (inputSearch.current !== undefined)
                  inputSearch.current.value = "";
              }}
            ></i>
          </div>
          <div className="flex-grow-1" data-simplebar>
            <div className="hover_category mb-4">
              <div className="pos--category-nav d-flex flex-column">
                {selectedDeptTag && Object.keys(availableFoodGroups).length ? (
                  availableFoodGroups[selectedDeptTag.id]?.map((foodGroup) => (
                    <span
                      key={foodGroup.id}
                      className={`pos--category-nav-item d-flex font-weight-bold rounded-05 ${
                        activeFoodGroup === foodGroup.id ? "active" : ""
                      }`}
                      onClick={() => handleFoodGroup(foodGroup)}
                    >
                      <div>{foodGroup.name}</div>
                      {activeFoodGroup === foodGroup.id ? (
                        <div>
                          <i class="fa fa-arrow-right ml-1"></i>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </span>
                  ))
                ) : (
                  <span></span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* main content */}

        <div className="col mh-inherit mt-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="pos--options-container mt-1 mb-2 pt-3 ml-2 col">
              {
                authUserInfo.details.order_type_id !== null && typeof(authUserInfo.details.order_type_id) === "string" ?
                onlineOrder.current && (
                  <NavLink
                    className="pos--btn btn mr-1 mb-1 bg-success text-white"
                    to={"/dashboard/pos/online-orders"}
                  >
                    Online Odr
                    {orderCounter.online > 0 && (
                      <span className=" rounded-md special-category">
                        ({orderCounter.online})
                      </span>
                    )}
                  </NavLink>
                ):
                (
                  <NavLink
                    className="pos--btn btn mb-1 mr-1 bg-success text-white"
                    to={"/dashboard/pos/online-orders"}
                  >
                    Online Odr
                    {orderCounter.online > 0 && (
                      <span className=" rounded-md special-category">
                        ({orderCounter.online})
                      </span>
                    )}
                  </NavLink>
                )
              }
             
              {availableDeliveryTypes.length
                ? availableDeliveryTypes.map((deliveryType) => (
                    <DropdownButton
                      value={deliveryType}
                      isSelected={deliveryType.id === selectedDeliveryType?.id}
                      setSelected={setSelectedDeliveryType}
                      getLabel={(dt) => dt.name}
                      options={availableDeliveryParties[deliveryType.id]}
                      selectedSubItem={selectedParty}
                      setSelectedSubItem={setSelectedParty}
                      getSubItemLabel={(p) => p.name}
                    />
                  ))
                : null}
              {authUserInfo.details.user_type !== "call_center" && (
                <>
                 {authUserInfo.details.user_type !== "waiter" && (
                  <NavLink
                    className="pos--btn btn mb-1 mr-1 bg-success  text-white"
                    to={"/dashboard/pos/settled"}
                  >
                    Settled
                  </NavLink>
                 )} 

                  <div className="d-inline-block mb-1 btn-group">
                    <NavLink
                      className={`dropdwn--btn btn text-white bg-success text-decoration-none ${
                        runningOrders && runningOrders?.length > 0
                          ? ""
                          : "mr-2 rounded-05"
                      }`}
                      to={"/dashboard/pos/submitted"}
                    >
                      Running Odr.
                      {orderCounter.ready > 0 && (
                        <span className=" rounded-md special-category">
                          ({orderCounter.ready})
                        </span>
                      )}
                    </NavLink>
                    {runningOrders && runningOrders?.length > 0 && (
                      <button
                        className="dropdwn1--btn  btn bg-success text-white mr-1"
                        onClick={() => setEditOrder(!editOrder)}
                      >
                        <i className="fa-sharp fa fa-caret-down lg-text" />
                      </button>
                    )}
                    {editOrder && (
                      <div
                        className="pos--dropdown-list-edit"
                        ref={scrollHeight}
                      >
                        {runningOrders?.map((item, index) => (
                          <div
                            key={index}
                            className={`editOrderPos d-flex align-items-center justify-content-between mb-2 ml-2 ${
                              heightScroll?.actualHeight >=
                              heightScroll?.overflowHeight
                                ? "mr-2"
                                : ""
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                authUserInfo.permissions !== null &&
                                checkPermission(
                                  authUserInfo.permissions,
                                  "Order edit"
                                )
                              ) {
                                history.push("/dashboard/epos/" + item?.id);
                              }
                            }}
                          >
                            <div className="p-1 sm-text" style={{ width: "80%" }}>
                              <div className="editDetail">
                                #
                                {item.token?.id}
                                {item && item.table_name !== "-" &&(
                                  <span className="pl-0"> | Table : {item.table_name}</span>
                                )}
                              </div>
                              <div className="editDetail mt-1">
                                {
                                  item.order_type_name +
                                  " - " +
                                  item.party_name
                                }
                              </div>
                              <div className="editOrderBranch mt-1 sm-text">
                                {item?.branch_name}
                                <span className="d-flex mt-1">
                                <span className="editPrice">
                                  {currencySymbolLeft()}
                                  {item.total_payable}
                                  {currencySymbolRight()}
                                </span>
                                {
                                  item?.waiter_name && item?.waiter_name !== "-" && (
                                    <span className="editPrice"> <Dinner className="edit_icon"/>{item?.waiter_name}</span>
                                  )
                                }
                                </span>
                              </div>
                            </div>
                            <div className="editIcon">
                              <span className="mr-2">
                                <i className="fa fa-pencil"></i>
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
              {
                authUserInfo.details.user_type === "call_center" && parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1 ? (
                  <>
                    <CallCenterModal
                    show={modal9Show}
                    onHide={() => setModal9Show(false)}
                    modal9Show={modal9Show}
                    setModal9Show={setModal9Show}
                    setModalOrderType={setModalOrderType}
                    setOrderDetails={setOrderDetails}
                    orderDetails={orderDetails}
                    selectedParty={selectedParty}
                    checkDelivery={selectedDeliveryType && selectedDeliveryType?.name.toLowerCase() === "delivery"}
                    newSettings={newSettings}
                    handleSetBranch={handleSetBranch}
                    zoneArea={zoneArea}
                    setZoneArea={setZoneArea}
                    area={area}
                    setArea={setArea}
                    setDeliveryCharges={setDeliveryCharges}
                    selectedBranch={selectedBranch}
                    workPeriodScreen={workPeriodScreen}
                    />
                    <CallCenterOrdertype 
                    show={modalOrderType}
                    onHide={() => setModalOrderType(false)}
                    modalOrderType={modalOrderType}
                    setModalOrderType={setModalOrderType}
                    setModal9Show={setModal9Show}
                    selectedParty={selectedParty}
                    selectedDeliveryType={selectedDeliveryType}
                    availableDeliveryTypes={availableDeliveryTypes}
                    setSelectedDeliveryType={setSelectedDeliveryType}
                    setSelectedParty={setSelectedParty}
                    availableDeliveryParties={availableDeliveryParties}
                    />
                  </>

                ):(
                  <CustomerModal
                    show={modal9Show}
                    onHide={() => setModal9Show(false)}
                    modal9Show={modal9Show}
                    setModal9Show={setModal9Show}
                    setOrderDetails={setOrderDetails}
                    orderDetails={orderDetails}
                    selectedParty={selectedParty}
                    checkDelivery={selectedDeliveryType && selectedDeliveryType?.name.toLowerCase() === "delivery"}
                  />
                )
              }
              
              <Button
                className="pos--btn mb-1 btn bg2-success xlg-text pos--icon-btn text-white border-0 mr-1"
                onClick={() =>
                  selectedParty
                    ? setModal9Show(true)
                    : toast.error("Please select party type")
                }
              >
                <i className="fa fa-user-plus new-btn"></i>
              </Button>
              {authUserInfo.details.user_type !== "call_center" && (
                <span>
                  {parseInt(
                    getSystemSettings(generalSettings, "table_waiter")
                  ) === 1 ? (
                    selectedDeliveryType &&
                    selectedDeliveryType.name.match(/dine(.*)in/i) &&
                    parseInt(
                      getSystemSettings(generalSettings, "table_waiter")
                    ) === 1 ? (
                      <Button
                        className="pos--btn btn mb-1 mr-1 bg2-success xlg-text pos--icon-btn text-white border-0"
                        onClick={() =>
                          selectedDeliveryType &&
                          selectedDeliveryType.name.match(/dine(.*)in/i)
                            ? setModal10Show(true)
                            : toast.error("Please select Dine In ")
                        }
                      >
                        <i className="fa fa-address-card new-btn"></i>
                      </Button>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </span>
              )}
              {
                parseInt(getSystemSettings(generalSettings, "foodPanda_integration")) === 1 && authUserInfo.details.user_type !== "waiter" && (
                <NavLink 
                  ref={taxHeight}
                  className="btn foodPanda-btn text-white mr-1 mb-1"
                  to={"/dashboard/pos/foodpanda"}
                >
                  <span className="d-flex align-items-center">
                    <FP  className="foodPandabtn mr-1"/>
                    Food Panda 
                    {orderCounter.foodPanda > 0 && (
                      <span className=" pl-1 rounded-md special-category">
                        ({orderCounter.foodPanda})
                      </span>
                    )}
                  </span>
                </NavLink>
                )
              }
              
              <Button className="pos--btn btn bg2-success xlg-text pos--icon-btn text-white border-0 mr-1 mb-1" onClick={()=>setItemImage(!itemImage)}>
               {
                itemImage ? 
                <i className="fa fa-th-list" aria-hidden="true"></i>
                :
                <i className="fa fa-th" aria-hidden="true"></i>
                }
              </Button>               

              <TableModal
                show={modal10Show}
                onHide={() => setModal10Show(false)}
                modal10Show={modal10Show}
                setModal10Show={setModal10Show}
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                selectedDeliveryType={selectedDeliveryType}
                selectedParty={selectedParty}
                selectedDeptTag={selectedDeptTag}
                selectTable={selectTable}
                setSelectTable={setSelectTable}
                tableId={tableId}
                setTableId={setTableId}
                guestSelected={guestSelected}
                setGuestSelected={setGuestSelected}
                buttonDisabled={buttonDisabled}
              />
            </div>
            {/* Branch select Dropdown*/}
            <div className="col-2 mr-2 mt-2 mb-2 pt-3">
              {authUserInfo.details &&
                (authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter" ) && (
                  <Select
                    options={branchForSearch && branchForSearch}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={selectedBranch}
                    onChange={(branch) => handleSetBranch(branch)}
                    classNamePrefix="Branch"
                    className="sm-text"
                    maxMenuHeight="13rem"
                    placeholder="Branch"
                    styles={customStyle}
                  />
                )}
              {authUserInfo.details &&
                (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager" || authUserInfo.details.user_type === "waiter") && (
                  <div className="user_branch_name  text-right">
                    {orderDetails?.branch?.name}{" "}
                  </div>
                )}
            </div>
          </div>

          {/* main container */}
          <div className="row pos--main-content-wrapper">
            <div
              className="col d-flex flex-row pt-1 p-3 pr-4 menu-left"
              data-simplebar
            >
              <div className="pos--menu-container row m-0 flex-1">
                <div className="col flex-grow-1 mh-100 p-1">
                  <div className="pos--menu-items-conatiner row m-0 flex-wrap">
                  {!searchedFoodItem.searched
                    ? menu &&
                    orderDetails.branch &&
                        selectedDeptTag &&
                        activeFoodGroup &&
                        menu[selectedDeptTag.id] &&
                        menu[selectedDeptTag.id][activeFoodGroup]
                        ? menu[selectedDeptTag.id][activeFoodGroup]?.map(
                            (foodItem) => (
                              <div
                              ref={flyStart}
                              key={foodItem.id}
                              title={foodItem?.item_description}
                              className={`font-weight-bold pos--menu-item rounded-05 mr-2 mb-2 
                              ${generalSettings && parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1 && (foodItem?.stock_qty <= 0 || foodItem?.stock_qty == undefined) && foodItem?.is_deal === 0 && "disabled"}`}
                              onClick={(e) =>{
                                if(!buttonDisabled){
                                  handleFoodItemClicked(foodItem,e)
                                }
                               }}
                              >
                              
                                <figure className="position-relative w-100">
                                  { generalSettings &&
                                    parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1 && foodItem?.is_deal === 0 && (
                                      foodItem?.stock_qty <= 0 || foodItem?.stock_qty == undefined ?
                                      <div className= "position-absolute stockQty stock-bg-danger"> 
                                        Out of stock
                                      </div>
                                      :
                                      <div className= "position-absolute stockQty stock-bg"> 
                                        Qty : {" " + foodItem?.stock_qty}
                                      </div>
                                    )
                                  }
                                  <img
                                    src={ foodItem.image || defaultImg }
                                    alt=""
                                    className={`img-fluid item-img ${itemImage ? "d-none" : ""}`}
                                    loading="lazy"
                                  />
                                  <figcaption className={`item-name text-center px-2 ${itemImage ? "mt-4 mb-0" : "mt-2"}`}>
                                    {foodItem.name}
                                  </figcaption>
                                </figure>
                              </div>
                            )
                          )
                        : null
                      : menu &&
                        orderDetails.branch &&
                        selectedDeptTag &&
                        activeFoodGroup &&
                        menu[selectedDeptTag.id] &&
                        menu[selectedDeptTag.id][activeFoodGroup]
                      ? searchedFoodItem.list?.map((foodItem) => (
                          <div
                            ref={flyStart}
                           title={foodItem?.item_description}
                            key={foodItem.id}
                            className={`font-weight-bold pos--menu-item rounded-05 mr-2 mb-2 
                            ${generalSettings && parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1  && (foodItem?.stock_qty <= 0 || foodItem?.stock_qty == undefined) && foodItem?.is_deal === 0 && "disabled"}`}
                            onClick={(e) =>{
                              if(!buttonDisabled){
                               handleFoodItemClicked(foodItem,e)
                              }
                            }}
                          >
                         
                            <figure className="position-relative w-100">
                              { generalSettings &&
                                parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1 && foodItem?.is_deal === 0 && (
                                  foodItem?.stock_qty <= 0 || foodItem?.stock_qty == undefined ?
                                  <div className= "position-absolute stockQty stock-bg-danger"> 
                                    Out of stock
                                  </div>
                                  :
                                  <div className= "position-absolute stockQty stock-bg"> 
                                    Qty : {" " + foodItem?.stock_qty}
                                  </div>
                                )
                              }
                              <img
                                src={ foodItem.image || defaultImg }
                                alt=""
                                className={`img-fluid item-img ${itemImage ? "d-none" : ""}`}
                                loading="lazy"
                              />
                              <figcaption className={`item-name text-center px-2 ${itemImage ? "mt-4 mb-0" : "mt-2"}`}>
                                {foodItem.name}
                              </figcaption>
                            </figure>
                          </div>
                        ))
                      : null}
                    <ItemModal
                      closeModal={handleCloseItemModal}
                      showModal={showModal}
                      itemData={selectedFoodItem}
                      itemId={selectedFoodItemId}
                      addToCart={addToCart}
                      buttonDisabled={buttonDisabled}
                      menuWisePrice = {menuWisePrice}
                      menuId = {selectedDeptTag?.id}
                    />
                  </div>
                </div>
              </div>
            </div>
            {((parseInt(getSystemSettings(generalSettings, "call_center_enable")) === 1 ? authUserInfo.details.user_type !== "call_center" : true) && ((newSettings && newSettings.workPeriod === null) || workPeriodScreen == 0)) && (
              <div className="fk-overlay">
                <div className="fk-left-overlay__content text-center m-auto">
                  <h6
                    className={`text-primary text-uppercase ${
                      authUserInfo.details &&
                      (authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter") &&
                      "mb-0"
                    }`}
                  >
                    
                    {workPeriodScreen == 1 ? authUserInfo.details &&
                    (authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter")
                      ? _t(_t("Select branch to active POS"))
                      : _t(_t("start workperiod")) : _t(_t("Close previous workperiod")) }
                  </h6>
                  <div className="m-auto col-8 mt-3">
                    {authUserInfo.details &&
                      (authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter") && (
                        <Select
                          options={branchForSearch && branchForSearch}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={selectedBranch}
                          onChange={(branch) => handleSetBranch(branch)}
                          classNamePrefix="Branch"
                          className="sm-text"
                          maxMenuHeight="13rem"
                          placeholder="Branch"
                          styles={customStyle}
                        />
                      )}
                  </div>
                  {authUserInfo.details &&
                    (authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter") && (
                      <>
                        <h4 className="mt-2 text-uppercase new-sm-text mb-2">
                          {_t(_t("Start workperiod if it is not started"))}
                        </h4>
                      </>
                    )}
                  <NavLink
                    to="/dashboard/work-periods"
                    className="t-heading-font btn btn-success btn-sm text-uppercase sm-text"
                  >
                    {_t(_t("Start Your Work Period"))}
                  </NavLink>
                </div>
              </div>
            )}

            <div className="col-5 pt-2 py-5 pr-cart bill-right">
              <div className="pos--bill-container rounded-25 w-80 d-flex flex-column pb-2">
                <div className="cart-1">
                  <div className="text-center bussines-day font-weight-md btn-primary rounded-top"> Business Day: {newSettings?.workPeriod && newSettings.workPeriod?.date}</div>
                  <h6 className="p-1 px-2 mb-0 font-weight-bold border-bottom d-flex justify-content-between">
                    {selectedDeliveryType &&
                      `${
                        selectedDeliveryType
                          ? selectedDeliveryType.name + " "
                          : ""
                      } - `}
                    {selectedParty ? selectedParty.name : ""}
                    <h6
                      className="m-0 ml-2 text-capitalize"
                      style={{ fontSize: "1rem" }}
                    >
                      {orderDetails.token && orderDetails
                        ? getSystemSettings(generalSettings, "token_text")?.slice(0,5) + " # " + orderDetails.token.id
                        : ""}
                    </h6>
                  </h6>
                </div>

                <div className="flex-grow-1 container">
                  <div className="px-1 d-flex flex-row justify-content-between border-bottom bill-list">
                    <span className="text-capitalize total-bill d-inline-block fw-normal col-5">
                      <div>Food Item</div>
                    </span>

                    <span className="text-capitalize total-bill d-inline-block col-3 text-center pr-1">
                      <div>Qty</div>
                    </span>
                    <span className="pr-1 text-capitalize sm-text d-inline-block col-4 text-right">
                      <div>Price</div>
                    </span>
                  </div>
                  {/* <div className="simplebar-scrollbar"> */}
                  <div className="items-wrap ">
                    {cartItems && Object.keys(cartItems).length
                      ? Object.keys(cartItems)
                          .sort((a, b) =>
                            cartItems[a].seq < cartItems[b].seq ? 1 : -1
                          )
                          .map((itemHash) => {
                            return(
                            <div key = {itemHash} ref={(e)=> cardAnimate.current[itemHash] = e} className="cart-item-wrapper"
                            >
                            <CartItem
                              itemId={itemHash}
                              animate={animate}
                              item={cartItems[itemHash]}
                              deleteItem={deleteCartItem}
                              editItem={editCartItem}
                              addToCart={addToCart}
                              deleteOneCartItem={deleteOneCartItem}
                              setAddReasonModalShowing={setAddReasonModalShowing}
                              setCommentId={setCommentId}
                              orderScheme = {orderDetails.scheme}
                              deletedItemsOnEdit = {deletedItemsOnEdit}
                              handleSetItemQty={handleSetItemQty}
                              itemAdd={itemAdd}
                              authenticateStaffUser={authenticateStaffUser}
                              isOpenQty = {parseInt(getSystemSettings(generalSettings,"open_qty_box")) === 1}
                              isLocked={isLocked}
                              getPrice={(p) =>
                                getFormattedPrice(
                                  p.after_tax_price ==
                                    getBeforeTaxPrice(
                                      calculatePriceForCartItem(p)
                                    )
                                    ? p.after_tax_price
                                    : getBeforeTaxPrice(
                                        calculatePriceForCartItem(p)
                                      )
                                )
                              }
                            />
                            </div>
                          )})
                      : null}

                       {cartItems && Object.keys(cartItems).length && commentId ? (
                          <InstructionModal
                           cartItems={cartItems}
                           updateItemImage={updateItemImage}
                           animate={animate}
                           commentId={commentId}
                           setCommentId={setCommentId}
                           updateComments={updateComments}
                          />
                        ) : null
                      }
                  </div>
                </div>
                {/* </div> */}

                <div className="col-12 p-0 m-0 t mt-10">
                  <div className="">
                    <div className="container 2">
                      <div className="row  border-bottom p-1 px-2">
                        {/*Subtotal*/}
                        <div className="col-6">
                          <div className=" d-flex flex-row justify-content-between">
                            <span className="text-capitalize d-inline-block total-bill">
                              SubTotal
                            </span>
                            <span className="text-capitalize total-bill d-inline-block font-weight-bold text-right">
                             
                              {currencySymbolLeft()}
                              {cartSubTotal}
                              {currencySymbolRight()}
                           
                            </span>
                          </div>
                        </div>
                        {/*Tax*/}
                        <div className="col-6">
                          <div className="d-flex flex-row justify-content-evenly">
                            <span className="text-capitalize total-bill d-inline-block col-6 position-relative">
                              Tax
                              <span className=" text-capitalize total-bill text-center font-weight-bold text-right ">
                                ({theTax && theTax + "%"})
                              </span>
                               {
                                parseInt(
                                  getSystemSettings(
                                    generalSettings,
                                    "payment_type_tax_calculation"
                                  )
                                ) === 1 && (
                                  <TriangleDown  className="dashboard_card_down cursor-pointer" onClick={()=> setPaymentTypeModalState({
                                    ...paymentTypeModalState,
                                    taxCalSelect : !paymentTypeModalState.taxCalSelect
                                  })}/>
                                )
                                }
                              {paymentTypeModalState.taxCalSelect === true && (
                              <div className={`taxSelect 
                                ${taxScroll.current?.actualHeight >=
                                taxScroll.current?.overflowHeight ? 
                                "pr-1" : ""}`}
                              > 
                              {
                               paymentTypeAvailable &&
                               paymentTypeAvailable?.map((groupItem, groupIndex)=>{
                                 return (
                                   <span className="m-0 p-0 total-bill" key={groupIndex}  onClick={() => {
                                    handleTaxOnPaymentTypeChange(groupItem)
                                   }}>
                                    <li className={`list-unstyled dashboard_border p-1 cursor-pointer ${groupIndex == (paymentTypeAvailable?.length - 1) ? "mb-0" : "mb-1"}`}>{groupItem.name}</li>
                                   </span>
                                 )
                               })
                              }
                             </div>
                             )}
                            </span>
                            <span className="text-capitalize total-bill d-inline-block font-weight-bold col-6 text-right pl-4">
                              <>
                                {theTax &&
                                  currencySymbolLeft() +
                                    theVat +
                                    currencySymbolRight()}
                              </>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Input tags */}
                    <div className="input_fields d-flex flex-row flex-wrap align-items-center justify-content-between my-1 mx-1 px-2">
                      <div className="col-4 my-1 d-flex align-items-center">
                        <label className="total-bill col-5 font-weight-bold mr-1 pl-2" htmlFor="delivery">
                          D.C:
                        </label>
                        <input
                          id="delivery"
                          disabled={!(selectedDeliveryType && (selectedDeliveryType.name.toLowerCase() === "delivery") && parseInt(getSystemSettings(generalSettings, "delivery_charges_lock")) === 0)}
                          class="form-control cartInput rounded-25"
                          type="number"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onWheel={(e) => e.target.blur()}  
                          min="0"
                          value={deliveryCharges}
                          onChange={(e) => setDeliveryCharges(e.target.value)}
                        />
                      </div>
                      <div className="col-4 px-2 my-1">
                        <span className="total-bill text-center d-flex flex-row">
                          <span className=" font-weight-bold mr-1">
                            S.C(
                            {orderDetails?.branch &&
                              orderDetails.branch?.service_fees}
                            %):
                          </span>
                          <span>{currencySymbolLeft()}{serviceCharges.toFixed(2)}{currencySymbolRight()}</span>
                        </span>
                      </div>
                      <div className="col-4 my-1 d-flex align-items-center">
                        <label className={`total-bill ${ orderDetails.scheme.open_discount ? "col-6" : "col-5"} d-flex align-items-center font-weight-bold mr-1 text-center`}>
                          Dis:
                          {
                            orderDetails.scheme.open_discount ? (
                              <input
                                title="Discount percent"
                                class="ml-1 form-control cartInput rounded-25 "
                                type="number"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}  
                                min="0"
                                max="100"
                                placeholder="%"
                                value={orderDetails.scheme.how_much_discount || ""}
                                onChange={(e) => hanldeDiscountPercent(cartSubTotal, e.target.value)}
                              />
                            ) : ""
                          }
                        </label>
                        <input
                          disabled={ newOrder?.length === 0 || (orderDetails.scheme.open_discount ? orderDetails.scheme.how_much_discount : !orderDetails.scheme?.open_discount) }
                          class="form-control cartInput rounded-25 "
                          type="number"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onWheel={(e) => e.target.blur()}  
                          min="0"
                          placeholder="Discount"
                          value={orderDetails.scheme.discount_amount}
                          onChange={(e) => {
                            if(parseFloat(e.target.value) > parseFloat(cartSubTotal)){
                              toast.error(
                                `${_t(t("You can't type discount greater than bill amount"))}`,
                                toastObj
                              )
                            }else{
                              setOrderDetails({
                                ...orderDetails,
                                scheme:{
                                  ...orderDetails.scheme,
                                  discount_amount: e.target.value
                                }
                              });
                              calculateTax(cartSubTotal, e.target.value)
                            }}
                          }
                        />
                      </div>
                    </div>
                    <div className="mt-1 container">
                      <div className="row justify-content-between mb-1">
                        <div className="col-1 d-flex align-items-center">
                          {isLocked("offer_cp") &&
                          parseInt(
                            getSystemSettings(
                              generalSettings,
                              "offers_lock_status"
                            )
                          ) === 1 ? (
                            <div
                              className="cursor-pointer mx-1 d-flex align-items-center"
                              onClick={(e) => authenticateStaffUser("offer_cp")}
                            >
                              <i className="fa fa-lock lock_icon"></i>
                            </div>
                          ) : (
                            <div className="text-success mx-1 d-flex align-items-center">
                              <i className="las la-lock-open lock_icon"></i>
                            </div>
                          )}
                        </div>
                        <div className="col-5">
                          {tempSchemeForSearch !== null &&
                          tempSchemeForSearch?.length !== 0 ? (
                            <span className="position-relative offer-tip">
                              <span
                                className={`${
                                  isLocked("offer_cp") &&
                                  parseInt(
                                    getSystemSettings(
                                      generalSettings,
                                      "offers_lock_status"
                                    )
                                  ) === 1
                                    ? "tool-tip"
                                    : "disable-tip"
                                }`}
                              >
                                please unlock offer
                              </span>
                              <Select
                                isDisabled={
                                  (isLocked("offer_cp") &&
                                  parseInt(
                                    getSystemSettings(
                                      generalSettings,
                                      "offers_lock_status"
                                    )
                                  ) === 1) || buttonDisabled
                                }
                                isSearchable={true}
                                options={tempSchemeForSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={
                                  orderDetails.scheme?.scheme_id
                                    ? schemeForSearchObj[
                                        orderDetails.scheme?.scheme_id
                                      ]
                                    : null
                                }
                                onChange={(offer) => handleSetOffer(offer)}
                                classNamePrefix="Offers"
                                className="sm-text rounded-25 cursor-pointer"
                                menuPlacement="top"
                                maxMenuHeight="13rem"
                                placeholder="Offers"
                                styles={customStyle}
                              />
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                        <div className="col-6 d-flex justify-content-end print-cancel-row align-items-center">
                          <span className="mr-2 font-weight-bold bill-total">
                            Total Bill:
                          </span>
                          <span className="font-weight-bold mr-1 bill-total">
                            <>
                              {currencySymbolLeft()}
                              {cartTotal}
                              {currencySymbolRight()}
                            </>
                          </span>
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-between align-items-center">
                        <div className="col-7 row">
                          <div className="mr-1 col-6">
                             <button
                              type="button"
                              className="w-100 btn btn-success xsm-text text-uppercase col-3"
                            >
                              Print Bill
                            </button>
                          </div>

                          <div className="col-4 m-0 p-0 cancel-btn">
                            <button
                              type="button"
                              className="w-100 btn btn-success2 xsm-text text-uppercase col-6"
                              onClick={emptyCard}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div> */}

                      <div className="row mt-2 mb-3">
                        <div className={`${authUserInfo.details.user_type === "waiter" ? "col-5" : "col-7"} d-flex align-items-center align-items-center`}>
                          {authUserInfo.details.user_type === "call_center" && (
                            <>
                              <div
                                aria-label="payment Type"
                                className="indolj-switcher-container mx-2"
                              >
                                {paymentOptions.map((paymentOption) =>
                                  (
                                    <div
                                      className={`switch ${
                                        callCenterPayment.payment_type == paymentOption
                                          ? "active-switch"
                                          : ""
                                      }`}
                                      key={paymentOption}
                                      ref={(e) =>
                                        callCenterPayment.payment_type == paymentOption
                                          ? currentNavPill(e)
                                          : null
                                      }
                                      onClick={() =>
                                        handlePaymentType(paymentOption)
                                      }
                                    >
                                      {paymentOption}
                                    </div>
                                  )
                                )}
                                <div className="driveActive"></div>
                              </div>
                            </>
                          )}
                           <div className=" ml-1 fk-calculator-container calculator-btn ">
                             {
                              /*
                              <button
                                className="fa fa-calculator btn btn-info text-uppercase w-100 h-1000 rounded-25"
                                onClick={() => setCalculatorMenu(!calculatorMenu)}
                              ></button>
                              <div
                                className={`calculator ${
                                  calculatorMenu && "active"
                                }`}
                              >
                                <Calculator />
                              </div>
                            */
                             }  
                                <button
                                  title="Rider Note"
                                  disabled={buttonDisabled || (selectedDeliveryType &&
                                    selectedDeliveryType?.name?.match(
                                      /dine(.*)in/i
                                    ))}
                                  className=" btn btn-primary new-sm-text text-uppercase rounded-25 d-flex align-items-center py-1 px-2"
                                  onClick={(e) => newOrder && newOrder.length > 0
                                      && setNoteRiderModal(true)
                                  }
                                >
                                 <img src="/assets/img/writing.png" style={{height:"1.25rem"}}/>
                                </button>
                              <div>
                              </div>
                            </div>

                          {/*authUserInfo.details.user_type !== "call_center" && (
                            <div className=" m-0 p-0 cash d-flex justify-content-start">
                              <button
                                disabled={buttonDisabled}
                                className="btn btn-info sm-text text-uppercase font-weight-bold rounded-25"
                                type="button"
                                data-toggle="modal"
                                data-target={`${
                                  orderDetails.branch && newSettings?.workPeriod
                                    ? "#cashModal"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  !orderDetails.branch &&
                                  toast.error(
                                    `${_t(t("You have to select a branch"))}`,
                                    toastObj
                                  )
                                }
                              >
                                cash{" "}
                              </button>
                            </div>
                              )*/}
                          
                          {authUserInfo.permissions !== null &&
                            checkPermission(
                              authUserInfo.permissions,
                              "Submit"
                            ) && authUserInfo.details.user_type !== "call_center" ? (
                              <>
                                <div className="ml-1 d-flex justify-content-start">
                                  <button
                                    disabled={buttonDisabled}
                                    className=" btn btn-success new-sm-text text-uppercase rounded-25"
                                    onClick={(e) => setBackModal(true)}
                                  >
                                    Back
                                  </button>
                                </div>
                                <button
                                  disabled={buttonDisabled}
                                  type="button"
                                  className="w-15 ml-1 btn btn-danger new-sm-text d-flex align-items-center justify-content-center text-uppercase col-3 rounded-25 font-weight-bold hover-effect"
                                  onClick={(e) => {
                                    order_hold.current = 1;
                                    !loading && handleSubmitOrder(e)
                                  }}
                                >
                                  {!loading ?
                                      _t(_t("hold"))
                                    :  _t(_t("Please wait"))}
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                            {
                              navigator.onLine && authUserInfo.details.user_type !== "waiter" && (
                                <button
                                  disabled={buttonDisabled || syncOrder}
                                  type="button"
                                  style={{width:"2.9rem"}}
                                  className=" ml-1 btn btn-secondary new-sm-text d-flex align-items-center justify-content-center text-uppercase col-2 rounded-25 font-weight-bold hover-effect mr-1"
                                  onClick={(e) => !syncOrder && syncLocalOrders("sync")}
                                >
                                  Sync
                                </button>
                              )
                            }
                        </div>
                        <div className={`${authUserInfo.details.user_type === "waiter" ? "col-7" : "col-5"} d-flex justify-content-end`}>
                          {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "Settle"
                          ) ? (
                            !order_id && authUserInfo.details.user_type !== "call_center" ? (
                              <button
                                disabled={
                                  (selectedDeliveryType &&
                                  selectedDeliveryType?.name?.match(/dine(.*)in/i) &&
                                  parseInt(getSystemSettings(generalSettings,"table_waiter")) === 1) ||
                                  buttonDisabled
                                }
                                type="button"
                                className="mr-1 w-15 btn btn-success3 d-flex align-items-center justify-content-center total-bill text-uppercase col-6 rounded-25 font-weight-bold hover-effect"
                                onClick={handleSettleOrderButton}
                              >
                                {!loading ? "settle" : "Please wait"}
                              </button>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                         
                          {authUserInfo.permissions !== null && checkPermission(authUserInfo.permissions,"Submit") ? (
                            <button
                              disabled={buttonDisabled}
                              type="button"
                              className="w-15 btn btn-primary total-bill d-flex align-items-center justify-content-center text-uppercase col-6 rounded-25 font-weight-bold hover-effect mr-1"
                              onClick={(e)=> !loading && handleSubmitOrder(e)}
                            >
                              {!loading
                                ? _t(_t("submit"))
                                :  _t(_t("Please wait"))}
                            </button>
                          ) : (
                            ""
                          )}
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SettleOrder
        managerId={managerId}
        showSettle={showSettle}
        setSelectedKot={setSelectedKot}
        setShowSettle={setShowSettle}
        selectedDeptTag={selectedDeptTag}
        selectedParty={selectedParty}
        selectedDeliveryType={selectedDeliveryType}
        setSelectedDeliveryType={setSelectedDeliveryType}
        buttonDisabled={buttonDisabled}
        setButtonDisabled={setButtonDisabled}
        newOrder={newOrder}
        cartItems={cartItems}
        cartTotal={cartTotal}
        selectedBranch={selectedBranch}
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
        deliveryCharges={deliveryCharges}
        showPriceOfEachOrderItem={(p) =>
          getFormattedPrice(
            p.after_tax_price
              ? p.after_tax_price
              : getBeforeTaxPrice(calculatePriceForCartItem(p))
          )
        }
        theVatSettle={theVatSettle}
        setTheVatSettle={setTheVatSettle}
        setPaidMoney={setPaidMoney}
        setNewSettings={setNewSettings}
        newSettings={newSettings}
        totalsettle={totalsettle}
        setTotalSettle={setTotalSettle}
        paidMoney={paidMoney}
        serviceCharges={serviceCharges}
        cartSubTotal={cartSubTotal}
        deptCommission={deptCommission}
        tokenNumber={tokenNumber}
        theVat={theVat}
        theTax={theTax}
        taxType={taxType}
        setTheVat={setTheVat}
        returnMoneyUsd={returnMoneyUsd}
        setReturnMoneyUsd={setReturnMoneyUsd}
        isSettle={isSettle}
        getPrice={(p) => getFormattedPrice(getBeforeTaxPrice(p))}
        handleLocalPrint={handleLocalPrint}
        handleReceiptPrint={handleReceiptPrint}
        handleKotItems={handleKotItems}
        paymentTypeAvailable={paymentTypeAvailable}
        fbrInvoiceNum = {fbrInvoiceNum}
        srbInvoiceNum = {srbInvoiceNum}
        token_No={token_No}
      />

      <OrderPrint
        newOrder={newOrder}
        setNewOrder={setNewOrder}
        foodGroupsToPrint={foodGroupsToPrint}
        cartSubTotal={cartSubTotal}
        cartTotal={cartTotal}
        newSettings={newSettings}
        con_Kot={con_Kot}
        totalSettle={totalsettle}
        theVat={theVat}
        theVatSettle={theVatSettle}
        paidMoney={paidMoney}
        orderDetails={orderDetails}
        taxType={taxType}
        returnMoneyUsd={returnMoneyUsd}
        isSettle={isSettle.current}
        srbInvoiceNum={srbInvoiceNum.current}
        fbrInvoiceNum={fbrInvoiceNum.current}
        serviceCharges={serviceCharges}
        deliveryCharges={deliveryCharges}
        selectedDeliveryType={selectedDeliveryType}
        selectedParty={selectedParty}
        selectedDeptTag={selectedDeptTag}
        customerPrint={componentRef}
        component2Ref={component2Ref}
      />
    
      {/* Authenticate Modal */}
      {showPasswordPopup ? (
        <PasscodeAuthenticator
          correctPasscode={authUserInfo.details.user_passcode}
          managerPass = {managerPasscode}
          setComponentUnlock={setComponentUnlock}
          setManagerId = {setManagerId}
          thisComponent={componentUnlock}
          onSuccess={onSuccessAuthenticationStaff}
          show={showPasswordPopup}
          hanleCancelAuthenticate={hanleCancelAuthenticate}
        />
      ) : null}

      <PosModal
        handleCardSubmit={handleCardSubmit}
        setOrderDetails={setOrderDetails}
        orderDetails={orderDetails}
        cardModal={cardModal}
        backModal={backModal}
        setBackModal={setBackModal}
        emptyCard={emptyCard}
        setButtonDisabled={setButtonDisabled}
        cashDetails={cashDetails}
        setCashDetails={setCashDetails}
        noteRiderModal={noteRiderModal}
        setNoteRiderModal={setNoteRiderModal}
      />
      <CustomPosModal
        addReasonOnSubmit={addReasonOnSubmit}
        setAddReasonModalShowing={setAddReasonModalShowing}
        addReasonModalShowing={addReasonModalShowing}
        deleteReason={deleteReason}
        setDeleteReason={setDeleteReason}
        setPaymentTypeModalState={setPaymentTypeModalState}
        paymentTypeModalState={paymentTypeModalState}
        callCenterPayment={callCenterPayment}
        setCallCenterPayment={setCallCenterPayment}
      />
      {/* beep sound */}
      <audio id="myAudio">
        <source src="/assets/beep/beep.mp3" type="audio/mpeg" />
      </audio>

      <audio id="myAudioNew">
        <source src="/assets/beep/beep2.mp3" type="audio/mpeg" />
      </audio>

    </>
  );
}

export default Pos;
